.virtual-terminal {
	#credit-card-submit {
		height: 50px !important;
	}
	#credit-card-card-holder,
	#credit-card-card-number,
	#credit-card-card-expiration,
	#credit-card-card-cvv {
	    iframe {
	    	width: 100%;
	    }
	}
	.form-group {
		.dv-paymentlink-btns {
			#credit-card-submit {
				iframe {
					width: 100%;
				}
			}
		}
	}
}