.gu-unselectable {
	scroll-behavior: auto;
	.gu-mirror {
		scroll-behavior: auto;
		* {
		  display: none;
		}
	}
}
.recurring-configuration {
  .e-spin-up {
    &::before {
      display: none;
    }
    &::after {
      display: inline-block;
      content: "";
      border-bottom: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-top: 0;
      border-left: 0.5em solid transparent;
    }
  }
  #RecurrenceEditor {
    .e-end-on-date {
      display: none;
    }
  }
}

.formio-component-key {
  .col-form-label {
    color: #fff;
    position: relative;
    font-size: 0;
    &::before {
      content: "Field Name";
      color: #5c6874;
      font-size: 12px;
    }
    svg {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

.invoice-recurring-payment {
  .e-spin-up {
    &::before {
      display: none;
    }
    &::after {
      display: inline-block;
      content: "";
      border-bottom: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-top: 0;
      border-left: 0.5em solid transparent;
    }
  }
}
.formio {
	.builder-sidebar {
		.card {
			margin-bottom: 15px;
    		border: 0;
    		.card-header {
			    border-bottom: 0;
			}
    		.card-body {
    			.btn-block {
				    justify-content: flex-start;
				    border-radius: 6px !important;
				    border: 0;
				    margin-bottom: 5px;
				    padding: 0 25px;
    			}
    		}
		}
	}
}

.formio-dialog {
	.formio-component {
		.ck-editor {
			.ck-sticky-panel {
				.ck-toolbar__items {
					.ck-file-dialog-button,
					.ck-dropdown {
					    display: none !important;
					}
					.ck.ck-dropdown.ck-heading-dropdown,
					.ck.ck-dropdown.ck-font-family-dropdown,
					.ck.ck-dropdown.ck-font-size-dropdown {
					    display: inline-block !important;
					}
				}
			}
			.ck-content {
				ul {
			      	padding: 0 0 0 30px;
			      	li {
			      		list-style: initial;
			      	}
			    }
		      	ol {
				    padding: 0 0 0 30px;
				}
				dl {
				    padding: 0 0 0 20px;
				}
			}
		}
	    &.formio-component-datagrid {
	      .table {
	        tbody {
	          tr {
	            td {
	              &:first-child {
	                position: relative;
	                width: 65px;
	                &::before {
	                    content: "=";
					    font-size: 25px;
					    padding: 0px 13px 0;
					    line-height: 1.6;
					    color: #fff;
					    position: absolute;
					    background: #6c757d;
					    border-radius: 50px;
	                }
	                svg {
	                  	padding: 19px 20px;
					    border: 0;
					    position: absolute;
	                }
	              }
	            }
	          }
	        }
	      }
	    }
	    &.formio-component-customConditionalPanel {
	      .card-title {
	        position: relative;
	        vertical-align: middle;
	        &::before {
	          content: "\229E";
	          color: #fff;
	          font-size: 26px;
	          background: #6c757d;
	          border-radius: 3px;
	          padding: 1px 11px 5px;
	          top: 0;
	          left: 26px;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    &.formio-component-overlay {
	      .card-title {
	        position: relative;
	        &::before {
	          content: "\229E";
	          color: #666;
	          font-size: 20px;
	          line-height: 1em;
	          text-align: center;
	          vertical-align: bottom;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    &.formio-component-custom-validation-js {
	      .card-title {
	        position: relative;
	        &::before {
	          content: "\229E";
	          color: #666;
	          font-size: 20px;
	          line-height: 1em;
	          text-align: center;
	          vertical-align: bottom;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    &.formio-component-json-validation-json {
	      .card-title {
	        position: relative;
	        &::before {
	          content: "\229E";
	          color: #666;
	          font-size: 20px;
	          line-height: 1em;
	          text-align: center;
	          vertical-align: bottom;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    &.formio-component-customDefaultValuePanel {
	      .card-title {
	        position: relative;
	        &::before {
	          content: "\229E";
	          color: #666;
	          font-size: 20px;
	          line-height: 1em;
	          text-align: center;
	          vertical-align: bottom;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    &.formio-component-calculateValuePanel {
	      .card-title {
	        position: relative;
	        &::before {
	          content: "\229E";
	          color: #666;
	          font-size: 20px;
	          line-height: 1em;
	          text-align: center;
	          vertical-align: bottom;
	        }
	        svg {
	          display: none;
	        }
	      }
	    }
	    .formio-button-remove-row {
	      position: relative;
	      padding: 5px 11px;
	      &::before {
	        content: "\2716";
	        color: #fff;
	        font-size: 20px;
	        line-height: 1.2em;
	        text-align: center;
	        vertical-align: text-top;
	      }
	      svg {
	        display: none;
	      }
	    }
	    table {
	      tbody {
	        td {
	          svg {
	            path {
	              display: none;
	            }
	          }
	        }
	      }
	    }
	}
	.component-edit-container {
		.form-check-input {
		    margin-top: 0.1rem;
		}
		.formio-component {
			.choices__list--dropdown {
		    	.choices__list {
				    position: relative;
				}
			}
		}
		.choices__list--dropdown {
		    width: 250px;
	    	.choices__list {
	    		background: #fff;
			    position: fixed;
			    width: 250px;
			    margin-top: -10px;
			}
		}
		.row {
			&:first-child {
				.col-sm-6 {
					&:nth-child(2) {
			            > div {
			              margin-right: 40px !important;
			              margin-top: 5px !important;
			            }
			        }
					&:last-child {
						display: none;
					}
				}
			}
			&:last-child {
				.col-sm-6 {
					&:last-child {
						.preview-panel {
							display: block;
						}
						> div {
							display: inline-flex;
						}
					}
				}
				.col-sm-12 {
					> div {
						&:last-child {
							display: flex;
    					justify-content: flex-start;
						}
					}
				}
			}
		}
		.formio-component-subscriptionPlans {
			.subscription-location-filter {
		      width: 215px;
    		  margin: 0 0 30px auto;

		      @media (max-width: 981px) {
		        width: 270px;
		      }

		      @media (max-width: 667px) {
		        width: auto;
		      }
		    }

			.product-tabs {
		      &> .nav {
		        .nav-item {
		          .nav-link  {

		            &.active {
		              width: 60%;

		              @media (max-width: 981px) {
		                width: 60%;
		              }

		              @media (max-width: 667px) {
		                width: 80%;
		              }

		              @media (max-width: 414px) {
		                width: 100%;
		              }
		            }
		          }
		        }
		      }
		    }

			.tab-pane {
				.owl-nav {
					.owl-prev {
						svg {
							margin: 5px 0 0;
						}
					}
					.owl-next {
						svg {
							margin: 5px 0 0;
						}
					}
				}
				.card {
					.card-img-top {
					    height: 150px;
					    vertical-align: middle;
					}
				}
			}
		}
		.formio-component-textarea {
			&.required {
				color: #212529;
			}
		}
	}
}

.formio-component-termscondition {
	.custom-modal-terms {
		overflow: auto !important;
	  	.modal-content {
		    .modal-body {
			    ul {
			      	padding: 0 0 0 30px;
			      	li {
			      		list-style: initial;
			      	}
			    }
		      	ol {
				    padding: 0 0 0 30px;
				}
				dl {
				    padding: 0 0 0 20px;
				}
				.text-huge {
				    font-size: 1.8em;
				}
				.text-big {
				    font-size: 1.4em;
				}
				.text-small {
				    font-size: .85em;
				}
				.text-tiny {
				    font-size: .7em;
				}
				blockquote {
				    overflow: hidden;
				    padding-right: 1.5em;
				    padding-left: 1.5em;
				    margin-left: 0;
				    margin-right: 0;
				    font-style: italic;
				    border-left: 5px solid #ccc;
				}
		    }
	  	}
	}
}

.payment-confirm-action {
    position: fixed;
    left: 0;
    right: 0;
    width: 40%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      width: 75%;
    }
    @media (max-width: 485px) {
      width: 90%;
    }
}
.payment-form-builder {
  .form-check-input {
    margin-top: .1rem;
  }
  .btn-primary[data-type="radio"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\2299";
      font-size: 17px;
      line-height: 0.85em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .builder-component {
    .component-settings-button-move {
      position: relative;
      &::before {
        content: "\21C4";
        color: #212529;
        font-size: 25px;
        line-height: 1.2em;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
      }
      svg {
        display: none;
      }
    }
    .component-settings-button-remove {
      position: relative;
      &::before {
        content: "\2716";
        color: #fff;
        font-size: 28px;
        line-height: 1.2em;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
      }
      svg {
        display: none;
      }
    }
  }
  .btn-primary[data-type="time"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "◴";
      font-size: 17px;
      line-height: 0.60em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="currency"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\0024";
      font-size: 14px;
      line-height: 1em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="signature"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\270E";
      font-size: 17px;
      line-height: 0.9em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="recaptcha-v3"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\2672";
      font-size: 14px;
      line-height: 0.9em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="content"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\2631";
      font-size: 14px;
      line-height: 0.9em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="tabs"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\274F";
      font-size: 14px;
      line-height: 0.9em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
  .btn-primary[data-type="well"] {
    position: relative;
    padding-left: 40px !important;
    &::before {
      content: "\22A1";
      font-size: 16px;
      line-height: 0.8em;
      position: absolute;
      left: 25px;
      margin-top: -4px;
    }
    svg {
      display: none;
    }
  }
}

.embedded {
	body {
		background: #fff;
		> div {
			@media (max-width: 736px) {
				top: 63vh !important;
				position: fixed !important;
			}
			&.swal2-container {
				@media (max-width: 736px) {
					top: 0 !important;
				}
			}
		}
	}
}