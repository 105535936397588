.modal {
    &.fade {
        transition: none;
        .modal-dialog {
            transition: none;
            height: auto;
            width: auto;
        }
    }
    .modal-title {
      font: $h5;
    }
    > .modal-dialog {
      > .modal-content {
        border: 0;
      }
    }
}

.modal-backdrop {
  background-color: #121227;

  &.show {
    opacity: 0.85;
  }

  &.fade {
    transition: none;
  }

  &.semi {
    background-color: #203645;

    &.show {
      opacity: 0.2;
    }

    &.fade {
      transition: opacity 0.25s ease-in-out;
    }
  }
}

.modal-content-right {
    width: 100%;
    min-width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: rgb(0 0 0 / 15%) -10px 0 20px;
    @include breakpoint(tablet-wide){
      width: 50%;
      min-width: 600px;
    }
    .modal-header {
        display: block;
        position: relative;
    }
    .modal-footer {
        padding: 15px;
    }
    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
    }
    button {
        &.close {
          background: url(../../images/icon/ico-mobile-sidebar-close.svg) no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          outline: 0;
          position: absolute;
          left: 0;
          top: 17px;
          right: 20px;
          opacity: 1;
          text-indent: -9999px;
          overflow: hidden;
          &:hover,
          &:focus {
              opacity: 1;
          }
          &:not(:disabled):not(.disabled) {
              opacity: 1;
          }
          span {
            display: inherit;
            margin-top: -8px;
            margin-left: -1px;
          }
          @include breakpoint(tablet-wide){
            position: absolute;
            left: -44px;
            top: 26px;
            right: initial;
          }
        }
    }
}

.modal-full-size {
  background: #FFFFFF;
  width: 100%;
  max-width: 100%;
  margin: 0;
  max-height: initial;
  min-height: 100%;
  .modal-content {
    width: 75%;
    margin: 0 auto;
    border: 0;
    border-radius: 0;
    padding: 75px 0;
    .modal-header {
      padding: 1rem 0;
      .feature-logo {
        width: 12% !important;
        @media (max-width: 1024px) {
          width: 20% !important;
        }
        @media (max-width: 768px) {
          width: 25% !important;
        }
        @media (max-width: 667px) {
          width: 30% !important;
        }
        @media (max-width: 480px) {
          width: 50% !important;
        }
      }
    }
    .modal-body {
      padding: 1rem 0;
      .feature-wrap {
        .feature-list {
          .icon {
            @media (max-width: 1470px) {
              flex: 0 0 31.4% !important;
            }
            @media (max-width: 1399px) {
              flex: 0 0 31.3% !important;
            }
            @media (max-width: 1365px) {
              flex: 0 0 31.2% !important;
            }
            @media (max-width: 1279px) {
              flex: 0 0 31% !important;
            }
            @media (max-width: 1190px) {
              flex: 0 0 48% !important;
            }
            @media (max-width: 981px) {
              flex: 0 0 100% !important;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 15px 0 0;
    }
  }
}


// Workorder / Invoice
.workorder {
  h3 {
    font: $h3;
    padding-left: 4px;
    .badge {
      position: relative;
      top: -3px;
    }
  }
  section {
    padding: 10px 0;
    label {
      color: $teal-100;
      font: $font-bold-14;
      margin-bottom: 10px;
    }
  }
}
.form-control-static {
  display: flex;
  span {
    flex: 0 0 35%;
  }
  p {
    font:$font-bold-14;
  }
}
.form-col-left {
  padding: 0 0 0 20px;
}