footer {
  padding: 3rem 0;
  color: $coolGray-60;
  font:$small;
}
.footnote {
  border-left: 5px solid $primary;
  padding: 10px 0 10px 25px;
  ul {
  	margin: 0;
    padding: 0;
    list-style: none;
    li {
      p {
        font-size: $font_sml;
        font-weight: 800;
        display: inline-block;
        line-height: 1;
        margin-right: 30px;
        margin-bottom: 5px;
        color: #324e57cc;
        span {
          font-weight: normal;
          margin-right: 6px;
          text-transform: uppercase;
        }
      }
    }
  }
}
.footnote-dialog {
  background: $coolGray-30;
  @include border-radius($rounded-8);
  ul {
  	margin: 0;
    padding: 15px;
    list-style: none;
    li {
      p {
        font: $small;
        font-weight: 800;
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 4px;
        color: #324e57cc;
        span {
          font-weight: normal;
          margin-right: 6px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.cta-btngroup {
  width: 100%;
  text-align: right;
  padding: 20px 0;
  display: block;
  .btn {
    display: inline-block;
    flex: none;
    margin-left: 10px !important;
  }
}