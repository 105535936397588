@use 'src/themes' as *;

// TODO: Remove once refactored and UI library is fully implemented.
*, *::before, *::after {
  box-sizing: border-box;
}
body, .layout,
p, .paragraph {
  line-height: normal !important;
}
a {
  text-decoration: none !important;

  &.btn {
    font-weight: 700;
  }
}

.tag-pill {
  padding: 4px 12px;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  &.tag-new {
    color: cj-theme(color-cyan-70);
    background-color: cj-theme(color-blue-20);
  }
  &.tag-overdue {
    color: cj-theme(color-red-80);
    background-color: cj-theme(color-red-30);
  }
  &.tag-suspended {
    color: cj-theme(color-red-60);
    background-color: cj-theme(color-red-20);
  }
  &.tag-archive,
  &.tag-archived {
    color: cj-theme(color-cyan-60);
    background-color: cj-theme(color-cyan-20);
  }
  &.tag-accepted {
    color: cj-theme(color-teal-100);
    background-color: cj-theme(color-teal-40);
  }
  &.tag-active,
  &.tag-recurring-active {
    color: cj-theme(color-teal-100);
    background-color: cj-theme(color-teal-60);
  }
  &.tag-live {
    color: cj-theme(color-pink-10);
    background-color: cj-theme(color-pink-60);
  }
  &.tag-completed {
    color: cj-theme(color-green-80);
    background-color: cj-theme(color-green-40);
  }
  &.tag-in-progress {
    color: cj-theme(color-orange-60);
    background-color: cj-theme(color-salmon-20);
  }
  &.tag-partially-paid,
  &.tag-partial {
    color: cj-theme(color-gold-100);
    background-color: cj-theme(color-gold-20);
  }
  &.tag-cancelled,
  &.tag-paused {
    color: cj-theme(color-red-10);
    background-color: cj-theme(color-red-50);
  }
  &.tag-viewed {
    color: cj-theme(color-cyan-70);
    background-color: cj-theme(color-cyan-30);
  }
  &.tag-paid {
    color: cj-theme(color-green-90);
    background-color: cj-theme(color-green-40);
  }
  &.tag-processed,
  &.tag-refunded {
    color: cj-theme(color-cyan-100);
    background-color: cj-theme(color-purple-40);
  }
  &.tag-for-payment {
    color: cj-theme(color-orange-100);
    background-color: cj-theme(color-orange-40);
  }
  &.tag-pending,
  &.tag-cancel-pending {
    color: cj-theme(color-salmon-40);
    background-color: cj-theme(color-gold-20);
  }
  &.tag-sent {
    color: cj-theme(color-purple-80);
    background-color: cj-theme(color-plum-40);
  }
}

.sk-loader {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 4px;

  background-color: cj-theme(color-gray-30);

  width: 100%;
  height: 20px;
  will-change: transform;

  &::after,
  &::before {
    box-sizing: border-box;
  }

  &.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &.progress-wave {
    &::after,
    .progess-highlight {
      box-sizing: border-box;
    }

    transform: translate3d(0, 0, 0);

    .progess-highlight {
      background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
      animation: progress 4s ease-in-out infinite;
      background-size: 200px 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 200px;
      height: 100%;
      content: '';
    }
  }
}

@keyframes progress {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  100% {
    transform: translate3d(calc(200px + 100vw), 0, 0);
  }
}


// Custom CSS
hr,
.header-content,
.footer-content,
.summary-container {
  border-style: solid;
  border-color: cj-theme(color-gray-50);
  border-width: 0;
}

.header-content {
  padding-bottom: 20px;
  border-bottom-width: 1px;
}

.footer-content {
  padding-top: 20px;
  border-top-width: 1px;
}

.modal-content-right {
  border-radius: 12px 0 0 12px;

  .modal-close {
    font-size: 26px;
    position: absolute;
    left: -50px;
    top: 10px;
  }
}

.icon-small {
  font-size: 14px;
}

.icon-giant {
  font-size: 24px;
}

.currency-item::first-letter {
  padding-right: 4px;
}

.summary-container {
  border-width: 1px;
  border-radius: 12px;
  overflow-y: auto;

  @include cj-scrollbars(
    cj-theme(layout-scrollbar-color),
    transparent,
    8px
  );

  hr {
    margin: 0;
    border-top-width: 1px;
  }

  label {
    display: block;
    color: cj-theme(color-gray-100);
    margin-bottom: 10px;
    font-weight: 700;
    font-size: initial;
    line-height: 19px;
  }

  cj-form-field label {
    margin-bottom: 0;
  }

  .details-container {
    border: 1px solid cj-theme(color-cyan-20);
    border-radius: 10px;
    padding: 1.25rem;
  }
}

.select-field {
  position: relative;

  &:after {
    content: '\f078';
    display: inline-block;
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    bottom: 10px;
    right: 16px;
    color: var(--color-blue-90);
  }

  label {
    display: block;
    font-weight: 500;
    line-height: 1.375rem;
    margin-bottom: .5rem;

    &.required::after {
      content: ' *';
      color: cj-theme(color-danger-active);
    }
  }

  select {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.4375rem 2.5rem 0.4375rem 1rem;
    height: 40px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    appearance: none;

    color: cj-theme(input-text-color);
    background-color: cj-theme(input-background-color);
    border-style: cj-theme(input-border-style);
    border-width: cj-theme(input-border-width);
    border-color: cj-theme(input-border-color);
    border-radius: cj-theme(input-border-radius);

    &:focus {
      outline: none;
      border-color: cj-theme(input-primary-focus-border-color);
      box-shadow: 0 0 0 4px cj-theme(input-primary-outline-color);
    }
  
    &:hover {
      border-color: cj-theme(input-hover-border-color);
    }
  
    &:disabled {
      background-color: cj-theme(input-disabled-background-color);
      border-color: cj-theme(input-disabled-border-color);
      color: cj-theme(input-disabled-text-color);
    }

    &.invalid {
      border-color: cj-theme(input-danger-border-color);

      &:focus {
        border-color: cj-theme(input-danger-focus-border-color);
        box-shadow: 0 0 0 4px cj-theme(input-danger-outline-color);
      }

      &:hover {
        border-color: cj-theme(input-danger-hover-border-color);
      }
    }
  }
}

.modal-content {
  border-radius: 12px;
}

.summary-content {
  height: 100%;
  overflow: auto;

  @media (max-width: 1500px) {
    overflow: auto;
  }
  @media (max-width: 1200px) {
    .summary-history {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .summary-container  {
    @media (max-width: 1366px) {
      display: flex;
      max-height: 100% !important;
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 20px;
      .summary-details {
        padding: 0 25px 30px;
      }
    }
  }
}

.invoice-summary-number {
  color: cj-theme(color-teal-100);
  font-size: 24px;
  line-height: 29px;
}

.summary-history {
  color: cj-theme(color-cyan-60);
  border-left: 4px solid cj-theme(color-cyan-80);

  strong {
    color: cj-theme(color-cyan-70);
  }
}