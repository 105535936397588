/*
 * Grid Defaults
 */

// column measurements
$grid-column-width: 5rem; // 5rem = exactly 960px at desktop breakpoint
$grid-type: magic; // fluid max width = 100%; magic max width = largest breakpoint width
$grid-gutter: 32px; // grid spacing/gap
$grid-padding: false; // value or false

// responsive breakpoints (min-width)
$grid-breakpoints: (
        mobile: 375px,//above Iphone
        mobile-wide: 414px,//above iPhone+
        mobile-landscape-small: 568px,//above iPhone+
        mobile-landscape-medium: 650px,//above iPhone+
        mobile-landscape: 667px,//above iPhone landscape
        mobile-wide-landscape: 740px,//above iPhone wide landscape
        tablet: 768px, // above iPad portrait
        tablet-wide: 1024px, //above iPad landscape, iPad Pro portrait
        desktop-small: 900px, //
        desktop: 1200px, //above Laptop screen, iPad Pro landscape
        desktop-normal: 1280px, //
        desktop-medium: 1380px, //
        desktop-wide: 1440px, //
        desktop-extra-wide: 1550px, //
        desktop-normal-wide: 1600px, //
        desktop-wide-normal: 1720px, //
        desktop-ultra-wide: 1820px, //
);

$site-container-width : map_get($grid-breakpoints, desktop-normal);

// type families
$font-family-primary: var(--font-primary), Arial, sans-serif;
$font-family-secondary: var(--font-secondary), Arial, sans-serif;

// Customizer Colors
$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$color-primary-dark: var(--color-primary-dark);
$color-primary-darker: var(--color-primary-darker);

$color-secondary: var(--color-secondary);
$color-secondary-light: var(--color-secondary-light);
$color-secondary-dark: var(--color-secondary-dark);
$color-secondary-darker: var(--color-secondary-darker);

$color-body-text: var(--color-body-text);
$color-body-text-light: var(--color-body-text-light);
$color-body-text-dark: var(--color-body-text-dark);
$color-body-text-darker: var(--color-body-text-darker);
$color-links: $color-secondary;
$color-links-hover: $color-secondary-light;

/*
 * Typography Settings
 */

// base sizing
$base-font-size: 16px;
$base-line-height: 30px;

// element margins
$margin-bottom-heading-1: 2rem;
$margin-bottom-heading-2: 1rem;
$margin-bottom-heading-3: 1rem;
$margin-bottom-heading-4: 1rem;
$margin-bottom-paragraph: 1rem;

/*
 * Form and UI Settings
 */

// buttons
$button-line-height: $base-line-height;
$button-border-radius: 3rem;

//Transitions
$duration: 0.3s;
