@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: unset;
  overflow: hidden;
}

/*
  Declare variables before making them global.
  dart-sass doesn't allow to declare variable with !global.
 */
/**
 * This mixin generates keyfames.
 * Because of all keyframes can't be scoped,
 * we need to puts unique name in each btn-pulse call.
 */
/*

According to the specification (https://www.w3.org/TR/css-scoping-1/#host-selector)
:host and :host-context are pseudo-classes. So we assume they could be combined,
like other pseudo-classes, even same ones.
For example: ':nth-of-type(2n):nth-of-type(even)'.

Ideal solution would be to prepend any selector with :host-context([dir=rtl]).
Then nebular components will behave as an html element and respond to [dir] attribute on any level,
so direction could be overridden on any component level.

Implementation code:

@mixin cj-rtl() {
  // add # to scss interpolation statement.
  // it works in comments and we can't use it here
  @at-root {selector-append(':host-context([dir=rtl])', &)} {
    @content;
  }
}

And when we call it somewhere:

:host {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}
:host-context(...) {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}

Result will look like:

:host-context([dir=rtl]):host .some-class {
  ...
}
:host-context([dir=rtl]):host-context(...) .some-class {
  ...
}

*
  Side note:
  :host-context():host selector are valid. https://lists.w3.org/Archives/Public/www-style/2015Feb/0305.html

  :host-context([dir=rtl]):host-context(...) should match any permutation,
  so order is not important.
*


Currently, there're two problems with this approach:

First, is that we can't combine :host, :host-context. Angular bugs #14349, #19199.
For the moment of writing, the only possible way is:
:host {
  :host-context(...) {
    ...
  }
}
It doesn't work for us because mixin could be called somewhere deeper, like:
:host {
  p {
    @include cj-rtl() { ... }
  }
}
We are not able to go up to :host level to place content passed to mixin.

The second problem is that we only can be sure that we appending :host-context([dir=rtl]) to another
:host/:host-context pseudo-class when called in theme files (*.theme.scss).
  *
    Side note:
    Currently, cj-install-component uses another approach where :host prepended with the theme name
    (https://github.com/angular/angular/blob/5b96078624b0a4760f2dbcf6fdf0bd62791be5bb/packages/compiler/src/shadow_css.ts#L441),
    but it was made to be able to use current realization of rtl and it can be rewritten back to
    :host-context($theme) once we will be able to use multiple shadow selectors.
  *
But when it's called in *.component.scss we can't be sure, that selector starts with :host/:host-context,
because angular allows omitting pseudo-classes if we don't need to style :host component itself.
We can break such selectors, by just appending :host-context([dir=rtl]) to them.
  ***
    Possible solution
    check if we in theme by some theme variables and if so append, otherwise nest like
    @at-root :host-context([dir=rtl]) {
      // add # to scss interpolation statement.
      // it works in comments and we can't use it here
      {&} {
        @content;
      }
    }
    What if :host specified? Can we add space in :host-context(...) :host?
    Or maybe add :host selector anyway? If multiple :host selectors are allowed
  ***


Problems with the current approach.

1. Direction can be applied only on document level, because mixin prepends theme class,
which placed on the body.
2. *.component.scss styles should be in :host selector. Otherwise angular will add host
attribute to [dir=rtl] attribute as well.


General problems.

Ltr is default document direction, but for proper work of cj-ltr (means ltr only),
[dir=ltr] should be specified at least somewhere. ':not([dir=rtl]' not applicable here,
because it's satisfy any parent, that don't have [dir=rtl] attribute.
Previous approach was to use single rtl mixin and reset ltr properties to initial value.
But sometimes it's hard to find, what the previous value should be. And such mixin call looks too verbose.
*/
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

/**
 * In case when CashJar UI Layout custom scroll `withScroll` mode is enabled
 * we need to disable default CDK scroll blocker (@link CjBlockScrollStrategyAdapter) on HTML element
 * so that it won't add additional positioning.
 */
.cj-global-scrollblock {
  position: static;
  width: auto;
  overflow: hidden;
}

/*
 * Normalization of HTML elements, manually forked from Normalize.css to remove
 * styles targeting irrelevant browsers while applying new styles.
 *
 * Normalize is licensed MIT. https://github.com/necolas/normalize.css
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html, body {
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/**
 * Sass map contains a list of all Theme variables and also their mappings into Component variables
 * A theme consists of a list of colors, backgrounds, borders, text styles and supporting variables.
 */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
body,
cj-layout .scrollable-container {
  scrollbar-face-color: var(--layout-scrollbar-color);
  scrollbar-track-color: var(--layout-scrollbar-background-color);
}
body::-webkit-scrollbar,
cj-layout .scrollable-container::-webkit-scrollbar {
  width: var(--layout-scrollbar-width);
  height: var(--layout-scrollbar-width);
}
body::-webkit-scrollbar-thumb,
cj-layout .scrollable-container::-webkit-scrollbar-thumb {
  background: var(--layout-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
body::-webkit-scrollbar-track,
cj-layout .scrollable-container::-webkit-scrollbar-track {
  background: var(--layout-scrollbar-background-color);
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
body::-webkit-scrollbar-corner,
cj-layout .scrollable-container::-webkit-scrollbar-corner {
  background: var(--layout-scrollbar-background-color);
}

cj-layout.with-scroll .scrollable-container {
  overflow: auto;
  height: 100vh;
  display: block;
}
@media (max-width: 767.98px) {
  cj-layout.with-scroll .scrollable-container {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.layout {
  min-width: var(--layout-window-mode-min-width);
}

cj-layout.window-mode {
  background: var(--layout-window-mode-background-color);
  display: block;
}
cj-layout.window-mode .scrollable-container {
  max-width: 1920px;
  margin: 0 auto;
}
cj-layout.window-mode .layout cj-layout-header {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
cj-layout.window-mode .layout cj-layout-header nav {
  max-width: 1920px;
  margin: 0 auto;
}
@media screen and (min-width: 1940px) {
  cj-layout.window-mode {
    padding-top: calc(var(--layout-window-mode-padding-top) * 0.25);
  }
  cj-layout.window-mode cj-layout-header.fixed {
    top: calc(var(--layout-window-mode-padding-top) * 0.25);
  }
  cj-layout.window-mode cj-layout-header.fixed ~ .layout-container cj-sidebar .main-container-fixed {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.25) - var(--header-height));
    top: calc(calc(var(--layout-window-mode-padding-top) * 0.25) + var(--header-height));
  }
  cj-layout.window-mode cj-sidebar.fixed {
    left: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed.right {
    right: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed {
    top: calc(var(--header-height) + calc(var(--layout-window-mode-padding-top) * 0.25));
  }
  cj-layout.window-mode .scrollable-container {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.25));
    box-shadow: var(--layout-window-shadow);
  }
  cj-layout.window-mode cj-layout.with-scroll .scrollable-container {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.25));
  }
}
@media screen and (min-width: 2070px) {
  cj-layout.window-mode {
    padding-top: calc(var(--layout-window-mode-padding-top) * 0.5);
  }
  cj-layout.window-mode cj-layout-header.fixed {
    top: calc(var(--layout-window-mode-padding-top) * 0.5);
  }
  cj-layout.window-mode cj-layout-header.fixed ~ .layout-container cj-sidebar .main-container-fixed {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.5) - var(--header-height));
    top: calc(calc(var(--layout-window-mode-padding-top) * 0.5) + var(--header-height));
  }
  cj-layout.window-mode cj-sidebar.fixed {
    left: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed.right {
    right: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed {
    top: calc(var(--header-height) + calc(var(--layout-window-mode-padding-top) * 0.5));
  }
  cj-layout.window-mode .scrollable-container {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.5));
    box-shadow: var(--layout-window-shadow);
  }
  cj-layout.window-mode cj-layout.with-scroll .scrollable-container {
    height: calc(100vh - calc(var(--layout-window-mode-padding-top) * 0.5));
  }
}
@media screen and (min-width: 2220px) {
  cj-layout.window-mode {
    padding-top: var(--layout-window-mode-padding-top);
  }
  cj-layout.window-mode cj-layout-header.fixed {
    top: var(--layout-window-mode-padding-top);
  }
  cj-layout.window-mode cj-layout-header.fixed ~ .layout-container cj-sidebar .main-container-fixed {
    height: calc(100vh - var(--layout-window-mode-padding-top) - var(--header-height));
    top: calc(var(--layout-window-mode-padding-top) + var(--header-height));
  }
  cj-layout.window-mode cj-sidebar.fixed {
    left: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed.right {
    right: calc((100vw - 1920px) / 2);
  }
  cj-layout.window-mode .layout .layout-container cj-sidebar.fixed {
    top: calc(var(--header-height) + var(--layout-window-mode-padding-top));
  }
  cj-layout.window-mode .scrollable-container {
    height: calc(100vh - var(--layout-window-mode-padding-top));
    box-shadow: var(--layout-window-shadow);
  }
  cj-layout.window-mode cj-layout.with-scroll .scrollable-container {
    height: calc(100vh - var(--layout-window-mode-padding-top));
  }
}

cj-layout .layout {
  background-color: var(--layout-background-color);
  color: var(--layout-text-color);
  font-family: var(--layout-text-font-family);
  font-size: var(--layout-text-font-size);
  font-weight: var(--layout-text-font-weight);
  line-height: var(--layout-text-line-height);
  min-height: var(--layout-min-height);
}
cj-layout .layout .layout-container .content cj-layout-footer {
  box-shadow: var(--footer-shadow);
}
cj-layout .layout .layout-container .content cj-layout-footer nav {
  background-color: var(--footer-background-color);
  border-top: var(--footer-divider-width) var(--footer-divider-style) var(--footer-divider-color);
  color: var(--footer-text-color);
  font-family: var(--footer-text-font-family);
  font-size: var(--footer-text-font-size);
  font-weight: var(--footer-text-font-weight);
  line-height: var(--footer-text-line-height);
  padding: var(--footer-padding);
}
cj-layout .layout .layout-container .content cj-layout-footer nav a {
  color: var(--footer-text-highlight-color);
}
cj-layout .layout .layout-container .content cj-layout-footer nav a:focus, cj-layout .layout .layout-container .content cj-layout-footer nav a:active, cj-layout .layout .layout-container .content cj-layout-footer nav a:hover {
  color: var(--footer-text-highlight-color);
}
cj-layout .layout .layout-container .content.center {
  width: var(--layout-content-width);
  flex: 0 100 var(--layout-content-width) !important;
}
cj-layout .layout .layout-container .content .columns {
  scrollbar-face-color: var(--layout-scrollbar-color);
  scrollbar-track-color: var(--layout-scrollbar-background-color);
}
cj-layout .layout .layout-container .content .columns::-webkit-scrollbar {
  width: var(--layout-scrollbar-width);
  height: var(--layout-scrollbar-width);
}
cj-layout .layout .layout-container .content .columns::-webkit-scrollbar-thumb {
  background: var(--layout-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
cj-layout .layout .layout-container .content .columns::-webkit-scrollbar-track {
  background: var(--layout-scrollbar-background-color);
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
cj-layout .layout .layout-container .content .columns::-webkit-scrollbar-corner {
  background: var(--layout-scrollbar-background-color);
}
cj-layout .layout .layout-container .content .columns cj-layout-column {
  padding: var(--layout-padding);
}

cj-layout-header header {
  background-color: var(--header-fixed-background-color);
  padding: var(--header-fixed-padding);
  height: var(--header-fixed-height);
}
cj-layout-header a {
  color: var(--header-text-color);
}
cj-layout-header a:focus, cj-layout-header a:active, cj-layout-header a:hover {
  color: var(--header-text-color);
}
cj-layout-header:not(.sticky) header {
  border-bottom: var(--header-border-bottom-width) var(--header-border-bottom-style) var(--header-border-bottom-color);
}
cj-layout-header:not(.sticky) ~ .columns {
  height: calc(var(--layout-min-height) - var(--header-fixed-height));
  overflow: auto;
  flex: unset;
}
cj-layout-header ~ .layout-container {
  min-height: calc(var(--layout-min-height) - var(--header-height));
}
cj-layout-header.sticky header {
  background-color: var(--header-background-color);
  height: var(--header-height);
  padding: var(--header-padding);
}

cj-menu {
  background-color: var(--menu-background-color);
}
cj-menu ul.menu-items {
  margin: 0 0.5rem;
  padding: 0;
}
cj-menu .menu-item .menu-item-container a {
  font-weight: var(--menu-text-font-weight);
  padding: var(--menu-item-padding);
}
cj-menu .menu-group,
cj-menu .menu-group cj-icon.menu-icon {
  color: var(--menu-group-text-color);
}
cj-menu .menu-group {
  font-size: var(--menu-group-text-font-size);
  font-weight: var(--menu-group-text-font-weight);
  text-transform: var(--menu-group-text-transform);
  padding: var(--menu-group-padding);
}
cj-menu .menu-item.item-previous {
  border-bottom: 1px solid var(--sidebar-header-border-color);
}
cj-menu .menu-item .menu-item-container {
  border-radius: var(--menu-item-border-radius);
}
cj-menu .menu-item .menu-item-container.expanded {
  border-radius: var(--menu-item-expanded-border-radius);
}
cj-menu .menu-item .menu-item-container a {
  color: var(--menu-text-color);
}
cj-menu .menu-item .menu-item-container a.with-icon {
  padding: var(--menu-item-with-icon-padding);
}
cj-menu .menu-item .menu-item-container .menu-item-action {
  background-color: transparent;
  border: none;
  color: var(--color-cyan-40);
}
cj-menu .menu-item .menu-item-container .menu-item-action:hover, cj-menu .menu-item .menu-item-container .menu-item-action:active, cj-menu .menu-item .menu-item-container .menu-item-action:focus, cj-menu .menu-item .menu-item-container .menu-item-action.active {
  color: var(--color-white) !important;
}
cj-menu .menu-item .menu-item-container .menu-item-action.active {
  background-color: var(--menu-item-active-text-color);
}
cj-menu .menu-item .menu-item-container.active {
  background-color: var(--menu-item-active-background-color);
}
cj-menu .menu-item .menu-item-container.active a {
  color: var(--menu-item-active-text-color);
}
cj-menu .menu-item .menu-item-container.active a .menu-icon-container {
  background-color: var(--menu-item-icon-active-background-color);
  color: var(--menu-item-icon-active-color);
}
cj-menu .menu-item .menu-item-container.active a .menu-icon-container svg.menu-gradient {
  fill: url(#menu-gradient);
}
cj-menu .menu-item .menu-item-container.active .expand-state,
cj-menu .menu-item .menu-item-container.active .next-state {
  color: var(--menu-item-active-text-color);
}
cj-menu .menu-item .menu-item-container:not(.active):hover {
  background-color: var(--menu-item-hover-background-color);
  cursor: var(--menu-item-hover-cursor);
}
cj-menu .menu-item .menu-item-container:not(.active):hover a {
  color: var(--menu-item-hover-text-color);
}
cj-menu .menu-item .menu-item-container:not(.active):hover a .menu-icon-container {
  background-color: var(--menu-item-icon-hover-background-color);
  color: var(--menu-item-icon-hover-color);
}
cj-menu .menu-item .menu-item-container:not(.active):hover a .menu-icon-container svg.menu-gradient {
  fill: url(#menu-gradient);
}
cj-menu .menu-item .menu-item-container:not(.active):hover .menu-item-action {
  color: var(--color-cool-gray-70);
}
cj-menu .menu-item .menu-item-container:not(.active):hover .expand-state,
cj-menu .menu-item .menu-item-container:not(.active):hover .next-state {
  color: var(--menu-item-hover-text-color);
}
cj-menu .menu-item .menu-item-container:focus {
  border-color: var(--menu-item-focus-border-color);
}
cj-menu .menu-item .menu-icon-container {
  color: var(--menu-item-icon-color);
  font-size: var(--menu-item-icon-width);
  border-radius: var(--menu-item-border-radius);
}
cj-menu .menu-item .expand-state,
cj-menu .menu-item .next-state {
  color: var(--menu-item-icon-color);
}
cj-menu .menu-item .action-state {
  color: var(--color-cyan-40);
}
cj-menu .menu-item > .menu-submenu-items {
  padding: 0;
  margin: var(--menu-submenu-margin);
  background-color: var(--menu-submenu-background-color);
  border-radius: var(--menu-submenu-border-radius);
}
cj-menu .menu-item > .menu-submenu-items a {
  padding: var(--menu-submenu-item-padding);
  border-radius: 9999px;
}
cj-menu .menu-item > .menu-submenu-items > .menu-item .menu-item-container {
  background-color: transparent;
  padding-left: var(--menu-submenu-padding);
}
cj-menu .menu-item > .menu-submenu-items > .menu-item .menu-item-container.with-action:hover {
  background-color: var(--color-white);
}
cj-menu .menu-item > .menu-submenu-items > .menu-item .menu-item-container.with-action a:hover {
  background-color: var(--color-cool-gray-30);
}
cj-menu .menu-item > .menu-submenu-items > .menu-item .menu-item-container.with-action a:hover .action-state {
  left: 0;
  opacity: 1;
}
cj-menu .menu-item > .menu-submenu-items > .menu-item .menu-item-container a {
  font-weight: var(--menu-submenu-text-font-weight);
}
cj-menu .menu-item > .menu-submenu-items > .menu-group cj-icon.menu-icon {
  color: var(--menu-group-text-color);
}

cj-sidebar {
  color: var(--menu-text-color);
  border-right: var(--sidebar-border-right-width) var(--sidebar-border-right-style) var(--sidebar-border-right-color);
}
cj-sidebar .nav-content-wrapper {
  width: var(--sidebar-width);
  transition: width var(--transition-duration-easing);
}
cj-sidebar .nav-content {
  height: var(--sidebar-height);
  background-color: var(--sidebar-background-color);
  min-width: var(--sidebar-width);
}
cj-sidebar .scrollable {
  padding: var(--sidebar-nav-padding);
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  scrollbar-face-color: var(--sidebar-scrollbar-color);
  scrollbar-track-color: var(--sidebar-scrollbar-background-color);
}
@media (max-width: 767.98px) {
  cj-sidebar .scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
cj-sidebar .scrollable::-webkit-scrollbar {
  width: var(--sidebar-scrollbar-width);
  height: var(--sidebar-scrollbar-width);
}
cj-sidebar .scrollable::-webkit-scrollbar-thumb {
  background: var(--sidebar-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--sidebar-scrollbar-width) * 0.5);
}
cj-sidebar .scrollable::-webkit-scrollbar-track {
  background: var(--sidebar-scrollbar-background-color);
  border-radius: calc(var(--sidebar-scrollbar-width) * 0.5);
}
cj-sidebar .scrollable::-webkit-scrollbar-corner {
  background: var(--sidebar-scrollbar-background-color);
}
cj-sidebar .collapse-btn {
  color: var(--text-hint-color);
}
cj-sidebar ~ .content {
  margin-left: var(--sidebar-width);
  transition: margin-left var(--transition-duration-easing);
}
cj-sidebar.collapsed .nav-content-wrapper {
  width: 0;
  padding: 0;
  transition: width var(--transition-duration-easing);
}
cj-sidebar.collapsed .scrollable {
  width: 0;
  padding: 0;
  overflow: hidden;
}
cj-sidebar.collapsed ~ .content {
  margin-left: 0;
}
cj-sidebar.collapsed ~ .content.center {
  padding-left: 0;
}
cj-sidebar.collapsed cj-sidebar-header,
cj-sidebar.collapsed cj-sidebar-footer {
  width: 0;
  padding: 0;
  overflow: hidden;
}
cj-sidebar.compacted .nav-content-wrapper {
  width: var(--sidebar-width-compact);
}
cj-sidebar.compacted .scrollable {
  padding: var(--sidebar-nav-compact-padding);
}
cj-sidebar.compacted cj-sidebar-header {
  padding: var(--sidebar-header-compact-padding);
  margin: var(--sidebar-header-compact-margin);
  border-bottom: 1px solid var(--sidebar-header-compact-border-color);
}
cj-sidebar.compacted cj-sidebar-footer {
  padding: var(--sidebar-footer-compact-padding);
  margin: var(--sidebar-footer-compact-margin);
  border-top: 1px solid var(--sidebar-footer-compact-border-color);
}
cj-sidebar.compacted cj-menu .menu-item a.active {
  position: relative;
}
cj-sidebar.compacted cj-menu > .menu-items > .menu-item > a span,
cj-sidebar.compacted cj-menu > .menu-items > .menu-item > a cj-badge,
cj-sidebar.compacted cj-menu > .menu-items > .menu-item > a .expand-state {
  display: none;
}
cj-sidebar.compacted cj-menu .menu-items > .menu-item.menu-group {
  display: block;
  color: transparent;
  width: 0;
  padding: 0;
  overflow: hidden;
}
cj-sidebar.compacted cj-menu .menu-items > .menu-item i {
  margin-right: 0;
}
cj-sidebar.compacted cj-menu .menu-items > .menu-item a {
  padding: var(--menu-item-compact-padding);
  width: fit-content;
}
cj-sidebar.compacted cj-menu .menu-items > .menu-item > .expanded, cj-sidebar.compacted cj-menu .menu-items > .menu-item > .collapsed {
  display: none;
}
cj-sidebar.compacted ~ .content {
  margin-left: var(--sidebar-width-compact);
}
cj-sidebar.compacted ~ .content.center {
  padding-left: var(--sidebar-width-compact);
}
cj-sidebar.expanded ~ .content {
  margin-left: var(--sidebar-width);
}
cj-sidebar.expanded ~ .content.center {
  padding-left: var(--sidebar-width);
}
cj-sidebar.fixed.expanded ~ .content {
  margin-left: var(--sidebar-width-compact);
}
cj-sidebar.fixed.expanded ~ .content.center {
  padding-left: var(--sidebar-width-compact);
}
cj-sidebar.fixed.mobile.expanded ~ .content {
  margin-left: 0;
}
cj-sidebar.fixed.mobile.expanded ~ .content.center {
  padding-left: 0;
}
cj-sidebar cj-sidebar-header {
  padding: var(--sidebar-header-padding);
  margin: var(--sidebar-header-margin);
  height: var(--sidebar-header-height);
  border-bottom: 1px solid var(--sidebar-header-border-color);
  scrollbar-face-color: var(--sidebar-scrollbar-color);
  scrollbar-track-color: var(--sidebar-scrollbar-background-color);
}
cj-sidebar cj-sidebar-header h1, cj-sidebar cj-sidebar-header h2, cj-sidebar cj-sidebar-header h3,
cj-sidebar cj-sidebar-header h4, cj-sidebar cj-sidebar-header h5, cj-sidebar cj-sidebar-header h6 {
  color: var(--color-cool-gray-60);
}
cj-sidebar cj-sidebar-header::-webkit-scrollbar {
  width: var(--sidebar-scrollbar-width);
  height: var(--sidebar-scrollbar-width);
}
cj-sidebar cj-sidebar-header::-webkit-scrollbar-thumb {
  background: var(--sidebar-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--sidebar-scrollbar-width) * 0.5);
}
cj-sidebar cj-sidebar-header::-webkit-scrollbar-track {
  background: var(--sidebar-scrollbar-background-color);
  border-radius: calc(var(--sidebar-scrollbar-width) * 0.5);
}
cj-sidebar cj-sidebar-header::-webkit-scrollbar-corner {
  background: var(--sidebar-scrollbar-background-color);
}
cj-sidebar cj-sidebar-footer {
  padding: var(--sidebar-footer-padding);
  margin: var(--sidebar-footer-margin);
  height: var(--sidebar-footer-height);
  font-size: var(--sidebar-footer-font-size);
  border-top: 1px solid var(--sidebar-footer-border-color);
}

[cjButton] {
  appearance: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  cursor: var(--button-cursor);
  font-family: var(--button-text-font-family);
  font-weight: var(--button-text-font-weight);
  font-size: var(--button-text-font-size);
  line-height: var(--button-text-line-height);
  border-radius: var(--button-border-radius);
}
[cjButton]:hover, [cjButton]:focus {
  text-decoration: none;
}
[cjButton].full-width {
  width: 100%;
}
[cjButton] cj-icon {
  vertical-align: top;
}
[cjButton].cj-transition {
  transition-duration: 0.15s;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: ease-in;
}
[cjButton]:focus {
  outline: none;
}
[cjButton][disabled], [cjButton].btn-disabled {
  cursor: var(--button-disabled-cursor);
}
[cjButton].size-small {
  padding: var(--button-small-padding);
}
[cjButton].size-small cj-icon {
  display: flex;
  height: var(--button-small-icon-size);
  width: var(--button-small-icon-size);
  margin-top: var(--button-small-icon-vertical-margin);
  margin-bottom: var(--button-small-icon-vertical-margin);
}
[dir=ltr] [cjButton].size-small.icon-start:not(.icon-end) cj-icon, [dir=ltr] [cjButton].size-small.icon-start:not(.icon-end) > svg {
  margin-right: var(--button-small-icon-offset);
}
[dir=rtl] [cjButton].size-small.icon-start:not(.icon-end) cj-icon, [dir=rtl] [cjButton].size-small.icon-start:not(.icon-end) > svg {
  margin-left: var(--button-small-icon-offset);
}
[dir=ltr] [cjButton].size-small.icon-end:not(.icon-start) cj-icon, [dir=ltr] [cjButton].size-small.icon-end:not(.icon-start) > svg {
  margin-left: var(--button-small-icon-offset);
}
[dir=rtl] [cjButton].size-small.icon-end:not(.icon-start) cj-icon, [dir=rtl] [cjButton].size-small.icon-end:not(.icon-start) > svg {
  margin-right: var(--button-small-icon-offset);
}
[cjButton].size-small.icon-start.icon-end {
  width: var(--icon-button-small-width);
  height: var(--icon-button-small-width);
  padding: var(--icon-button-small-padding);
}
[cjButton].size-medium {
  padding: var(--button-medium-padding);
}
[cjButton].size-medium cj-icon {
  display: flex;
  height: var(--button-medium-icon-size);
  width: var(--button-medium-icon-size);
  margin-top: var(--button-medium-icon-vertical-margin);
  margin-bottom: var(--button-medium-icon-vertical-margin);
}
[dir=ltr] [cjButton].size-medium.icon-start:not(.icon-end) cj-icon, [dir=ltr] [cjButton].size-medium.icon-start:not(.icon-end) > svg {
  margin-right: var(--button-medium-icon-offset);
}
[dir=rtl] [cjButton].size-medium.icon-start:not(.icon-end) cj-icon, [dir=rtl] [cjButton].size-medium.icon-start:not(.icon-end) > svg {
  margin-left: var(--button-medium-icon-offset);
}
[dir=ltr] [cjButton].size-medium.icon-end:not(.icon-start) cj-icon, [dir=ltr] [cjButton].size-medium.icon-end:not(.icon-start) > svg {
  margin-left: var(--button-medium-icon-offset);
}
[dir=rtl] [cjButton].size-medium.icon-end:not(.icon-start) cj-icon, [dir=rtl] [cjButton].size-medium.icon-end:not(.icon-start) > svg {
  margin-right: var(--button-medium-icon-offset);
}
[cjButton].size-medium.icon-start.icon-end {
  width: var(--icon-button-medium-width);
  height: var(--icon-button-medium-width);
  padding: var(--icon-button-medium-padding);
}
[cjButton].size-large {
  padding: var(--button-large-padding);
}
[cjButton].size-large cj-icon {
  display: flex;
  height: var(--button-large-icon-size);
  width: var(--button-large-icon-size);
  margin-top: var(--button-large-icon-vertical-margin);
  margin-bottom: var(--button-large-icon-vertical-margin);
}
[dir=ltr] [cjButton].size-large.icon-start:not(.icon-end) cj-icon, [dir=ltr] [cjButton].size-large.icon-start:not(.icon-end) > svg {
  margin-right: var(--button-large-icon-offset);
}
[dir=rtl] [cjButton].size-large.icon-start:not(.icon-end) cj-icon, [dir=rtl] [cjButton].size-large.icon-start:not(.icon-end) > svg {
  margin-left: var(--button-large-icon-offset);
}
[dir=ltr] [cjButton].size-large.icon-end:not(.icon-start) cj-icon, [dir=ltr] [cjButton].size-large.icon-end:not(.icon-start) > svg {
  margin-left: var(--button-large-icon-offset);
}
[dir=rtl] [cjButton].size-large.icon-end:not(.icon-start) cj-icon, [dir=rtl] [cjButton].size-large.icon-end:not(.icon-start) > svg {
  margin-right: var(--button-large-icon-offset);
}
[cjButton].size-large.icon-start.icon-end {
  width: var(--icon-button-large-width);
  height: var(--icon-button-large-width);
  padding: var(--icon-button-large-padding);
}

a[cjButton] {
  text-decoration: none;
}

[cjButton].appearance-filled {
  border-style: var(--button-filled-border-style);
  border-width: var(--button-filled-border-width);
  text-transform: var(--button-filled-text-transform);
}
[cjButton].appearance-filled.status-primary {
  background-color: var(--button-filled-primary-background-color);
  border-color: var(--button-filled-primary-border-color);
  color: var(--button-filled-primary-text-color);
}
[cjButton].appearance-filled.status-primary:focus {
  background-color: var(--button-filled-primary-focus-background-color);
  border-color: var(--button-filled-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--button-filled-primary-outline-color);
}
[cjButton].appearance-filled.status-primary:hover {
  background-color: var(--button-filled-primary-hover-background-color);
  border-color: var(--button-filled-primary-hover-border-color);
}
[cjButton].appearance-filled.status-primary:active {
  background-color: var(--button-filled-primary-active-background-color);
  border-color: var(--button-filled-primary-active-border-color);
}
[cjButton].appearance-filled.status-primary[disabled], [cjButton].appearance-filled.status-primary.btn-disabled {
  background-color: var(--button-filled-primary-disabled-background-color);
  border-color: var(--button-filled-primary-disabled-border-color);
  color: var(--button-filled-primary-disabled-text-color);
}
[cjButton].appearance-filled.status-success {
  background-color: var(--button-filled-success-background-color);
  border-color: var(--button-filled-success-border-color);
  color: var(--button-filled-success-text-color);
}
[cjButton].appearance-filled.status-success:focus {
  background-color: var(--button-filled-success-focus-background-color);
  border-color: var(--button-filled-success-focus-border-color);
  box-shadow: 0 0 0 4px var(--button-filled-success-outline-color);
}
[cjButton].appearance-filled.status-success:hover {
  background-color: var(--button-filled-success-hover-background-color);
  border-color: var(--button-filled-success-hover-border-color);
}
[cjButton].appearance-filled.status-success:active {
  background-color: var(--button-filled-success-active-background-color);
  border-color: var(--button-filled-success-active-border-color);
}
[cjButton].appearance-filled.status-success[disabled], [cjButton].appearance-filled.status-success.btn-disabled {
  background-color: var(--button-filled-success-disabled-background-color);
  border-color: var(--button-filled-success-disabled-border-color);
  color: var(--button-filled-success-disabled-text-color);
}
[cjButton].appearance-filled.status-warning {
  background-color: var(--button-filled-warning-background-color);
  border-color: var(--button-filled-warning-border-color);
  color: var(--button-filled-warning-text-color);
}
[cjButton].appearance-filled.status-warning:focus {
  background-color: var(--button-filled-warning-focus-background-color);
  border-color: var(--button-filled-warning-focus-border-color);
  box-shadow: 0 0 0 4px var(--button-filled-warning-outline-color);
}
[cjButton].appearance-filled.status-warning:hover {
  background-color: var(--button-filled-warning-hover-background-color);
  border-color: var(--button-filled-warning-hover-border-color);
}
[cjButton].appearance-filled.status-warning:active {
  background-color: var(--button-filled-warning-active-background-color);
  border-color: var(--button-filled-warning-active-border-color);
}
[cjButton].appearance-filled.status-warning[disabled], [cjButton].appearance-filled.status-warning.btn-disabled {
  background-color: var(--button-filled-warning-disabled-background-color);
  border-color: var(--button-filled-warning-disabled-border-color);
  color: var(--button-filled-warning-disabled-text-color);
}
[cjButton].appearance-filled.status-danger {
  background-color: var(--button-filled-danger-background-color);
  border-color: var(--button-filled-danger-border-color);
  color: var(--button-filled-danger-text-color);
}
[cjButton].appearance-filled.status-danger:focus {
  background-color: var(--button-filled-danger-focus-background-color);
  border-color: var(--button-filled-danger-focus-border-color);
  box-shadow: 0 0 0 4px var(--button-filled-danger-outline-color);
}
[cjButton].appearance-filled.status-danger:hover {
  background-color: var(--button-filled-danger-hover-background-color);
  border-color: var(--button-filled-danger-hover-border-color);
}
[cjButton].appearance-filled.status-danger:active {
  background-color: var(--button-filled-danger-active-background-color);
  border-color: var(--button-filled-danger-active-border-color);
}
[cjButton].appearance-filled.status-danger[disabled], [cjButton].appearance-filled.status-danger.btn-disabled {
  background-color: var(--button-filled-danger-disabled-background-color);
  border-color: var(--button-filled-danger-disabled-border-color);
  color: var(--button-filled-danger-disabled-text-color);
}
[cjButton].appearance-filled.status-info {
  background-color: var(--button-filled-info-background-color);
  border-color: var(--button-filled-info-border-color);
  color: var(--button-filled-info-text-color);
}
[cjButton].appearance-filled.status-info:focus {
  background-color: var(--button-filled-info-focus-background-color);
  border-color: var(--button-filled-info-focus-border-color);
  box-shadow: 0 0 0 4px var(--button-filled-info-outline-color);
}
[cjButton].appearance-filled.status-info:hover {
  background-color: var(--button-filled-info-hover-background-color);
  border-color: var(--button-filled-info-hover-border-color);
}
[cjButton].appearance-filled.status-info:active {
  background-color: var(--button-filled-info-active-background-color);
  border-color: var(--button-filled-info-active-border-color);
}
[cjButton].appearance-filled.status-info[disabled], [cjButton].appearance-filled.status-info.btn-disabled {
  background-color: var(--button-filled-info-disabled-background-color);
  border-color: var(--button-filled-info-disabled-border-color);
  color: var(--button-filled-info-disabled-text-color);
}

[cjButton].appearance-outline {
  border-style: var(--button-outline-border-style);
  border-width: var(--button-outline-border-width);
  text-transform: var(--button-outline-text-transform);
}
[cjButton].appearance-outline.status-primary {
  background-color: var(--button-outline-primary-background-color);
  border-color: var(--button-outline-primary-border-color);
  color: var(--button-outline-primary-text-color);
}
[cjButton].appearance-outline.status-primary:focus {
  background-color: var(--button-outline-primary-focus-background-color);
  border-color: var(--button-outline-primary-focus-border-color);
  color: var(--button-outline-primary-focus-text-color);
  box-shadow: 0 0 0 4px var(--button-outline-primary-outline-color);
}
[cjButton].appearance-outline.status-primary:hover {
  background-color: var(--button-outline-primary-hover-background-color);
  border-color: var(--button-outline-primary-hover-border-color);
  color: var(--button-outline-primary-hover-text-color);
}
[cjButton].appearance-outline.status-primary:active {
  background-color: var(--button-outline-primary-active-background-color);
  border-color: var(--button-outline-primary-active-border-color);
  color: var(--button-outline-primary-active-text-color);
}
[cjButton].appearance-outline.status-primary[disabled], [cjButton].appearance-outline.status-primary.btn-disabled {
  background-color: var(--button-outline-primary-disabled-background-color);
  border-color: var(--button-outline-primary-disabled-border-color);
  color: var(--button-outline-primary-disabled-text-color);
}
[cjButton].appearance-outline.status-success {
  background-color: var(--button-outline-success-background-color);
  border-color: var(--button-outline-success-border-color);
  color: var(--button-outline-success-text-color);
}
[cjButton].appearance-outline.status-success:focus {
  background-color: var(--button-outline-success-focus-background-color);
  border-color: var(--button-outline-success-focus-border-color);
  color: var(--button-outline-success-focus-text-color);
  box-shadow: 0 0 0 4px var(--button-outline-success-outline-color);
}
[cjButton].appearance-outline.status-success:hover {
  background-color: var(--button-outline-success-hover-background-color);
  border-color: var(--button-outline-success-hover-border-color);
  color: var(--button-outline-success-hover-text-color);
}
[cjButton].appearance-outline.status-success:active {
  background-color: var(--button-outline-success-active-background-color);
  border-color: var(--button-outline-success-active-border-color);
  color: var(--button-outline-success-active-text-color);
}
[cjButton].appearance-outline.status-success[disabled], [cjButton].appearance-outline.status-success.btn-disabled {
  background-color: var(--button-outline-success-disabled-background-color);
  border-color: var(--button-outline-success-disabled-border-color);
  color: var(--button-outline-success-disabled-text-color);
}
[cjButton].appearance-outline.status-warning {
  background-color: var(--button-outline-warning-background-color);
  border-color: var(--button-outline-warning-border-color);
  color: var(--button-outline-warning-text-color);
}
[cjButton].appearance-outline.status-warning:focus {
  background-color: var(--button-outline-warning-focus-background-color);
  border-color: var(--button-outline-warning-focus-border-color);
  color: var(--button-outline-warning-focus-text-color);
  box-shadow: 0 0 0 4px var(--button-outline-warning-outline-color);
}
[cjButton].appearance-outline.status-warning:hover {
  background-color: var(--button-outline-warning-hover-background-color);
  border-color: var(--button-outline-warning-hover-border-color);
  color: var(--button-outline-warning-hover-text-color);
}
[cjButton].appearance-outline.status-warning:active {
  background-color: var(--button-outline-warning-active-background-color);
  border-color: var(--button-outline-warning-active-border-color);
  color: var(--button-outline-warning-active-text-color);
}
[cjButton].appearance-outline.status-warning[disabled], [cjButton].appearance-outline.status-warning.btn-disabled {
  background-color: var(--button-outline-warning-disabled-background-color);
  border-color: var(--button-outline-warning-disabled-border-color);
  color: var(--button-outline-warning-disabled-text-color);
}
[cjButton].appearance-outline.status-danger {
  background-color: var(--button-outline-danger-background-color);
  border-color: var(--button-outline-danger-border-color);
  color: var(--button-outline-danger-text-color);
}
[cjButton].appearance-outline.status-danger:focus {
  background-color: var(--button-outline-danger-focus-background-color);
  border-color: var(--button-outline-danger-focus-border-color);
  color: var(--button-outline-danger-focus-text-color);
  box-shadow: 0 0 0 4px var(--button-outline-danger-outline-color);
}
[cjButton].appearance-outline.status-danger:hover {
  background-color: var(--button-outline-danger-hover-background-color);
  border-color: var(--button-outline-danger-hover-border-color);
  color: var(--button-outline-danger-hover-text-color);
}
[cjButton].appearance-outline.status-danger:active {
  background-color: var(--button-outline-danger-active-background-color);
  border-color: var(--button-outline-danger-active-border-color);
  color: var(--button-outline-danger-active-text-color);
}
[cjButton].appearance-outline.status-danger[disabled], [cjButton].appearance-outline.status-danger.btn-disabled {
  background-color: var(--button-outline-danger-disabled-background-color);
  border-color: var(--button-outline-danger-disabled-border-color);
  color: var(--button-outline-danger-disabled-text-color);
}
[cjButton].appearance-outline.status-info {
  background-color: var(--button-outline-info-background-color);
  border-color: var(--button-outline-info-border-color);
  color: var(--button-outline-info-text-color);
}
[cjButton].appearance-outline.status-info:focus {
  background-color: var(--button-outline-info-focus-background-color);
  border-color: var(--button-outline-info-focus-border-color);
  color: var(--button-outline-info-focus-text-color);
  box-shadow: 0 0 0 4px var(--button-outline-info-outline-color);
}
[cjButton].appearance-outline.status-info:hover {
  background-color: var(--button-outline-info-hover-background-color);
  border-color: var(--button-outline-info-hover-border-color);
  color: var(--button-outline-info-hover-text-color);
}
[cjButton].appearance-outline.status-info:active {
  background-color: var(--button-outline-info-active-background-color);
  border-color: var(--button-outline-info-active-border-color);
  color: var(--button-outline-info-active-text-color);
}
[cjButton].appearance-outline.status-info[disabled], [cjButton].appearance-outline.status-info.btn-disabled {
  background-color: var(--button-outline-info-disabled-background-color);
  border-color: var(--button-outline-info-disabled-border-color);
  color: var(--button-outline-info-disabled-text-color);
}

cj-icon {
  font-size: var(--icon-font-size);
  line-height: var(--icon-line-height);
  width: var(--icon-width);
  height: var(--icon-height);
}

cj-icon.status-primary {
  color: var(--icon-primary-color);
}

cj-icon.status-success {
  color: var(--icon-success-color);
}

cj-icon.status-warning {
  color: var(--icon-warning-color);
}

cj-icon.status-danger {
  color: var(--icon-danger-color);
}

cj-icon.status-info {
  color: var(--icon-info-color);
}

cj-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  margin-bottom: var(--card-margin-bottom);
  padding: var(--card-padding);
  scrollbar-face-color: var(--layout-scrollbar-color);
  scrollbar-track-color: var(--layout-scrollbar-background-color);
}
cj-card.shadow {
  box-shadow: var(--card-shadow);
}
cj-card::-webkit-scrollbar {
  width: var(--layout-scrollbar-width);
  height: var(--layout-scrollbar-width);
}
cj-card::-webkit-scrollbar-thumb {
  background: var(--layout-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
cj-card::-webkit-scrollbar-track {
  background: var(--layout-scrollbar-background-color);
  border-radius: calc(var(--layout-scrollbar-width) * 0.5);
}
cj-card::-webkit-scrollbar-corner {
  background: var(--layout-scrollbar-background-color);
}

[cjInput] {
  font-family: var(--input-text-font-family);
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
  appearance: none;
}
[cjInput].cj-transition {
  transition-duration: 0.15s;
  transition-property: border, background-color, color, box-shadow;
  transition-timing-function: ease-in;
}
[cjInput]::placeholder {
  font-family: var(--input-placeholder-text-font-family);
  color: var(--input-placeholder-text-color);
  text-overflow: ellipsis;
}
[cjInput]:focus {
  outline: none;
  border-color: var(--input-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-primary-outline-color);
}
[cjInput]:hover {
  border-color: var(--input-hover-border-color);
}
[cjInput].input-full-width {
  width: 100%;
}
[cjInput]:disabled {
  background-color: var(--input-disabled-background-color);
  border-color: var(--input-disabled-border-color);
  color: var(--input-disabled-text-color);
}
[cjInput].status-primary {
  border-color: var(--input-primary-border-color);
}
[cjInput].status-primary:focus {
  border-color: var(--input-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-primary-outline-color);
}
[cjInput].status-primary:hover {
  border-color: var(--input-primary-hover-border-color);
}
[cjInput].status-success {
  border-color: var(--input-success-border-color);
}
[cjInput].status-success:focus {
  border-color: var(--input-success-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-success-outline-color);
}
[cjInput].status-success:hover {
  border-color: var(--input-success-hover-border-color);
}
[cjInput].status-warning {
  border-color: var(--input-warning-border-color);
}
[cjInput].status-warning:focus {
  border-color: var(--input-warning-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-warning-outline-color);
}
[cjInput].status-warning:hover {
  border-color: var(--input-warning-hover-border-color);
}
[cjInput].status-danger {
  border-color: var(--input-danger-border-color);
}
[cjInput].status-danger:focus {
  border-color: var(--input-danger-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-danger-outline-color);
}
[cjInput].status-danger:hover {
  border-color: var(--input-danger-hover-border-color);
}
[cjInput].status-info {
  border-color: var(--input-info-border-color);
}
[cjInput].status-info:focus {
  border-color: var(--input-info-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-info-outline-color);
}
[cjInput].status-info:hover {
  border-color: var(--input-info-hover-border-color);
}
[cjInput].size-small {
  font-size: var(--input-small-text-font-size);
  font-weight: var(--input-small-text-font-weight);
  line-height: var(--input-small-text-line-height);
  padding: var(--input-small-padding);
}
[cjInput].size-small:not(.input-full-width) {
  max-width: var(--input-small-max-width);
  width: var(--input-small-max-width);
}
[cjInput].size-medium {
  font-size: var(--input-medium-text-font-size);
  font-weight: var(--input-medium-text-font-weight);
  line-height: var(--input-medium-text-line-height);
  padding: var(--input-medium-padding);
}
[cjInput].size-medium:not(.input-full-width) {
  max-width: var(--input-medium-max-width);
  width: var(--input-medium-max-width);
}
[cjInput].size-large {
  font-size: var(--input-large-text-font-size);
  font-weight: var(--input-large-text-font-weight);
  line-height: var(--input-large-text-line-height);
  padding: var(--input-large-padding);
}
[cjInput].size-large:not(.input-full-width) {
  max-width: var(--input-large-max-width);
  width: var(--input-large-max-width);
}

cj-form-field [cjInput] {
  width: 100%;
}

[dir=ltr] .cj-form-field-control-with-prefix [cjInput].size-small {
  padding-left: var(--form-field-addon-small-padding);
}
[dir=rtl] .cj-form-field-control-with-prefix [cjInput].size-small {
  padding-right: var(--form-field-addon-small-padding);
}

[dir=ltr] .cj-form-field-control-with-suffix [cjInput].size-small,
[dir=ltr] .cj-form-field-control-with-clear [cjInput].size-small {
  padding-right: var(--form-field-addon-small-padding);
}
[dir=rtl] .cj-form-field-control-with-suffix [cjInput].size-small,
[dir=rtl] .cj-form-field-control-with-clear [cjInput].size-small {
  padding-left: var(--form-field-addon-small-padding);
}

[dir=ltr] .cj-form-field-control-with-prefix [cjInput].size-medium {
  padding-left: var(--form-field-addon-medium-padding);
}
[dir=rtl] .cj-form-field-control-with-prefix [cjInput].size-medium {
  padding-right: var(--form-field-addon-medium-padding);
}

[dir=ltr] .cj-form-field-control-with-suffix [cjInput].size-medium,
[dir=ltr] .cj-form-field-control-with-clear [cjInput].size-medium {
  padding-right: var(--form-field-addon-medium-padding);
}
[dir=rtl] .cj-form-field-control-with-suffix [cjInput].size-medium,
[dir=rtl] .cj-form-field-control-with-clear [cjInput].size-medium {
  padding-left: var(--form-field-addon-medium-padding);
}

[dir=ltr] .cj-form-field-control-with-prefix [cjInput].size-large {
  padding-left: var(--form-field-addon-large-padding);
}
[dir=rtl] .cj-form-field-control-with-prefix [cjInput].size-large {
  padding-right: var(--form-field-addon-large-padding);
}

[dir=ltr] .cj-form-field-control-with-suffix [cjInput].size-large,
[dir=ltr] .cj-form-field-control-with-clear [cjInput].size-large {
  padding-right: var(--form-field-addon-large-padding);
}
[dir=rtl] .cj-form-field-control-with-suffix [cjInput].size-large,
[dir=rtl] .cj-form-field-control-with-clear [cjInput].size-large {
  padding-left: var(--form-field-addon-large-padding);
}

cj-form-field.required cj-form-label::after {
  color: var(--color-danger-active);
}

.cj-form-control-container {
  max-width: inherit;
}

.cj-form-field-disabled cj-form-label {
  color: var(--text-hint-color);
}

.cj-form-field-addon {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: var(--form-field-addon-text-color);
}
.cj-form-field-addon-disabled {
  color: var(--form-field-addon-disabled-text-color);
}
.cj-form-field-addon [cjButton] {
  color: var(--form-field-addon-text-color) !important;
}

.cj-form-control-hint {
  color: var(--text-hint-color);
}

.cj-form-control-error {
  color: var(--color-danger-active);
}

.cj-form-control-clear-icon {
  color: var(--color-cool-gray-60);
}

cj-form-field.cj-transition .cj-form-field-addon {
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: ease-in;
}

.cj-form-field-limited-width.cj-form-field-size-small {
  max-width: var(--form-field-small-max-width);
}

[dir=ltr] .cj-form-field-size-small .cj-form-control-clear-icon {
  margin-left: calc(var(--form-field-addon-small-width) * -0.8);
}
[dir=rtl] .cj-form-field-size-small .cj-form-control-clear-icon {
  margin-right: calc(var(--form-field-addon-small-width) * -0.8);
}
.cj-form-field-size-small .cj-form-control-clear-icon cj-icon {
  font-size: calc(cj-theme(form-field-addon-small-icon-size) + 0.25rem);
  line-height: calc(cj-theme(form-field-addon-small-icon-size) + 0.25rem);
}

.cj-form-field-prefix-small,
.cj-form-field-suffix-small {
  height: var(--form-field-addon-small-height);
  width: var(--form-field-addon-small-width);
  font-size: var(--form-field-addon-small-font-size);
  line-height: var(--form-field-addon-small-line-height);
}
.cj-form-field-prefix-small cj-icon,
.cj-form-field-suffix-small cj-icon {
  font-size: var(--form-field-addon-small-icon-size);
  line-height: var(--form-field-addon-small-icon-size);
}

[dir=ltr] .cj-form-field-prefix-small {
  margin-right: calc(var(--form-field-addon-small-width) * -1);
}
[dir=rtl] .cj-form-field-prefix-small {
  margin-left: calc(var(--form-field-addon-small-width) * -1);
}

[dir=ltr] .cj-form-field-suffix-small {
  margin-left: calc(var(--form-field-addon-small-width) * -1);
}
[dir=rtl] .cj-form-field-suffix-small {
  margin-right: calc(var(--form-field-addon-small-width) * -1);
}

.cj-form-field-limited-width.cj-form-field-size-medium {
  max-width: var(--form-field-medium-max-width);
}

[dir=ltr] .cj-form-field-size-medium .cj-form-control-clear-icon {
  margin-left: calc(var(--form-field-addon-medium-width) * -0.8);
}
[dir=rtl] .cj-form-field-size-medium .cj-form-control-clear-icon {
  margin-right: calc(var(--form-field-addon-medium-width) * -0.8);
}
.cj-form-field-size-medium .cj-form-control-clear-icon cj-icon {
  font-size: calc(cj-theme(form-field-addon-medium-icon-size) + 0.25rem);
  line-height: calc(cj-theme(form-field-addon-medium-icon-size) + 0.25rem);
}

.cj-form-field-prefix-medium,
.cj-form-field-suffix-medium {
  height: var(--form-field-addon-medium-height);
  width: var(--form-field-addon-medium-width);
  font-size: var(--form-field-addon-medium-font-size);
  line-height: var(--form-field-addon-medium-line-height);
}
.cj-form-field-prefix-medium cj-icon,
.cj-form-field-suffix-medium cj-icon {
  font-size: var(--form-field-addon-medium-icon-size);
  line-height: var(--form-field-addon-medium-icon-size);
}

[dir=ltr] .cj-form-field-prefix-medium {
  margin-right: calc(var(--form-field-addon-medium-width) * -1);
}
[dir=rtl] .cj-form-field-prefix-medium {
  margin-left: calc(var(--form-field-addon-medium-width) * -1);
}

[dir=ltr] .cj-form-field-suffix-medium {
  margin-left: calc(var(--form-field-addon-medium-width) * -1);
}
[dir=rtl] .cj-form-field-suffix-medium {
  margin-right: calc(var(--form-field-addon-medium-width) * -1);
}

.cj-form-field-limited-width.cj-form-field-size-large {
  max-width: var(--form-field-large-max-width);
}

[dir=ltr] .cj-form-field-size-large .cj-form-control-clear-icon {
  margin-left: calc(var(--form-field-addon-large-width) * -0.8);
}
[dir=rtl] .cj-form-field-size-large .cj-form-control-clear-icon {
  margin-right: calc(var(--form-field-addon-large-width) * -0.8);
}
.cj-form-field-size-large .cj-form-control-clear-icon cj-icon {
  font-size: calc(cj-theme(form-field-addon-large-icon-size) + 0.25rem);
  line-height: calc(cj-theme(form-field-addon-large-icon-size) + 0.25rem);
}

.cj-form-field-prefix-large,
.cj-form-field-suffix-large {
  height: var(--form-field-addon-large-height);
  width: var(--form-field-addon-large-width);
  font-size: var(--form-field-addon-large-font-size);
  line-height: var(--form-field-addon-large-line-height);
}
.cj-form-field-prefix-large cj-icon,
.cj-form-field-suffix-large cj-icon {
  font-size: var(--form-field-addon-large-icon-size);
  line-height: var(--form-field-addon-large-icon-size);
}

[dir=ltr] .cj-form-field-prefix-large {
  margin-right: calc(var(--form-field-addon-large-width) * -1);
}
[dir=rtl] .cj-form-field-prefix-large {
  margin-left: calc(var(--form-field-addon-large-width) * -1);
}

[dir=ltr] .cj-form-field-suffix-large {
  margin-left: calc(var(--form-field-addon-large-width) * -1);
}
[dir=rtl] .cj-form-field-suffix-large {
  margin-right: calc(var(--form-field-addon-large-width) * -1);
}

cj-checkbox .label {
  padding: var(--checkbox-padding);
}
cj-checkbox .custom-checkbox {
  border-style: var(--checkbox-border-style);
  border-width: var(--checkbox-border-width);
  border-radius: var(--checkbox-border-radius);
  background-color: var(--checkbox-background-color);
  position: relative;
}
cj-checkbox .custom-checkbox-icon {
  color: var(--checkbox-checked-checkmark-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}
cj-checkbox .text {
  color: var(--checkbox-text-color);
  font-weight: var(--checkbox-text-font-weight);
}
cj-checkbox.size-small .custom-checkbox {
  width: var(--checkbox-small-width);
  height: var(--checkbox-small-width);
}
cj-checkbox.size-small .text {
  font-size: var(--checkbox-small-text-font-size);
  line-height: var(--checkbox-small-text-line-height);
}
[dir=ltr] cj-checkbox.size-small .text:not(:empty) {
  padding-left: var(--checkbox-small-text-space);
}
[dir=rtl] cj-checkbox.size-small .text:not(:empty) {
  padding-right: var(--checkbox-small-text-space);
}
cj-checkbox.size-medium .custom-checkbox {
  width: var(--checkbox-medium-width);
  height: var(--checkbox-medium-width);
}
cj-checkbox.size-medium .text {
  font-size: var(--checkbox-medium-text-font-size);
  line-height: var(--checkbox-medium-text-line-height);
}
[dir=ltr] cj-checkbox.size-medium .text:not(:empty) {
  padding-left: var(--checkbox-medium-text-space);
}
[dir=rtl] cj-checkbox.size-medium .text:not(:empty) {
  padding-right: var(--checkbox-medium-text-space);
}
cj-checkbox.size-large .custom-checkbox {
  width: var(--checkbox-large-width);
  height: var(--checkbox-large-width);
}
cj-checkbox.size-large .text {
  font-size: var(--checkbox-large-text-font-size);
  line-height: var(--checkbox-large-text-line-height);
}
[dir=ltr] cj-checkbox.size-large .text:not(:empty) {
  padding-left: var(--checkbox-large-text-space);
}
[dir=rtl] cj-checkbox.size-large .text:not(:empty) {
  padding-right: var(--checkbox-large-text-space);
}

cj-checkbox.status-primary .custom-checkbox {
  border-color: var(--checkbox-primary-border-color);
}
cj-checkbox.status-primary .custom-checkbox.checked {
  background-color: var(--checkbox-primary-checked-background-color);
  border-color: var(--checkbox-primary-checked-border-color);
}
cj-checkbox.status-primary .custom-checkbox.indeterminate {
  background-color: var(--checkbox-primary-indeterminate-background-color);
  border-color: var(--checkbox-primary-indeterminate-border-color);
}
cj-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--checkbox-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--checkbox-primary-focus-outline-color);
}
cj-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox.indeterminate, cj-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--checkbox-primary-focus-checked-background-color);
  border-color: var(--checkbox-primary-focus-checked-border-color);
}
cj-checkbox.status-primary .custom-checkbox:hover {
  border-color: var(--checkbox-primary-hover-border-color);
}
cj-checkbox.status-primary .custom-checkbox:hover.indeterminate, cj-checkbox.status-primary .custom-checkbox:hover.checked {
  background-color: var(--checkbox-primary-hover-checked-background-color);
  border-color: var(--checkbox-primary-hover-checked-border-color);
}
cj-checkbox.status-primary .native-input:enabled:active + .custom-checkbox {
  border-color: var(--checkbox-primary-active-border-color);
}
cj-checkbox.status-primary .native-input:enabled:active + .custom-checkbox.indeterminate, cj-checkbox.status-primary .native-input:enabled:active + .custom-checkbox.checked {
  background-color: var(--checkbox-primary-active-checked-background-color);
  border-color: var(--checkbox-primary-active-checked-border-color);
}
cj-checkbox.status-primary .native-input:disabled + .custom-checkbox {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}
cj-checkbox.status-primary .native-input:disabled ~ .text {
  color: var(--checkbox-disabled-text-color);
}
cj-checkbox.status-primary .native-input:disabled:indeterminate + .custom-checkbox, cj-checkbox.status-primary .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--checkbox-disabled-checked-background-color);
  border-color: var(--checkbox-disabled-checked-border-color);
}

cj-checkbox.status-success .custom-checkbox {
  border-color: var(--checkbox-success-border-color);
}
cj-checkbox.status-success .custom-checkbox.checked {
  background-color: var(--checkbox-success-checked-background-color);
  border-color: var(--checkbox-success-checked-border-color);
}
cj-checkbox.status-success .custom-checkbox.indeterminate {
  background-color: var(--checkbox-success-indeterminate-background-color);
  border-color: var(--checkbox-success-indeterminate-border-color);
}
cj-checkbox.status-success .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--checkbox-success-focus-border-color);
  box-shadow: 0 0 0 4px var(--checkbox-success-focus-outline-color);
}
cj-checkbox.status-success .native-input:enabled:focus + .custom-checkbox.indeterminate, cj-checkbox.status-success .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--checkbox-success-focus-checked-background-color);
  border-color: var(--checkbox-success-focus-checked-border-color);
}
cj-checkbox.status-success .custom-checkbox:hover {
  border-color: var(--checkbox-success-hover-border-color);
}
cj-checkbox.status-success .custom-checkbox:hover.indeterminate, cj-checkbox.status-success .custom-checkbox:hover.checked {
  background-color: var(--checkbox-success-hover-checked-background-color);
  border-color: var(--checkbox-success-hover-checked-border-color);
}
cj-checkbox.status-success .native-input:enabled:active + .custom-checkbox {
  border-color: var(--checkbox-success-active-border-color);
}
cj-checkbox.status-success .native-input:enabled:active + .custom-checkbox.indeterminate, cj-checkbox.status-success .native-input:enabled:active + .custom-checkbox.checked {
  background-color: var(--checkbox-success-active-checked-background-color);
  border-color: var(--checkbox-success-active-checked-border-color);
}
cj-checkbox.status-success .native-input:disabled + .custom-checkbox {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}
cj-checkbox.status-success .native-input:disabled ~ .text {
  color: var(--checkbox-disabled-text-color);
}
cj-checkbox.status-success .native-input:disabled:indeterminate + .custom-checkbox, cj-checkbox.status-success .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--checkbox-disabled-checked-background-color);
  border-color: var(--checkbox-disabled-checked-border-color);
}

cj-checkbox.status-warning .custom-checkbox {
  border-color: var(--checkbox-warning-border-color);
}
cj-checkbox.status-warning .custom-checkbox.checked {
  background-color: var(--checkbox-warning-checked-background-color);
  border-color: var(--checkbox-warning-checked-border-color);
}
cj-checkbox.status-warning .custom-checkbox.indeterminate {
  background-color: var(--checkbox-warning-indeterminate-background-color);
  border-color: var(--checkbox-warning-indeterminate-border-color);
}
cj-checkbox.status-warning .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--checkbox-warning-focus-border-color);
  box-shadow: 0 0 0 4px var(--checkbox-warning-focus-outline-color);
}
cj-checkbox.status-warning .native-input:enabled:focus + .custom-checkbox.indeterminate, cj-checkbox.status-warning .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--checkbox-warning-focus-checked-background-color);
  border-color: var(--checkbox-warning-focus-checked-border-color);
}
cj-checkbox.status-warning .custom-checkbox:hover {
  border-color: var(--checkbox-warning-hover-border-color);
}
cj-checkbox.status-warning .custom-checkbox:hover.indeterminate, cj-checkbox.status-warning .custom-checkbox:hover.checked {
  background-color: var(--checkbox-warning-hover-checked-background-color);
  border-color: var(--checkbox-warning-hover-checked-border-color);
}
cj-checkbox.status-warning .native-input:enabled:active + .custom-checkbox {
  border-color: var(--checkbox-warning-active-border-color);
}
cj-checkbox.status-warning .native-input:enabled:active + .custom-checkbox.indeterminate, cj-checkbox.status-warning .native-input:enabled:active + .custom-checkbox.checked {
  background-color: var(--checkbox-warning-active-checked-background-color);
  border-color: var(--checkbox-warning-active-checked-border-color);
}
cj-checkbox.status-warning .native-input:disabled + .custom-checkbox {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}
cj-checkbox.status-warning .native-input:disabled ~ .text {
  color: var(--checkbox-disabled-text-color);
}
cj-checkbox.status-warning .native-input:disabled:indeterminate + .custom-checkbox, cj-checkbox.status-warning .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--checkbox-disabled-checked-background-color);
  border-color: var(--checkbox-disabled-checked-border-color);
}

cj-checkbox.status-danger .custom-checkbox {
  border-color: var(--checkbox-danger-border-color);
}
cj-checkbox.status-danger .custom-checkbox.checked {
  background-color: var(--checkbox-danger-checked-background-color);
  border-color: var(--checkbox-danger-checked-border-color);
}
cj-checkbox.status-danger .custom-checkbox.indeterminate {
  background-color: var(--checkbox-danger-indeterminate-background-color);
  border-color: var(--checkbox-danger-indeterminate-border-color);
}
cj-checkbox.status-danger .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--checkbox-danger-focus-border-color);
  box-shadow: 0 0 0 4px var(--checkbox-danger-focus-outline-color);
}
cj-checkbox.status-danger .native-input:enabled:focus + .custom-checkbox.indeterminate, cj-checkbox.status-danger .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--checkbox-danger-focus-checked-background-color);
  border-color: var(--checkbox-danger-focus-checked-border-color);
}
cj-checkbox.status-danger .custom-checkbox:hover {
  border-color: var(--checkbox-danger-hover-border-color);
}
cj-checkbox.status-danger .custom-checkbox:hover.indeterminate, cj-checkbox.status-danger .custom-checkbox:hover.checked {
  background-color: var(--checkbox-danger-hover-checked-background-color);
  border-color: var(--checkbox-danger-hover-checked-border-color);
}
cj-checkbox.status-danger .native-input:enabled:active + .custom-checkbox {
  border-color: var(--checkbox-danger-active-border-color);
}
cj-checkbox.status-danger .native-input:enabled:active + .custom-checkbox.indeterminate, cj-checkbox.status-danger .native-input:enabled:active + .custom-checkbox.checked {
  background-color: var(--checkbox-danger-active-checked-background-color);
  border-color: var(--checkbox-danger-active-checked-border-color);
}
cj-checkbox.status-danger .native-input:disabled + .custom-checkbox {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}
cj-checkbox.status-danger .native-input:disabled ~ .text {
  color: var(--checkbox-disabled-text-color);
}
cj-checkbox.status-danger .native-input:disabled:indeterminate + .custom-checkbox, cj-checkbox.status-danger .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--checkbox-disabled-checked-background-color);
  border-color: var(--checkbox-disabled-checked-border-color);
}

cj-checkbox.status-info .custom-checkbox {
  border-color: var(--checkbox-info-border-color);
}
cj-checkbox.status-info .custom-checkbox.checked {
  background-color: var(--checkbox-info-checked-background-color);
  border-color: var(--checkbox-info-checked-border-color);
}
cj-checkbox.status-info .custom-checkbox.indeterminate {
  background-color: var(--checkbox-info-indeterminate-background-color);
  border-color: var(--checkbox-info-indeterminate-border-color);
}
cj-checkbox.status-info .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--checkbox-info-focus-border-color);
  box-shadow: 0 0 0 4px var(--checkbox-info-focus-outline-color);
}
cj-checkbox.status-info .native-input:enabled:focus + .custom-checkbox.indeterminate, cj-checkbox.status-info .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--checkbox-info-focus-checked-background-color);
  border-color: var(--checkbox-info-focus-checked-border-color);
}
cj-checkbox.status-info .custom-checkbox:hover {
  border-color: var(--checkbox-info-hover-border-color);
}
cj-checkbox.status-info .custom-checkbox:hover.indeterminate, cj-checkbox.status-info .custom-checkbox:hover.checked {
  background-color: var(--checkbox-info-hover-checked-background-color);
  border-color: var(--checkbox-info-hover-checked-border-color);
}
cj-checkbox.status-info .native-input:enabled:active + .custom-checkbox {
  border-color: var(--checkbox-info-active-border-color);
}
cj-checkbox.status-info .native-input:enabled:active + .custom-checkbox.indeterminate, cj-checkbox.status-info .native-input:enabled:active + .custom-checkbox.checked {
  background-color: var(--checkbox-info-active-checked-background-color);
  border-color: var(--checkbox-info-active-checked-border-color);
}
cj-checkbox.status-info .native-input:disabled + .custom-checkbox {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}
cj-checkbox.status-info .native-input:disabled ~ .text {
  color: var(--checkbox-disabled-text-color);
}
cj-checkbox.status-info .native-input:disabled:indeterminate + .custom-checkbox, cj-checkbox.status-info .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--checkbox-disabled-checked-background-color);
  border-color: var(--checkbox-disabled-checked-border-color);
}

cj-radio .outer-circle {
  border-style: var(--radio-border-style);
  border-width: var(--radio-border-width);
  background-color: var(--radio-background-color);
}
cj-radio .native-input:disabled + .outer-circle {
  background-color: var(--radio-disabled-background-color);
  border-color: var(--radio-disabled-border-color);
}
cj-radio .native-input:disabled ~ .text {
  color: var(--radio-disabled-text-color);
}
cj-radio .native-input:disabled:checked + .outer-circle {
  background-color: var(--radio-disabled-checked-background-color);
  border-color: var(--radio-disabled-checked-border-color);
}
cj-radio .native-input:disabled:checked ~ .inner-circle {
  background-color: var(--radio-disabled-checked-inner-circle-color);
}
cj-radio .text {
  font-weight: var(--radio-text-font-weight);
  color: var(--radio-text-color);
}
cj-radio.status-primary .native-input:enabled + .outer-circle {
  border-color: var(--radio-primary-border-color);
}
cj-radio.status-primary .native-input:enabled:checked + .outer-circle {
  border-color: var(--radio-primary-checked-border-color);
}
cj-radio.status-primary .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--radio-primary-inner-circle-color);
}
cj-radio.status-primary .native-input:enabled:focus + .outer-circle {
  border-color: var(--radio-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--radio-primary-focus-outline-color);
}
cj-radio.status-primary .native-input:enabled:checked:focus + .outer-circle {
  border-color: var(--radio-primary-focus-checked-border-color);
  box-shadow: 0 0 0 4px var(--radio-primary-focus-outline-color);
}
cj-radio.status-primary .native-input:enabled:checked:focus ~ .inner-circle {
  background-color: var(--radio-primary-focus-inner-circle-color);
}
cj-radio.status-primary label:hover .native-input:enabled + .outer-circle {
  border-color: var(--radio-primary-hover-border-color);
}
cj-radio.status-primary label:hover .native-input:checked:enabled + .outer-circle {
  border-color: var(--radio-primary-hover-checked-border-color);
}
cj-radio.status-primary label:hover .native-input:checked:enabled ~ .inner-circle {
  background-color: var(--radio-primary-hover-inner-circle-color);
}
cj-radio.status-primary label .native-input:enabled:active + .outer-circle {
  border-color: var(--radio-primary-active-border-color);
}
cj-radio.status-primary label .native-input:enabled:checked:active + .outer-circle {
  border-color: var(--radio-primary-active-checked-border-color);
}
cj-radio.status-primary label .native-input:enabled:checked:active ~ .inner-circle {
  background-color: var(--radio-primary-active-inner-circle-color);
}
cj-radio.status-success .native-input:enabled + .outer-circle {
  border-color: var(--radio-success-border-color);
}
cj-radio.status-success .native-input:enabled:checked + .outer-circle {
  border-color: var(--radio-success-checked-border-color);
}
cj-radio.status-success .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--radio-success-inner-circle-color);
}
cj-radio.status-success .native-input:enabled:focus + .outer-circle {
  border-color: var(--radio-success-focus-border-color);
  box-shadow: 0 0 0 4px var(--radio-success-focus-outline-color);
}
cj-radio.status-success .native-input:enabled:checked:focus + .outer-circle {
  border-color: var(--radio-success-focus-checked-border-color);
  box-shadow: 0 0 0 4px var(--radio-success-focus-outline-color);
}
cj-radio.status-success .native-input:enabled:checked:focus ~ .inner-circle {
  background-color: var(--radio-success-focus-inner-circle-color);
}
cj-radio.status-success label:hover .native-input:enabled + .outer-circle {
  border-color: var(--radio-success-hover-border-color);
}
cj-radio.status-success label:hover .native-input:checked:enabled + .outer-circle {
  border-color: var(--radio-success-hover-checked-border-color);
}
cj-radio.status-success label:hover .native-input:checked:enabled ~ .inner-circle {
  background-color: var(--radio-success-hover-inner-circle-color);
}
cj-radio.status-success label .native-input:enabled:active + .outer-circle {
  border-color: var(--radio-success-active-border-color);
}
cj-radio.status-success label .native-input:enabled:checked:active + .outer-circle {
  border-color: var(--radio-success-active-checked-border-color);
}
cj-radio.status-success label .native-input:enabled:checked:active ~ .inner-circle {
  background-color: var(--radio-success-active-inner-circle-color);
}
cj-radio.status-warning .native-input:enabled + .outer-circle {
  border-color: var(--radio-warning-border-color);
}
cj-radio.status-warning .native-input:enabled:checked + .outer-circle {
  border-color: var(--radio-warning-checked-border-color);
}
cj-radio.status-warning .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--radio-warning-inner-circle-color);
}
cj-radio.status-warning .native-input:enabled:focus + .outer-circle {
  border-color: var(--radio-warning-focus-border-color);
  box-shadow: 0 0 0 4px var(--radio-warning-focus-outline-color);
}
cj-radio.status-warning .native-input:enabled:checked:focus + .outer-circle {
  border-color: var(--radio-warning-focus-checked-border-color);
  box-shadow: 0 0 0 4px var(--radio-warning-focus-outline-color);
}
cj-radio.status-warning .native-input:enabled:checked:focus ~ .inner-circle {
  background-color: var(--radio-warning-focus-inner-circle-color);
}
cj-radio.status-warning label:hover .native-input:enabled + .outer-circle {
  border-color: var(--radio-warning-hover-border-color);
}
cj-radio.status-warning label:hover .native-input:checked:enabled + .outer-circle {
  border-color: var(--radio-warning-hover-checked-border-color);
}
cj-radio.status-warning label:hover .native-input:checked:enabled ~ .inner-circle {
  background-color: var(--radio-warning-hover-inner-circle-color);
}
cj-radio.status-warning label .native-input:enabled:active + .outer-circle {
  border-color: var(--radio-warning-active-border-color);
}
cj-radio.status-warning label .native-input:enabled:checked:active + .outer-circle {
  border-color: var(--radio-warning-active-checked-border-color);
}
cj-radio.status-warning label .native-input:enabled:checked:active ~ .inner-circle {
  background-color: var(--radio-warning-active-inner-circle-color);
}
cj-radio.status-danger .native-input:enabled + .outer-circle {
  border-color: var(--radio-danger-border-color);
}
cj-radio.status-danger .native-input:enabled:checked + .outer-circle {
  border-color: var(--radio-danger-checked-border-color);
}
cj-radio.status-danger .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--radio-danger-inner-circle-color);
}
cj-radio.status-danger .native-input:enabled:focus + .outer-circle {
  border-color: var(--radio-danger-focus-border-color);
  box-shadow: 0 0 0 4px var(--radio-danger-focus-outline-color);
}
cj-radio.status-danger .native-input:enabled:checked:focus + .outer-circle {
  border-color: var(--radio-danger-focus-checked-border-color);
  box-shadow: 0 0 0 4px var(--radio-danger-focus-outline-color);
}
cj-radio.status-danger .native-input:enabled:checked:focus ~ .inner-circle {
  background-color: var(--radio-danger-focus-inner-circle-color);
}
cj-radio.status-danger label:hover .native-input:enabled + .outer-circle {
  border-color: var(--radio-danger-hover-border-color);
}
cj-radio.status-danger label:hover .native-input:checked:enabled + .outer-circle {
  border-color: var(--radio-danger-hover-checked-border-color);
}
cj-radio.status-danger label:hover .native-input:checked:enabled ~ .inner-circle {
  background-color: var(--radio-danger-hover-inner-circle-color);
}
cj-radio.status-danger label .native-input:enabled:active + .outer-circle {
  border-color: var(--radio-danger-active-border-color);
}
cj-radio.status-danger label .native-input:enabled:checked:active + .outer-circle {
  border-color: var(--radio-danger-active-checked-border-color);
}
cj-radio.status-danger label .native-input:enabled:checked:active ~ .inner-circle {
  background-color: var(--radio-danger-active-inner-circle-color);
}
cj-radio.status-info .native-input:enabled + .outer-circle {
  border-color: var(--radio-info-border-color);
}
cj-radio.status-info .native-input:enabled:checked + .outer-circle {
  border-color: var(--radio-info-checked-border-color);
}
cj-radio.status-info .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--radio-info-inner-circle-color);
}
cj-radio.status-info .native-input:enabled:focus + .outer-circle {
  border-color: var(--radio-info-focus-border-color);
  box-shadow: 0 0 0 4px var(--radio-info-focus-outline-color);
}
cj-radio.status-info .native-input:enabled:checked:focus + .outer-circle {
  border-color: var(--radio-info-focus-checked-border-color);
  box-shadow: 0 0 0 4px var(--radio-info-focus-outline-color);
}
cj-radio.status-info .native-input:enabled:checked:focus ~ .inner-circle {
  background-color: var(--radio-info-focus-inner-circle-color);
}
cj-radio.status-info label:hover .native-input:enabled + .outer-circle {
  border-color: var(--radio-info-hover-border-color);
}
cj-radio.status-info label:hover .native-input:checked:enabled + .outer-circle {
  border-color: var(--radio-info-hover-checked-border-color);
}
cj-radio.status-info label:hover .native-input:checked:enabled ~ .inner-circle {
  background-color: var(--radio-info-hover-inner-circle-color);
}
cj-radio.status-info label .native-input:enabled:active + .outer-circle {
  border-color: var(--radio-info-active-border-color);
}
cj-radio.status-info label .native-input:enabled:checked:active + .outer-circle {
  border-color: var(--radio-info-active-checked-border-color);
}
cj-radio.status-info label .native-input:enabled:checked:active ~ .inner-circle {
  background-color: var(--radio-info-active-inner-circle-color);
}
cj-radio.size-small .outer-circle,
cj-radio.size-small .inner-circle {
  width: var(--radio-small-width);
  height: var(--radio-small-width);
}
cj-radio.size-small .text {
  font-size: var(--radio-small-text-font-size);
  line-height: var(--radio-small-text-line-height);
}
[dir=ltr] cj-radio.size-small .text {
  margin-left: var(--radio-small-width);
}
[dir=rtl] cj-radio.size-small .text {
  margin-right: var(--radio-small-width);
}
[dir=ltr] cj-radio.size-small .text {
  padding-left: var(--radio-small-text-space);
}
[dir=rtl] cj-radio.size-small .text {
  padding-right: var(--radio-small-text-space);
}
cj-radio.size-medium .outer-circle,
cj-radio.size-medium .inner-circle {
  width: var(--radio-medium-width);
  height: var(--radio-medium-width);
}
cj-radio.size-medium .text {
  font-size: var(--radio-medium-text-font-size);
  line-height: var(--radio-medium-text-line-height);
}
[dir=ltr] cj-radio.size-medium .text {
  margin-left: var(--radio-medium-width);
}
[dir=rtl] cj-radio.size-medium .text {
  margin-right: var(--radio-medium-width);
}
[dir=ltr] cj-radio.size-medium .text {
  padding-left: var(--radio-medium-text-space);
}
[dir=rtl] cj-radio.size-medium .text {
  padding-right: var(--radio-medium-text-space);
}
cj-radio.size-large .outer-circle,
cj-radio.size-large .inner-circle {
  width: var(--radio-large-width);
  height: var(--radio-large-width);
}
cj-radio.size-large .text {
  font-size: var(--radio-large-text-font-size);
  line-height: var(--radio-large-text-line-height);
}
[dir=ltr] cj-radio.size-large .text {
  margin-left: var(--radio-large-width);
}
[dir=rtl] cj-radio.size-large .text {
  margin-right: var(--radio-large-width);
}
[dir=ltr] cj-radio.size-large .text {
  padding-left: var(--radio-large-text-space);
}
[dir=rtl] cj-radio.size-large .text {
  padding-right: var(--radio-large-text-space);
}

cj-tabset {
  background-color: var(--tabset-background-color);
}
cj-tabset .tabset {
  border-bottom: var(--tabset-divider-width) var(--tabset-divider-style) var(--tabset-divider-color);
  gap: var(--tabset-tab-gap);
}
cj-tabset .tab {
  border-radius: var(--tabset-tab-border-radius);
  max-width: var(--tabset-tab-max-width);
}
cj-tabset .tab-link {
  font-weight: var(--tabset-tab-text-font-weight);
  line-height: var(--tabset-tab-text-line-height);
  background-color: var(--tabset-tab-background-color);
  padding: var(--tabset-tab-padding);
  color: var(--tabset-tab-text-color);
  cursor: pointer;
}
cj-tabset .tab:focus .tab-link {
  background-color: var(--tabset-tab-focus-background-color);
  color: var(--tabset-tab-focus-text-color);
}
cj-tabset .tab:hover .tab-link {
  color: var(--tabset-tab-hover-text-color);
  background-color: var(--tabset-tab-hover-background-color);
}
cj-tabset .tab.active:hover,
cj-tabset .tab.active .tab-link {
  background-color: var(--tabset-tab-active-background-color);
  color: var(--tabset-tab-active-text-color);
}
cj-tabset .tab.disabled {
  cursor: default;
  pointer-events: none;
}
cj-tabset .tab.disabled .tab-link {
  background-color: var(--tabset-tab-disabled-background-color);
  color: var(--tabset-tab-disabled-text-color);
  cursor: default;
  pointer-events: none;
}
@media screen and (max-width: 36rem) {
  cj-tabset .tab.responsive .tab-text {
    display: none;
  }
}
cj-tabset cj-tab {
  background-color: var(--tabset-content-background-color);
  padding: var(--tabset-content-padding);
  scrollbar-face-color: var(--tabset-scrollbar-color);
  scrollbar-track-color: var(--tabset-scrollbar-background-color);
}
cj-tabset cj-tab::-webkit-scrollbar {
  width: var(--tabset-scrollbar-width);
  height: var(--tabset-scrollbar-width);
}
cj-tabset cj-tab::-webkit-scrollbar-thumb {
  background: var(--tabset-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--tabset-scrollbar-width) * 0.5);
}
cj-tabset cj-tab::-webkit-scrollbar-track {
  background: var(--tabset-scrollbar-background-color);
  border-radius: calc(var(--tabset-scrollbar-width) * 0.5);
}
cj-tabset cj-tab::-webkit-scrollbar-corner {
  background: var(--tabset-scrollbar-background-color);
}

cj-route-tabset {
  scrollbar-face-color: var(--route-tabset-scrollbar-color);
  scrollbar-track-color: var(--route-tabset-scrollbar-background-color);
  background-color: var(--route-tabset-background-color);
}
cj-route-tabset::-webkit-scrollbar {
  width: var(--route-tabset-scrollbar-width);
  height: var(--route-tabset-scrollbar-width);
}
cj-route-tabset::-webkit-scrollbar-thumb {
  background: var(--route-tabset-scrollbar-color);
  cursor: pointer;
  border-radius: calc(var(--route-tabset-scrollbar-width) * 0.5);
}
cj-route-tabset::-webkit-scrollbar-track {
  background: var(--route-tabset-scrollbar-background-color);
  border-radius: calc(var(--route-tabset-scrollbar-width) * 0.5);
}
cj-route-tabset::-webkit-scrollbar-corner {
  background: var(--route-tabset-scrollbar-background-color);
}
cj-route-tabset .route-tabset {
  border-bottom: var(--route-tabset-divider-width) var(--route-tabset-divider-style) var(--route-tabset-divider-color);
  gap: var(--tabset-tab-gap);
}
cj-route-tabset .route-tab {
  border-radius: var(--route-tabset-tab-border-radius);
  max-width: var(--route-tabset-tab-max-width);
}
cj-route-tabset .tab-link {
  background-color: var(--route-tabset-tab-background-color);
  padding: var(--route-tabset-tab-padding);
  color: var(--route-tabset-tab-text-color);
  font-weight: var(--route-tabset-tab-text-font-weight);
  line-height: var(--route-tabset-tab-text-line-height);
  cursor: pointer;
}
cj-route-tabset .tab-link:focus {
  background-color: var(--route-tabset-tab-focus-background-color);
  color: var(--route-tabset-tab-focus-text-color);
}
cj-route-tabset .tab-link:hover {
  background-color: var(--route-tabset-tab-hover-background-color);
  color: var(--route-tabset-tab-hover-text-color);
}
cj-route-tabset .route-tab.active:hover,
cj-route-tabset .route-tab.active .tab-link {
  background-color: var(--tabset-tab-active-background-color);
  color: var(--tabset-tab-active-text-color);
}
cj-route-tabset .route-tab.disabled {
  cursor: default;
  pointer-events: none;
}
cj-route-tabset .route-tab.disabled .tab-link {
  background-color: var(--route-tabset-tab-disabled-background-color);
  color: var(--route-tabset-tab-disabled-text-color);
  cursor: default;
  pointer-events: none;
}
@media screen and (max-width: 36rem) {
  cj-route-tabset .route-tab.responsive .tab-text {
    display: none;
  }
}

cj-tooltip {
  background: var(--tooltip-background-color);
  border-radius: var(--tooltip-border-radius);
  padding: var(--tooltip-padding);
  max-width: var(--tooltip-max-width);
}
cj-tooltip cj-icon:only-child {
  height: var(--tooltip-icon-height);
  width: var(--tooltip-icon-width);
  font-size: var(--tooltip-text-font-size);
  line-height: var(--tooltip-text-font-size);
}
cj-tooltip cj-icon:not(:only-child) {
  height: var(--tooltip-text-font-size);
  width: var(--tooltip-text-font-size);
  font-size: var(--tooltip-text-font-size);
  line-height: var(--tooltip-text-font-size);
}
cj-tooltip .content {
  color: var(--tooltip-text-color);
  font-size: var(--tooltip-text-font-size);
  line-height: var(--tooltip-text-line-height);
}
cj-tooltip .arrow {
  border-bottom: 4px solid var(--tooltip-background-color);
}

cj-popover {
  border-radius: var(--popover-border-radius);
  background: var(--popover-background-color);
  box-shadow: var(--popover-shadow);
  color: var(--popover-text-color);
}
cj-popover .primitive-overlay {
  padding: var(--popover-padding);
}

body {
  color: var(--text-basic-color);
  font-family: var(--text-paragraph-font-family);
  font-size: var(--text-paragraph-font-size);
  font-weight: var(--text-paragraph-font-weight);
  line-height: var(--text-paragraph-line-height);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--text-basic-color);
  margin: 0;
}

h1,
.h1 {
  font-size: var(--text-heading-1-font-size);
  font-family: var(--text-heading-1-font-family);
  font-weight: var(--text-heading-1-font-weight);
  line-height: var(--text-heading-1-line-height);
}

h2,
.h2 {
  font-size: var(--text-heading-2-font-size);
  font-family: var(--text-heading-2-font-family);
  font-weight: var(--text-heading-2-font-weight);
  line-height: var(--text-heading-2-line-height);
}

h3,
.h3 {
  font-size: var(--text-heading-3-font-size);
  font-family: var(--text-heading-3-font-family);
  font-weight: var(--text-heading-3-font-weight);
  line-height: var(--text-heading-3-line-height);
}

h4,
.h4 {
  font-size: var(--text-heading-4-font-size);
  font-family: var(--text-heading-4-font-family);
  font-weight: var(--text-heading-4-font-weight);
  line-height: var(--text-heading-4-line-height);
}

h5,
.h5 {
  font-size: var(--text-heading-5-font-size);
  font-family: var(--text-heading-5-font-family);
  font-weight: var(--text-heading-5-font-weight);
  line-height: var(--text-heading-5-line-height);
}

h6,
.h6 {
  font-size: var(--text-heading-6-font-size);
  font-family: var(--text-heading-6-font-family);
  font-weight: var(--text-heading-6-font-weight);
  line-height: var(--text-heading-6-line-height);
}

.subtitle {
  color: var(--text-subtitle-color);
  font-family: var(--text-subtitle-font-family);
  font-size: var(--text-subtitle-font-size);
  font-weight: var(--text-subtitle-font-weight);
  line-height: var(--text-subtitle-line-height);
}

.subtitle-2 {
  color: var(--text-subtitle-2-color);
  font-family: var(--text-subtitle-2-font-family);
  font-size: var(--text-subtitle-2-font-size);
  font-weight: var(--text-subtitle-2-font-weight);
  line-height: var(--text-subtitle-2-line-height);
}

p,
.paragraph {
  color: var(--text-basic-color);
  font-family: var(--text-paragraph-font-family);
  font-size: var(--text-paragraph-font-size);
  font-weight: var(--text-paragraph-font-weight);
  line-height: var(--text-paragraph-line-height);
  margin-top: 0;
}

.paragraph-2 {
  color: var(--text-basic-color);
  font-family: var(--text-paragraph-2-font-family);
  font-size: var(--text-paragraph-2-font-size);
  font-weight: var(--text-paragraph-2-font-weight);
  line-height: var(--text-paragraph-2-line-height);
}

a {
  color: var(--link-text-color);
  text-decoration: var(--link-text-decoration);
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}
a:focus {
  color: var(--link-text-focus-color);
}
a:hover {
  color: var(--link-text-hover-color);
}

.label {
  color: var(--text-hint-color);
  font-family: var(--text-label-font-family);
  font-size: var(--text-label-font-size);
  font-weight: var(--text-label-font-weight);
  line-height: var(--text-label-line-height);
}

.caption {
  font-family: var(--text-caption-font-family);
  font-size: var(--text-caption-font-size);
  font-weight: var(--text-caption-font-weight);
  line-height: var(--text-caption-line-height);
}

.caption-2 {
  font-family: var(--text-caption-2-font-family);
  font-size: var(--text-caption-2-font-size);
  font-weight: var(--text-caption-2-font-weight);
  line-height: var(--text-caption-2-line-height);
}

.caption,
.caption-2 {
  color: var(--text-hint-color);
}

li {
  color: var(--list-item-text-color);
  font-family: var(--list-item-font-family);
  font-size: var(--list-item-font-size);
  font-weight: var(--list-item-font-weight);
  line-height: var(--list-item-line-height);
}

.text-disabled {
  color: var(--text-disabled-color);
}

.text-hint {
  color: var(--text-hint-color);
}

.italic,
.hover\:italic:hover,
.focus\:italic:focus,
.active\:italic:active {
  font-style: italic;
}

.not-italic,
.hover\:not-italic:hover,
.focus\:not-italic:focus,
.active\:not-italic:active {
  font-style: normal;
}

.\!italic,
.\!hover\:italic:hover,
.\!focus\:italic:focus,
.\!active\:italic:active {
  font-style: italic !important;
}

.\!not-italic,
.\!hover\:not-italic:hover,
.\!focus\:not-italic:focus,
.\!active\:not-italic:active {
  font-style: normal !important;
}

.font-thin,
.hover\:font-thin:hover,
.focus\:font-thin:focus,
.active\:font-thin:active {
  font-weight: 100;
}

.font-extralight,
.hover\:font-extralight:hover,
.focus\:font-extralight:focus,
.active\:font-extralight:active {
  font-weight: 200;
}

.font-light,
.hover\:font-light:hover,
.focus\:font-thin:focus,
.active\:font-thin:active {
  font-weight: 300;
}

.font-normal,
.hover\:font-normal:hover,
.focus\:font-normal:focus,
.active\:font-normal:active {
  font-weight: 400;
}

.font-medium,
.hover\:font-medium:hover,
.focus\:font-medium:focus,
.active\:font-medium:active {
  font-weight: 500;
}

.font-semibold,
.hover\:font-semibold:hover,
.focus\:font-semibold:focus,
.active\:font-semibold:active {
  font-weight: 600;
}

.font-bold,
.hover\:font-bold:hover,
.focus\:font-bold:focus,
.active\:font-bold:active {
  font-weight: 700;
}

.font-extrabold,
.hover\:font-extrabold:hover,
.focus\:font-extrabold:focus,
.active\:font-extrabold:active {
  font-weight: 800;
}

.font-black,
.hover\:font-black:hover,
.focus\:font-black:focus,
.active\:font-black:active {
  font-weight: 900;
}

.\!font-thin,
.\!hover\:font-thin:hover,
.\!focus\:font-thin:focus,
.\!active\:font-thin:active {
  font-weight: 100 !important;
}

.\!font-extralight,
.\!hover\:font-extralight:hover,
.\!focus\:font-extralight:focus,
.\!active\:font-extralight:active {
  font-weight: 200 !important;
}

.\!font-light,
.\!hover\:font-light:hover,
.\!focus\:font-thin:focus,
.\!active\:font-thin:active {
  font-weight: 300 !important;
}

.\!font-normal,
.\!hover\:font-normal:hover,
.\!focus\:font-normal:focus,
.\!active\:font-normal:active {
  font-weight: 400 !important;
}

.\!font-medium,
.\!hover\:font-medium:hover,
.\!focus\:font-medium:focus,
.\!active\:font-medium:active {
  font-weight: 500 !important;
}

.\!font-semibold,
.\!hover\:font-semibold:hover,
.\!focus\:font-semibold:focus,
.\!active\:font-semibold:active {
  font-weight: 600 !important;
}

.\!font-bold,
.\!hover\:font-bold:hover,
.\!focus\:font-bold:focus,
.\!active\:font-bold:active {
  font-weight: 700 !important;
}

.\!font-extrabold,
.\!hover\:font-extrabold:hover,
.\!focus\:font-extrabold:focus,
.\!active\:font-extrabold:active {
  font-weight: 800 !important;
}

.\!font-black,
.\!hover\:font-black:hover,
.\!focus\:font-black:focus,
.\!active\:font-black:active {
  font-weight: 900 !important;
}

.underline,
.hover\:underline:hover,
.focus\:underline:focus,
.active\:underline:active {
  text-decoration-line: underline;
}

.overline,
.hover\:overline:hover,
.focus\:overline:focus,
.active\:overline:active {
  text-decoration-line: overline;
}

.line-through,
.hover\:line-through:hover,
.focus\:line-through:focus,
.active\:line-through:active {
  text-decoration-line: line-through;
}

.no-underline,
.hover\:no-underline:hover,
.focus\:no-underline:focus,
.active\:no-underline:active {
  text-decoration-line: none;
}

.\!underline,
.\!hover\:underline:hover,
.\!focus\:underline:focus,
.\!active\:underline:active {
  text-decoration-line: underline !important;
}

.\!overline,
.\!hover\:overline:hover,
.\!focus\:overline:focus,
.\!active\:overline:active {
  text-decoration-line: overline !important;
}

.\!line-through,
.\!hover\:line-through:hover,
.\!focus\:line-through:focus,
.\!active\:line-through:active {
  text-decoration-line: line-through !important;
}

.\!no-underline,
.\!hover\:no-underline:hover,
.\!focus\:no-underline:focus,
.\!active\:no-underline:active {
  text-decoration-line: none !important;
}

.uppercase,
.hover\:uppercase:hover,
.focus\:uppercase:focus,
.active\:uppercase:active {
  text-transform: uppercase;
}

.lowercase,
.hover\:lowercase:hover,
.focus\:lowercase:focus,
.active\:lowercase:active {
  text-transform: lowercase;
}

.capitalize,
.hover\:capitalize:hover,
.focus\:capitalize:focus,
.active\:capitalize:active {
  text-transform: capitalize;
}

.normal-case,
.hover\:normal-case:hover,
.focus\:normal-case:focus,
.active\:normal-case:active {
  text-transform: none;
}

.\!uppercase,
.\!hover\:uppercase:hover,
.\!focus\:uppercase:focus,
.\!active\:uppercase:active {
  text-transform: uppercase !important;
}

.\!lowercase,
.\!hover\:lowercase:hover,
.\!focus\:lowercase:focus,
.\!active\:lowercase:active {
  text-transform: lowercase !important;
}

.\!capitalize,
.\!hover\:capitalize:hover,
.\!focus\:capitalize:focus,
.\!active\:capitalize:active {
  text-transform: capitalize !important;
}

.\!normal-case,
.\!hover\:normal-case:hover,
.\!focus\:normal-case:focus,
.\!active\:normal-case:active {
  text-transform: none !important;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-wrap-none {
  flex-wrap: nowrap;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.grid {
  display: grid;
}

.grid-cols-none {
  grid-template-columns: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1/span 1;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-5 {
  grid-column: span 5/span 5;
}

.col-span-6 {
  grid-column: span 6/span 6;
}

.col-span-7 {
  grid-column: span 7/span 7;
}

.col-span-8 {
  grid-column: span 8/span 8;
}

.col-span-9 {
  grid-column: span 9/span 9;
}

.col-span-10 {
  grid-column: span 10/span 10;
}

.col-span-11 {
  grid-column: span 11/span 11;
}

.col-span-12 {
  grid-column: span 12/span 12;
}

.col-span-full {
  grid-column: 1/-1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1/span 1;
}

.row-span-2 {
  grid-row: span 2/span 2;
}

.row-span-3 {
  grid-row: span 3/span 3;
}

.row-span-4 {
  grid-row: span 4/span 4;
}

.row-span-5 {
  grid-row: span 5/span 5;
}

.row-span-6 {
  grid-row: span 6/span 6;
}

.row-span-full {
  grid-row: 1/-1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.content-start {
  align-content: flex-start;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.gap-0 {
  gap: 0px;
}

.gap-x-0 {
  column-gap: 0px;
}

.gap-y-0 {
  row-gap: 0px;
}

.gap-px {
  gap: 1px;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-y-px {
  row-gap: 1px;
}

.gap-0\.5 {
  gap: 0.125rem;
  /* 2px */
}

.gap-x-0\.5 {
  column-gap: 0.125rem;
  /* 2px */
}

.gap-y-0\.5 {
  row-gap: 0.125rem;
  /* 2px */
}

.gap-1 {
  gap: 0.25rem;
  /* 4px */
}

.gap-x-1 {
  column-gap: 0.25rem;
  /* 4px */
}

.gap-y-1 {
  row-gap: 0.25rem;
  /* 4px */
}

.gap-1\.5 {
  gap: 0.375rem;
  /* 6px */
}

.gap-x-1\.5 {
  column-gap: 0.375rem;
  /* 6px */
}

.gap-y-1\.5 {
  row-gap: 0.375rem;
  /* 6px */
}

.gap-2 {
  gap: 0.5rem;
  /* 8px */
}

.gap-x-2 {
  column-gap: 0.5rem;
  /* 8px */
}

.gap-y-2 {
  row-gap: 0.5rem;
  /* 8px */
}

.gap-2\.5 {
  gap: 0.625rem;
  /* 10px */
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
  /* 10px */
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
  /* 10px */
}

.gap-3 {
  gap: 0.75rem;
  /* 12px */
}

.gap-x-3 {
  column-gap: 0.75rem;
  /* 12px */
}

.gap-y-3 {
  row-gap: 0.75rem;
  /* 12px */
}

.gap-3\.5 {
  gap: 0.875rem;
  /* 14px */
}

.gap-x-3\.5 {
  column-gap: 0.875rem;
  /* 14px */
}

.gap-y-3\.5 {
  row-gap: 0.875rem;
  /* 14px */
}

.gap-4 {
  gap: 1rem;
  /* 16px */
}

.gap-x-4 {
  column-gap: 1rem;
  /* 16px */
}

.gap-y-4 {
  row-gap: 1rem;
  /* 16px */
}

.gap-5 {
  gap: 1.25rem;
  /* 20px */
}

.gap-x-5 {
  column-gap: 1.25rem;
  /* 20px */
}

.gap-y-5 {
  row-gap: 1.25rem;
  /* 20px */
}

.gap-6 {
  gap: 1.5rem;
  /* 24px */
}

.gap-x-6 {
  column-gap: 1.5rem;
  /* 24px */
}

.gap-y-6 {
  row-gap: 1.5rem;
  /* 24px */
}

.gap-7 {
  gap: 1.75rem;
  /* 28px */
}

.gap-x-7 {
  column-gap: 1.75rem;
  /* 28px */
}

.gap-y-7 {
  row-gap: 1.75rem;
  /* 28px */
}

.gap-8 {
  gap: 2rem;
  /* 32px */
}

.gap-x-8 {
  column-gap: 2rem;
  /* 32px */
}

.gap-y-8 {
  row-gap: 2rem;
  /* 32px */
}

.gap-9 {
  gap: 2.25rem;
  /* 36px */
}

.gap-x-9 {
  column-gap: 2.25rem;
  /* 36px */
}

.gap-y-9 {
  row-gap: 2.25rem;
  /* 36px */
}

.gap-10 {
  gap: 2.5rem;
  /* 40px */
}

.gap-x-10 {
  column-gap: 2.5rem;
  /* 40px */
}

.gap-y-10 {
  row-gap: 2.5rem;
  /* 40px */
}

.gap-11 {
  gap: 2.75rem;
  /* 44px */
}

.gap-x-11 {
  column-gap: 2.75rem;
  /* 44px */
}

.gap-y-11 {
  row-gap: 2.75rem;
  /* 44px */
}

.gap-12 {
  gap: 3rem;
  /* 48px */
}

.gap-x-12 {
  column-gap: 3rem;
  /* 48px */
}

.gap-y-12 {
  row-gap: 3rem;
  /* 48px */
}

.gap-14 {
  gap: 3.5rem;
  /* 56px */
}

.gap-x-14 {
  column-gap: 3.5rem;
  /* 56px */
}

.gap-y-14 {
  row-gap: 3.5rem;
  /* 56px */
}

.gap-16 {
  gap: 4rem;
  /* 64px */
}

.gap-x-16 {
  column-gap: 4rem;
  /* 64px */
}

.gap-y-16 {
  row-gap: 4rem;
  /* 64px */
}

.gap-18 {
  gap: 4.5rem;
  /* 72px */
}

.gap-x-18 {
  column-gap: 4.5rem;
  /* 72px */
}

.gap-y-18 {
  row-gap: 4.5rem;
  /* 72px */
}

.gap-20 {
  gap: 5rem;
  /* 80px */
}

.gap-x-20 {
  column-gap: 5rem;
  /* 80px */
}

.gap-y-20 {
  row-gap: 5rem;
  /* 80px */
}

.gap-24 {
  gap: 6rem;
  /* 96px */
}

.gap-x-24 {
  column-gap: 6rem;
  /* 96px */
}

.gap-y-24 {
  row-gap: 6rem;
  /* 96px */
}

.gap-28 {
  gap: 7rem;
  /* 112px */
}

.gap-x-28 {
  column-gap: 7rem;
  /* 112px */
}

.gap-y-28 {
  row-gap: 7rem;
  /* 112px */
}

.gap-32 {
  gap: 8rem;
  /* 128px */
}

.gap-x-32 {
  column-gap: 8rem;
  /* 128px */
}

.gap-y-32 {
  row-gap: 8rem;
  /* 128px */
}

.gap-36 {
  gap: 9rem;
  /* 144px */
}

.gap-x-36 {
  column-gap: 9rem;
  /* 144px */
}

.gap-y-36 {
  row-gap: 9rem;
  /* 144px */
}

.gap-40 {
  gap: 10rem;
  /* 160px */
}

.gap-x-40 {
  column-gap: 10rem;
  /* 160px */
}

.gap-y-40 {
  row-gap: 10rem;
  /* 160px */
}

.m-0 {
  margin: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.m-px {
  margin: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.m-0\.5 {
  margin: 0.125rem;
  /* 2px */
}

.mx-0\.5 {
  margin-left: 0.125rem;
  /* 2px */
  margin-right: 0.125rem;
  /* 2px */
}

.my-0\.5 {
  margin-top: 0.125rem;
  /* 2px */
  margin-bottom: 0.125rem;
  /* 2px */
}

.mt-0\.5 {
  margin-top: 0.125rem;
  /* 2px */
}

.mr-0\.5 {
  margin-right: 0.125rem;
  /* 2px */
}

.mb-0\.5 {
  margin-bottom: 0.125rem;
  /* 2px */
}

.ml-0\.5 {
  margin-left: 0.125rem;
  /* 2px */
}

.m-1 {
  margin: 0.25rem;
  /* 4px */
}

.mx-1 {
  margin-left: 0.25rem;
  /* 4px */
  margin-right: 0.25rem;
  /* 4px */
}

.my-1 {
  margin-top: 0.25rem;
  /* 4px */
  margin-bottom: 0.25rem;
  /* 4px */
}

.mt-1 {
  margin-top: 0.25rem;
  /* 4px */
}

.mr-1 {
  margin-right: 0.25rem;
  /* 4px */
}

.mb-1 {
  margin-bottom: 0.25rem;
  /* 4px */
}

.ml-1 {
  margin-left: 0.25rem;
  /* 4px */
}

.m-1\.5 {
  margin: 0.375rem;
  /* 6px */
}

.mx-1\.5 {
  margin-left: 0.375rem;
  /* 6px */
  margin-right: 0.375rem;
  /* 6px */
}

.my-1\.5 {
  margin-top: 0.375rem;
  /* 6px */
  margin-bottom: 0.375rem;
  /* 6px */
}

.mt-1\.5 {
  margin-top: 0.375rem;
  /* 6px */
}

.mr-1\.5 {
  margin-right: 0.375rem;
  /* 6px */
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
  /* 6px */
}

.ml-1\.5 {
  margin-left: 0.375rem;
  /* 6px */
}

.m-2 {
  margin: 0.5rem;
  /* 8px */
}

.mx-2 {
  margin-left: 0.5rem;
  /* 8px */
  margin-right: 0.5rem;
  /* 8px */
}

.my-2 {
  margin-top: 0.5rem;
  /* 8px */
  margin-bottom: 0.5rem;
  /* 8px */
}

.mt-2 {
  margin-top: 0.5rem;
  /* 8px */
}

.mr-2 {
  margin-right: 0.5rem;
  /* 8px */
}

.mb-2 {
  margin-bottom: 0.5rem;
  /* 8px */
}

.ml-2 {
  margin-left: 0.5rem;
  /* 8px */
}

.m-2\.5 {
  margin: 0.625rem;
  /* 10px */
}

.mx-2\.5 {
  margin-left: 0.625rem;
  /* 10px */
  margin-right: 0.625rem;
  /* 10px */
}

.my-2\.5 {
  margin-top: 0.625rem;
  /* 10px */
  margin-bottom: 0.625rem;
  /* 10px */
}

.mt-2\.5 {
  margin-top: 0.625rem;
  /* 10px */
}

.mr-2\.5 {
  margin-right: 0.625rem;
  /* 10px */
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
  /* 10px */
}

.ml-2\.5 {
  margin-left: 0.625rem;
  /* 10px */
}

.m-3 {
  margin: 0.75rem;
  /* 12px */
}

.mx-3 {
  margin-left: 0.75rem;
  /* 12px */
  margin-right: 0.75rem;
  /* 12px */
}

.my-3 {
  margin-top: 0.75rem;
  /* 12px */
  margin-bottom: 0.75rem;
  /* 12px */
}

.mt-3 {
  margin-top: 0.75rem;
  /* 12px */
}

.mr-3 {
  margin-right: 0.75rem;
  /* 12px */
}

.mb-3 {
  margin-bottom: 0.75rem;
  /* 12px */
}

.ml-3 {
  margin-left: 0.75rem;
  /* 12px */
}

.m-3\.5 {
  margin: 0.875rem;
  /* 14px */
}

.mx-3\.5 {
  margin-left: 0.875rem;
  /* 14px */
  margin-right: 0.875rem;
  /* 14px */
}

.my-3\.5 {
  margin-top: 0.875rem;
  /* 14px */
  margin-bottom: 0.875rem;
  /* 14px */
}

.mt-3\.5 {
  margin-top: 0.875rem;
  /* 14px */
}

.mr-3\.5 {
  margin-right: 0.875rem;
  /* 14px */
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
  /* 14px */
}

.ml-3\.5 {
  margin-left: 0.875rem;
  /* 14px */
}

.m-4 {
  margin: 1rem;
  /* 16px */
}

.mx-4 {
  margin-left: 1rem;
  /* 16px */
  margin-right: 1rem;
  /* 16px */
}

.my-4 {
  margin-top: 1rem;
  /* 16px */
  margin-bottom: 1rem;
  /* 16px */
}

.mt-4 {
  margin-top: 1rem;
  /* 16px */
}

.mr-4 {
  margin-right: 1rem;
  /* 16px */
}

.mb-4 {
  margin-bottom: 1rem;
  /* 16px */
}

.ml-4 {
  margin-left: 1rem;
  /* 16px */
}

.m-5 {
  margin: 1.25rem;
  /* 20px */
}

.mx-5 {
  margin-left: 1.25rem;
  /* 20px */
  margin-right: 1.25rem;
  /* 20px */
}

.my-5 {
  margin-top: 1.25rem;
  /* 20px */
  margin-bottom: 1.25rem;
  /* 20px */
}

.mt-5 {
  margin-top: 1.25rem;
  /* 20px */
}

.mr-5 {
  margin-right: 1.25rem;
  /* 20px */
}

.mb-5 {
  margin-bottom: 1.25rem;
  /* 20px */
}

.ml-5 {
  margin-left: 1.25rem;
  /* 20px */
}

.m-6 {
  margin: 1.5rem;
  /* 24px */
}

.mx-6 {
  margin-left: 1.5rem;
  /* 24px */
  margin-right: 1.5rem;
  /* 24px */
}

.my-6 {
  margin-top: 1.5rem;
  /* 24px */
  margin-bottom: 1.5rem;
  /* 24px */
}

.mt-6 {
  margin-top: 1.5rem;
  /* 24px */
}

.mr-6 {
  margin-right: 1.5rem;
  /* 24px */
}

.mb-6 {
  margin-bottom: 1.5rem;
  /* 24px */
}

.ml-6 {
  margin-left: 1.5rem;
  /* 24px */
}

.m-7 {
  margin: 1.75rem;
  /* 28px */
}

.mx-7 {
  margin-left: 1.75rem;
  /* 28px */
  margin-right: 1.75rem;
  /* 28px */
}

.my-7 {
  margin-top: 1.75rem;
  /* 28px */
  margin-bottom: 1.75rem;
  /* 28px */
}

.mt-7 {
  margin-top: 1.75rem;
  /* 28px */
}

.mr-7 {
  margin-right: 1.75rem;
  /* 28px */
}

.mb-7 {
  margin-bottom: 1.75rem;
  /* 28px */
}

.ml-7 {
  margin-left: 1.75rem;
  /* 28px */
}

.m-8 {
  margin: 2rem;
  /* 32px */
}

.mx-8 {
  margin-left: 2rem;
  /* 32px */
  margin-right: 2rem;
  /* 32px */
}

.my-8 {
  margin-top: 2rem;
  /* 32px */
  margin-bottom: 2rem;
  /* 32px */
}

.mt-8 {
  margin-top: 2rem;
  /* 32px */
}

.mr-8 {
  margin-right: 2rem;
  /* 32px */
}

.mb-8 {
  margin-bottom: 2rem;
  /* 32px */
}

.ml-8 {
  margin-left: 2rem;
  /* 32px */
}

.m-9 {
  margin: 2.25rem;
  /* 36px */
}

.mx-9 {
  margin-left: 2.25rem;
  /* 36px */
  margin-right: 2.25rem;
  /* 36px */
}

.my-9 {
  margin-top: 2.25rem;
  /* 36px */
  margin-bottom: 2.25rem;
  /* 36px */
}

.mt-9 {
  margin-top: 2.25rem;
  /* 36px */
}

.mr-9 {
  margin-right: 2.25rem;
  /* 36px */
}

.mb-9 {
  margin-bottom: 2.25rem;
  /* 36px */
}

.ml-9 {
  margin-left: 2.25rem;
  /* 36px */
}

.m-10 {
  margin: 2.5rem;
  /* 40px */
}

.mx-10 {
  margin-left: 2.5rem;
  /* 40px */
  margin-right: 2.5rem;
  /* 40px */
}

.my-10 {
  margin-top: 2.5rem;
  /* 40px */
  margin-bottom: 2.5rem;
  /* 40px */
}

.mt-10 {
  margin-top: 2.5rem;
  /* 40px */
}

.mr-10 {
  margin-right: 2.5rem;
  /* 40px */
}

.mb-10 {
  margin-bottom: 2.5rem;
  /* 40px */
}

.ml-10 {
  margin-left: 2.5rem;
  /* 40px */
}

.m-11 {
  margin: 2.75rem;
  /* 44px */
}

.mx-11 {
  margin-left: 2.75rem;
  /* 44px */
  margin-right: 2.75rem;
  /* 44px */
}

.my-11 {
  margin-top: 2.75rem;
  /* 44px */
  margin-bottom: 2.75rem;
  /* 44px */
}

.mt-11 {
  margin-top: 2.75rem;
  /* 44px */
}

.mr-11 {
  margin-right: 2.75rem;
  /* 44px */
}

.mb-11 {
  margin-bottom: 2.75rem;
  /* 44px */
}

.ml-11 {
  margin-left: 2.75rem;
  /* 44px */
}

.m-12 {
  margin: 3rem;
  /* 48px */
}

.mx-12 {
  margin-left: 3rem;
  /* 48px */
  margin-right: 3rem;
  /* 48px */
}

.my-12 {
  margin-top: 3rem;
  /* 48px */
  margin-bottom: 3rem;
  /* 48px */
}

.mt-12 {
  margin-top: 3rem;
  /* 48px */
}

.mr-12 {
  margin-right: 3rem;
  /* 48px */
}

.mb-12 {
  margin-bottom: 3rem;
  /* 48px */
}

.ml-12 {
  margin-left: 3rem;
  /* 48px */
}

.m-14 {
  margin: 3.5rem;
  /* 56px */
}

.mx-14 {
  margin-left: 3.5rem;
  /* 56px */
  margin-right: 3.5rem;
  /* 56px */
}

.my-14 {
  margin-top: 3.5rem;
  /* 56px */
  margin-bottom: 3.5rem;
  /* 56px */
}

.mt-14 {
  margin-top: 3.5rem;
  /* 56px */
}

.mr-14 {
  margin-right: 3.5rem;
  /* 56px */
}

.mb-14 {
  margin-bottom: 3.5rem;
  /* 56px */
}

.ml-14 {
  margin-left: 3.5rem;
  /* 56px */
}

.m-16 {
  margin: 4rem;
  /* 64px */
}

.mx-16 {
  margin-left: 4rem;
  /* 64px */
  margin-right: 4rem;
  /* 64px */
}

.my-16 {
  margin-top: 4rem;
  /* 64px */
  margin-bottom: 4rem;
  /* 64px */
}

.mt-16 {
  margin-top: 4rem;
  /* 64px */
}

.mr-16 {
  margin-right: 4rem;
  /* 64px */
}

.mb-16 {
  margin-bottom: 4rem;
  /* 64px */
}

.ml-16 {
  margin-left: 4rem;
  /* 64px */
}

.m-18 {
  margin: 4.5rem;
  /* 72px */
}

.mx-18 {
  margin-left: 4.5rem;
  /* 72px */
  margin-right: 4.5rem;
  /* 72px */
}

.my-18 {
  margin-top: 4.5rem;
  /* 72px */
  margin-bottom: 4.5rem;
  /* 72px */
}

.mt-18 {
  margin-top: 4.5rem;
  /* 72px */
}

.mr-18 {
  margin-right: 4.5rem;
  /* 72px */
}

.mb-18 {
  margin-bottom: 4.5rem;
  /* 72px */
}

.ml-18 {
  margin-left: 4.5rem;
  /* 72px */
}

.m-20 {
  margin: 5rem;
  /* 80px */
}

.mx-20 {
  margin-left: 5rem;
  /* 80px */
  margin-right: 5rem;
  /* 80px */
}

.my-20 {
  margin-top: 5rem;
  /* 80px */
  margin-bottom: 5rem;
  /* 80px */
}

.mt-20 {
  margin-top: 5rem;
  /* 80px */
}

.mr-20 {
  margin-right: 5rem;
  /* 80px */
}

.mb-20 {
  margin-bottom: 5rem;
  /* 80px */
}

.ml-20 {
  margin-left: 5rem;
  /* 80px */
}

.m-24 {
  margin: 6rem;
  /* 96px */
}

.mx-24 {
  margin-left: 6rem;
  /* 96px */
  margin-right: 6rem;
  /* 96px */
}

.my-24 {
  margin-top: 6rem;
  /* 96px */
  margin-bottom: 6rem;
  /* 96px */
}

.mt-24 {
  margin-top: 6rem;
  /* 96px */
}

.mr-24 {
  margin-right: 6rem;
  /* 96px */
}

.mb-24 {
  margin-bottom: 6rem;
  /* 96px */
}

.ml-24 {
  margin-left: 6rem;
  /* 96px */
}

.m-28 {
  margin: 7rem;
  /* 112px */
}

.mx-28 {
  margin-left: 7rem;
  /* 112px */
  margin-right: 7rem;
  /* 112px */
}

.my-28 {
  margin-top: 7rem;
  /* 112px */
  margin-bottom: 7rem;
  /* 112px */
}

.mt-28 {
  margin-top: 7rem;
  /* 112px */
}

.mr-28 {
  margin-right: 7rem;
  /* 112px */
}

.mb-28 {
  margin-bottom: 7rem;
  /* 112px */
}

.ml-28 {
  margin-left: 7rem;
  /* 112px */
}

.m-32 {
  margin: 8rem;
  /* 128px */
}

.mx-32 {
  margin-left: 8rem;
  /* 128px */
  margin-right: 8rem;
  /* 128px */
}

.my-32 {
  margin-top: 8rem;
  /* 128px */
  margin-bottom: 8rem;
  /* 128px */
}

.mt-32 {
  margin-top: 8rem;
  /* 128px */
}

.mr-32 {
  margin-right: 8rem;
  /* 128px */
}

.mb-32 {
  margin-bottom: 8rem;
  /* 128px */
}

.ml-32 {
  margin-left: 8rem;
  /* 128px */
}

.m-36 {
  margin: 9rem;
  /* 144px */
}

.mx-36 {
  margin-left: 9rem;
  /* 144px */
  margin-right: 9rem;
  /* 144px */
}

.my-36 {
  margin-top: 9rem;
  /* 144px */
  margin-bottom: 9rem;
  /* 144px */
}

.mt-36 {
  margin-top: 9rem;
  /* 144px */
}

.mr-36 {
  margin-right: 9rem;
  /* 144px */
}

.mb-36 {
  margin-bottom: 9rem;
  /* 144px */
}

.ml-36 {
  margin-left: 9rem;
  /* 144px */
}

.m-40 {
  margin: 10rem;
  /* 160px */
}

.mx-40 {
  margin-left: 10rem;
  /* 160px */
  margin-right: 10rem;
  /* 160px */
}

.my-40 {
  margin-top: 10rem;
  /* 160px */
  margin-bottom: 10rem;
  /* 160px */
}

.mt-40 {
  margin-top: 10rem;
  /* 160px */
}

.mr-40 {
  margin-right: 10rem;
  /* 160px */
}

.mb-40 {
  margin-bottom: 10rem;
  /* 160px */
}

.ml-40 {
  margin-left: 10rem;
  /* 160px */
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.p-px {
  padding: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.p-0\.5 {
  padding: 0.125rem;
  /* 2px */
}

.px-0\.5 {
  padding-left: 0.125rem;
  /* 2px */
  padding-right: 0.125rem;
  /* 2px */
}

.py-0\.5 {
  padding-top: 0.125rem;
  /* 2px */
  padding-bottom: 0.125rem;
  /* 2px */
}

.pt-0\.5 {
  padding-top: 0.125rem;
  /* 2px */
}

.pr-0\.5 {
  padding-right: 0.125rem;
  /* 2px */
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
  /* 2px */
}

.pl-0\.5 {
  padding-left: 0.125rem;
  /* 2px */
}

.p-1 {
  padding: 0.25rem;
  /* 4px */
}

.px-1 {
  padding-left: 0.25rem;
  /* 4px */
  padding-right: 0.25rem;
  /* 4px */
}

.py-1 {
  padding-top: 0.25rem;
  /* 4px */
  padding-bottom: 0.25rem;
  /* 4px */
}

.pt-1 {
  padding-top: 0.25rem;
  /* 4px */
}

.pr-1 {
  padding-right: 0.25rem;
  /* 4px */
}

.pb-1 {
  padding-bottom: 0.25rem;
  /* 4px */
}

.pl-1 {
  padding-left: 0.25rem;
  /* 4px */
}

.p-1\.5 {
  padding: 0.375rem;
  /* 6px */
}

.px-1\.5 {
  padding-left: 0.375rem;
  /* 6px */
  padding-right: 0.375rem;
  /* 6px */
}

.py-1\.5 {
  padding-top: 0.375rem;
  /* 6px */
  padding-bottom: 0.375rem;
  /* 6px */
}

.pt-1\.5 {
  padding-top: 0.375rem;
  /* 6px */
}

.pr-1\.5 {
  padding-right: 0.375rem;
  /* 6px */
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
  /* 6px */
}

.pl-1\.5 {
  padding-left: 0.375rem;
  /* 6px */
}

.p-2 {
  padding: 0.5rem;
  /* 8px */
}

.px-2 {
  padding-left: 0.5rem;
  /* 8px */
  padding-right: 0.5rem;
  /* 8px */
}

.py-2 {
  padding-top: 0.5rem;
  /* 8px */
  padding-bottom: 0.5rem;
  /* 8px */
}

.pt-2 {
  padding-top: 0.5rem;
  /* 8px */
}

.pr-2 {
  padding-right: 0.5rem;
  /* 8px */
}

.pb-2 {
  padding-bottom: 0.5rem;
  /* 8px */
}

.pl-2 {
  padding-left: 0.5rem;
  /* 8px */
}

.p-2\.5 {
  padding: 0.625rem;
  /* 10px */
}

.px-2\.5 {
  padding-left: 0.625rem;
  /* 10px */
  padding-right: 0.625rem;
  /* 10px */
}

.py-2\.5 {
  padding-top: 0.625rem;
  /* 10px */
  padding-bottom: 0.625rem;
  /* 10px */
}

.pt-2\.5 {
  padding-top: 0.625rem;
  /* 10px */
}

.pr-2\.5 {
  padding-right: 0.625rem;
  /* 10px */
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
  /* 10px */
}

.pl-2\.5 {
  padding-left: 0.625rem;
  /* 10px */
}

.p-3 {
  padding: 0.75rem;
  /* 12px */
}

.px-3 {
  padding-left: 0.75rem;
  /* 12px */
  padding-right: 0.75rem;
  /* 12px */
}

.py-3 {
  padding-top: 0.75rem;
  /* 12px */
  padding-bottom: 0.75rem;
  /* 12px */
}

.pt-3 {
  padding-top: 0.75rem;
  /* 12px */
}

.pr-3 {
  padding-right: 0.75rem;
  /* 12px */
}

.pb-3 {
  padding-bottom: 0.75rem;
  /* 12px */
}

.pl-3 {
  padding-left: 0.75rem;
  /* 12px */
}

.p-3\.5 {
  padding: 0.875rem;
  /* 14px */
}

.px-3\.5 {
  padding-left: 0.875rem;
  /* 14px */
  padding-right: 0.875rem;
  /* 14px */
}

.py-3\.5 {
  padding-top: 0.875rem;
  /* 14px */
  padding-bottom: 0.875rem;
  /* 14px */
}

.pt-3\.5 {
  padding-top: 0.875rem;
  /* 14px */
}

.pr-3\.5 {
  padding-right: 0.875rem;
  /* 14px */
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
  /* 14px */
}

.pl-3\.5 {
  padding-left: 0.875rem;
  /* 14px */
}

.p-4 {
  padding: 1rem;
  /* 16px */
}

.px-4 {
  padding-left: 1rem;
  /* 16px */
  padding-right: 1rem;
  /* 16px */
}

.py-4 {
  padding-top: 1rem;
  /* 16px */
  padding-bottom: 1rem;
  /* 16px */
}

.pt-4 {
  padding-top: 1rem;
  /* 16px */
}

.pr-4 {
  padding-right: 1rem;
  /* 16px */
}

.pb-4 {
  padding-bottom: 1rem;
  /* 16px */
}

.pl-4 {
  padding-left: 1rem;
  /* 16px */
}

.p-5 {
  padding: 1.25rem;
  /* 20px */
}

.px-5 {
  padding-left: 1.25rem;
  /* 20px */
  padding-right: 1.25rem;
  /* 20px */
}

.py-5 {
  padding-top: 1.25rem;
  /* 20px */
  padding-bottom: 1.25rem;
  /* 20px */
}

.pt-5 {
  padding-top: 1.25rem;
  /* 20px */
}

.pr-5 {
  padding-right: 1.25rem;
  /* 20px */
}

.pb-5 {
  padding-bottom: 1.25rem;
  /* 20px */
}

.pl-5 {
  padding-left: 1.25rem;
  /* 20px */
}

.p-6 {
  padding: 1.5rem;
  /* 24px */
}

.px-6 {
  padding-left: 1.5rem;
  /* 24px */
  padding-right: 1.5rem;
  /* 24px */
}

.py-6 {
  padding-top: 1.5rem;
  /* 24px */
  padding-bottom: 1.5rem;
  /* 24px */
}

.pt-6 {
  padding-top: 1.5rem;
  /* 24px */
}

.pr-6 {
  padding-right: 1.5rem;
  /* 24px */
}

.pb-6 {
  padding-bottom: 1.5rem;
  /* 24px */
}

.pl-6 {
  padding-left: 1.5rem;
  /* 24px */
}

.p-7 {
  padding: 1.75rem;
  /* 28px */
}

.px-7 {
  padding-left: 1.75rem;
  /* 28px */
  padding-right: 1.75rem;
  /* 28px */
}

.py-7 {
  padding-top: 1.75rem;
  /* 28px */
  padding-bottom: 1.75rem;
  /* 28px */
}

.pt-7 {
  padding-top: 1.75rem;
  /* 28px */
}

.pr-7 {
  padding-right: 1.75rem;
  /* 28px */
}

.pb-7 {
  padding-bottom: 1.75rem;
  /* 28px */
}

.pl-7 {
  padding-left: 1.75rem;
  /* 28px */
}

.p-8 {
  padding: 2rem;
  /* 32px */
}

.px-8 {
  padding-left: 2rem;
  /* 32px */
  padding-right: 2rem;
  /* 32px */
}

.py-8 {
  padding-top: 2rem;
  /* 32px */
  padding-bottom: 2rem;
  /* 32px */
}

.pt-8 {
  padding-top: 2rem;
  /* 32px */
}

.pr-8 {
  padding-right: 2rem;
  /* 32px */
}

.pb-8 {
  padding-bottom: 2rem;
  /* 32px */
}

.pl-8 {
  padding-left: 2rem;
  /* 32px */
}

.p-9 {
  padding: 2.25rem;
  /* 36px */
}

.px-9 {
  padding-left: 2.25rem;
  /* 36px */
  padding-right: 2.25rem;
  /* 36px */
}

.py-9 {
  padding-top: 2.25rem;
  /* 36px */
  padding-bottom: 2.25rem;
  /* 36px */
}

.pt-9 {
  padding-top: 2.25rem;
  /* 36px */
}

.pr-9 {
  padding-right: 2.25rem;
  /* 36px */
}

.pb-9 {
  padding-bottom: 2.25rem;
  /* 36px */
}

.pl-9 {
  padding-left: 2.25rem;
  /* 36px */
}

.p-10 {
  padding: 2.5rem;
  /* 40px */
}

.px-10 {
  padding-left: 2.5rem;
  /* 40px */
  padding-right: 2.5rem;
  /* 40px */
}

.py-10 {
  padding-top: 2.5rem;
  /* 40px */
  padding-bottom: 2.5rem;
  /* 40px */
}

.pt-10 {
  padding-top: 2.5rem;
  /* 40px */
}

.pr-10 {
  padding-right: 2.5rem;
  /* 40px */
}

.pb-10 {
  padding-bottom: 2.5rem;
  /* 40px */
}

.pl-10 {
  padding-left: 2.5rem;
  /* 40px */
}

.p-11 {
  padding: 2.75rem;
  /* 44px */
}

.px-11 {
  padding-left: 2.75rem;
  /* 44px */
  padding-right: 2.75rem;
  /* 44px */
}

.py-11 {
  padding-top: 2.75rem;
  /* 44px */
  padding-bottom: 2.75rem;
  /* 44px */
}

.pt-11 {
  padding-top: 2.75rem;
  /* 44px */
}

.pr-11 {
  padding-right: 2.75rem;
  /* 44px */
}

.pb-11 {
  padding-bottom: 2.75rem;
  /* 44px */
}

.pl-11 {
  padding-left: 2.75rem;
  /* 44px */
}

.p-12 {
  padding: 3rem;
  /* 48px */
}

.px-12 {
  padding-left: 3rem;
  /* 48px */
  padding-right: 3rem;
  /* 48px */
}

.py-12 {
  padding-top: 3rem;
  /* 48px */
  padding-bottom: 3rem;
  /* 48px */
}

.pt-12 {
  padding-top: 3rem;
  /* 48px */
}

.pr-12 {
  padding-right: 3rem;
  /* 48px */
}

.pb-12 {
  padding-bottom: 3rem;
  /* 48px */
}

.pl-12 {
  padding-left: 3rem;
  /* 48px */
}

.p-14 {
  padding: 3.5rem;
  /* 56px */
}

.px-14 {
  padding-left: 3.5rem;
  /* 56px */
  padding-right: 3.5rem;
  /* 56px */
}

.py-14 {
  padding-top: 3.5rem;
  /* 56px */
  padding-bottom: 3.5rem;
  /* 56px */
}

.pt-14 {
  padding-top: 3.5rem;
  /* 56px */
}

.pr-14 {
  padding-right: 3.5rem;
  /* 56px */
}

.pb-14 {
  padding-bottom: 3.5rem;
  /* 56px */
}

.pl-14 {
  padding-left: 3.5rem;
  /* 56px */
}

.p-16 {
  padding: 4rem;
  /* 64px */
}

.px-16 {
  padding-left: 4rem;
  /* 64px */
  padding-right: 4rem;
  /* 64px */
}

.py-16 {
  padding-top: 4rem;
  /* 64px */
  padding-bottom: 4rem;
  /* 64px */
}

.pt-16 {
  padding-top: 4rem;
  /* 64px */
}

.pr-16 {
  padding-right: 4rem;
  /* 64px */
}

.pb-16 {
  padding-bottom: 4rem;
  /* 64px */
}

.pl-16 {
  padding-left: 4rem;
  /* 64px */
}

.p-18 {
  padding: 4.5rem;
  /* 72px */
}

.px-18 {
  padding-left: 4.5rem;
  /* 72px */
  padding-right: 4.5rem;
  /* 72px */
}

.py-18 {
  padding-top: 4.5rem;
  /* 72px */
  padding-bottom: 4.5rem;
  /* 72px */
}

.pt-18 {
  padding-top: 4.5rem;
  /* 72px */
}

.pr-18 {
  padding-right: 4.5rem;
  /* 72px */
}

.pb-18 {
  padding-bottom: 4.5rem;
  /* 72px */
}

.pl-18 {
  padding-left: 4.5rem;
  /* 72px */
}

.p-20 {
  padding: 5rem;
  /* 80px */
}

.px-20 {
  padding-left: 5rem;
  /* 80px */
  padding-right: 5rem;
  /* 80px */
}

.py-20 {
  padding-top: 5rem;
  /* 80px */
  padding-bottom: 5rem;
  /* 80px */
}

.pt-20 {
  padding-top: 5rem;
  /* 80px */
}

.pr-20 {
  padding-right: 5rem;
  /* 80px */
}

.pb-20 {
  padding-bottom: 5rem;
  /* 80px */
}

.pl-20 {
  padding-left: 5rem;
  /* 80px */
}

.p-24 {
  padding: 6rem;
  /* 96px */
}

.px-24 {
  padding-left: 6rem;
  /* 96px */
  padding-right: 6rem;
  /* 96px */
}

.py-24 {
  padding-top: 6rem;
  /* 96px */
  padding-bottom: 6rem;
  /* 96px */
}

.pt-24 {
  padding-top: 6rem;
  /* 96px */
}

.pr-24 {
  padding-right: 6rem;
  /* 96px */
}

.pb-24 {
  padding-bottom: 6rem;
  /* 96px */
}

.pl-24 {
  padding-left: 6rem;
  /* 96px */
}

.p-28 {
  padding: 7rem;
  /* 112px */
}

.px-28 {
  padding-left: 7rem;
  /* 112px */
  padding-right: 7rem;
  /* 112px */
}

.py-28 {
  padding-top: 7rem;
  /* 112px */
  padding-bottom: 7rem;
  /* 112px */
}

.pt-28 {
  padding-top: 7rem;
  /* 112px */
}

.pr-28 {
  padding-right: 7rem;
  /* 112px */
}

.pb-28 {
  padding-bottom: 7rem;
  /* 112px */
}

.pl-28 {
  padding-left: 7rem;
  /* 112px */
}

.p-32 {
  padding: 8rem;
  /* 128px */
}

.px-32 {
  padding-left: 8rem;
  /* 128px */
  padding-right: 8rem;
  /* 128px */
}

.py-32 {
  padding-top: 8rem;
  /* 128px */
  padding-bottom: 8rem;
  /* 128px */
}

.pt-32 {
  padding-top: 8rem;
  /* 128px */
}

.pr-32 {
  padding-right: 8rem;
  /* 128px */
}

.pb-32 {
  padding-bottom: 8rem;
  /* 128px */
}

.pl-32 {
  padding-left: 8rem;
  /* 128px */
}

.p-36 {
  padding: 9rem;
  /* 144px */
}

.px-36 {
  padding-left: 9rem;
  /* 144px */
  padding-right: 9rem;
  /* 144px */
}

.py-36 {
  padding-top: 9rem;
  /* 144px */
  padding-bottom: 9rem;
  /* 144px */
}

.pt-36 {
  padding-top: 9rem;
  /* 144px */
}

.pr-36 {
  padding-right: 9rem;
  /* 144px */
}

.pb-36 {
  padding-bottom: 9rem;
  /* 144px */
}

.pl-36 {
  padding-left: 9rem;
  /* 144px */
}

.p-40 {
  padding: 10rem;
  /* 160px */
}

.px-40 {
  padding-left: 10rem;
  /* 160px */
  padding-right: 10rem;
  /* 160px */
}

.py-40 {
  padding-top: 10rem;
  /* 160px */
  padding-bottom: 10rem;
  /* 160px */
}

.pt-40 {
  padding-top: 10rem;
  /* 160px */
}

.pr-40 {
  padding-right: 10rem;
  /* 160px */
}

.pb-40 {
  padding-bottom: 10rem;
  /* 160px */
}

.pl-40 {
  padding-left: 10rem;
  /* 160px */
}

.w-0 {
  width: 0px;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
  /* 2px */
}

.w-1 {
  width: 0.25rem;
  /* 4px */
}

.w-1\.5 {
  width: 0.375rem;
  /* 6px */
}

.w-2 {
  width: 0.5rem;
  /* 8px */
}

.w-2\.5 {
  width: 0.625rem;
  /* 10px */
}

.w-3 {
  width: 0.75rem;
  /* 12px */
}

.w-3\.5 {
  width: 0.875rem;
  /* 14px */
}

.w-4 {
  width: 1rem;
  /* 16px */
}

.w-5 {
  width: 1.25rem;
  /* 20px */
}

.w-6 {
  width: 1.5rem;
  /* 24px */
}

.w-7 {
  width: 1.75rem;
  /* 28px */
}

.w-8 {
  width: 2rem;
  /* 32px */
}

.w-9 {
  width: 2.25rem;
  /* 36px */
}

.w-10 {
  width: 2.5rem;
  /* 40px */
}

.w-11 {
  width: 2.75rem;
  /* 44px */
}

.w-12 {
  width: 3rem;
  /* 48px */
}

.w-14 {
  width: 3.5rem;
  /* 56px */
}

.w-16 {
  width: 4rem;
  /* 64px */
}

.w-18 {
  width: 4.5rem;
  /* 72px */
}

.w-20 {
  width: 5rem;
  /* 80px */
}

.w-24 {
  width: 6rem;
  /* 96px */
}

.w-28 {
  width: 7rem;
  /* 112px */
}

.w-32 {
  width: 8rem;
  /* 128px */
}

.w-36 {
  width: 9rem;
  /* 144px */
}

.w-40 {
  width: 10rem;
  /* 160px */
}

.w-44 {
  width: 11rem;
  /* 176px */
}

.w-48 {
  width: 12rem;
  /* 192px */
}

.w-52 {
  width: 13rem;
  /* 208px */
}

.w-56 {
  width: 14rem;
  /* 224px */
}

.w-60 {
  width: 15rem;
  /* 240px */
}

.w-64 {
  width: 16rem;
  /* 256px */
}

.w-72 {
  width: 18rem;
  /* 288px */
}

.w-80 {
  width: 20rem;
  /* 320px */
}

.w-96 {
  width: 24rem;
  /* 384px */
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.min-w-max {
  min-width: max-content;
}

.min-w-fit {
  min-width: fit-content;
}

.max-w-0 {
  max-width: 0rem;
  /* 0px */
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
  /* 320px */
}

.max-w-sm {
  max-width: 24rem;
  /* 384px */
}

.max-w-md {
  max-width: 28rem;
  /* 448px */
}

.max-w-lg {
  max-width: 32rem;
  /* 512px */
}

.max-w-xl {
  max-width: 36rem;
  /* 576px */
}

.max-w-2xl {
  max-width: 42rem;
  /* 672px */
}

.max-w-3xl {
  max-width: 48rem;
  /* 768px */
}

.max-w-4xl {
  max-width: 56rem;
  /* 896px */
}

.max-w-5xl {
  max-width: 64rem;
  /* 1024px */
}

.max-w-6xl {
  max-width: 72rem;
  /* 1152px */
}

.max-w-7xl {
  max-width: 80rem;
  /* 1280px */
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.h-0 {
  height: 0px;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: 0.125rem;
  /* 2px */
}

.h-1 {
  height: 0.25rem;
  /* 4px */
}

.h-1\.5 {
  height: 0.375rem;
  /* 6px */
}

.h-2 {
  height: 0.5rem;
  /* 8px */
}

.h-2\.5 {
  height: 0.625rem;
  /* 10px */
}

.h-3 {
  height: 0.75rem;
  /* 12px */
}

.h-3\.5 {
  height: 0.875rem;
  /* 14px */
}

.h-4 {
  height: 1rem;
  /* 16px */
}

.h-5 {
  height: 1.25rem;
  /* 20px */
}

.h-6 {
  height: 1.5rem;
  /* 24px */
}

.h-7 {
  height: 1.75rem;
  /* 28px */
}

.h-8 {
  height: 2rem;
  /* 32px */
}

.h-9 {
  height: 2.25rem;
  /* 36px */
}

.h-10 {
  height: 2.5rem;
  /* 40px */
}

.h-11 {
  height: 2.75rem;
  /* 44px */
}

.h-12 {
  height: 3rem;
  /* 48px */
}

.h-14 {
  height: 3.5rem;
  /* 56px */
}

.h-16 {
  height: 4rem;
  /* 64px */
}

.h-18 {
  height: 4.5rem;
  /* 72px */
}

.h-20 {
  height: 5rem;
  /* 80px */
}

.h-24 {
  height: 6rem;
  /* 96px */
}

.h-28 {
  height: 7rem;
  /* 112px */
}

.h-32 {
  height: 8rem;
  /* 128px */
}

.h-36 {
  height: 9rem;
  /* 144px */
}

.h-40 {
  height: 10rem;
  /* 160px */
}

.h-44 {
  height: 11rem;
  /* 176px */
}

.h-48 {
  height: 12rem;
  /* 192px */
}

.h-52 {
  height: 13rem;
  /* 208px */
}

.h-56 {
  height: 14rem;
  /* 224px */
}

.h-60 {
  height: 15rem;
  /* 240px */
}

.h-64 {
  height: 16rem;
  /* 256px */
}

.h-72 {
  height: 18rem;
  /* 288px */
}

.h-80 {
  height: 20rem;
  /* 320px */
}

.h-96 {
  height: 24rem;
  /* 384px */
}

.h-auto {
  height: auto;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.333333%;
}

.h-2\/3 {
  height: 66.666667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.666667%;
}

.h-2\/6 {
  height: 33.333333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.666667%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.h-fit {
  height: fit-content;
}

.min-h-0 {
  min-height: 0px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-min {
  min-height: min-content;
}

.min-h-max {
  min-height: max-content;
}

.min-h-fit {
  min-height: fit-content;
}

.max-h-0 {
  max-height: 0px;
}

.max-h-px {
  max-height: 1px;
}

.max-h-0\.5 {
  max-height: 0.125rem;
  /* 2px */
}

.max-h-1 {
  max-height: 0.25rem;
  /* 4px */
}

.max-h-1\.5 {
  max-height: 0.375rem;
  /* 6px */
}

.max-h-2 {
  max-height: 0.5rem;
  /* 8px */
}

.max-h-2\.5 {
  max-height: 0.625rem;
  /* 10px */
}

.max-h-3 {
  max-height: 0.75rem;
  /* 12px */
}

.max-h-3\.5 {
  max-height: 0.875rem;
  /* 14px */
}

.max-h-4 {
  max-height: 1rem;
  /* 16px */
}

.max-h-5 {
  max-height: 1.25rem;
  /* 20px */
}

.max-h-6 {
  max-height: 1.5rem;
  /* 24px */
}

.max-h-7 {
  max-height: 1.75rem;
  /* 28px */
}

.max-h-8 {
  max-height: 2rem;
  /* 32px */
}

.max-h-9 {
  max-height: 2.25rem;
  /* 36px */
}

.max-h-10 {
  max-height: 2.5rem;
  /* 40px */
}

.max-h-11 {
  max-height: 2.75rem;
  /* 44px */
}

.max-h-12 {
  max-height: 3rem;
  /* 48px */
}

.max-h-14 {
  max-height: 3.5rem;
  /* 56px */
}

.max-h-16 {
  max-height: 4rem;
  /* 64px */
}

.max-h-20 {
  max-height: 5rem;
  /* 80px */
}

.max-h-24 {
  max-height: 6rem;
  /* 96px */
}

.max-h-28 {
  max-height: 7rem;
  /* 112px */
}

.max-h-32 {
  max-height: 8rem;
  /* 128px */
}

.max-h-36 {
  max-height: 9rem;
  /* 144px */
}

.max-h-40 {
  max-height: 10rem;
  /* 160px */
}

.max-h-44 {
  max-height: 11rem;
  /* 176px */
}

.max-h-48 {
  max-height: 12rem;
  /* 192px */
}

.max-h-52 {
  max-height: 13rem;
  /* 208px */
}

.max-h-56 {
  max-height: 14rem;
  /* 224px */
}

.max-h-60 {
  max-height: 15rem;
  /* 240px */
}

.max-h-64 {
  max-height: 16rem;
  /* 256px */
}

.max-h-72 {
  max-height: 18rem;
  /* 288px */
}

.max-h-80 {
  max-height: 20rem;
  /* 320px */
}

.max-h-96 {
  max-height: 24rem;
  /* 384px */
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-min {
  max-height: min-content;
}

.max-h-max {
  max-height: max-content;
}

.max-h-fit {
  max-height: fit-content;
}

.text-transparent,
.hover\:text-transparent:hover,
.focus\:text-transparent:focus,
.active\:text-transparent:active {
  color: var(--color-transparent);
}

.bg-transparent,
.hover\:bg-transparent:hover,
.focus\:text-transparent:focus,
.active\:text-transparent:active {
  background-color: var(--color-transparent);
}

.border-transparent,
.hover\:border-transparent:hover,
.focus\:text-transparent:focus,
.active\:text-transparent:active {
  border-color: var(--color-transparent);
}

.text-white,
.hover\:text-white:hover,
.focus\:text-white:focus,
.active\:text-white:active {
  color: var(--color-white);
}

.bg-white,
.hover\:bg-white:hover,
.focus\:text-white:focus,
.active\:text-white:active {
  background-color: var(--color-white);
}

.border-white,
.hover\:border-white:hover,
.focus\:text-white:focus,
.active\:text-white:active {
  border-color: var(--color-white);
}

.text-black,
.hover\:text-black:hover,
.focus\:text-black:focus,
.active\:text-black:active {
  color: var(--color-black);
}

.bg-black,
.hover\:bg-black:hover,
.focus\:text-black:focus,
.active\:text-black:active {
  background-color: var(--color-black);
}

.border-black,
.hover\:border-black:hover,
.focus\:text-black:focus,
.active\:text-black:active {
  border-color: var(--color-black);
}

.text-red-10,
.hover\:text-red-10:hover,
.focus\:text-red-10:focus,
.active\:text-red-10:active {
  color: var(--color-red-10);
}

.bg-red-10,
.hover\:bg-red-10:hover,
.focus\:bg-red-10:focus,
.active\:bg-red-10:active {
  background-color: var(--color-red-10);
}

.border-red-10,
.hover\:border-red-10:hover,
.focus\:border-red-10:focus,
.active\:border-red-10:active {
  border-color: var(--color-red-10);
}

.text-red-20,
.hover\:text-red-20:hover,
.focus\:text-red-20:focus,
.active\:text-red-20:active {
  color: var(--color-red-20);
}

.bg-red-20,
.hover\:bg-red-20:hover,
.focus\:bg-red-20:focus,
.active\:bg-red-20:active {
  background-color: var(--color-red-20);
}

.border-red-20,
.hover\:border-red-20:hover,
.focus\:border-red-20:focus,
.active\:border-red-20:active {
  border-color: var(--color-red-20);
}

.text-red-30,
.hover\:text-red-30:hover,
.focus\:text-red-30:focus,
.active\:text-red-30:active {
  color: var(--color-red-30);
}

.bg-red-30,
.hover\:bg-red-30:hover,
.focus\:bg-red-30:focus,
.active\:bg-red-30:active {
  background-color: var(--color-red-30);
}

.border-red-30,
.hover\:border-red-30:hover,
.focus\:border-red-30:focus,
.active\:border-red-30:active {
  border-color: var(--color-red-30);
}

.text-red-40,
.hover\:text-red-40:hover,
.focus\:text-red-40:focus,
.active\:text-red-40:active {
  color: var(--color-red-40);
}

.bg-red-40,
.hover\:bg-red-40:hover,
.focus\:bg-red-40:focus,
.active\:bg-red-40:active {
  background-color: var(--color-red-40);
}

.border-red-40,
.hover\:border-red-40:hover,
.focus\:border-red-40:focus,
.active\:border-red-40:active {
  border-color: var(--color-red-40);
}

.text-red-50,
.hover\:text-red-50:hover,
.focus\:text-red-50:focus,
.active\:text-red-50:active {
  color: var(--color-red-50);
}

.bg-red-50,
.hover\:bg-red-50:hover,
.focus\:bg-red-50:focus,
.active\:bg-red-50:active {
  background-color: var(--color-red-50);
}

.border-red-50,
.hover\:border-red-50:hover,
.focus\:border-red-50:focus,
.active\:border-red-50:active {
  border-color: var(--color-red-50);
}

.text-red-60,
.hover\:text-red-60:hover,
.focus\:text-red-60:focus,
.active\:text-red-60:active {
  color: var(--color-red-60);
}

.bg-red-60,
.hover\:bg-red-60:hover,
.focus\:bg-red-60:focus,
.active\:bg-red-60:active {
  background-color: var(--color-red-60);
}

.border-red-60,
.hover\:border-red-60:hover,
.focus\:border-red-60:focus,
.active\:border-red-60:active {
  border-color: var(--color-red-60);
}

.text-red-70,
.hover\:text-red-70:hover,
.focus\:text-red-70:focus,
.active\:text-red-70:active {
  color: var(--color-red-70);
}

.bg-red-70,
.hover\:bg-red-70:hover,
.focus\:bg-red-70:focus,
.active\:bg-red-70:active {
  background-color: var(--color-red-70);
}

.border-red-70,
.hover\:border-red-70:hover,
.focus\:border-red-70:focus,
.active\:border-red-70:active {
  border-color: var(--color-red-70);
}

.text-red-80,
.hover\:text-red-80:hover,
.focus\:text-red-80:focus,
.active\:text-red-80:active {
  color: var(--color-red-80);
}

.bg-red-80,
.hover\:bg-red-80:hover,
.focus\:bg-red-80:focus,
.active\:bg-red-80:active {
  background-color: var(--color-red-80);
}

.border-red-80,
.hover\:border-red-80:hover,
.focus\:border-red-80:focus,
.active\:border-red-80:active {
  border-color: var(--color-red-80);
}

.text-red-90,
.hover\:text-red-90:hover,
.focus\:text-red-90:focus,
.active\:text-red-90:active {
  color: var(--color-red-90);
}

.bg-red-90,
.hover\:bg-red-90:hover,
.focus\:bg-red-90:focus,
.active\:bg-red-90:active {
  background-color: var(--color-red-90);
}

.border-red-90,
.hover\:border-red-90:hover,
.focus\:border-red-90:focus,
.active\:border-red-90:active {
  border-color: var(--color-red-90);
}

.text-red-100,
.hover\:text-red-100:hover,
.focus\:text-red-100:focus,
.active\:text-red-100:active {
  color: var(--color-red-100);
}

.bg-red-100,
.hover\:bg-red-100:hover,
.focus\:bg-red-100:focus,
.active\:bg-red-100:active {
  background-color: var(--color-red-100);
}

.border-red-100,
.hover\:border-red-100:hover,
.focus\:border-red-100:focus,
.active\:border-red-100:active {
  border-color: var(--color-red-100);
}

.text-pink-10,
.hover\:text-pink-10:hover,
.focus\:text-pink-10:focus,
.active\:text-pink-10:active {
  color: var(--color-pink-10);
}

.bg-pink-10,
.hover\:bg-pink-10:hover,
.focus\:bg-pink-10:focus,
.active\:bg-pink-10:active {
  background-color: var(--color-pink-10);
}

.border-pink-10,
.hover\:border-pink-10:hover,
.focus\:border-pink-10:focus,
.active\:border-pink-10:active {
  border-color: var(--color-pink-10);
}

.text-pink-20,
.hover\:text-pink-20:hover,
.focus\:text-pink-20:focus,
.active\:text-pink-20:active {
  color: var(--color-pink-20);
}

.bg-pink-20,
.hover\:bg-pink-20:hover,
.focus\:bg-pink-20:focus,
.active\:bg-pink-20:active {
  background-color: var(--color-pink-20);
}

.border-pink-20,
.hover\:border-pink-20:hover,
.focus\:border-pink-20:focus,
.active\:border-pink-20:active {
  border-color: var(--color-pink-20);
}

.text-pink-30,
.hover\:text-pink-30:hover,
.focus\:text-pink-30:focus,
.active\:text-pink-30:active {
  color: var(--color-pink-30);
}

.bg-pink-30,
.hover\:bg-pink-30:hover,
.focus\:bg-pink-30:focus,
.active\:bg-pink-30:active {
  background-color: var(--color-pink-30);
}

.border-pink-30,
.hover\:border-pink-30:hover,
.focus\:border-pink-30:focus,
.active\:border-pink-30:active {
  border-color: var(--color-pink-30);
}

.text-pink-40,
.hover\:text-pink-40:hover,
.focus\:text-pink-40:focus,
.active\:text-pink-40:active {
  color: var(--color-pink-40);
}

.bg-pink-40,
.hover\:bg-pink-40:hover,
.focus\:bg-pink-40:focus,
.active\:bg-pink-40:active {
  background-color: var(--color-pink-40);
}

.border-pink-40,
.hover\:border-pink-40:hover,
.focus\:border-pink-40:focus,
.active\:border-pink-40:active {
  border-color: var(--color-pink-40);
}

.text-pink-50,
.hover\:text-pink-50:hover,
.focus\:text-pink-50:focus,
.active\:text-pink-50:active {
  color: var(--color-pink-50);
}

.bg-pink-50,
.hover\:bg-pink-50:hover,
.focus\:bg-pink-50:focus,
.active\:bg-pink-50:active {
  background-color: var(--color-pink-50);
}

.border-pink-50,
.hover\:border-pink-50:hover,
.focus\:border-pink-50:focus,
.active\:border-pink-50:active {
  border-color: var(--color-pink-50);
}

.text-pink-60,
.hover\:text-pink-60:hover,
.focus\:text-pink-60:focus,
.active\:text-pink-60:active {
  color: var(--color-pink-60);
}

.bg-pink-60,
.hover\:bg-pink-60:hover,
.focus\:bg-pink-60:focus,
.active\:bg-pink-60:active {
  background-color: var(--color-pink-60);
}

.border-pink-60,
.hover\:border-pink-60:hover,
.focus\:border-pink-60:focus,
.active\:border-pink-60:active {
  border-color: var(--color-pink-60);
}

.text-pink-70,
.hover\:text-pink-70:hover,
.focus\:text-pink-70:focus,
.active\:text-pink-70:active {
  color: var(--color-pink-70);
}

.bg-pink-70,
.hover\:bg-pink-70:hover,
.focus\:bg-pink-70:focus,
.active\:bg-pink-70:active {
  background-color: var(--color-pink-70);
}

.border-pink-70,
.hover\:border-pink-70:hover,
.focus\:border-pink-70:focus,
.active\:border-pink-70:active {
  border-color: var(--color-pink-70);
}

.text-pink-80,
.hover\:text-pink-80:hover,
.focus\:text-pink-80:focus,
.active\:text-pink-80:active {
  color: var(--color-pink-80);
}

.bg-pink-80,
.hover\:bg-pink-80:hover,
.focus\:bg-pink-80:focus,
.active\:bg-pink-80:active {
  background-color: var(--color-pink-80);
}

.border-pink-80,
.hover\:border-pink-80:hover,
.focus\:border-pink-80:focus,
.active\:border-pink-80:active {
  border-color: var(--color-pink-80);
}

.text-pink-90,
.hover\:text-pink-90:hover,
.focus\:text-pink-90:focus,
.active\:text-pink-90:active {
  color: var(--color-pink-90);
}

.bg-pink-90,
.hover\:bg-pink-90:hover,
.focus\:bg-pink-90:focus,
.active\:bg-pink-90:active {
  background-color: var(--color-pink-90);
}

.border-pink-90,
.hover\:border-pink-90:hover,
.focus\:border-pink-90:focus,
.active\:border-pink-90:active {
  border-color: var(--color-pink-90);
}

.text-pink-100,
.hover\:text-pink-100:hover,
.focus\:text-pink-100:focus,
.active\:text-pink-100:active {
  color: var(--color-pink-100);
}

.bg-pink-100,
.hover\:bg-pink-100:hover,
.focus\:bg-pink-100:focus,
.active\:bg-pink-100:active {
  background-color: var(--color-pink-100);
}

.border-pink-100,
.hover\:border-pink-100:hover,
.focus\:border-pink-100:focus,
.active\:border-pink-100:active {
  border-color: var(--color-pink-100);
}

.text-plum-40,
.hover\:text-plum-40:hover,
.focus\:text-plum-40:focus,
.active\:text-plum-40:active {
  color: var(--color-plum-40);
}

.bg-plum-40,
.hover\:bg-plum-40:hover,
.focus\:bg-plum-40:focus,
.active\:bg-plum-40:active {
  background-color: var(--color-plum-40);
}

.border-plum-40,
.hover\:border-plum-40:hover,
.focus\:border-plum-40:focus,
.active\:border-plum-40:active {
  border-color: var(--color-plum-40);
}

.text-plum-60,
.hover\:text-plum-60:hover,
.focus\:text-plum-60:focus,
.active\:text-plum-60:active {
  color: var(--color-plum-60);
}

.bg-plum-60,
.hover\:bg-plum-60:hover,
.focus\:bg-plum-60:focus,
.active\:bg-plum-60:active {
  background-color: var(--color-plum-60);
}

.border-plum-60,
.hover\:border-plum-60:hover,
.focus\:border-plum-60:focus,
.active\:border-plum-60:active {
  border-color: var(--color-plum-60);
}

.text-plum-100,
.hover\:text-plum-100:hover,
.focus\:text-plum-100:focus,
.active\:text-plum-100:active {
  color: var(--color-plum-100);
}

.bg-plum-100,
.hover\:bg-plum-100:hover,
.focus\:bg-plum-100:focus,
.active\:bg-plum-100:active {
  background-color: var(--color-plum-100);
}

.border-plum-100,
.hover\:border-plum-100:hover,
.focus\:border-plum-100:focus,
.active\:border-plum-100:active {
  border-color: var(--color-plum-100);
}

.text-purple-20,
.hover\:text-purple-20:hover,
.focus\:text-purple-20:focus,
.active\:text-purple-20:active {
  color: var(--color-purple-20);
}

.bg-purple-20,
.hover\:bg-purple-20:hover,
.focus\:bg-purple-20:focus,
.active\:bg-purple-20:active {
  background-color: var(--color-purple-20);
}

.border-purple-20,
.hover\:border-purple-20:hover,
.focus\:border-purple-20:focus,
.active\:border-purple-20:active {
  border-color: var(--color-purple-20);
}

.text-purple-30,
.hover\:text-purple-30:hover,
.focus\:text-purple-30:focus,
.active\:text-purple-30:active {
  color: var(--color-purple-30);
}

.bg-purple-30,
.hover\:bg-purple-30:hover,
.focus\:bg-purple-30:focus,
.active\:bg-purple-30:active {
  background-color: var(--color-purple-30);
}

.border-purple-30,
.hover\:border-purple-30:hover,
.focus\:border-purple-30:focus,
.active\:border-purple-30:active {
  border-color: var(--color-purple-30);
}

.text-purple-40,
.hover\:text-purple-40:hover,
.focus\:text-purple-40:focus,
.active\:text-purple-40:active {
  color: var(--color-purple-40);
}

.bg-purple-40,
.hover\:bg-purple-40:hover,
.focus\:bg-purple-40:focus,
.active\:bg-purple-40:active {
  background-color: var(--color-purple-40);
}

.border-purple-40,
.hover\:border-purple-40:hover,
.focus\:border-purple-40:focus,
.active\:border-purple-40:active {
  border-color: var(--color-purple-40);
}

.text-purple-50,
.hover\:text-purple-50:hover,
.focus\:text-purple-50:focus,
.active\:text-purple-50:active {
  color: var(--color-purple-50);
}

.bg-purple-50,
.hover\:bg-purple-50:hover,
.focus\:bg-purple-50:focus,
.active\:bg-purple-50:active {
  background-color: var(--color-purple-50);
}

.border-purple-50,
.hover\:border-purple-50:hover,
.focus\:border-purple-50:focus,
.active\:border-purple-50:active {
  border-color: var(--color-purple-50);
}

.text-purple-80,
.hover\:text-purple-80:hover,
.focus\:text-purple-80:focus,
.active\:text-purple-80:active {
  color: var(--color-purple-80);
}

.bg-purple-80,
.hover\:bg-purple-80:hover,
.focus\:bg-purple-80:focus,
.active\:bg-purple-80:active {
  background-color: var(--color-purple-80);
}

.border-purple-80,
.hover\:border-purple-80:hover,
.focus\:border-purple-80:focus,
.active\:border-purple-80:active {
  border-color: var(--color-purple-80);
}

.text-purple-100,
.hover\:text-purple-100:hover,
.focus\:text-purple-100:focus,
.active\:text-purple-100:active {
  color: var(--color-purple-100);
}

.bg-purple-100,
.hover\:bg-purple-100:hover,
.focus\:bg-purple-100:focus,
.active\:bg-purple-100:active {
  background-color: var(--color-purple-100);
}

.border-purple-100,
.hover\:border-purple-100:hover,
.focus\:border-purple-100:focus,
.active\:border-purple-100:active {
  border-color: var(--color-purple-100);
}

.text-blue-10,
.hover\:text-blue-10:hover,
.focus\:text-blue-10:focus,
.active\:text-blue-10:active {
  color: var(--color-blue-10);
}

.bg-blue-10,
.hover\:bg-blue-10:hover,
.focus\:bg-blue-10:focus,
.active\:bg-blue-10:active {
  background-color: var(--color-blue-10);
}

.border-blue-10,
.hover\:border-blue-10:hover,
.focus\:border-blue-10:focus,
.active\:border-blue-10:active {
  border-color: var(--color-blue-10);
}

.text-blue-20,
.hover\:text-blue-20:hover,
.focus\:text-blue-20:focus,
.active\:text-blue-20:active {
  color: var(--color-blue-20);
}

.bg-blue-20,
.hover\:bg-blue-20:hover,
.focus\:bg-blue-20:focus,
.active\:bg-blue-20:active {
  background-color: var(--color-blue-20);
}

.border-blue-20,
.hover\:border-blue-20:hover,
.focus\:border-blue-20:focus,
.active\:border-blue-20:active {
  border-color: var(--color-blue-20);
}

.text-blue-30,
.hover\:text-blue-30:hover,
.focus\:text-blue-30:focus,
.active\:text-blue-30:active {
  color: var(--color-blue-30);
}

.bg-blue-30,
.hover\:bg-blue-30:hover,
.focus\:bg-blue-30:focus,
.active\:bg-blue-30:active {
  background-color: var(--color-blue-30);
}

.border-blue-30,
.hover\:border-blue-30:hover,
.focus\:border-blue-30:focus,
.active\:border-blue-30:active {
  border-color: var(--color-blue-30);
}

.text-blue-40,
.hover\:text-blue-40:hover,
.focus\:text-blue-40:focus,
.active\:text-blue-40:active {
  color: var(--color-blue-40);
}

.bg-blue-40,
.hover\:bg-blue-40:hover,
.focus\:bg-blue-40:focus,
.active\:bg-blue-40:active {
  background-color: var(--color-blue-40);
}

.border-blue-40,
.hover\:border-blue-40:hover,
.focus\:border-blue-40:focus,
.active\:border-blue-40:active {
  border-color: var(--color-blue-40);
}

.text-blue-50,
.hover\:text-blue-50:hover,
.focus\:text-blue-50:focus,
.active\:text-blue-50:active {
  color: var(--color-blue-50);
}

.bg-blue-50,
.hover\:bg-blue-50:hover,
.focus\:bg-blue-50:focus,
.active\:bg-blue-50:active {
  background-color: var(--color-blue-50);
}

.border-blue-50,
.hover\:border-blue-50:hover,
.focus\:border-blue-50:focus,
.active\:border-blue-50:active {
  border-color: var(--color-blue-50);
}

.text-blue-60,
.hover\:text-blue-60:hover,
.focus\:text-blue-60:focus,
.active\:text-blue-60:active {
  color: var(--color-blue-60);
}

.bg-blue-60,
.hover\:bg-blue-60:hover,
.focus\:bg-blue-60:focus,
.active\:bg-blue-60:active {
  background-color: var(--color-blue-60);
}

.border-blue-60,
.hover\:border-blue-60:hover,
.focus\:border-blue-60:focus,
.active\:border-blue-60:active {
  border-color: var(--color-blue-60);
}

.text-blue-70,
.hover\:text-blue-70:hover,
.focus\:text-blue-70:focus,
.active\:text-blue-70:active {
  color: var(--color-blue-70);
}

.bg-blue-70,
.hover\:bg-blue-70:hover,
.focus\:bg-blue-70:focus,
.active\:bg-blue-70:active {
  background-color: var(--color-blue-70);
}

.border-blue-70,
.hover\:border-blue-70:hover,
.focus\:border-blue-70:focus,
.active\:border-blue-70:active {
  border-color: var(--color-blue-70);
}

.text-blue-80,
.hover\:text-blue-80:hover,
.focus\:text-blue-80:focus,
.active\:text-blue-80:active {
  color: var(--color-blue-80);
}

.bg-blue-80,
.hover\:bg-blue-80:hover,
.focus\:bg-blue-80:focus,
.active\:bg-blue-80:active {
  background-color: var(--color-blue-80);
}

.border-blue-80,
.hover\:border-blue-80:hover,
.focus\:border-blue-80:focus,
.active\:border-blue-80:active {
  border-color: var(--color-blue-80);
}

.text-blue-90,
.hover\:text-blue-90:hover,
.focus\:text-blue-90:focus,
.active\:text-blue-90:active {
  color: var(--color-blue-90);
}

.bg-blue-90,
.hover\:bg-blue-90:hover,
.focus\:bg-blue-90:focus,
.active\:bg-blue-90:active {
  background-color: var(--color-blue-90);
}

.border-blue-90,
.hover\:border-blue-90:hover,
.focus\:border-blue-90:focus,
.active\:border-blue-90:active {
  border-color: var(--color-blue-90);
}

.text-blue-100,
.hover\:text-blue-100:hover,
.focus\:text-blue-100:focus,
.active\:text-blue-100:active {
  color: var(--color-blue-100);
}

.bg-blue-100,
.hover\:bg-blue-100:hover,
.focus\:bg-blue-100:focus,
.active\:bg-blue-100:active {
  background-color: var(--color-blue-100);
}

.border-blue-100,
.hover\:border-blue-100:hover,
.focus\:border-blue-100:focus,
.active\:border-blue-100:active {
  border-color: var(--color-blue-100);
}

.text-cyan-10,
.hover\:text-cyan-10:hover,
.focus\:text-cyan-10:focus,
.active\:text-cyan-10:active {
  color: var(--color-cyan-10);
}

.bg-cyan-10,
.hover\:bg-cyan-10:hover,
.focus\:bg-cyan-10:focus,
.active\:bg-cyan-10:active {
  background-color: var(--color-cyan-10);
}

.border-cyan-10,
.hover\:border-cyan-10:hover,
.focus\:border-cyan-10:focus,
.active\:border-cyan-10:active {
  border-color: var(--color-cyan-10);
}

.text-cyan-20,
.hover\:text-cyan-20:hover,
.focus\:text-cyan-20:focus,
.active\:text-cyan-20:active {
  color: var(--color-cyan-20);
}

.bg-cyan-20,
.hover\:bg-cyan-20:hover,
.focus\:bg-cyan-20:focus,
.active\:bg-cyan-20:active {
  background-color: var(--color-cyan-20);
}

.border-cyan-20,
.hover\:border-cyan-20:hover,
.focus\:border-cyan-20:focus,
.active\:border-cyan-20:active {
  border-color: var(--color-cyan-20);
}

.text-cyan-30,
.hover\:text-cyan-30:hover,
.focus\:text-cyan-30:focus,
.active\:text-cyan-30:active {
  color: var(--color-cyan-30);
}

.bg-cyan-30,
.hover\:bg-cyan-30:hover,
.focus\:bg-cyan-30:focus,
.active\:bg-cyan-30:active {
  background-color: var(--color-cyan-30);
}

.border-cyan-30,
.hover\:border-cyan-30:hover,
.focus\:border-cyan-30:focus,
.active\:border-cyan-30:active {
  border-color: var(--color-cyan-30);
}

.text-cyan-40,
.hover\:text-cyan-40:hover,
.focus\:text-cyan-40:focus,
.active\:text-cyan-40:active {
  color: var(--color-cyan-40);
}

.bg-cyan-40,
.hover\:bg-cyan-40:hover,
.focus\:bg-cyan-40:focus,
.active\:bg-cyan-40:active {
  background-color: var(--color-cyan-40);
}

.border-cyan-40,
.hover\:border-cyan-40:hover,
.focus\:border-cyan-40:focus,
.active\:border-cyan-40:active {
  border-color: var(--color-cyan-40);
}

.text-cyan-50,
.hover\:text-cyan-50:hover,
.focus\:text-cyan-50:focus,
.active\:text-cyan-50:active {
  color: var(--color-cyan-50);
}

.bg-cyan-50,
.hover\:bg-cyan-50:hover,
.focus\:bg-cyan-50:focus,
.active\:bg-cyan-50:active {
  background-color: var(--color-cyan-50);
}

.border-cyan-50,
.hover\:border-cyan-50:hover,
.focus\:border-cyan-50:focus,
.active\:border-cyan-50:active {
  border-color: var(--color-cyan-50);
}

.text-cyan-60,
.hover\:text-cyan-60:hover,
.focus\:text-cyan-60:focus,
.active\:text-cyan-60:active {
  color: var(--color-cyan-60);
}

.bg-cyan-60,
.hover\:bg-cyan-60:hover,
.focus\:bg-cyan-60:focus,
.active\:bg-cyan-60:active {
  background-color: var(--color-cyan-60);
}

.border-cyan-60,
.hover\:border-cyan-60:hover,
.focus\:border-cyan-60:focus,
.active\:border-cyan-60:active {
  border-color: var(--color-cyan-60);
}

.text-cyan-70,
.hover\:text-cyan-70:hover,
.focus\:text-cyan-70:focus,
.active\:text-cyan-70:active {
  color: var(--color-cyan-70);
}

.bg-cyan-70,
.hover\:bg-cyan-70:hover,
.focus\:bg-cyan-70:focus,
.active\:bg-cyan-70:active {
  background-color: var(--color-cyan-70);
}

.border-cyan-70,
.hover\:border-cyan-70:hover,
.focus\:border-cyan-70:focus,
.active\:border-cyan-70:active {
  border-color: var(--color-cyan-70);
}

.text-cyan-80,
.hover\:text-cyan-80:hover,
.focus\:text-cyan-80:focus,
.active\:text-cyan-80:active {
  color: var(--color-cyan-80);
}

.bg-cyan-80,
.hover\:bg-cyan-80:hover,
.focus\:bg-cyan-80:focus,
.active\:bg-cyan-80:active {
  background-color: var(--color-cyan-80);
}

.border-cyan-80,
.hover\:border-cyan-80:hover,
.focus\:border-cyan-80:focus,
.active\:border-cyan-80:active {
  border-color: var(--color-cyan-80);
}

.text-cyan-90,
.hover\:text-cyan-90:hover,
.focus\:text-cyan-90:focus,
.active\:text-cyan-90:active {
  color: var(--color-cyan-90);
}

.bg-cyan-90,
.hover\:bg-cyan-90:hover,
.focus\:bg-cyan-90:focus,
.active\:bg-cyan-90:active {
  background-color: var(--color-cyan-90);
}

.border-cyan-90,
.hover\:border-cyan-90:hover,
.focus\:border-cyan-90:focus,
.active\:border-cyan-90:active {
  border-color: var(--color-cyan-90);
}

.text-cyan-100,
.hover\:text-cyan-100:hover,
.focus\:text-cyan-100:focus,
.active\:text-cyan-100:active {
  color: var(--color-cyan-100);
}

.bg-cyan-100,
.hover\:bg-cyan-100:hover,
.focus\:bg-cyan-100:focus,
.active\:bg-cyan-100:active {
  background-color: var(--color-cyan-100);
}

.border-cyan-100,
.hover\:border-cyan-100:hover,
.focus\:border-cyan-100:focus,
.active\:border-cyan-100:active {
  border-color: var(--color-cyan-100);
}

.text-green-10,
.hover\:text-green-10:hover,
.focus\:text-green-10:focus,
.active\:text-green-10:active {
  color: var(--color-green-10);
}

.bg-green-10,
.hover\:bg-green-10:hover,
.focus\:bg-green-10:focus,
.active\:bg-green-10:active {
  background-color: var(--color-green-10);
}

.border-green-10,
.hover\:border-green-10:hover,
.focus\:border-green-10:focus,
.active\:border-green-10:active {
  border-color: var(--color-green-10);
}

.text-green-20,
.hover\:text-green-20:hover,
.focus\:text-green-20:focus,
.active\:text-green-20:active {
  color: var(--color-green-20);
}

.bg-green-20,
.hover\:bg-green-20:hover,
.focus\:bg-green-20:focus,
.active\:bg-green-20:active {
  background-color: var(--color-green-20);
}

.border-green-20,
.hover\:border-green-20:hover,
.focus\:border-green-20:focus,
.active\:border-green-20:active {
  border-color: var(--color-green-20);
}

.text-green-30,
.hover\:text-green-30:hover,
.focus\:text-green-30:focus,
.active\:text-green-30:active {
  color: var(--color-green-30);
}

.bg-green-30,
.hover\:bg-green-30:hover,
.focus\:bg-green-30:focus,
.active\:bg-green-30:active {
  background-color: var(--color-green-30);
}

.border-green-30,
.hover\:border-green-30:hover,
.focus\:border-green-30:focus,
.active\:border-green-30:active {
  border-color: var(--color-green-30);
}

.text-green-40,
.hover\:text-green-40:hover,
.focus\:text-green-40:focus,
.active\:text-green-40:active {
  color: var(--color-green-40);
}

.bg-green-40,
.hover\:bg-green-40:hover,
.focus\:bg-green-40:focus,
.active\:bg-green-40:active {
  background-color: var(--color-green-40);
}

.border-green-40,
.hover\:border-green-40:hover,
.focus\:border-green-40:focus,
.active\:border-green-40:active {
  border-color: var(--color-green-40);
}

.text-green-50,
.hover\:text-green-50:hover,
.focus\:text-green-50:focus,
.active\:text-green-50:active {
  color: var(--color-green-50);
}

.bg-green-50,
.hover\:bg-green-50:hover,
.focus\:bg-green-50:focus,
.active\:bg-green-50:active {
  background-color: var(--color-green-50);
}

.border-green-50,
.hover\:border-green-50:hover,
.focus\:border-green-50:focus,
.active\:border-green-50:active {
  border-color: var(--color-green-50);
}

.text-green-60,
.hover\:text-green-60:hover,
.focus\:text-green-60:focus,
.active\:text-green-60:active {
  color: var(--color-green-60);
}

.bg-green-60,
.hover\:bg-green-60:hover,
.focus\:bg-green-60:focus,
.active\:bg-green-60:active {
  background-color: var(--color-green-60);
}

.border-green-60,
.hover\:border-green-60:hover,
.focus\:border-green-60:focus,
.active\:border-green-60:active {
  border-color: var(--color-green-60);
}

.text-green-70,
.hover\:text-green-70:hover,
.focus\:text-green-70:focus,
.active\:text-green-70:active {
  color: var(--color-green-70);
}

.bg-green-70,
.hover\:bg-green-70:hover,
.focus\:bg-green-70:focus,
.active\:bg-green-70:active {
  background-color: var(--color-green-70);
}

.border-green-70,
.hover\:border-green-70:hover,
.focus\:border-green-70:focus,
.active\:border-green-70:active {
  border-color: var(--color-green-70);
}

.text-green-80,
.hover\:text-green-80:hover,
.focus\:text-green-80:focus,
.active\:text-green-80:active {
  color: var(--color-green-80);
}

.bg-green-80,
.hover\:bg-green-80:hover,
.focus\:bg-green-80:focus,
.active\:bg-green-80:active {
  background-color: var(--color-green-80);
}

.border-green-80,
.hover\:border-green-80:hover,
.focus\:border-green-80:focus,
.active\:border-green-80:active {
  border-color: var(--color-green-80);
}

.text-green-90,
.hover\:text-green-90:hover,
.focus\:text-green-90:focus,
.active\:text-green-90:active {
  color: var(--color-green-90);
}

.bg-green-90,
.hover\:bg-green-90:hover,
.focus\:bg-green-90:focus,
.active\:bg-green-90:active {
  background-color: var(--color-green-90);
}

.border-green-90,
.hover\:border-green-90:hover,
.focus\:border-green-90:focus,
.active\:border-green-90:active {
  border-color: var(--color-green-90);
}

.text-green-100,
.hover\:text-green-100:hover,
.focus\:text-green-100:focus,
.active\:text-green-100:active {
  color: var(--color-green-100);
}

.bg-green-100,
.hover\:bg-green-100:hover,
.focus\:bg-green-100:focus,
.active\:bg-green-100:active {
  background-color: var(--color-green-100);
}

.border-green-100,
.hover\:border-green-100:hover,
.focus\:border-green-100:focus,
.active\:border-green-100:active {
  border-color: var(--color-green-100);
}

.text-teal-10,
.hover\:text-teal-10:hover,
.focus\:text-teal-10:focus,
.active\:text-teal-10:active {
  color: var(--color-teal-10);
}

.bg-teal-10,
.hover\:bg-teal-10:hover,
.focus\:bg-teal-10:focus,
.active\:bg-teal-10:active {
  background-color: var(--color-teal-10);
}

.border-teal-10,
.hover\:border-teal-10:hover,
.focus\:border-teal-10:focus,
.active\:border-teal-10:active {
  border-color: var(--color-teal-10);
}

.text-teal-20,
.hover\:text-teal-20:hover,
.focus\:text-teal-20:focus,
.active\:text-teal-20:active {
  color: var(--color-teal-20);
}

.bg-teal-20,
.hover\:bg-teal-20:hover,
.focus\:bg-teal-20:focus,
.active\:bg-teal-20:active {
  background-color: var(--color-teal-20);
}

.border-teal-20,
.hover\:border-teal-20:hover,
.focus\:border-teal-20:focus,
.active\:border-teal-20:active {
  border-color: var(--color-teal-20);
}

.text-teal-30,
.hover\:text-teal-30:hover,
.focus\:text-teal-30:focus,
.active\:text-teal-30:active {
  color: var(--color-teal-30);
}

.bg-teal-30,
.hover\:bg-teal-30:hover,
.focus\:bg-teal-30:focus,
.active\:bg-teal-30:active {
  background-color: var(--color-teal-30);
}

.border-teal-30,
.hover\:border-teal-30:hover,
.focus\:border-teal-30:focus,
.active\:border-teal-30:active {
  border-color: var(--color-teal-30);
}

.text-teal-40,
.hover\:text-teal-40:hover,
.focus\:text-teal-40:focus,
.active\:text-teal-40:active {
  color: var(--color-teal-40);
}

.bg-teal-40,
.hover\:bg-teal-40:hover,
.focus\:bg-teal-40:focus,
.active\:bg-teal-40:active {
  background-color: var(--color-teal-40);
}

.border-teal-40,
.hover\:border-teal-40:hover,
.focus\:border-teal-40:focus,
.active\:border-teal-40:active {
  border-color: var(--color-teal-40);
}

.text-teal-50,
.hover\:text-teal-50:hover,
.focus\:text-teal-50:focus,
.active\:text-teal-50:active {
  color: var(--color-teal-50);
}

.bg-teal-50,
.hover\:bg-teal-50:hover,
.focus\:bg-teal-50:focus,
.active\:bg-teal-50:active {
  background-color: var(--color-teal-50);
}

.border-teal-50,
.hover\:border-teal-50:hover,
.focus\:border-teal-50:focus,
.active\:border-teal-50:active {
  border-color: var(--color-teal-50);
}

.text-teal-60,
.hover\:text-teal-60:hover,
.focus\:text-teal-60:focus,
.active\:text-teal-60:active {
  color: var(--color-teal-60);
}

.bg-teal-60,
.hover\:bg-teal-60:hover,
.focus\:bg-teal-60:focus,
.active\:bg-teal-60:active {
  background-color: var(--color-teal-60);
}

.border-teal-60,
.hover\:border-teal-60:hover,
.focus\:border-teal-60:focus,
.active\:border-teal-60:active {
  border-color: var(--color-teal-60);
}

.text-teal-70,
.hover\:text-teal-70:hover,
.focus\:text-teal-70:focus,
.active\:text-teal-70:active {
  color: var(--color-teal-70);
}

.bg-teal-70,
.hover\:bg-teal-70:hover,
.focus\:bg-teal-70:focus,
.active\:bg-teal-70:active {
  background-color: var(--color-teal-70);
}

.border-teal-70,
.hover\:border-teal-70:hover,
.focus\:border-teal-70:focus,
.active\:border-teal-70:active {
  border-color: var(--color-teal-70);
}

.text-teal-80,
.hover\:text-teal-80:hover,
.focus\:text-teal-80:focus,
.active\:text-teal-80:active {
  color: var(--color-teal-80);
}

.bg-teal-80,
.hover\:bg-teal-80:hover,
.focus\:bg-teal-80:focus,
.active\:bg-teal-80:active {
  background-color: var(--color-teal-80);
}

.border-teal-80,
.hover\:border-teal-80:hover,
.focus\:border-teal-80:focus,
.active\:border-teal-80:active {
  border-color: var(--color-teal-80);
}

.text-teal-90,
.hover\:text-teal-90:hover,
.focus\:text-teal-90:focus,
.active\:text-teal-90:active {
  color: var(--color-teal-90);
}

.bg-teal-90,
.hover\:bg-teal-90:hover,
.focus\:bg-teal-90:focus,
.active\:bg-teal-90:active {
  background-color: var(--color-teal-90);
}

.border-teal-90,
.hover\:border-teal-90:hover,
.focus\:border-teal-90:focus,
.active\:border-teal-90:active {
  border-color: var(--color-teal-90);
}

.text-teal-100,
.hover\:text-teal-100:hover,
.focus\:text-teal-100:focus,
.active\:text-teal-100:active {
  color: var(--color-teal-100);
}

.bg-teal-100,
.hover\:bg-teal-100:hover,
.focus\:bg-teal-100:focus,
.active\:bg-teal-100:active {
  background-color: var(--color-teal-100);
}

.border-teal-100,
.hover\:border-teal-100:hover,
.focus\:border-teal-100:focus,
.active\:border-teal-100:active {
  border-color: var(--color-teal-100);
}

.text-orange-10,
.hover\:text-orange-10:hover,
.focus\:text-orange-10:focus,
.active\:text-orange-10:active {
  color: var(--color-orange-10);
}

.bg-orange-10,
.hover\:bg-orange-10:hover,
.focus\:bg-orange-10:focus,
.active\:bg-orange-10:active {
  background-color: var(--color-orange-10);
}

.border-orange-10,
.hover\:border-orange-10:hover,
.focus\:border-orange-10:focus,
.active\:border-orange-10:active {
  border-color: var(--color-orange-10);
}

.text-orange-20,
.hover\:text-orange-20:hover,
.focus\:text-orange-20:focus,
.active\:text-orange-20:active {
  color: var(--color-orange-20);
}

.bg-orange-20,
.hover\:bg-orange-20:hover,
.focus\:bg-orange-20:focus,
.active\:bg-orange-20:active {
  background-color: var(--color-orange-20);
}

.border-orange-20,
.hover\:border-orange-20:hover,
.focus\:border-orange-20:focus,
.active\:border-orange-20:active {
  border-color: var(--color-orange-20);
}

.text-orange-30,
.hover\:text-orange-30:hover,
.focus\:text-orange-30:focus,
.active\:text-orange-30:active {
  color: var(--color-orange-30);
}

.bg-orange-30,
.hover\:bg-orange-30:hover,
.focus\:bg-orange-30:focus,
.active\:bg-orange-30:active {
  background-color: var(--color-orange-30);
}

.border-orange-30,
.hover\:border-orange-30:hover,
.focus\:border-orange-30:focus,
.active\:border-orange-30:active {
  border-color: var(--color-orange-30);
}

.text-orange-40,
.hover\:text-orange-40:hover,
.focus\:text-orange-40:focus,
.active\:text-orange-40:active {
  color: var(--color-orange-40);
}

.bg-orange-40,
.hover\:bg-orange-40:hover,
.focus\:bg-orange-40:focus,
.active\:bg-orange-40:active {
  background-color: var(--color-orange-40);
}

.border-orange-40,
.hover\:border-orange-40:hover,
.focus\:border-orange-40:focus,
.active\:border-orange-40:active {
  border-color: var(--color-orange-40);
}

.text-orange-50,
.hover\:text-orange-50:hover,
.focus\:text-orange-50:focus,
.active\:text-orange-50:active {
  color: var(--color-orange-50);
}

.bg-orange-50,
.hover\:bg-orange-50:hover,
.focus\:bg-orange-50:focus,
.active\:bg-orange-50:active {
  background-color: var(--color-orange-50);
}

.border-orange-50,
.hover\:border-orange-50:hover,
.focus\:border-orange-50:focus,
.active\:border-orange-50:active {
  border-color: var(--color-orange-50);
}

.text-orange-60,
.hover\:text-orange-60:hover,
.focus\:text-orange-60:focus,
.active\:text-orange-60:active {
  color: var(--color-orange-60);
}

.bg-orange-60,
.hover\:bg-orange-60:hover,
.focus\:bg-orange-60:focus,
.active\:bg-orange-60:active {
  background-color: var(--color-orange-60);
}

.border-orange-60,
.hover\:border-orange-60:hover,
.focus\:border-orange-60:focus,
.active\:border-orange-60:active {
  border-color: var(--color-orange-60);
}

.text-orange-70,
.hover\:text-orange-70:hover,
.focus\:text-orange-70:focus,
.active\:text-orange-70:active {
  color: var(--color-orange-70);
}

.bg-orange-70,
.hover\:bg-orange-70:hover,
.focus\:bg-orange-70:focus,
.active\:bg-orange-70:active {
  background-color: var(--color-orange-70);
}

.border-orange-70,
.hover\:border-orange-70:hover,
.focus\:border-orange-70:focus,
.active\:border-orange-70:active {
  border-color: var(--color-orange-70);
}

.text-orange-80,
.hover\:text-orange-80:hover,
.focus\:text-orange-80:focus,
.active\:text-orange-80:active {
  color: var(--color-orange-80);
}

.bg-orange-80,
.hover\:bg-orange-80:hover,
.focus\:bg-orange-80:focus,
.active\:bg-orange-80:active {
  background-color: var(--color-orange-80);
}

.border-orange-80,
.hover\:border-orange-80:hover,
.focus\:border-orange-80:focus,
.active\:border-orange-80:active {
  border-color: var(--color-orange-80);
}

.text-orange-90,
.hover\:text-orange-90:hover,
.focus\:text-orange-90:focus,
.active\:text-orange-90:active {
  color: var(--color-orange-90);
}

.bg-orange-90,
.hover\:bg-orange-90:hover,
.focus\:bg-orange-90:focus,
.active\:bg-orange-90:active {
  background-color: var(--color-orange-90);
}

.border-orange-90,
.hover\:border-orange-90:hover,
.focus\:border-orange-90:focus,
.active\:border-orange-90:active {
  border-color: var(--color-orange-90);
}

.text-orange-100,
.hover\:text-orange-100:hover,
.focus\:text-orange-100:focus,
.active\:text-orange-100:active {
  color: var(--color-orange-100);
}

.bg-orange-100,
.hover\:bg-orange-100:hover,
.focus\:bg-orange-100:focus,
.active\:bg-orange-100:active {
  background-color: var(--color-orange-100);
}

.border-orange-100,
.hover\:border-orange-100:hover,
.focus\:border-orange-100:focus,
.active\:border-orange-100:active {
  border-color: var(--color-orange-100);
}

.text-salmon-10,
.hover\:text-salmon-10:hover,
.focus\:text-salmon-10:focus,
.active\:text-salmon-10:active {
  color: var(--color-salmon-10);
}

.bg-salmon-10,
.hover\:bg-salmon-10:hover,
.focus\:bg-salmon-10:focus,
.active\:bg-salmon-10:active {
  background-color: var(--color-salmon-10);
}

.border-salmon-10,
.hover\:border-salmon-10:hover,
.focus\:border-salmon-10:focus,
.active\:border-salmon-10:active {
  border-color: var(--color-salmon-10);
}

.text-salmon-20,
.hover\:text-salmon-20:hover,
.focus\:text-salmon-20:focus,
.active\:text-salmon-20:active {
  color: var(--color-salmon-20);
}

.bg-salmon-20,
.hover\:bg-salmon-20:hover,
.focus\:bg-salmon-20:focus,
.active\:bg-salmon-20:active {
  background-color: var(--color-salmon-20);
}

.border-salmon-20,
.hover\:border-salmon-20:hover,
.focus\:border-salmon-20:focus,
.active\:border-salmon-20:active {
  border-color: var(--color-salmon-20);
}

.text-salmon-30,
.hover\:text-salmon-30:hover,
.focus\:text-salmon-30:focus,
.active\:text-salmon-30:active {
  color: var(--color-salmon-30);
}

.bg-salmon-30,
.hover\:bg-salmon-30:hover,
.focus\:bg-salmon-30:focus,
.active\:bg-salmon-30:active {
  background-color: var(--color-salmon-30);
}

.border-salmon-30,
.hover\:border-salmon-30:hover,
.focus\:border-salmon-30:focus,
.active\:border-salmon-30:active {
  border-color: var(--color-salmon-30);
}

.text-salmon-40,
.hover\:text-salmon-40:hover,
.focus\:text-salmon-40:focus,
.active\:text-salmon-40:active {
  color: var(--color-salmon-40);
}

.bg-salmon-40,
.hover\:bg-salmon-40:hover,
.focus\:bg-salmon-40:focus,
.active\:bg-salmon-40:active {
  background-color: var(--color-salmon-40);
}

.border-salmon-40,
.hover\:border-salmon-40:hover,
.focus\:border-salmon-40:focus,
.active\:border-salmon-40:active {
  border-color: var(--color-salmon-40);
}

.text-salmon-50,
.hover\:text-salmon-50:hover,
.focus\:text-salmon-50:focus,
.active\:text-salmon-50:active {
  color: var(--color-salmon-50);
}

.bg-salmon-50,
.hover\:bg-salmon-50:hover,
.focus\:bg-salmon-50:focus,
.active\:bg-salmon-50:active {
  background-color: var(--color-salmon-50);
}

.border-salmon-50,
.hover\:border-salmon-50:hover,
.focus\:border-salmon-50:focus,
.active\:border-salmon-50:active {
  border-color: var(--color-salmon-50);
}

.text-salmon-60,
.hover\:text-salmon-60:hover,
.focus\:text-salmon-60:focus,
.active\:text-salmon-60:active {
  color: var(--color-salmon-60);
}

.bg-salmon-60,
.hover\:bg-salmon-60:hover,
.focus\:bg-salmon-60:focus,
.active\:bg-salmon-60:active {
  background-color: var(--color-salmon-60);
}

.border-salmon-60,
.hover\:border-salmon-60:hover,
.focus\:border-salmon-60:focus,
.active\:border-salmon-60:active {
  border-color: var(--color-salmon-60);
}

.text-gold-10,
.hover\:text-gold-10:hover,
.focus\:text-gold-10:focus,
.active\:text-gold-10:active {
  color: var(--color-gold-10);
}

.bg-gold-10,
.hover\:bg-gold-10:hover,
.focus\:bg-gold-10:focus,
.active\:bg-gold-10:active {
  background-color: var(--color-gold-10);
}

.border-gold-10,
.hover\:border-gold-10:hover,
.focus\:border-gold-10:focus,
.active\:border-gold-10:active {
  border-color: var(--color-gold-10);
}

.text-gold-20,
.hover\:text-gold-20:hover,
.focus\:text-gold-20:focus,
.active\:text-gold-20:active {
  color: var(--color-gold-20);
}

.bg-gold-20,
.hover\:bg-gold-20:hover,
.focus\:bg-gold-20:focus,
.active\:bg-gold-20:active {
  background-color: var(--color-gold-20);
}

.border-gold-20,
.hover\:border-gold-20:hover,
.focus\:border-gold-20:focus,
.active\:border-gold-20:active {
  border-color: var(--color-gold-20);
}

.text-gold-30,
.hover\:text-gold-30:hover,
.focus\:text-gold-30:focus,
.active\:text-gold-30:active {
  color: var(--color-gold-30);
}

.bg-gold-30,
.hover\:bg-gold-30:hover,
.focus\:bg-gold-30:focus,
.active\:bg-gold-30:active {
  background-color: var(--color-gold-30);
}

.border-gold-30,
.hover\:border-gold-30:hover,
.focus\:border-gold-30:focus,
.active\:border-gold-30:active {
  border-color: var(--color-gold-30);
}

.text-gold-40,
.hover\:text-gold-40:hover,
.focus\:text-gold-40:focus,
.active\:text-gold-40:active {
  color: var(--color-gold-40);
}

.bg-gold-40,
.hover\:bg-gold-40:hover,
.focus\:bg-gold-40:focus,
.active\:bg-gold-40:active {
  background-color: var(--color-gold-40);
}

.border-gold-40,
.hover\:border-gold-40:hover,
.focus\:border-gold-40:focus,
.active\:border-gold-40:active {
  border-color: var(--color-gold-40);
}

.text-gold-50,
.hover\:text-gold-50:hover,
.focus\:text-gold-50:focus,
.active\:text-gold-50:active {
  color: var(--color-gold-50);
}

.bg-gold-50,
.hover\:bg-gold-50:hover,
.focus\:bg-gold-50:focus,
.active\:bg-gold-50:active {
  background-color: var(--color-gold-50);
}

.border-gold-50,
.hover\:border-gold-50:hover,
.focus\:border-gold-50:focus,
.active\:border-gold-50:active {
  border-color: var(--color-gold-50);
}

.text-gold-60,
.hover\:text-gold-60:hover,
.focus\:text-gold-60:focus,
.active\:text-gold-60:active {
  color: var(--color-gold-60);
}

.bg-gold-60,
.hover\:bg-gold-60:hover,
.focus\:bg-gold-60:focus,
.active\:bg-gold-60:active {
  background-color: var(--color-gold-60);
}

.border-gold-60,
.hover\:border-gold-60:hover,
.focus\:border-gold-60:focus,
.active\:border-gold-60:active {
  border-color: var(--color-gold-60);
}

.text-gold-70,
.hover\:text-gold-70:hover,
.focus\:text-gold-70:focus,
.active\:text-gold-70:active {
  color: var(--color-gold-70);
}

.bg-gold-70,
.hover\:bg-gold-70:hover,
.focus\:bg-gold-70:focus,
.active\:bg-gold-70:active {
  background-color: var(--color-gold-70);
}

.border-gold-70,
.hover\:border-gold-70:hover,
.focus\:border-gold-70:focus,
.active\:border-gold-70:active {
  border-color: var(--color-gold-70);
}

.text-gold-80,
.hover\:text-gold-80:hover,
.focus\:text-gold-80:focus,
.active\:text-gold-80:active {
  color: var(--color-gold-80);
}

.bg-gold-80,
.hover\:bg-gold-80:hover,
.focus\:bg-gold-80:focus,
.active\:bg-gold-80:active {
  background-color: var(--color-gold-80);
}

.border-gold-80,
.hover\:border-gold-80:hover,
.focus\:border-gold-80:focus,
.active\:border-gold-80:active {
  border-color: var(--color-gold-80);
}

.text-gold-90,
.hover\:text-gold-90:hover,
.focus\:text-gold-90:focus,
.active\:text-gold-90:active {
  color: var(--color-gold-90);
}

.bg-gold-90,
.hover\:bg-gold-90:hover,
.focus\:bg-gold-90:focus,
.active\:bg-gold-90:active {
  background-color: var(--color-gold-90);
}

.border-gold-90,
.hover\:border-gold-90:hover,
.focus\:border-gold-90:focus,
.active\:border-gold-90:active {
  border-color: var(--color-gold-90);
}

.text-gold-100,
.hover\:text-gold-100:hover,
.focus\:text-gold-100:focus,
.active\:text-gold-100:active {
  color: var(--color-gold-100);
}

.bg-gold-100,
.hover\:bg-gold-100:hover,
.focus\:bg-gold-100:focus,
.active\:bg-gold-100:active {
  background-color: var(--color-gold-100);
}

.border-gold-100,
.hover\:border-gold-100:hover,
.focus\:border-gold-100:focus,
.active\:border-gold-100:active {
  border-color: var(--color-gold-100);
}

.text-gray-10,
.hover\:text-gray-10:hover,
.focus\:text-gray-10:focus,
.active\:text-gray-10:active {
  color: var(--color-gray-10);
}

.bg-gray-10,
.hover\:bg-gray-10:hover,
.focus\:bg-gray-10:focus,
.active\:bg-gray-10:active {
  background-color: var(--color-gray-10);
}

.border-gray-10,
.hover\:border-gray-10:hover,
.focus\:border-gray-10:focus,
.active\:border-gray-10:active {
  border-color: var(--color-gray-10);
}

.text-gray-20,
.hover\:text-gray-20:hover,
.focus\:text-gray-20:focus,
.active\:text-gray-20:active {
  color: var(--color-gray-20);
}

.bg-gray-20,
.hover\:bg-gray-20:hover,
.focus\:bg-gray-20:focus,
.active\:bg-gray-20:active {
  background-color: var(--color-gray-20);
}

.border-gray-20,
.hover\:border-gray-20:hover,
.focus\:border-gray-20:focus,
.active\:border-gray-20:active {
  border-color: var(--color-gray-20);
}

.text-gray-30,
.hover\:text-gray-30:hover,
.focus\:text-gray-30:focus,
.active\:text-gray-30:active {
  color: var(--color-gray-30);
}

.bg-gray-30,
.hover\:bg-gray-30:hover,
.focus\:bg-gray-30:focus,
.active\:bg-gray-30:active {
  background-color: var(--color-gray-30);
}

.border-gray-30,
.hover\:border-gray-30:hover,
.focus\:border-gray-30:focus,
.active\:border-gray-30:active {
  border-color: var(--color-gray-30);
}

.text-gray-40,
.hover\:text-gray-40:hover,
.focus\:text-gray-40:focus,
.active\:text-gray-40:active {
  color: var(--color-gray-40);
}

.bg-gray-40,
.hover\:bg-gray-40:hover,
.focus\:bg-gray-40:focus,
.active\:bg-gray-40:active {
  background-color: var(--color-gray-40);
}

.border-gray-40,
.hover\:border-gray-40:hover,
.focus\:border-gray-40:focus,
.active\:border-gray-40:active {
  border-color: var(--color-gray-40);
}

.text-gray-50,
.hover\:text-gray-50:hover,
.focus\:text-gray-50:focus,
.active\:text-gray-50:active {
  color: var(--color-gray-50);
}

.bg-gray-50,
.hover\:bg-gray-50:hover,
.focus\:bg-gray-50:focus,
.active\:bg-gray-50:active {
  background-color: var(--color-gray-50);
}

.border-gray-50,
.hover\:border-gray-50:hover,
.focus\:border-gray-50:focus,
.active\:border-gray-50:active {
  border-color: var(--color-gray-50);
}

.text-gray-60,
.hover\:text-gray-60:hover,
.focus\:text-gray-60:focus,
.active\:text-gray-60:active {
  color: var(--color-gray-60);
}

.bg-gray-60,
.hover\:bg-gray-60:hover,
.focus\:bg-gray-60:focus,
.active\:bg-gray-60:active {
  background-color: var(--color-gray-60);
}

.border-gray-60,
.hover\:border-gray-60:hover,
.focus\:border-gray-60:focus,
.active\:border-gray-60:active {
  border-color: var(--color-gray-60);
}

.text-gray-70,
.hover\:text-gray-70:hover,
.focus\:text-gray-70:focus,
.active\:text-gray-70:active {
  color: var(--color-gray-70);
}

.bg-gray-70,
.hover\:bg-gray-70:hover,
.focus\:bg-gray-70:focus,
.active\:bg-gray-70:active {
  background-color: var(--color-gray-70);
}

.border-gray-70,
.hover\:border-gray-70:hover,
.focus\:border-gray-70:focus,
.active\:border-gray-70:active {
  border-color: var(--color-gray-70);
}

.text-gray-80,
.hover\:text-gray-80:hover,
.focus\:text-gray-80:focus,
.active\:text-gray-80:active {
  color: var(--color-gray-80);
}

.bg-gray-80,
.hover\:bg-gray-80:hover,
.focus\:bg-gray-80:focus,
.active\:bg-gray-80:active {
  background-color: var(--color-gray-80);
}

.border-gray-80,
.hover\:border-gray-80:hover,
.focus\:border-gray-80:focus,
.active\:border-gray-80:active {
  border-color: var(--color-gray-80);
}

.text-gray-90,
.hover\:text-gray-90:hover,
.focus\:text-gray-90:focus,
.active\:text-gray-90:active {
  color: var(--color-gray-90);
}

.bg-gray-90,
.hover\:bg-gray-90:hover,
.focus\:bg-gray-90:focus,
.active\:bg-gray-90:active {
  background-color: var(--color-gray-90);
}

.border-gray-90,
.hover\:border-gray-90:hover,
.focus\:border-gray-90:focus,
.active\:border-gray-90:active {
  border-color: var(--color-gray-90);
}

.text-gray-100,
.hover\:text-gray-100:hover,
.focus\:text-gray-100:focus,
.active\:text-gray-100:active {
  color: var(--color-gray-100);
}

.bg-gray-100,
.hover\:bg-gray-100:hover,
.focus\:bg-gray-100:focus,
.active\:bg-gray-100:active {
  background-color: var(--color-gray-100);
}

.border-gray-100,
.hover\:border-gray-100:hover,
.focus\:border-gray-100:focus,
.active\:border-gray-100:active {
  border-color: var(--color-gray-100);
}

.text-cool-gray-10,
.hover\:text-cool-gray-10:hover,
.focus\:text-cool-gray-10:focus,
.active\:text-cool-gray-10:active {
  color: var(--color-cool-gray-10);
}

.bg-cool-gray-10,
.hover\:bg-cool-gray-10:hover,
.focus\:bg-cool-gray-10:focus,
.active\:bg-cool-gray-10:active {
  background-color: var(--color-cool-gray-10);
}

.border-cool-gray-10,
.hover\:border-cool-gray-10:hover,
.focus\:border-cool-gray-10:focus,
.active\:border-cool-gray-10:active {
  border-color: var(--color-cool-gray-10);
}

.text-cool-gray-20,
.hover\:text-cool-gray-20:hover,
.focus\:text-cool-gray-20:focus,
.active\:text-cool-gray-20:active {
  color: var(--color-cool-gray-20);
}

.bg-cool-gray-20,
.hover\:bg-cool-gray-20:hover,
.focus\:bg-cool-gray-20:focus,
.active\:bg-cool-gray-20:active {
  background-color: var(--color-cool-gray-20);
}

.border-cool-gray-20,
.hover\:border-cool-gray-20:hover,
.focus\:border-cool-gray-20:focus,
.active\:border-cool-gray-20:active {
  border-color: var(--color-cool-gray-20);
}

.text-cool-gray-30,
.hover\:text-cool-gray-30:hover,
.focus\:text-cool-gray-30:focus,
.active\:text-cool-gray-30:active {
  color: var(--color-cool-gray-30);
}

.bg-cool-gray-30,
.hover\:bg-cool-gray-30:hover,
.focus\:bg-cool-gray-30:focus,
.active\:bg-cool-gray-30:active {
  background-color: var(--color-cool-gray-30);
}

.border-cool-gray-30,
.hover\:border-cool-gray-30:hover,
.focus\:border-cool-gray-30:focus,
.active\:border-cool-gray-30:active {
  border-color: var(--color-cool-gray-30);
}

.text-cool-gray-40,
.hover\:text-cool-gray-40:hover,
.focus\:text-cool-gray-40:focus,
.active\:text-cool-gray-40:active {
  color: var(--color-cool-gray-40);
}

.bg-cool-gray-40,
.hover\:bg-cool-gray-40:hover,
.focus\:bg-cool-gray-40:focus,
.active\:bg-cool-gray-40:active {
  background-color: var(--color-cool-gray-40);
}

.border-cool-gray-40,
.hover\:border-cool-gray-40:hover,
.focus\:border-cool-gray-40:focus,
.active\:border-cool-gray-40:active {
  border-color: var(--color-cool-gray-40);
}

.text-cool-gray-50,
.hover\:text-cool-gray-50:hover,
.focus\:text-cool-gray-50:focus,
.active\:text-cool-gray-50:active {
  color: var(--color-cool-gray-50);
}

.bg-cool-gray-50,
.hover\:bg-cool-gray-50:hover,
.focus\:bg-cool-gray-50:focus,
.active\:bg-cool-gray-50:active {
  background-color: var(--color-cool-gray-50);
}

.border-cool-gray-50,
.hover\:border-cool-gray-50:hover,
.focus\:border-cool-gray-50:focus,
.active\:border-cool-gray-50:active {
  border-color: var(--color-cool-gray-50);
}

.text-cool-gray-60,
.hover\:text-cool-gray-60:hover,
.focus\:text-cool-gray-60:focus,
.active\:text-cool-gray-60:active {
  color: var(--color-cool-gray-60);
}

.bg-cool-gray-60,
.hover\:bg-cool-gray-60:hover,
.focus\:bg-cool-gray-60:focus,
.active\:bg-cool-gray-60:active {
  background-color: var(--color-cool-gray-60);
}

.border-cool-gray-60,
.hover\:border-cool-gray-60:hover,
.focus\:border-cool-gray-60:focus,
.active\:border-cool-gray-60:active {
  border-color: var(--color-cool-gray-60);
}

.text-cool-gray-70,
.hover\:text-cool-gray-70:hover,
.focus\:text-cool-gray-70:focus,
.active\:text-cool-gray-70:active {
  color: var(--color-cool-gray-70);
}

.bg-cool-gray-70,
.hover\:bg-cool-gray-70:hover,
.focus\:bg-cool-gray-70:focus,
.active\:bg-cool-gray-70:active {
  background-color: var(--color-cool-gray-70);
}

.border-cool-gray-70,
.hover\:border-cool-gray-70:hover,
.focus\:border-cool-gray-70:focus,
.active\:border-cool-gray-70:active {
  border-color: var(--color-cool-gray-70);
}

.text-cool-gray-80,
.hover\:text-cool-gray-80:hover,
.focus\:text-cool-gray-80:focus,
.active\:text-cool-gray-80:active {
  color: var(--color-cool-gray-80);
}

.bg-cool-gray-80,
.hover\:bg-cool-gray-80:hover,
.focus\:bg-cool-gray-80:focus,
.active\:bg-cool-gray-80:active {
  background-color: var(--color-cool-gray-80);
}

.border-cool-gray-80,
.hover\:border-cool-gray-80:hover,
.focus\:border-cool-gray-80:focus,
.active\:border-cool-gray-80:active {
  border-color: var(--color-cool-gray-80);
}

.text-cool-gray-90,
.hover\:text-cool-gray-90:hover,
.focus\:text-cool-gray-90:focus,
.active\:text-cool-gray-90:active {
  color: var(--color-cool-gray-90);
}

.bg-cool-gray-90,
.hover\:bg-cool-gray-90:hover,
.focus\:bg-cool-gray-90:focus,
.active\:bg-cool-gray-90:active {
  background-color: var(--color-cool-gray-90);
}

.border-cool-gray-90,
.hover\:border-cool-gray-90:hover,
.focus\:border-cool-gray-90:focus,
.active\:border-cool-gray-90:active {
  border-color: var(--color-cool-gray-90);
}

.text-cool-gray-100,
.hover\:text-cool-gray-100:hover,
.focus\:text-cool-gray-100:focus,
.active\:text-cool-gray-100:active {
  color: var(--color-cool-gray-100);
}

.bg-cool-gray-100,
.hover\:bg-cool-gray-100:hover,
.focus\:bg-cool-gray-100:focus,
.active\:bg-cool-gray-100:active {
  background-color: var(--color-cool-gray-100);
}

.border-cool-gray-100,
.hover\:border-cool-gray-100:hover,
.focus\:border-cool-gray-100:focus,
.active\:border-cool-gray-100:active {
  border-color: var(--color-cool-gray-100);
}

.\!text-transparent,
.\!hover\:text-transparent:hover,
.\!focus\:text-transparent:focus,
.\!active\:text-transparent:active {
  color: var(--color-transparent) !important;
}

.\!bg-transparent,
.\!hover\:bg-transparent:hover,
.\!focus\:text-transparent:focus,
.\!active\:text-transparent:active {
  background-color: var(--color-transparent) !important;
}

.\!border-transparent,
.\!hover\:border-transparent:hover,
.\!focus\:text-transparent:focus,
.\!active\:text-transparent:active {
  border-color: var(--color-transparent) !important;
}

.\!text-white,
.\!hover\:text-white:hover,
.\!focus\:text-white:focus,
.\!active\:text-white:active {
  color: var(--color-white) !important;
}

.\!bg-white,
.\!hover\:bg-white:hover,
.\!focus\:text-white:focus,
.\!active\:text-white:active {
  background-color: var(--color-white) !important;
}

.\!border-white,
.\!hover\:border-white:hover,
.\!focus\:text-white:focus,
.\!active\:text-white:active {
  border-color: var(--color-white) !important;
}

.\!text-black,
.\!hover\:text-black:hover,
.\!focus\:text-black:focus,
.\!active\:text-black:active {
  color: var(--color-black) !important;
}

.\!bg-black,
.\!hover\:bg-black:hover,
.\!focus\:text-black:focus,
.\!active\:text-black:active {
  background-color: var(--color-black) !important;
}

.\!border-black,
.\!hover\:border-black:hover,
.\!focus\:text-black:focus,
.\!active\:text-black:active {
  border-color: var(--color-black) !important;
}

.\!text-red-10,
.\!hover\:text-red-10:hover,
.\!focus\:text-red-10:focus,
.\!active\:text-red-10:active {
  color: var(--color-red-10) !important;
}

.\!bg-red-10,
.\!hover\:bg-red-10:hover,
.\!focus\:bg-red-10:focus,
.\!active\:bg-red-10:active {
  background-color: var(--color-red-10) !important;
}

.\!border-red-10,
.\!hover\:border-red-10:hover,
.\!focus\:border-red-10:focus,
.\!active\:border-red-10:active {
  border-color: var(--color-red-10) !important;
}

.\!text-red-20,
.\!hover\:text-red-20:hover,
.\!focus\:text-red-20:focus,
.\!active\:text-red-20:active {
  color: var(--color-red-20) !important;
}

.\!bg-red-20,
.\!hover\:bg-red-20:hover,
.\!focus\:bg-red-20:focus,
.\!active\:bg-red-20:active {
  background-color: var(--color-red-20) !important;
}

.\!border-red-20,
.\!hover\:border-red-20:hover,
.\!focus\:border-red-20:focus,
.\!active\:border-red-20:active {
  border-color: var(--color-red-20) !important;
}

.\!text-red-30,
.\!hover\:text-red-30:hover,
.\!focus\:text-red-30:focus,
.\!active\:text-red-30:active {
  color: var(--color-red-30) !important;
}

.\!bg-red-30,
.\!hover\:bg-red-30:hover,
.\!focus\:bg-red-30:focus,
.\!active\:bg-red-30:active {
  background-color: var(--color-red-30) !important;
}

.\!border-red-30,
.\!hover\:border-red-30:hover,
.\!focus\:border-red-30:focus,
.\!active\:border-red-30:active {
  border-color: var(--color-red-30) !important;
}

.\!text-red-40,
.\!hover\:text-red-40:hover,
.\!focus\:text-red-40:focus,
.\!active\:text-red-40:active {
  color: var(--color-red-40) !important;
}

.\!bg-red-40,
.\!hover\:bg-red-40:hover,
.\!focus\:bg-red-40:focus,
.\!active\:bg-red-40:active {
  background-color: var(--color-red-40) !important;
}

.\!border-red-40,
.\!hover\:border-red-40:hover,
.\!focus\:border-red-40:focus,
.\!active\:border-red-40:active {
  border-color: var(--color-red-40) !important;
}

.\!text-red-50,
.\!hover\:text-red-50:hover,
.\!focus\:text-red-50:focus,
.\!active\:text-red-50:active {
  color: var(--color-red-50) !important;
}

.\!bg-red-50,
.\!hover\:bg-red-50:hover,
.\!focus\:bg-red-50:focus,
.\!active\:bg-red-50:active {
  background-color: var(--color-red-50) !important;
}

.\!border-red-50,
.\!hover\:border-red-50:hover,
.\!focus\:border-red-50:focus,
.\!active\:border-red-50:active {
  border-color: var(--color-red-50) !important;
}

.\!text-red-60,
.\!hover\:text-red-60:hover,
.\!focus\:text-red-60:focus,
.\!active\:text-red-60:active {
  color: var(--color-red-60) !important;
}

.\!bg-red-60,
.\!hover\:bg-red-60:hover,
.\!focus\:bg-red-60:focus,
.\!active\:bg-red-60:active {
  background-color: var(--color-red-60) !important;
}

.\!border-red-60,
.\!hover\:border-red-60:hover,
.\!focus\:border-red-60:focus,
.\!active\:border-red-60:active {
  border-color: var(--color-red-60) !important;
}

.\!text-red-70,
.\!hover\:text-red-70:hover,
.\!focus\:text-red-70:focus,
.\!active\:text-red-70:active {
  color: var(--color-red-70) !important;
}

.\!bg-red-70,
.\!hover\:bg-red-70:hover,
.\!focus\:bg-red-70:focus,
.\!active\:bg-red-70:active {
  background-color: var(--color-red-70) !important;
}

.\!border-red-70,
.\!hover\:border-red-70:hover,
.\!focus\:border-red-70:focus,
.\!active\:border-red-70:active {
  border-color: var(--color-red-70) !important;
}

.\!text-red-80,
.\!hover\:text-red-80:hover,
.\!focus\:text-red-80:focus,
.\!active\:text-red-80:active {
  color: var(--color-red-80) !important;
}

.\!bg-red-80,
.\!hover\:bg-red-80:hover,
.\!focus\:bg-red-80:focus,
.\!active\:bg-red-80:active {
  background-color: var(--color-red-80) !important;
}

.\!border-red-80,
.\!hover\:border-red-80:hover,
.\!focus\:border-red-80:focus,
.\!active\:border-red-80:active {
  border-color: var(--color-red-80) !important;
}

.\!text-red-90,
.\!hover\:text-red-90:hover,
.\!focus\:text-red-90:focus,
.\!active\:text-red-90:active {
  color: var(--color-red-90) !important;
}

.\!bg-red-90,
.\!hover\:bg-red-90:hover,
.\!focus\:bg-red-90:focus,
.\!active\:bg-red-90:active {
  background-color: var(--color-red-90) !important;
}

.\!border-red-90,
.\!hover\:border-red-90:hover,
.\!focus\:border-red-90:focus,
.\!active\:border-red-90:active {
  border-color: var(--color-red-90) !important;
}

.\!text-red-100,
.\!hover\:text-red-100:hover,
.\!focus\:text-red-100:focus,
.\!active\:text-red-100:active {
  color: var(--color-red-100) !important;
}

.\!bg-red-100,
.\!hover\:bg-red-100:hover,
.\!focus\:bg-red-100:focus,
.\!active\:bg-red-100:active {
  background-color: var(--color-red-100) !important;
}

.\!border-red-100,
.\!hover\:border-red-100:hover,
.\!focus\:border-red-100:focus,
.\!active\:border-red-100:active {
  border-color: var(--color-red-100) !important;
}

.\!text-pink-10,
.\!hover\:text-pink-10:hover,
.\!focus\:text-pink-10:focus,
.\!active\:text-pink-10:active {
  color: var(--color-pink-10) !important;
}

.\!bg-pink-10,
.\!hover\:bg-pink-10:hover,
.\!focus\:bg-pink-10:focus,
.\!active\:bg-pink-10:active {
  background-color: var(--color-pink-10) !important;
}

.\!border-pink-10,
.\!hover\:border-pink-10:hover,
.\!focus\:border-pink-10:focus,
.\!active\:border-pink-10:active {
  border-color: var(--color-pink-10) !important;
}

.\!text-pink-20,
.\!hover\:text-pink-20:hover,
.\!focus\:text-pink-20:focus,
.\!active\:text-pink-20:active {
  color: var(--color-pink-20) !important;
}

.\!bg-pink-20,
.\!hover\:bg-pink-20:hover,
.\!focus\:bg-pink-20:focus,
.\!active\:bg-pink-20:active {
  background-color: var(--color-pink-20) !important;
}

.\!border-pink-20,
.\!hover\:border-pink-20:hover,
.\!focus\:border-pink-20:focus,
.\!active\:border-pink-20:active {
  border-color: var(--color-pink-20) !important;
}

.\!text-pink-30,
.\!hover\:text-pink-30:hover,
.\!focus\:text-pink-30:focus,
.\!active\:text-pink-30:active {
  color: var(--color-pink-30) !important;
}

.\!bg-pink-30,
.\!hover\:bg-pink-30:hover,
.\!focus\:bg-pink-30:focus,
.\!active\:bg-pink-30:active {
  background-color: var(--color-pink-30) !important;
}

.\!border-pink-30,
.\!hover\:border-pink-30:hover,
.\!focus\:border-pink-30:focus,
.\!active\:border-pink-30:active {
  border-color: var(--color-pink-30) !important;
}

.\!text-pink-40,
.\!hover\:text-pink-40:hover,
.\!focus\:text-pink-40:focus,
.\!active\:text-pink-40:active {
  color: var(--color-pink-40) !important;
}

.\!bg-pink-40,
.\!hover\:bg-pink-40:hover,
.\!focus\:bg-pink-40:focus,
.\!active\:bg-pink-40:active {
  background-color: var(--color-pink-40) !important;
}

.\!border-pink-40,
.\!hover\:border-pink-40:hover,
.\!focus\:border-pink-40:focus,
.\!active\:border-pink-40:active {
  border-color: var(--color-pink-40) !important;
}

.\!text-pink-50,
.\!hover\:text-pink-50:hover,
.\!focus\:text-pink-50:focus,
.\!active\:text-pink-50:active {
  color: var(--color-pink-50) !important;
}

.\!bg-pink-50,
.\!hover\:bg-pink-50:hover,
.\!focus\:bg-pink-50:focus,
.\!active\:bg-pink-50:active {
  background-color: var(--color-pink-50) !important;
}

.\!border-pink-50,
.\!hover\:border-pink-50:hover,
.\!focus\:border-pink-50:focus,
.\!active\:border-pink-50:active {
  border-color: var(--color-pink-50) !important;
}

.\!text-pink-60,
.\!hover\:text-pink-60:hover,
.\!focus\:text-pink-60:focus,
.\!active\:text-pink-60:active {
  color: var(--color-pink-60) !important;
}

.\!bg-pink-60,
.\!hover\:bg-pink-60:hover,
.\!focus\:bg-pink-60:focus,
.\!active\:bg-pink-60:active {
  background-color: var(--color-pink-60) !important;
}

.\!border-pink-60,
.\!hover\:border-pink-60:hover,
.\!focus\:border-pink-60:focus,
.\!active\:border-pink-60:active {
  border-color: var(--color-pink-60) !important;
}

.\!text-pink-70,
.\!hover\:text-pink-70:hover,
.\!focus\:text-pink-70:focus,
.\!active\:text-pink-70:active {
  color: var(--color-pink-70) !important;
}

.\!bg-pink-70,
.\!hover\:bg-pink-70:hover,
.\!focus\:bg-pink-70:focus,
.\!active\:bg-pink-70:active {
  background-color: var(--color-pink-70) !important;
}

.\!border-pink-70,
.\!hover\:border-pink-70:hover,
.\!focus\:border-pink-70:focus,
.\!active\:border-pink-70:active {
  border-color: var(--color-pink-70) !important;
}

.\!text-pink-80,
.\!hover\:text-pink-80:hover,
.\!focus\:text-pink-80:focus,
.\!active\:text-pink-80:active {
  color: var(--color-pink-80) !important;
}

.\!bg-pink-80,
.\!hover\:bg-pink-80:hover,
.\!focus\:bg-pink-80:focus,
.\!active\:bg-pink-80:active {
  background-color: var(--color-pink-80) !important;
}

.\!border-pink-80,
.\!hover\:border-pink-80:hover,
.\!focus\:border-pink-80:focus,
.\!active\:border-pink-80:active {
  border-color: var(--color-pink-80) !important;
}

.\!text-pink-90,
.\!hover\:text-pink-90:hover,
.\!focus\:text-pink-90:focus,
.\!active\:text-pink-90:active {
  color: var(--color-pink-90) !important;
}

.\!bg-pink-90,
.\!hover\:bg-pink-90:hover,
.\!focus\:bg-pink-90:focus,
.\!active\:bg-pink-90:active {
  background-color: var(--color-pink-90) !important;
}

.\!border-pink-90,
.\!hover\:border-pink-90:hover,
.\!focus\:border-pink-90:focus,
.\!active\:border-pink-90:active {
  border-color: var(--color-pink-90) !important;
}

.\!text-pink-100,
.\!hover\:text-pink-100:hover,
.\!focus\:text-pink-100:focus,
.\!active\:text-pink-100:active {
  color: var(--color-pink-100) !important;
}

.\!bg-pink-100,
.\!hover\:bg-pink-100:hover,
.\!focus\:bg-pink-100:focus,
.\!active\:bg-pink-100:active {
  background-color: var(--color-pink-100) !important;
}

.\!border-pink-100,
.\!hover\:border-pink-100:hover,
.\!focus\:border-pink-100:focus,
.\!active\:border-pink-100:active {
  border-color: var(--color-pink-100) !important;
}

.\!text-plum-40,
.\!hover\:text-plum-40:hover,
.\!focus\:text-plum-40:focus,
.\!active\:text-plum-40:active {
  color: var(--color-plum-40) !important;
}

.\!bg-plum-40,
.\!hover\:bg-plum-40:hover,
.\!focus\:bg-plum-40:focus,
.\!active\:bg-plum-40:active {
  background-color: var(--color-plum-40) !important;
}

.\!border-plum-40,
.\!hover\:border-plum-40:hover,
.\!focus\:border-plum-40:focus,
.\!active\:border-plum-40:active {
  border-color: var(--color-plum-40) !important;
}

.\!text-plum-60,
.\!hover\:text-plum-60:hover,
.\!focus\:text-plum-60:focus,
.\!active\:text-plum-60:active {
  color: var(--color-plum-60) !important;
}

.\!bg-plum-60,
.\!hover\:bg-plum-60:hover,
.\!focus\:bg-plum-60:focus,
.\!active\:bg-plum-60:active {
  background-color: var(--color-plum-60) !important;
}

.\!border-plum-60,
.\!hover\:border-plum-60:hover,
.\!focus\:border-plum-60:focus,
.\!active\:border-plum-60:active {
  border-color: var(--color-plum-60) !important;
}

.\!text-plum-100,
.\!hover\:text-plum-100:hover,
.\!focus\:text-plum-100:focus,
.\!active\:text-plum-100:active {
  color: var(--color-plum-100) !important;
}

.\!bg-plum-100,
.\!hover\:bg-plum-100:hover,
.\!focus\:bg-plum-100:focus,
.\!active\:bg-plum-100:active {
  background-color: var(--color-plum-100) !important;
}

.\!border-plum-100,
.\!hover\:border-plum-100:hover,
.\!focus\:border-plum-100:focus,
.\!active\:border-plum-100:active {
  border-color: var(--color-plum-100) !important;
}

.\!text-purple-20,
.\!hover\:text-purple-20:hover,
.\!focus\:text-purple-20:focus,
.\!active\:text-purple-20:active {
  color: var(--color-purple-20) !important;
}

.\!bg-purple-20,
.\!hover\:bg-purple-20:hover,
.\!focus\:bg-purple-20:focus,
.\!active\:bg-purple-20:active {
  background-color: var(--color-purple-20) !important;
}

.\!border-purple-20,
.\!hover\:border-purple-20:hover,
.\!focus\:border-purple-20:focus,
.\!active\:border-purple-20:active {
  border-color: var(--color-purple-20) !important;
}

.\!text-purple-30,
.\!hover\:text-purple-30:hover,
.\!focus\:text-purple-30:focus,
.\!active\:text-purple-30:active {
  color: var(--color-purple-30) !important;
}

.\!bg-purple-30,
.\!hover\:bg-purple-30:hover,
.\!focus\:bg-purple-30:focus,
.\!active\:bg-purple-30:active {
  background-color: var(--color-purple-30) !important;
}

.\!border-purple-30,
.\!hover\:border-purple-30:hover,
.\!focus\:border-purple-30:focus,
.\!active\:border-purple-30:active {
  border-color: var(--color-purple-30) !important;
}

.\!text-purple-40,
.\!hover\:text-purple-40:hover,
.\!focus\:text-purple-40:focus,
.\!active\:text-purple-40:active {
  color: var(--color-purple-40) !important;
}

.\!bg-purple-40,
.\!hover\:bg-purple-40:hover,
.\!focus\:bg-purple-40:focus,
.\!active\:bg-purple-40:active {
  background-color: var(--color-purple-40) !important;
}

.\!border-purple-40,
.\!hover\:border-purple-40:hover,
.\!focus\:border-purple-40:focus,
.\!active\:border-purple-40:active {
  border-color: var(--color-purple-40) !important;
}

.\!text-purple-50,
.\!hover\:text-purple-50:hover,
.\!focus\:text-purple-50:focus,
.\!active\:text-purple-50:active {
  color: var(--color-purple-50) !important;
}

.\!bg-purple-50,
.\!hover\:bg-purple-50:hover,
.\!focus\:bg-purple-50:focus,
.\!active\:bg-purple-50:active {
  background-color: var(--color-purple-50) !important;
}

.\!border-purple-50,
.\!hover\:border-purple-50:hover,
.\!focus\:border-purple-50:focus,
.\!active\:border-purple-50:active {
  border-color: var(--color-purple-50) !important;
}

.\!text-purple-80,
.\!hover\:text-purple-80:hover,
.\!focus\:text-purple-80:focus,
.\!active\:text-purple-80:active {
  color: var(--color-purple-80) !important;
}

.\!bg-purple-80,
.\!hover\:bg-purple-80:hover,
.\!focus\:bg-purple-80:focus,
.\!active\:bg-purple-80:active {
  background-color: var(--color-purple-80) !important;
}

.\!border-purple-80,
.\!hover\:border-purple-80:hover,
.\!focus\:border-purple-80:focus,
.\!active\:border-purple-80:active {
  border-color: var(--color-purple-80) !important;
}

.\!text-purple-100,
.\!hover\:text-purple-100:hover,
.\!focus\:text-purple-100:focus,
.\!active\:text-purple-100:active {
  color: var(--color-purple-100) !important;
}

.\!bg-purple-100,
.\!hover\:bg-purple-100:hover,
.\!focus\:bg-purple-100:focus,
.\!active\:bg-purple-100:active {
  background-color: var(--color-purple-100) !important;
}

.\!border-purple-100,
.\!hover\:border-purple-100:hover,
.\!focus\:border-purple-100:focus,
.\!active\:border-purple-100:active {
  border-color: var(--color-purple-100) !important;
}

.\!text-blue-10,
.\!hover\:text-blue-10:hover,
.\!focus\:text-blue-10:focus,
.\!active\:text-blue-10:active {
  color: var(--color-blue-10) !important;
}

.\!bg-blue-10,
.\!hover\:bg-blue-10:hover,
.\!focus\:bg-blue-10:focus,
.\!active\:bg-blue-10:active {
  background-color: var(--color-blue-10) !important;
}

.\!border-blue-10,
.\!hover\:border-blue-10:hover,
.\!focus\:border-blue-10:focus,
.\!active\:border-blue-10:active {
  border-color: var(--color-blue-10) !important;
}

.\!text-blue-20,
.\!hover\:text-blue-20:hover,
.\!focus\:text-blue-20:focus,
.\!active\:text-blue-20:active {
  color: var(--color-blue-20) !important;
}

.\!bg-blue-20,
.\!hover\:bg-blue-20:hover,
.\!focus\:bg-blue-20:focus,
.\!active\:bg-blue-20:active {
  background-color: var(--color-blue-20) !important;
}

.\!border-blue-20,
.\!hover\:border-blue-20:hover,
.\!focus\:border-blue-20:focus,
.\!active\:border-blue-20:active {
  border-color: var(--color-blue-20) !important;
}

.\!text-blue-30,
.\!hover\:text-blue-30:hover,
.\!focus\:text-blue-30:focus,
.\!active\:text-blue-30:active {
  color: var(--color-blue-30) !important;
}

.\!bg-blue-30,
.\!hover\:bg-blue-30:hover,
.\!focus\:bg-blue-30:focus,
.\!active\:bg-blue-30:active {
  background-color: var(--color-blue-30) !important;
}

.\!border-blue-30,
.\!hover\:border-blue-30:hover,
.\!focus\:border-blue-30:focus,
.\!active\:border-blue-30:active {
  border-color: var(--color-blue-30) !important;
}

.\!text-blue-40,
.\!hover\:text-blue-40:hover,
.\!focus\:text-blue-40:focus,
.\!active\:text-blue-40:active {
  color: var(--color-blue-40) !important;
}

.\!bg-blue-40,
.\!hover\:bg-blue-40:hover,
.\!focus\:bg-blue-40:focus,
.\!active\:bg-blue-40:active {
  background-color: var(--color-blue-40) !important;
}

.\!border-blue-40,
.\!hover\:border-blue-40:hover,
.\!focus\:border-blue-40:focus,
.\!active\:border-blue-40:active {
  border-color: var(--color-blue-40) !important;
}

.\!text-blue-50,
.\!hover\:text-blue-50:hover,
.\!focus\:text-blue-50:focus,
.\!active\:text-blue-50:active {
  color: var(--color-blue-50) !important;
}

.\!bg-blue-50,
.\!hover\:bg-blue-50:hover,
.\!focus\:bg-blue-50:focus,
.\!active\:bg-blue-50:active {
  background-color: var(--color-blue-50) !important;
}

.\!border-blue-50,
.\!hover\:border-blue-50:hover,
.\!focus\:border-blue-50:focus,
.\!active\:border-blue-50:active {
  border-color: var(--color-blue-50) !important;
}

.\!text-blue-60,
.\!hover\:text-blue-60:hover,
.\!focus\:text-blue-60:focus,
.\!active\:text-blue-60:active {
  color: var(--color-blue-60) !important;
}

.\!bg-blue-60,
.\!hover\:bg-blue-60:hover,
.\!focus\:bg-blue-60:focus,
.\!active\:bg-blue-60:active {
  background-color: var(--color-blue-60) !important;
}

.\!border-blue-60,
.\!hover\:border-blue-60:hover,
.\!focus\:border-blue-60:focus,
.\!active\:border-blue-60:active {
  border-color: var(--color-blue-60) !important;
}

.\!text-blue-70,
.\!hover\:text-blue-70:hover,
.\!focus\:text-blue-70:focus,
.\!active\:text-blue-70:active {
  color: var(--color-blue-70) !important;
}

.\!bg-blue-70,
.\!hover\:bg-blue-70:hover,
.\!focus\:bg-blue-70:focus,
.\!active\:bg-blue-70:active {
  background-color: var(--color-blue-70) !important;
}

.\!border-blue-70,
.\!hover\:border-blue-70:hover,
.\!focus\:border-blue-70:focus,
.\!active\:border-blue-70:active {
  border-color: var(--color-blue-70) !important;
}

.\!text-blue-80,
.\!hover\:text-blue-80:hover,
.\!focus\:text-blue-80:focus,
.\!active\:text-blue-80:active {
  color: var(--color-blue-80) !important;
}

.\!bg-blue-80,
.\!hover\:bg-blue-80:hover,
.\!focus\:bg-blue-80:focus,
.\!active\:bg-blue-80:active {
  background-color: var(--color-blue-80) !important;
}

.\!border-blue-80,
.\!hover\:border-blue-80:hover,
.\!focus\:border-blue-80:focus,
.\!active\:border-blue-80:active {
  border-color: var(--color-blue-80) !important;
}

.\!text-blue-90,
.\!hover\:text-blue-90:hover,
.\!focus\:text-blue-90:focus,
.\!active\:text-blue-90:active {
  color: var(--color-blue-90) !important;
}

.\!bg-blue-90,
.\!hover\:bg-blue-90:hover,
.\!focus\:bg-blue-90:focus,
.\!active\:bg-blue-90:active {
  background-color: var(--color-blue-90) !important;
}

.\!border-blue-90,
.\!hover\:border-blue-90:hover,
.\!focus\:border-blue-90:focus,
.\!active\:border-blue-90:active {
  border-color: var(--color-blue-90) !important;
}

.\!text-blue-100,
.\!hover\:text-blue-100:hover,
.\!focus\:text-blue-100:focus,
.\!active\:text-blue-100:active {
  color: var(--color-blue-100) !important;
}

.\!bg-blue-100,
.\!hover\:bg-blue-100:hover,
.\!focus\:bg-blue-100:focus,
.\!active\:bg-blue-100:active {
  background-color: var(--color-blue-100) !important;
}

.\!border-blue-100,
.\!hover\:border-blue-100:hover,
.\!focus\:border-blue-100:focus,
.\!active\:border-blue-100:active {
  border-color: var(--color-blue-100) !important;
}

.\!text-cyan-10,
.\!hover\:text-cyan-10:hover,
.\!focus\:text-cyan-10:focus,
.\!active\:text-cyan-10:active {
  color: var(--color-cyan-10) !important;
}

.\!bg-cyan-10,
.\!hover\:bg-cyan-10:hover,
.\!focus\:bg-cyan-10:focus,
.\!active\:bg-cyan-10:active {
  background-color: var(--color-cyan-10) !important;
}

.\!border-cyan-10,
.\!hover\:border-cyan-10:hover,
.\!focus\:border-cyan-10:focus,
.\!active\:border-cyan-10:active {
  border-color: var(--color-cyan-10) !important;
}

.\!text-cyan-20,
.\!hover\:text-cyan-20:hover,
.\!focus\:text-cyan-20:focus,
.\!active\:text-cyan-20:active {
  color: var(--color-cyan-20) !important;
}

.\!bg-cyan-20,
.\!hover\:bg-cyan-20:hover,
.\!focus\:bg-cyan-20:focus,
.\!active\:bg-cyan-20:active {
  background-color: var(--color-cyan-20) !important;
}

.\!border-cyan-20,
.\!hover\:border-cyan-20:hover,
.\!focus\:border-cyan-20:focus,
.\!active\:border-cyan-20:active {
  border-color: var(--color-cyan-20) !important;
}

.\!text-cyan-30,
.\!hover\:text-cyan-30:hover,
.\!focus\:text-cyan-30:focus,
.\!active\:text-cyan-30:active {
  color: var(--color-cyan-30) !important;
}

.\!bg-cyan-30,
.\!hover\:bg-cyan-30:hover,
.\!focus\:bg-cyan-30:focus,
.\!active\:bg-cyan-30:active {
  background-color: var(--color-cyan-30) !important;
}

.\!border-cyan-30,
.\!hover\:border-cyan-30:hover,
.\!focus\:border-cyan-30:focus,
.\!active\:border-cyan-30:active {
  border-color: var(--color-cyan-30) !important;
}

.\!text-cyan-40,
.\!hover\:text-cyan-40:hover,
.\!focus\:text-cyan-40:focus,
.\!active\:text-cyan-40:active {
  color: var(--color-cyan-40) !important;
}

.\!bg-cyan-40,
.\!hover\:bg-cyan-40:hover,
.\!focus\:bg-cyan-40:focus,
.\!active\:bg-cyan-40:active {
  background-color: var(--color-cyan-40) !important;
}

.\!border-cyan-40,
.\!hover\:border-cyan-40:hover,
.\!focus\:border-cyan-40:focus,
.\!active\:border-cyan-40:active {
  border-color: var(--color-cyan-40) !important;
}

.\!text-cyan-50,
.\!hover\:text-cyan-50:hover,
.\!focus\:text-cyan-50:focus,
.\!active\:text-cyan-50:active {
  color: var(--color-cyan-50) !important;
}

.\!bg-cyan-50,
.\!hover\:bg-cyan-50:hover,
.\!focus\:bg-cyan-50:focus,
.\!active\:bg-cyan-50:active {
  background-color: var(--color-cyan-50) !important;
}

.\!border-cyan-50,
.\!hover\:border-cyan-50:hover,
.\!focus\:border-cyan-50:focus,
.\!active\:border-cyan-50:active {
  border-color: var(--color-cyan-50) !important;
}

.\!text-cyan-60,
.\!hover\:text-cyan-60:hover,
.\!focus\:text-cyan-60:focus,
.\!active\:text-cyan-60:active {
  color: var(--color-cyan-60) !important;
}

.\!bg-cyan-60,
.\!hover\:bg-cyan-60:hover,
.\!focus\:bg-cyan-60:focus,
.\!active\:bg-cyan-60:active {
  background-color: var(--color-cyan-60) !important;
}

.\!border-cyan-60,
.\!hover\:border-cyan-60:hover,
.\!focus\:border-cyan-60:focus,
.\!active\:border-cyan-60:active {
  border-color: var(--color-cyan-60) !important;
}

.\!text-cyan-70,
.\!hover\:text-cyan-70:hover,
.\!focus\:text-cyan-70:focus,
.\!active\:text-cyan-70:active {
  color: var(--color-cyan-70) !important;
}

.\!bg-cyan-70,
.\!hover\:bg-cyan-70:hover,
.\!focus\:bg-cyan-70:focus,
.\!active\:bg-cyan-70:active {
  background-color: var(--color-cyan-70) !important;
}

.\!border-cyan-70,
.\!hover\:border-cyan-70:hover,
.\!focus\:border-cyan-70:focus,
.\!active\:border-cyan-70:active {
  border-color: var(--color-cyan-70) !important;
}

.\!text-cyan-80,
.\!hover\:text-cyan-80:hover,
.\!focus\:text-cyan-80:focus,
.\!active\:text-cyan-80:active {
  color: var(--color-cyan-80) !important;
}

.\!bg-cyan-80,
.\!hover\:bg-cyan-80:hover,
.\!focus\:bg-cyan-80:focus,
.\!active\:bg-cyan-80:active {
  background-color: var(--color-cyan-80) !important;
}

.\!border-cyan-80,
.\!hover\:border-cyan-80:hover,
.\!focus\:border-cyan-80:focus,
.\!active\:border-cyan-80:active {
  border-color: var(--color-cyan-80) !important;
}

.\!text-cyan-90,
.\!hover\:text-cyan-90:hover,
.\!focus\:text-cyan-90:focus,
.\!active\:text-cyan-90:active {
  color: var(--color-cyan-90) !important;
}

.\!bg-cyan-90,
.\!hover\:bg-cyan-90:hover,
.\!focus\:bg-cyan-90:focus,
.\!active\:bg-cyan-90:active {
  background-color: var(--color-cyan-90) !important;
}

.\!border-cyan-90,
.\!hover\:border-cyan-90:hover,
.\!focus\:border-cyan-90:focus,
.\!active\:border-cyan-90:active {
  border-color: var(--color-cyan-90) !important;
}

.\!text-cyan-100,
.\!hover\:text-cyan-100:hover,
.\!focus\:text-cyan-100:focus,
.\!active\:text-cyan-100:active {
  color: var(--color-cyan-100) !important;
}

.\!bg-cyan-100,
.\!hover\:bg-cyan-100:hover,
.\!focus\:bg-cyan-100:focus,
.\!active\:bg-cyan-100:active {
  background-color: var(--color-cyan-100) !important;
}

.\!border-cyan-100,
.\!hover\:border-cyan-100:hover,
.\!focus\:border-cyan-100:focus,
.\!active\:border-cyan-100:active {
  border-color: var(--color-cyan-100) !important;
}

.\!text-green-10,
.\!hover\:text-green-10:hover,
.\!focus\:text-green-10:focus,
.\!active\:text-green-10:active {
  color: var(--color-green-10) !important;
}

.\!bg-green-10,
.\!hover\:bg-green-10:hover,
.\!focus\:bg-green-10:focus,
.\!active\:bg-green-10:active {
  background-color: var(--color-green-10) !important;
}

.\!border-green-10,
.\!hover\:border-green-10:hover,
.\!focus\:border-green-10:focus,
.\!active\:border-green-10:active {
  border-color: var(--color-green-10) !important;
}

.\!text-green-20,
.\!hover\:text-green-20:hover,
.\!focus\:text-green-20:focus,
.\!active\:text-green-20:active {
  color: var(--color-green-20) !important;
}

.\!bg-green-20,
.\!hover\:bg-green-20:hover,
.\!focus\:bg-green-20:focus,
.\!active\:bg-green-20:active {
  background-color: var(--color-green-20) !important;
}

.\!border-green-20,
.\!hover\:border-green-20:hover,
.\!focus\:border-green-20:focus,
.\!active\:border-green-20:active {
  border-color: var(--color-green-20) !important;
}

.\!text-green-30,
.\!hover\:text-green-30:hover,
.\!focus\:text-green-30:focus,
.\!active\:text-green-30:active {
  color: var(--color-green-30) !important;
}

.\!bg-green-30,
.\!hover\:bg-green-30:hover,
.\!focus\:bg-green-30:focus,
.\!active\:bg-green-30:active {
  background-color: var(--color-green-30) !important;
}

.\!border-green-30,
.\!hover\:border-green-30:hover,
.\!focus\:border-green-30:focus,
.\!active\:border-green-30:active {
  border-color: var(--color-green-30) !important;
}

.\!text-green-40,
.\!hover\:text-green-40:hover,
.\!focus\:text-green-40:focus,
.\!active\:text-green-40:active {
  color: var(--color-green-40) !important;
}

.\!bg-green-40,
.\!hover\:bg-green-40:hover,
.\!focus\:bg-green-40:focus,
.\!active\:bg-green-40:active {
  background-color: var(--color-green-40) !important;
}

.\!border-green-40,
.\!hover\:border-green-40:hover,
.\!focus\:border-green-40:focus,
.\!active\:border-green-40:active {
  border-color: var(--color-green-40) !important;
}

.\!text-green-50,
.\!hover\:text-green-50:hover,
.\!focus\:text-green-50:focus,
.\!active\:text-green-50:active {
  color: var(--color-green-50) !important;
}

.\!bg-green-50,
.\!hover\:bg-green-50:hover,
.\!focus\:bg-green-50:focus,
.\!active\:bg-green-50:active {
  background-color: var(--color-green-50) !important;
}

.\!border-green-50,
.\!hover\:border-green-50:hover,
.\!focus\:border-green-50:focus,
.\!active\:border-green-50:active {
  border-color: var(--color-green-50) !important;
}

.\!text-green-60,
.\!hover\:text-green-60:hover,
.\!focus\:text-green-60:focus,
.\!active\:text-green-60:active {
  color: var(--color-green-60) !important;
}

.\!bg-green-60,
.\!hover\:bg-green-60:hover,
.\!focus\:bg-green-60:focus,
.\!active\:bg-green-60:active {
  background-color: var(--color-green-60) !important;
}

.\!border-green-60,
.\!hover\:border-green-60:hover,
.\!focus\:border-green-60:focus,
.\!active\:border-green-60:active {
  border-color: var(--color-green-60) !important;
}

.\!text-green-70,
.\!hover\:text-green-70:hover,
.\!focus\:text-green-70:focus,
.\!active\:text-green-70:active {
  color: var(--color-green-70) !important;
}

.\!bg-green-70,
.\!hover\:bg-green-70:hover,
.\!focus\:bg-green-70:focus,
.\!active\:bg-green-70:active {
  background-color: var(--color-green-70) !important;
}

.\!border-green-70,
.\!hover\:border-green-70:hover,
.\!focus\:border-green-70:focus,
.\!active\:border-green-70:active {
  border-color: var(--color-green-70) !important;
}

.\!text-green-80,
.\!hover\:text-green-80:hover,
.\!focus\:text-green-80:focus,
.\!active\:text-green-80:active {
  color: var(--color-green-80) !important;
}

.\!bg-green-80,
.\!hover\:bg-green-80:hover,
.\!focus\:bg-green-80:focus,
.\!active\:bg-green-80:active {
  background-color: var(--color-green-80) !important;
}

.\!border-green-80,
.\!hover\:border-green-80:hover,
.\!focus\:border-green-80:focus,
.\!active\:border-green-80:active {
  border-color: var(--color-green-80) !important;
}

.\!text-green-90,
.\!hover\:text-green-90:hover,
.\!focus\:text-green-90:focus,
.\!active\:text-green-90:active {
  color: var(--color-green-90) !important;
}

.\!bg-green-90,
.\!hover\:bg-green-90:hover,
.\!focus\:bg-green-90:focus,
.\!active\:bg-green-90:active {
  background-color: var(--color-green-90) !important;
}

.\!border-green-90,
.\!hover\:border-green-90:hover,
.\!focus\:border-green-90:focus,
.\!active\:border-green-90:active {
  border-color: var(--color-green-90) !important;
}

.\!text-green-100,
.\!hover\:text-green-100:hover,
.\!focus\:text-green-100:focus,
.\!active\:text-green-100:active {
  color: var(--color-green-100) !important;
}

.\!bg-green-100,
.\!hover\:bg-green-100:hover,
.\!focus\:bg-green-100:focus,
.\!active\:bg-green-100:active {
  background-color: var(--color-green-100) !important;
}

.\!border-green-100,
.\!hover\:border-green-100:hover,
.\!focus\:border-green-100:focus,
.\!active\:border-green-100:active {
  border-color: var(--color-green-100) !important;
}

.\!text-teal-10,
.\!hover\:text-teal-10:hover,
.\!focus\:text-teal-10:focus,
.\!active\:text-teal-10:active {
  color: var(--color-teal-10) !important;
}

.\!bg-teal-10,
.\!hover\:bg-teal-10:hover,
.\!focus\:bg-teal-10:focus,
.\!active\:bg-teal-10:active {
  background-color: var(--color-teal-10) !important;
}

.\!border-teal-10,
.\!hover\:border-teal-10:hover,
.\!focus\:border-teal-10:focus,
.\!active\:border-teal-10:active {
  border-color: var(--color-teal-10) !important;
}

.\!text-teal-20,
.\!hover\:text-teal-20:hover,
.\!focus\:text-teal-20:focus,
.\!active\:text-teal-20:active {
  color: var(--color-teal-20) !important;
}

.\!bg-teal-20,
.\!hover\:bg-teal-20:hover,
.\!focus\:bg-teal-20:focus,
.\!active\:bg-teal-20:active {
  background-color: var(--color-teal-20) !important;
}

.\!border-teal-20,
.\!hover\:border-teal-20:hover,
.\!focus\:border-teal-20:focus,
.\!active\:border-teal-20:active {
  border-color: var(--color-teal-20) !important;
}

.\!text-teal-30,
.\!hover\:text-teal-30:hover,
.\!focus\:text-teal-30:focus,
.\!active\:text-teal-30:active {
  color: var(--color-teal-30) !important;
}

.\!bg-teal-30,
.\!hover\:bg-teal-30:hover,
.\!focus\:bg-teal-30:focus,
.\!active\:bg-teal-30:active {
  background-color: var(--color-teal-30) !important;
}

.\!border-teal-30,
.\!hover\:border-teal-30:hover,
.\!focus\:border-teal-30:focus,
.\!active\:border-teal-30:active {
  border-color: var(--color-teal-30) !important;
}

.\!text-teal-40,
.\!hover\:text-teal-40:hover,
.\!focus\:text-teal-40:focus,
.\!active\:text-teal-40:active {
  color: var(--color-teal-40) !important;
}

.\!bg-teal-40,
.\!hover\:bg-teal-40:hover,
.\!focus\:bg-teal-40:focus,
.\!active\:bg-teal-40:active {
  background-color: var(--color-teal-40) !important;
}

.\!border-teal-40,
.\!hover\:border-teal-40:hover,
.\!focus\:border-teal-40:focus,
.\!active\:border-teal-40:active {
  border-color: var(--color-teal-40) !important;
}

.\!text-teal-50,
.\!hover\:text-teal-50:hover,
.\!focus\:text-teal-50:focus,
.\!active\:text-teal-50:active {
  color: var(--color-teal-50) !important;
}

.\!bg-teal-50,
.\!hover\:bg-teal-50:hover,
.\!focus\:bg-teal-50:focus,
.\!active\:bg-teal-50:active {
  background-color: var(--color-teal-50) !important;
}

.\!border-teal-50,
.\!hover\:border-teal-50:hover,
.\!focus\:border-teal-50:focus,
.\!active\:border-teal-50:active {
  border-color: var(--color-teal-50) !important;
}

.\!text-teal-60,
.\!hover\:text-teal-60:hover,
.\!focus\:text-teal-60:focus,
.\!active\:text-teal-60:active {
  color: var(--color-teal-60) !important;
}

.\!bg-teal-60,
.\!hover\:bg-teal-60:hover,
.\!focus\:bg-teal-60:focus,
.\!active\:bg-teal-60:active {
  background-color: var(--color-teal-60) !important;
}

.\!border-teal-60,
.\!hover\:border-teal-60:hover,
.\!focus\:border-teal-60:focus,
.\!active\:border-teal-60:active {
  border-color: var(--color-teal-60) !important;
}

.\!text-teal-70,
.\!hover\:text-teal-70:hover,
.\!focus\:text-teal-70:focus,
.\!active\:text-teal-70:active {
  color: var(--color-teal-70) !important;
}

.\!bg-teal-70,
.\!hover\:bg-teal-70:hover,
.\!focus\:bg-teal-70:focus,
.\!active\:bg-teal-70:active {
  background-color: var(--color-teal-70) !important;
}

.\!border-teal-70,
.\!hover\:border-teal-70:hover,
.\!focus\:border-teal-70:focus,
.\!active\:border-teal-70:active {
  border-color: var(--color-teal-70) !important;
}

.\!text-teal-80,
.\!hover\:text-teal-80:hover,
.\!focus\:text-teal-80:focus,
.\!active\:text-teal-80:active {
  color: var(--color-teal-80) !important;
}

.\!bg-teal-80,
.\!hover\:bg-teal-80:hover,
.\!focus\:bg-teal-80:focus,
.\!active\:bg-teal-80:active {
  background-color: var(--color-teal-80) !important;
}

.\!border-teal-80,
.\!hover\:border-teal-80:hover,
.\!focus\:border-teal-80:focus,
.\!active\:border-teal-80:active {
  border-color: var(--color-teal-80) !important;
}

.\!text-teal-90,
.\!hover\:text-teal-90:hover,
.\!focus\:text-teal-90:focus,
.\!active\:text-teal-90:active {
  color: var(--color-teal-90) !important;
}

.\!bg-teal-90,
.\!hover\:bg-teal-90:hover,
.\!focus\:bg-teal-90:focus,
.\!active\:bg-teal-90:active {
  background-color: var(--color-teal-90) !important;
}

.\!border-teal-90,
.\!hover\:border-teal-90:hover,
.\!focus\:border-teal-90:focus,
.\!active\:border-teal-90:active {
  border-color: var(--color-teal-90) !important;
}

.\!text-teal-100,
.\!hover\:text-teal-100:hover,
.\!focus\:text-teal-100:focus,
.\!active\:text-teal-100:active {
  color: var(--color-teal-100) !important;
}

.\!bg-teal-100,
.\!hover\:bg-teal-100:hover,
.\!focus\:bg-teal-100:focus,
.\!active\:bg-teal-100:active {
  background-color: var(--color-teal-100) !important;
}

.\!border-teal-100,
.\!hover\:border-teal-100:hover,
.\!focus\:border-teal-100:focus,
.\!active\:border-teal-100:active {
  border-color: var(--color-teal-100) !important;
}

.\!text-orange-10,
.\!hover\:text-orange-10:hover,
.\!focus\:text-orange-10:focus,
.\!active\:text-orange-10:active {
  color: var(--color-orange-10) !important;
}

.\!bg-orange-10,
.\!hover\:bg-orange-10:hover,
.\!focus\:bg-orange-10:focus,
.\!active\:bg-orange-10:active {
  background-color: var(--color-orange-10) !important;
}

.\!border-orange-10,
.\!hover\:border-orange-10:hover,
.\!focus\:border-orange-10:focus,
.\!active\:border-orange-10:active {
  border-color: var(--color-orange-10) !important;
}

.\!text-orange-20,
.\!hover\:text-orange-20:hover,
.\!focus\:text-orange-20:focus,
.\!active\:text-orange-20:active {
  color: var(--color-orange-20) !important;
}

.\!bg-orange-20,
.\!hover\:bg-orange-20:hover,
.\!focus\:bg-orange-20:focus,
.\!active\:bg-orange-20:active {
  background-color: var(--color-orange-20) !important;
}

.\!border-orange-20,
.\!hover\:border-orange-20:hover,
.\!focus\:border-orange-20:focus,
.\!active\:border-orange-20:active {
  border-color: var(--color-orange-20) !important;
}

.\!text-orange-30,
.\!hover\:text-orange-30:hover,
.\!focus\:text-orange-30:focus,
.\!active\:text-orange-30:active {
  color: var(--color-orange-30) !important;
}

.\!bg-orange-30,
.\!hover\:bg-orange-30:hover,
.\!focus\:bg-orange-30:focus,
.\!active\:bg-orange-30:active {
  background-color: var(--color-orange-30) !important;
}

.\!border-orange-30,
.\!hover\:border-orange-30:hover,
.\!focus\:border-orange-30:focus,
.\!active\:border-orange-30:active {
  border-color: var(--color-orange-30) !important;
}

.\!text-orange-40,
.\!hover\:text-orange-40:hover,
.\!focus\:text-orange-40:focus,
.\!active\:text-orange-40:active {
  color: var(--color-orange-40) !important;
}

.\!bg-orange-40,
.\!hover\:bg-orange-40:hover,
.\!focus\:bg-orange-40:focus,
.\!active\:bg-orange-40:active {
  background-color: var(--color-orange-40) !important;
}

.\!border-orange-40,
.\!hover\:border-orange-40:hover,
.\!focus\:border-orange-40:focus,
.\!active\:border-orange-40:active {
  border-color: var(--color-orange-40) !important;
}

.\!text-orange-50,
.\!hover\:text-orange-50:hover,
.\!focus\:text-orange-50:focus,
.\!active\:text-orange-50:active {
  color: var(--color-orange-50) !important;
}

.\!bg-orange-50,
.\!hover\:bg-orange-50:hover,
.\!focus\:bg-orange-50:focus,
.\!active\:bg-orange-50:active {
  background-color: var(--color-orange-50) !important;
}

.\!border-orange-50,
.\!hover\:border-orange-50:hover,
.\!focus\:border-orange-50:focus,
.\!active\:border-orange-50:active {
  border-color: var(--color-orange-50) !important;
}

.\!text-orange-60,
.\!hover\:text-orange-60:hover,
.\!focus\:text-orange-60:focus,
.\!active\:text-orange-60:active {
  color: var(--color-orange-60) !important;
}

.\!bg-orange-60,
.\!hover\:bg-orange-60:hover,
.\!focus\:bg-orange-60:focus,
.\!active\:bg-orange-60:active {
  background-color: var(--color-orange-60) !important;
}

.\!border-orange-60,
.\!hover\:border-orange-60:hover,
.\!focus\:border-orange-60:focus,
.\!active\:border-orange-60:active {
  border-color: var(--color-orange-60) !important;
}

.\!text-orange-70,
.\!hover\:text-orange-70:hover,
.\!focus\:text-orange-70:focus,
.\!active\:text-orange-70:active {
  color: var(--color-orange-70) !important;
}

.\!bg-orange-70,
.\!hover\:bg-orange-70:hover,
.\!focus\:bg-orange-70:focus,
.\!active\:bg-orange-70:active {
  background-color: var(--color-orange-70) !important;
}

.\!border-orange-70,
.\!hover\:border-orange-70:hover,
.\!focus\:border-orange-70:focus,
.\!active\:border-orange-70:active {
  border-color: var(--color-orange-70) !important;
}

.\!text-orange-80,
.\!hover\:text-orange-80:hover,
.\!focus\:text-orange-80:focus,
.\!active\:text-orange-80:active {
  color: var(--color-orange-80) !important;
}

.\!bg-orange-80,
.\!hover\:bg-orange-80:hover,
.\!focus\:bg-orange-80:focus,
.\!active\:bg-orange-80:active {
  background-color: var(--color-orange-80) !important;
}

.\!border-orange-80,
.\!hover\:border-orange-80:hover,
.\!focus\:border-orange-80:focus,
.\!active\:border-orange-80:active {
  border-color: var(--color-orange-80) !important;
}

.\!text-orange-90,
.\!hover\:text-orange-90:hover,
.\!focus\:text-orange-90:focus,
.\!active\:text-orange-90:active {
  color: var(--color-orange-90) !important;
}

.\!bg-orange-90,
.\!hover\:bg-orange-90:hover,
.\!focus\:bg-orange-90:focus,
.\!active\:bg-orange-90:active {
  background-color: var(--color-orange-90) !important;
}

.\!border-orange-90,
.\!hover\:border-orange-90:hover,
.\!focus\:border-orange-90:focus,
.\!active\:border-orange-90:active {
  border-color: var(--color-orange-90) !important;
}

.\!text-orange-100,
.\!hover\:text-orange-100:hover,
.\!focus\:text-orange-100:focus,
.\!active\:text-orange-100:active {
  color: var(--color-orange-100) !important;
}

.\!bg-orange-100,
.\!hover\:bg-orange-100:hover,
.\!focus\:bg-orange-100:focus,
.\!active\:bg-orange-100:active {
  background-color: var(--color-orange-100) !important;
}

.\!border-orange-100,
.\!hover\:border-orange-100:hover,
.\!focus\:border-orange-100:focus,
.\!active\:border-orange-100:active {
  border-color: var(--color-orange-100) !important;
}

.\!text-salmon-10,
.\!hover\:text-salmon-10:hover,
.\!focus\:text-salmon-10:focus,
.\!active\:text-salmon-10:active {
  color: var(--color-salmon-10) !important;
}

.\!bg-salmon-10,
.\!hover\:bg-salmon-10:hover,
.\!focus\:bg-salmon-10:focus,
.\!active\:bg-salmon-10:active {
  background-color: var(--color-salmon-10) !important;
}

.\!border-salmon-10,
.\!hover\:border-salmon-10:hover,
.\!focus\:border-salmon-10:focus,
.\!active\:border-salmon-10:active {
  border-color: var(--color-salmon-10) !important;
}

.\!text-salmon-20,
.\!hover\:text-salmon-20:hover,
.\!focus\:text-salmon-20:focus,
.\!active\:text-salmon-20:active {
  color: var(--color-salmon-20) !important;
}

.\!bg-salmon-20,
.\!hover\:bg-salmon-20:hover,
.\!focus\:bg-salmon-20:focus,
.\!active\:bg-salmon-20:active {
  background-color: var(--color-salmon-20) !important;
}

.\!border-salmon-20,
.\!hover\:border-salmon-20:hover,
.\!focus\:border-salmon-20:focus,
.\!active\:border-salmon-20:active {
  border-color: var(--color-salmon-20) !important;
}

.\!text-salmon-30,
.\!hover\:text-salmon-30:hover,
.\!focus\:text-salmon-30:focus,
.\!active\:text-salmon-30:active {
  color: var(--color-salmon-30) !important;
}

.\!bg-salmon-30,
.\!hover\:bg-salmon-30:hover,
.\!focus\:bg-salmon-30:focus,
.\!active\:bg-salmon-30:active {
  background-color: var(--color-salmon-30) !important;
}

.\!border-salmon-30,
.\!hover\:border-salmon-30:hover,
.\!focus\:border-salmon-30:focus,
.\!active\:border-salmon-30:active {
  border-color: var(--color-salmon-30) !important;
}

.\!text-salmon-40,
.\!hover\:text-salmon-40:hover,
.\!focus\:text-salmon-40:focus,
.\!active\:text-salmon-40:active {
  color: var(--color-salmon-40) !important;
}

.\!bg-salmon-40,
.\!hover\:bg-salmon-40:hover,
.\!focus\:bg-salmon-40:focus,
.\!active\:bg-salmon-40:active {
  background-color: var(--color-salmon-40) !important;
}

.\!border-salmon-40,
.\!hover\:border-salmon-40:hover,
.\!focus\:border-salmon-40:focus,
.\!active\:border-salmon-40:active {
  border-color: var(--color-salmon-40) !important;
}

.\!text-salmon-50,
.\!hover\:text-salmon-50:hover,
.\!focus\:text-salmon-50:focus,
.\!active\:text-salmon-50:active {
  color: var(--color-salmon-50) !important;
}

.\!bg-salmon-50,
.\!hover\:bg-salmon-50:hover,
.\!focus\:bg-salmon-50:focus,
.\!active\:bg-salmon-50:active {
  background-color: var(--color-salmon-50) !important;
}

.\!border-salmon-50,
.\!hover\:border-salmon-50:hover,
.\!focus\:border-salmon-50:focus,
.\!active\:border-salmon-50:active {
  border-color: var(--color-salmon-50) !important;
}

.\!text-salmon-60,
.\!hover\:text-salmon-60:hover,
.\!focus\:text-salmon-60:focus,
.\!active\:text-salmon-60:active {
  color: var(--color-salmon-60) !important;
}

.\!bg-salmon-60,
.\!hover\:bg-salmon-60:hover,
.\!focus\:bg-salmon-60:focus,
.\!active\:bg-salmon-60:active {
  background-color: var(--color-salmon-60) !important;
}

.\!border-salmon-60,
.\!hover\:border-salmon-60:hover,
.\!focus\:border-salmon-60:focus,
.\!active\:border-salmon-60:active {
  border-color: var(--color-salmon-60) !important;
}

.\!text-gold-10,
.\!hover\:text-gold-10:hover,
.\!focus\:text-gold-10:focus,
.\!active\:text-gold-10:active {
  color: var(--color-gold-10) !important;
}

.\!bg-gold-10,
.\!hover\:bg-gold-10:hover,
.\!focus\:bg-gold-10:focus,
.\!active\:bg-gold-10:active {
  background-color: var(--color-gold-10) !important;
}

.\!border-gold-10,
.\!hover\:border-gold-10:hover,
.\!focus\:border-gold-10:focus,
.\!active\:border-gold-10:active {
  border-color: var(--color-gold-10) !important;
}

.\!text-gold-20,
.\!hover\:text-gold-20:hover,
.\!focus\:text-gold-20:focus,
.\!active\:text-gold-20:active {
  color: var(--color-gold-20) !important;
}

.\!bg-gold-20,
.\!hover\:bg-gold-20:hover,
.\!focus\:bg-gold-20:focus,
.\!active\:bg-gold-20:active {
  background-color: var(--color-gold-20) !important;
}

.\!border-gold-20,
.\!hover\:border-gold-20:hover,
.\!focus\:border-gold-20:focus,
.\!active\:border-gold-20:active {
  border-color: var(--color-gold-20) !important;
}

.\!text-gold-30,
.\!hover\:text-gold-30:hover,
.\!focus\:text-gold-30:focus,
.\!active\:text-gold-30:active {
  color: var(--color-gold-30) !important;
}

.\!bg-gold-30,
.\!hover\:bg-gold-30:hover,
.\!focus\:bg-gold-30:focus,
.\!active\:bg-gold-30:active {
  background-color: var(--color-gold-30) !important;
}

.\!border-gold-30,
.\!hover\:border-gold-30:hover,
.\!focus\:border-gold-30:focus,
.\!active\:border-gold-30:active {
  border-color: var(--color-gold-30) !important;
}

.\!text-gold-40,
.\!hover\:text-gold-40:hover,
.\!focus\:text-gold-40:focus,
.\!active\:text-gold-40:active {
  color: var(--color-gold-40) !important;
}

.\!bg-gold-40,
.\!hover\:bg-gold-40:hover,
.\!focus\:bg-gold-40:focus,
.\!active\:bg-gold-40:active {
  background-color: var(--color-gold-40) !important;
}

.\!border-gold-40,
.\!hover\:border-gold-40:hover,
.\!focus\:border-gold-40:focus,
.\!active\:border-gold-40:active {
  border-color: var(--color-gold-40) !important;
}

.\!text-gold-50,
.\!hover\:text-gold-50:hover,
.\!focus\:text-gold-50:focus,
.\!active\:text-gold-50:active {
  color: var(--color-gold-50) !important;
}

.\!bg-gold-50,
.\!hover\:bg-gold-50:hover,
.\!focus\:bg-gold-50:focus,
.\!active\:bg-gold-50:active {
  background-color: var(--color-gold-50) !important;
}

.\!border-gold-50,
.\!hover\:border-gold-50:hover,
.\!focus\:border-gold-50:focus,
.\!active\:border-gold-50:active {
  border-color: var(--color-gold-50) !important;
}

.\!text-gold-60,
.\!hover\:text-gold-60:hover,
.\!focus\:text-gold-60:focus,
.\!active\:text-gold-60:active {
  color: var(--color-gold-60) !important;
}

.\!bg-gold-60,
.\!hover\:bg-gold-60:hover,
.\!focus\:bg-gold-60:focus,
.\!active\:bg-gold-60:active {
  background-color: var(--color-gold-60) !important;
}

.\!border-gold-60,
.\!hover\:border-gold-60:hover,
.\!focus\:border-gold-60:focus,
.\!active\:border-gold-60:active {
  border-color: var(--color-gold-60) !important;
}

.\!text-gold-70,
.\!hover\:text-gold-70:hover,
.\!focus\:text-gold-70:focus,
.\!active\:text-gold-70:active {
  color: var(--color-gold-70) !important;
}

.\!bg-gold-70,
.\!hover\:bg-gold-70:hover,
.\!focus\:bg-gold-70:focus,
.\!active\:bg-gold-70:active {
  background-color: var(--color-gold-70) !important;
}

.\!border-gold-70,
.\!hover\:border-gold-70:hover,
.\!focus\:border-gold-70:focus,
.\!active\:border-gold-70:active {
  border-color: var(--color-gold-70) !important;
}

.\!text-gold-80,
.\!hover\:text-gold-80:hover,
.\!focus\:text-gold-80:focus,
.\!active\:text-gold-80:active {
  color: var(--color-gold-80) !important;
}

.\!bg-gold-80,
.\!hover\:bg-gold-80:hover,
.\!focus\:bg-gold-80:focus,
.\!active\:bg-gold-80:active {
  background-color: var(--color-gold-80) !important;
}

.\!border-gold-80,
.\!hover\:border-gold-80:hover,
.\!focus\:border-gold-80:focus,
.\!active\:border-gold-80:active {
  border-color: var(--color-gold-80) !important;
}

.\!text-gold-90,
.\!hover\:text-gold-90:hover,
.\!focus\:text-gold-90:focus,
.\!active\:text-gold-90:active {
  color: var(--color-gold-90) !important;
}

.\!bg-gold-90,
.\!hover\:bg-gold-90:hover,
.\!focus\:bg-gold-90:focus,
.\!active\:bg-gold-90:active {
  background-color: var(--color-gold-90) !important;
}

.\!border-gold-90,
.\!hover\:border-gold-90:hover,
.\!focus\:border-gold-90:focus,
.\!active\:border-gold-90:active {
  border-color: var(--color-gold-90) !important;
}

.\!text-gold-100,
.\!hover\:text-gold-100:hover,
.\!focus\:text-gold-100:focus,
.\!active\:text-gold-100:active {
  color: var(--color-gold-100) !important;
}

.\!bg-gold-100,
.\!hover\:bg-gold-100:hover,
.\!focus\:bg-gold-100:focus,
.\!active\:bg-gold-100:active {
  background-color: var(--color-gold-100) !important;
}

.\!border-gold-100,
.\!hover\:border-gold-100:hover,
.\!focus\:border-gold-100:focus,
.\!active\:border-gold-100:active {
  border-color: var(--color-gold-100) !important;
}

.\!text-gray-10,
.\!hover\:text-gray-10:hover,
.\!focus\:text-gray-10:focus,
.\!active\:text-gray-10:active {
  color: var(--color-gray-10) !important;
}

.\!bg-gray-10,
.\!hover\:bg-gray-10:hover,
.\!focus\:bg-gray-10:focus,
.\!active\:bg-gray-10:active {
  background-color: var(--color-gray-10) !important;
}

.\!border-gray-10,
.\!hover\:border-gray-10:hover,
.\!focus\:border-gray-10:focus,
.\!active\:border-gray-10:active {
  border-color: var(--color-gray-10) !important;
}

.\!text-gray-20,
.\!hover\:text-gray-20:hover,
.\!focus\:text-gray-20:focus,
.\!active\:text-gray-20:active {
  color: var(--color-gray-20) !important;
}

.\!bg-gray-20,
.\!hover\:bg-gray-20:hover,
.\!focus\:bg-gray-20:focus,
.\!active\:bg-gray-20:active {
  background-color: var(--color-gray-20) !important;
}

.\!border-gray-20,
.\!hover\:border-gray-20:hover,
.\!focus\:border-gray-20:focus,
.\!active\:border-gray-20:active {
  border-color: var(--color-gray-20) !important;
}

.\!text-gray-30,
.\!hover\:text-gray-30:hover,
.\!focus\:text-gray-30:focus,
.\!active\:text-gray-30:active {
  color: var(--color-gray-30) !important;
}

.\!bg-gray-30,
.\!hover\:bg-gray-30:hover,
.\!focus\:bg-gray-30:focus,
.\!active\:bg-gray-30:active {
  background-color: var(--color-gray-30) !important;
}

.\!border-gray-30,
.\!hover\:border-gray-30:hover,
.\!focus\:border-gray-30:focus,
.\!active\:border-gray-30:active {
  border-color: var(--color-gray-30) !important;
}

.\!text-gray-40,
.\!hover\:text-gray-40:hover,
.\!focus\:text-gray-40:focus,
.\!active\:text-gray-40:active {
  color: var(--color-gray-40) !important;
}

.\!bg-gray-40,
.\!hover\:bg-gray-40:hover,
.\!focus\:bg-gray-40:focus,
.\!active\:bg-gray-40:active {
  background-color: var(--color-gray-40) !important;
}

.\!border-gray-40,
.\!hover\:border-gray-40:hover,
.\!focus\:border-gray-40:focus,
.\!active\:border-gray-40:active {
  border-color: var(--color-gray-40) !important;
}

.\!text-gray-50,
.\!hover\:text-gray-50:hover,
.\!focus\:text-gray-50:focus,
.\!active\:text-gray-50:active {
  color: var(--color-gray-50) !important;
}

.\!bg-gray-50,
.\!hover\:bg-gray-50:hover,
.\!focus\:bg-gray-50:focus,
.\!active\:bg-gray-50:active {
  background-color: var(--color-gray-50) !important;
}

.\!border-gray-50,
.\!hover\:border-gray-50:hover,
.\!focus\:border-gray-50:focus,
.\!active\:border-gray-50:active {
  border-color: var(--color-gray-50) !important;
}

.\!text-gray-60,
.\!hover\:text-gray-60:hover,
.\!focus\:text-gray-60:focus,
.\!active\:text-gray-60:active {
  color: var(--color-gray-60) !important;
}

.\!bg-gray-60,
.\!hover\:bg-gray-60:hover,
.\!focus\:bg-gray-60:focus,
.\!active\:bg-gray-60:active {
  background-color: var(--color-gray-60) !important;
}

.\!border-gray-60,
.\!hover\:border-gray-60:hover,
.\!focus\:border-gray-60:focus,
.\!active\:border-gray-60:active {
  border-color: var(--color-gray-60) !important;
}

.\!text-gray-70,
.\!hover\:text-gray-70:hover,
.\!focus\:text-gray-70:focus,
.\!active\:text-gray-70:active {
  color: var(--color-gray-70) !important;
}

.\!bg-gray-70,
.\!hover\:bg-gray-70:hover,
.\!focus\:bg-gray-70:focus,
.\!active\:bg-gray-70:active {
  background-color: var(--color-gray-70) !important;
}

.\!border-gray-70,
.\!hover\:border-gray-70:hover,
.\!focus\:border-gray-70:focus,
.\!active\:border-gray-70:active {
  border-color: var(--color-gray-70) !important;
}

.\!text-gray-80,
.\!hover\:text-gray-80:hover,
.\!focus\:text-gray-80:focus,
.\!active\:text-gray-80:active {
  color: var(--color-gray-80) !important;
}

.\!bg-gray-80,
.\!hover\:bg-gray-80:hover,
.\!focus\:bg-gray-80:focus,
.\!active\:bg-gray-80:active {
  background-color: var(--color-gray-80) !important;
}

.\!border-gray-80,
.\!hover\:border-gray-80:hover,
.\!focus\:border-gray-80:focus,
.\!active\:border-gray-80:active {
  border-color: var(--color-gray-80) !important;
}

.\!text-gray-90,
.\!hover\:text-gray-90:hover,
.\!focus\:text-gray-90:focus,
.\!active\:text-gray-90:active {
  color: var(--color-gray-90) !important;
}

.\!bg-gray-90,
.\!hover\:bg-gray-90:hover,
.\!focus\:bg-gray-90:focus,
.\!active\:bg-gray-90:active {
  background-color: var(--color-gray-90) !important;
}

.\!border-gray-90,
.\!hover\:border-gray-90:hover,
.\!focus\:border-gray-90:focus,
.\!active\:border-gray-90:active {
  border-color: var(--color-gray-90) !important;
}

.\!text-gray-100,
.\!hover\:text-gray-100:hover,
.\!focus\:text-gray-100:focus,
.\!active\:text-gray-100:active {
  color: var(--color-gray-100) !important;
}

.\!bg-gray-100,
.\!hover\:bg-gray-100:hover,
.\!focus\:bg-gray-100:focus,
.\!active\:bg-gray-100:active {
  background-color: var(--color-gray-100) !important;
}

.\!border-gray-100,
.\!hover\:border-gray-100:hover,
.\!focus\:border-gray-100:focus,
.\!active\:border-gray-100:active {
  border-color: var(--color-gray-100) !important;
}

.\!text-cool-gray-10,
.\!hover\:text-cool-gray-10:hover,
.\!focus\:text-cool-gray-10:focus,
.\!active\:text-cool-gray-10:active {
  color: var(--color-cool-gray-10) !important;
}

.\!bg-cool-gray-10,
.\!hover\:bg-cool-gray-10:hover,
.\!focus\:bg-cool-gray-10:focus,
.\!active\:bg-cool-gray-10:active {
  background-color: var(--color-cool-gray-10) !important;
}

.\!border-cool-gray-10,
.\!hover\:border-cool-gray-10:hover,
.\!focus\:border-cool-gray-10:focus,
.\!active\:border-cool-gray-10:active {
  border-color: var(--color-cool-gray-10) !important;
}

.\!text-cool-gray-20,
.\!hover\:text-cool-gray-20:hover,
.\!focus\:text-cool-gray-20:focus,
.\!active\:text-cool-gray-20:active {
  color: var(--color-cool-gray-20) !important;
}

.\!bg-cool-gray-20,
.\!hover\:bg-cool-gray-20:hover,
.\!focus\:bg-cool-gray-20:focus,
.\!active\:bg-cool-gray-20:active {
  background-color: var(--color-cool-gray-20) !important;
}

.\!border-cool-gray-20,
.\!hover\:border-cool-gray-20:hover,
.\!focus\:border-cool-gray-20:focus,
.\!active\:border-cool-gray-20:active {
  border-color: var(--color-cool-gray-20) !important;
}

.\!text-cool-gray-30,
.\!hover\:text-cool-gray-30:hover,
.\!focus\:text-cool-gray-30:focus,
.\!active\:text-cool-gray-30:active {
  color: var(--color-cool-gray-30) !important;
}

.\!bg-cool-gray-30,
.\!hover\:bg-cool-gray-30:hover,
.\!focus\:bg-cool-gray-30:focus,
.\!active\:bg-cool-gray-30:active {
  background-color: var(--color-cool-gray-30) !important;
}

.\!border-cool-gray-30,
.\!hover\:border-cool-gray-30:hover,
.\!focus\:border-cool-gray-30:focus,
.\!active\:border-cool-gray-30:active {
  border-color: var(--color-cool-gray-30) !important;
}

.\!text-cool-gray-40,
.\!hover\:text-cool-gray-40:hover,
.\!focus\:text-cool-gray-40:focus,
.\!active\:text-cool-gray-40:active {
  color: var(--color-cool-gray-40) !important;
}

.\!bg-cool-gray-40,
.\!hover\:bg-cool-gray-40:hover,
.\!focus\:bg-cool-gray-40:focus,
.\!active\:bg-cool-gray-40:active {
  background-color: var(--color-cool-gray-40) !important;
}

.\!border-cool-gray-40,
.\!hover\:border-cool-gray-40:hover,
.\!focus\:border-cool-gray-40:focus,
.\!active\:border-cool-gray-40:active {
  border-color: var(--color-cool-gray-40) !important;
}

.\!text-cool-gray-50,
.\!hover\:text-cool-gray-50:hover,
.\!focus\:text-cool-gray-50:focus,
.\!active\:text-cool-gray-50:active {
  color: var(--color-cool-gray-50) !important;
}

.\!bg-cool-gray-50,
.\!hover\:bg-cool-gray-50:hover,
.\!focus\:bg-cool-gray-50:focus,
.\!active\:bg-cool-gray-50:active {
  background-color: var(--color-cool-gray-50) !important;
}

.\!border-cool-gray-50,
.\!hover\:border-cool-gray-50:hover,
.\!focus\:border-cool-gray-50:focus,
.\!active\:border-cool-gray-50:active {
  border-color: var(--color-cool-gray-50) !important;
}

.\!text-cool-gray-60,
.\!hover\:text-cool-gray-60:hover,
.\!focus\:text-cool-gray-60:focus,
.\!active\:text-cool-gray-60:active {
  color: var(--color-cool-gray-60) !important;
}

.\!bg-cool-gray-60,
.\!hover\:bg-cool-gray-60:hover,
.\!focus\:bg-cool-gray-60:focus,
.\!active\:bg-cool-gray-60:active {
  background-color: var(--color-cool-gray-60) !important;
}

.\!border-cool-gray-60,
.\!hover\:border-cool-gray-60:hover,
.\!focus\:border-cool-gray-60:focus,
.\!active\:border-cool-gray-60:active {
  border-color: var(--color-cool-gray-60) !important;
}

.\!text-cool-gray-70,
.\!hover\:text-cool-gray-70:hover,
.\!focus\:text-cool-gray-70:focus,
.\!active\:text-cool-gray-70:active {
  color: var(--color-cool-gray-70) !important;
}

.\!bg-cool-gray-70,
.\!hover\:bg-cool-gray-70:hover,
.\!focus\:bg-cool-gray-70:focus,
.\!active\:bg-cool-gray-70:active {
  background-color: var(--color-cool-gray-70) !important;
}

.\!border-cool-gray-70,
.\!hover\:border-cool-gray-70:hover,
.\!focus\:border-cool-gray-70:focus,
.\!active\:border-cool-gray-70:active {
  border-color: var(--color-cool-gray-70) !important;
}

.\!text-cool-gray-80,
.\!hover\:text-cool-gray-80:hover,
.\!focus\:text-cool-gray-80:focus,
.\!active\:text-cool-gray-80:active {
  color: var(--color-cool-gray-80) !important;
}

.\!bg-cool-gray-80,
.\!hover\:bg-cool-gray-80:hover,
.\!focus\:bg-cool-gray-80:focus,
.\!active\:bg-cool-gray-80:active {
  background-color: var(--color-cool-gray-80) !important;
}

.\!border-cool-gray-80,
.\!hover\:border-cool-gray-80:hover,
.\!focus\:border-cool-gray-80:focus,
.\!active\:border-cool-gray-80:active {
  border-color: var(--color-cool-gray-80) !important;
}

.\!text-cool-gray-90,
.\!hover\:text-cool-gray-90:hover,
.\!focus\:text-cool-gray-90:focus,
.\!active\:text-cool-gray-90:active {
  color: var(--color-cool-gray-90) !important;
}

.\!bg-cool-gray-90,
.\!hover\:bg-cool-gray-90:hover,
.\!focus\:bg-cool-gray-90:focus,
.\!active\:bg-cool-gray-90:active {
  background-color: var(--color-cool-gray-90) !important;
}

.\!border-cool-gray-90,
.\!hover\:border-cool-gray-90:hover,
.\!focus\:border-cool-gray-90:focus,
.\!active\:border-cool-gray-90:active {
  border-color: var(--color-cool-gray-90) !important;
}

.\!text-cool-gray-100,
.\!hover\:text-cool-gray-100:hover,
.\!focus\:text-cool-gray-100:focus,
.\!active\:text-cool-gray-100:active {
  color: var(--color-cool-gray-100) !important;
}

.\!bg-cool-gray-100,
.\!hover\:bg-cool-gray-100:hover,
.\!focus\:bg-cool-gray-100:focus,
.\!active\:bg-cool-gray-100:active {
  background-color: var(--color-cool-gray-100) !important;
}

.\!border-cool-gray-100,
.\!hover\:border-cool-gray-100:hover,
.\!focus\:border-cool-gray-100:focus,
.\!active\:border-cool-gray-100:active {
  border-color: var(--color-cool-gray-100) !important;
}

.cj-theme-light {
  --link-text-color: var(--color-primary-default);
  --link-text-decoration: underline;
  --link-text-focus-color: text-primary-focus-color;
  --link-text-hover-color: text-primary-hover-color;
  --overlay-backdrop-background-color: rgba(0, 0, 0, 0.35);
  --layout-background-color: var(--color-transparent);
  --layout-text-color: var(--text-basic-color);
  --layout-text-font-family: var(--text-paragraph-font-family);
  --layout-text-font-size: var(--text-paragraph-font-size);
  --layout-text-font-weight: var(--text-paragraph-font-weight);
  --layout-text-line-height: var(--text-paragraph-line-height);
  --layout-min-height: 100vh;
  --layout-content-width: 900px;
  --layout-window-mode-min-width: 300px;
  --layout-window-mode-background-color: background-basic-color-3;
  --layout-window-mode-padding-top: 4.75rem;
  --layout-window-shadow: var(--shadow);
  --layout-padding: 2rem;
  --layout-medium-padding: 1.5rem 1.5rem 0.5rem;
  --layout-small-padding: 1rem 1rem 0;
  --layout-scrollbar-background-color: var(--scrollbar-background-color);
  --layout-scrollbar-color: var(--scrollbar-color);
  --layout-scrollbar-width: var(--scrollbar-width);
  --header-background-color: rgba(240, 243, 247, 0.88);
  --header-text-color: var(--color-gray-90);
  --header-text-font-family: var(--text-paragraph-font-family);
  --header-text-font-size: var(--text-paragraph-font-size);
  --header-text-font-weight: var(--text-paragraph-font-weight);
  --header-text-line-height: var(--text-paragraph-line-height);
  --header-height: 7.375rem;
  --header-padding: 1.75rem 2rem;
  --header-fixed-height: 5rem;
  --header-fixed-padding: 1.25rem 1.875rem;
  --header-fixed-background-color: var(--color-white);
  --header-border-bottom-width: 0.0625rem;
  --header-border-bottom-style: solid;
  --header-border-bottom-color: var(--color-gray-40);
  --footer-background-color: var(--color-white);
  --footer-text-color: var(--color-gray-90);
  --footer-text-font-family: var(--text-paragraph-font-family);
  --footer-text-font-size: var(--text-paragraph-font-size);
  --footer-text-font-weight: var(--text-paragraph-font-weight);
  --footer-text-line-height: var(--text-paragraph-line-height);
  --footer-text-highlight-color: var(--color-primary-hover);
  --footer-height: 4.725rem;
  --footer-padding: 1.25rem;
  --footer-divider-color: var(--divider-color);
  --footer-divider-style: var(--divider-style);
  --footer-divider-width: var(--divider-width);
  --footer-shadow: var(--shadow);
  --sidebar-scrollbar-background-color: var(--scrollbar-background-color);
  --sidebar-scrollbar-color: var(--scrollbar-color);
  --sidebar-scrollbar-width: 0.25rem;
  --sidebar-background-color: var(--color-white);
  --sidebar-border-right-width: 0.0625rem;
  --sidebar-border-right-style: solid;
  --sidebar-border-right-color: var(--color-gray-40);
  --sidebar-height: 100vh;
  --sidebar-width: 15.625rem;
  --sidebar-width-compact: 4.25rem;
  --sidebar-nav-padding: 0 0 1.5rem;
  --sidebar-nav-compact-padding: 1rem 0.5rem 1.5rem;
  --sidebar-header-height: 6.25rem;
  --sidebar-header-padding: 1.5rem 0.75rem 1rem;
  --sidebar-header-margin: 0 0.75rem;
  --sidebar-header-border-color: var(--color-cyan-30);
  --sidebar-header-compact-border-color: transparent;
  --sidebar-header-compact-padding: 1.5rem 0.75rem 1rem;
  --sidebar-header-compact-margin: 0;
  --sidebar-footer-height: 3rem;
  --sidebar-footer-font-size: 0.75rem;
  --sidebar-footer-padding: 0.75rem;
  --sidebar-footer-margin: 0 0.75rem;
  --sidebar-footer-border-color: var(--color-cyan-30);
  --sidebar-footer-compact-border-color: transparent;
  --sidebar-footer-compact-padding: 0.75rem;
  --sidebar-footer-compact-margin: 0;
  --sidebar-shadow: 0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.08), 0px 6px 40px rgba(2, 2, 15, 0.04);
  --menu-background-color: transparent;
  --menu-text-color: var(--text-basic-color);
  --menu-text-font-weight: 700;
  --menu-group-text-font-weight: 400;
  --menu-group-text-font-size: var(--text-subtitle-font-size);
  --menu-group-text-color: var(--text-hint-color);
  --menu-group-padding: 0.5rem 0.625rem;
  --menu-group-text-transform: uppercase;
  --menu-item-expanded-border-radius: 0.5rem 0.5rem 0 0;
  --menu-item-border-radius: 0.5rem;
  --menu-item-border-width: 0.125rem;
  --menu-item-border-style: solid;
  --menu-item-border-color: transparent;
  --menu-item-padding: 0.75rem;
  --menu-item-compact-padding: 0.75rem;
  --menu-item-with-icon-padding: 0.75rem;
  --menu-item-hover-cursor: pointer;
  --menu-item-hover-background-color: var(--color-cool-gray-20);
  --menu-item-hover-text-color: var(--color-primary-default);
  --menu-item-icon-hover-color: var(--color-primary-default);
  --menu-item-icon-hover-background-color: transparent;
  --menu-item-active-background-color: var(--color-blue-10);
  --menu-item-active-text-color: var(--color-primary-default);
  --menu-item-icon-active-background-color: transparent;
  --menu-item-icon-active-color: var(--color-primary-default);
  --menu-item-active-border-color: var(--color-blue-10);
  --menu-item-focus-border-color: transparent;
  --menu-item-icon-color: var(--text-hint-color);
  --menu-item-icon-margin: 0 0.5rem 0 0;
  --menu-item-icon-width: 1.5rem;
  --menu-submenu-background-color: var(--color-cool-gray-30);
  --menu-submenu-border-radius: 0 0 0.5rem 0.5rem;
  --menu-submenu-padding: 1.5rem;
  --menu-submenu-margin: 0;
  --menu-submenu-item-padding: 0.5rem 0.75rem 0.5rem 1.5rem;
  --menu-submenu-text-font-weight: 700;
  --list-item-divider-color: var(--divider-color);
  --list-item-divider-style: var(--divider-style);
  --list-item-divider-width: var(--divider-width);
  --list-item-padding: 1rem;
  --list-item-text-color: var(--text-basic-color);
  --list-item-font-family: var(--text-paragraph-font-family);
  --list-item-font-size: var(--text-paragraph-font-size);
  --list-item-font-weight: var(--text-paragraph-font-weight);
  --list-item-line-height: var(--text-paragraph-line-height);
  --button-cursor: pointer;
  --button-border-radius: 9999px;
  --button-small-padding: 0.125rem 1rem;
  --button-medium-padding: 0.375rem 1.5rem;
  --button-large-padding: 0.875rem 2em;
  --button-text-font-family: var(--text-button-font-family);
  --button-text-font-weight: var(--text-button-font-weight);
  --button-text-font-size: var(--text-button-font-size);
  --button-text-line-height: var(--text-button-line-height);
  --button-disabled-cursor: default;
  --button-small-icon-size: 1rem;
  --button-small-icon-vertical-margin: -0.125rem;
  --button-small-icon-offset: 0.375rem;
  --button-medium-icon-size: 1.25rem;
  --button-medium-icon-vertical-margin: -0.125rem;
  --button-medium-icon-offset: 0.5rem;
  --button-large-icon-size: 1.5rem;
  --button-large-icon-vertical-margin: -0.125rem;
  --button-large-icon-offset: 0.75rem;
  --button-filled-border-style: solid;
  --button-filled-border-width: 0.125rem;
  --button-filled-text-transform: none;
  --button-filled-primary-background-color: var(--color-primary-default);
  --button-filled-primary-border-color: var(--color-primary-default);
  --button-filled-primary-text-color: var(--text-button-filled-color);
  --button-filled-primary-focus-background-color: var(--color-primary-focus);
  --button-filled-primary-focus-border-color: var(--color-primary-focus);
  --button-filled-primary-hover-background-color: var(--color-primary-hover);
  --button-filled-primary-hover-border-color: var(--color-primary-hover);
  --button-filled-primary-active-background-color: var(--color-primary-active);
  --button-filled-primary-active-border-color: var(--color-primary-active);
  --button-filled-primary-disabled-background-color: var(--color-disabled);
  --button-filled-primary-disabled-border-color: var(--color-disabled);
  --button-filled-primary-disabled-text-color: var(--text-button-filled-color);
  --button-filled-primary-outline-color: var(--color-primary-outline);
  --button-filled-success-background-color: var(--color-green-40);
  --button-filled-success-border-color: var(--color-green-40);
  --button-filled-success-text-color: var(--text-button-filled-color);
  --button-filled-success-focus-background-color: var(--color-green-50);
  --button-filled-success-focus-border-color: var(--color-green-50);
  --button-filled-success-hover-background-color: var(--color-green-30);
  --button-filled-success-hover-border-color: var(--color-green-30);
  --button-filled-success-active-background-color: var(--color-green-70);
  --button-filled-success-active-border-color: var(--color-green-70);
  --button-filled-success-disabled-background-color: var(--color-disabled);
  --button-filled-success-disabled-border-color: var(--color-disabled);
  --button-filled-success-disabled-text-color: var(--text-button-filled-color);
  --button-filled-success-outline-color: var(--color-green-transparent-40);
  --button-filled-info-background-color: var(--color-gray-50);
  --button-filled-info-border-color: var(--color-gray-50);
  --button-filled-info-text-color: var(--text-button-filled-color);
  --button-filled-info-focus-background-color: var(--color-cool-gray-50);
  --button-filled-info-focus-border-color: var(--color-cool-gray-50);
  --button-filled-info-hover-background-color: var(--color-gray-40);
  --button-filled-info-hover-border-color: var(--color-gray-40);
  --button-filled-info-active-background-color: var(--color-gray-60);
  --button-filled-info-active-border-color: var(--color-gray-60);
  --button-filled-info-disabled-background-color: var(--color-disabled);
  --button-filled-info-disabled-border-color: var(--color-disabled);
  --button-filled-info-disabled-text-color: var(--text-button-filled-color);
  --button-filled-info-outline-color: var(--color-gray-transparent-50);
  --button-filled-warning-background-color: var(--color-gold-50);
  --button-filled-warning-border-color: var(--color-gold-50);
  --button-filled-warning-text-color: var(--text-button-filled-color);
  --button-filled-warning-focus-background-color: var(--color-gold-60);
  --button-filled-warning-focus-border-color: var(--color-gold-60);
  --button-filled-warning-hover-background-color: var(--color-gold-40);
  --button-filled-warning-hover-border-color: var(--color-gold-40);
  --button-filled-warning-active-background-color: var(--color-gold-100);
  --button-filled-warning-active-border-color: var(--color-gold-100);
  --button-filled-warning-disabled-background-color: var(--color-disabled);
  --button-filled-warning-disabled-border-color: var(--color-disabled);
  --button-filled-warning-disabled-text-color: var(--text-button-filled-color);
  --button-filled-warning-outline-color: var(--color-gold-transparent-50);
  --button-filled-danger-background-color: var(--color-danger-default);
  --button-filled-danger-border-color: var(--color-danger-default);
  --button-filled-danger-text-color: var(--text-button-filled-color);
  --button-filled-danger-focus-background-color: var(--color-danger-focus);
  --button-filled-danger-focus-border-color: var(--color-danger-focus);
  --button-filled-danger-hover-background-color: var(--color-danger-hover);
  --button-filled-danger-hover-border-color: var(--color-danger-hover);
  --button-filled-danger-active-background-color: var(--color-danger-active);
  --button-filled-danger-active-border-color: var(--color-danger-active);
  --button-filled-danger-disabled-background-color: var(--color-disabled);
  --button-filled-danger-disabled-border-color: var(--color-disabled);
  --button-filled-danger-disabled-text-color: var(--text-button-filled-color);
  --button-filled-danger-outline-color: var(--color-danger-outline);
  --button-outline-border-style: solid;
  --button-outline-border-width: 0.125rem;
  --button-outline-text-transform: none;
  --button-outline-primary-background-color: var(--color-transparent);
  --button-outline-primary-border-color: var(--color-primary-default);
  --button-outline-primary-text-color: var(--color-primary-default);
  --button-outline-primary-focus-background-color: var(--color-transparent);
  --button-outline-primary-focus-border-color: var(--color-primary-default);
  --button-outline-primary-focus-text-color: var(--color-primary-default);
  --button-outline-primary-hover-background-color: var(--color-transparent);
  --button-outline-primary-hover-border-color: var(--color-primary-hover);
  --button-outline-primary-hover-text-color: var(--color-primary-default);
  --button-outline-primary-active-background-color: var(--color-transparent);
  --button-outline-primary-active-border-color: var(--color-primary-active);
  --button-outline-primary-active-text-color: var(--color-primary-active);
  --button-outline-primary-disabled-background-color: var(--color-transparent);
  --button-outline-primary-disabled-border-color: var(--color-border-disabled);
  --button-outline-primary-disabled-text-color: var(--color-disabled);
  --button-outline-primary-outline-color: var(--color-primary-outline);
  --button-outline-success-background-color: var(--color-transparent);
  --button-outline-success-border-color: var(--color-green-40);
  --button-outline-success-text-color: var(--color-green-40);
  --button-outline-success-focus-background-color: var(--color-transparent);
  --button-outline-success-focus-border-color: var(--color-green-50);
  --button-outline-success-focus-text-color: var(--color-green-50);
  --button-outline-success-hover-background-color: var(--color-transparent);
  --button-outline-success-hover-border-color: var(--color-green-30);
  --button-outline-success-hover-text-color: var(--color-green-40);
  --button-outline-success-active-background-color: var(--color-transparent);
  --button-outline-success-active-border-color: var(--color-green-70);
  --button-outline-success-active-text-color: var(--color-green-70);
  --button-outline-success-disabled-background-color: var(--color-transparent);
  --button-outline-success-disabled-border-color: var(--color-border-disabled);
  --button-outline-success-disabled-text-color: var(--color-disabled);
  --button-outline-success-outline-color: var(--color-green-transparent-40);
  --button-outline-info-background-color: var(--color-transparent);
  --button-outline-info-border-color: var(--color-gray-50);
  --button-outline-info-text-color: var(--color-gray-50);
  --button-outline-info-focus-background-color: var(--color-transparent);
  --button-outline-info-focus-border-color: var(--color-cool-gray-50);
  --button-outline-info-focus-text-color: var(--color-cool-gray-50);
  --button-outline-info-hover-background-color: var(--color-transparent);
  --button-outline-info-hover-border-color: var(--color-gray-40);
  --button-outline-info-hover-text-color: var(--color-gray-50);
  --button-outline-info-active-background-color: var(--color-transparent);
  --button-outline-info-active-border-color: var(--color-gray-60);
  --button-outline-info-active-text-color: var(--color-gray-60);
  --button-outline-info-disabled-background-color: var(--color-transparent);
  --button-outline-info-disabled-border-color: var(--color-border-disabled);
  --button-outline-info-disabled-text-color: var(--color-disabled);
  --button-outline-info-outline-color: var(--color-gray-transparent-50);
  --button-outline-warning-background-color: var(--color-transparent);
  --button-outline-warning-border-color: var(--color-gold-50);
  --button-outline-warning-text-color: var(--color-gold-50);
  --button-outline-warning-focus-background-color: var(--color-transparent);
  --button-outline-warning-focus-border-color: var(--color-gold-60);
  --button-outline-warning-focus-text-color: var(--color-gold-60);
  --button-outline-warning-hover-background-color: var(--color-transparent);
  --button-outline-warning-hover-border-color: var(--color-gold-40);
  --button-outline-warning-hover-text-color: var(--color-gold-50);
  --button-outline-warning-active-background-color: var(--color-transparent);
  --button-outline-warning-active-border-color: var(--color-gold-100);
  --button-outline-warning-active-text-color: var(--color-gold-100);
  --button-outline-warning-disabled-background-color: var(--color-transparent);
  --button-outline-warning-disabled-border-color: var(--color-border-disabled);
  --button-outline-warning-disabled-text-color: var(--color-disabled);
  --button-outline-warning-outline-color: var(--color-gold-transparent-50);
  --button-outline-danger-background-color: var(--color-transparent);
  --button-outline-danger-border-color: var(--color-danger-default);
  --button-outline-danger-text-color: var(--color-danger-default);
  --button-outline-danger-focus-background-color: var(--color-transparent);
  --button-outline-danger-focus-border-color: var(--color-danger-default);
  --button-outline-danger-focus-text-color: var(--color-danger-default);
  --button-outline-danger-hover-background-color: var(--color-transparent);
  --button-outline-danger-hover-border-color: var(--color-danger-hover);
  --button-outline-danger-hover-text-color: var(--color-red-80);
  --button-outline-danger-active-background-color: var(--color-transparent);
  --button-outline-danger-active-border-color: var(--color-danger-active);
  --button-outline-danger-active-text-color: var(--color-danger-active);
  --button-outline-danger-disabled-background-color: var(--color-transparent);
  --button-outline-danger-disabled-border-color: var(--color-border-disabled);
  --button-outline-danger-disabled-text-color: var(--color-disabled);
  --button-outline-danger-outline-color: var(--color-danger-outline);
  --icon-font-size: 1.75rem;
  --icon-line-height: 1;
  --icon-width: 1em;
  --icon-height: 1em;
  --icon-svg-vertical-align: middle;
  --icon-primary-color: var(--color-primary-default);
  --icon-info-color: var(--color-gray-50);
  --icon-success-color: var(--color-green-40);
  --icon-warning-color: var(--color-gold-50);
  --icon-danger-color: var(--color-danger-default);
  --icon-button-small-width: 2rem;
  --icon-button-small-padding: 0.5rem;
  --icon-button-medium-width: 2.5rem;
  --icon-button-medium-padding: 0.75rem;
  --icon-button-large-width: 3.5rem;
  --icon-button-large-padding: 1rem;
  --card-background-color: var(--color-white);
  --card-border-radius: 0.75rem;
  --card-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 2px 8px rgba(0, 0, 0, 0.04);
  --card-margin-bottom: 1.875rem;
  --card-padding: 1rem 1.5rem;
  --input-border-style: solid;
  --input-border-width: 0.0625rem;
  --input-border-color: var(--color-cyan-30);
  --input-hover-border-color: var(--color-cyan-50);
  --input-border-radius: 0.5rem;
  --input-placeholder-text-font-family: var(--text-paragraph-font-family);
  --input-placeholder-text-color: var(--text-disabled-color);
  --input-text-font-family: var(--text-paragraph-font-family);
  --input-text-color: var(--text-basic-color);
  --input-background-color: var(--color-gray-10);
  --input-disabled-background-color: var(--color-cool-gray-20);
  --input-disabled-border-color: var(--color-gray-40);
  --input-disabled-text-color: var(--text-hint-color);
  --input-primary-border-color: var(--color-primary-default);
  --input-primary-focus-border-color: var(--color-primary-focus);
  --input-primary-hover-border-color: var(--color-primary-hover);
  --input-primary-outline-color: var(--color-primary-outline);
  --input-success-border-color: var(--color-green-40);
  --input-success-focus-border-color: var(--color-green-50);
  --input-success-hover-border-color: var(--color-green-30);
  --input-success-outline-color: var(--color-green-transparent-40);
  --input-info-border-color: var(--color-gray-50);
  --input-info-focus-border-color: var(--color-cool-gray-50);
  --input-info-hover-border-color: var(--color-gray-40);
  --input-info-outline-color: var(--color-gray-transparent-50);
  --input-warning-border-color: var(--color-gold-50);
  --input-warning-focus-border-color: var(--color-gold-60);
  --input-warning-hover-border-color: var(--color-gold-40);
  --input-warning-outline-color: var(--color-gold-transparent-50);
  --input-danger-border-color: var(--color-danger-default);
  --input-danger-focus-border-color: var(--color-red-80);
  --input-danger-hover-border-color: var(--color-danger-hover);
  --input-danger-outline-color: var(--color-danger-outline);
  --input-small-text-font-size: var(--text-paragraph-font-size);
  --input-small-text-font-weight: var(--text-paragraph-font-weight);
  --input-small-text-line-height: var(--text-subtitle-line-height);
  --input-small-padding: 0.1875rem 0.75rem;
  --input-small-max-width: 18.75rem;
  --input-medium-text-font-size: var(--text-paragraph-font-size);
  --input-medium-text-font-weight: var(--text-paragraph-font-weight);
  --input-medium-text-line-height: var(--text-subtitle-line-height);
  --input-medium-padding: 0.4375rem 1rem;
  --input-medium-max-width: 18.75rem;
  --input-large-text-font-size: var(--text-paragraph-font-size);
  --input-large-text-font-weight: var(--text-paragraph-font-weight);
  --input-large-text-line-height: var(--text-subtitle-line-height);
  --input-large-padding: 0.9375rem 1.25rem;
  --input-large-max-width: 18.75rem;
  --form-field-small-max-width: var(--input-small-max-width);
  --form-field-medium-max-width: var(--input-medium-max-width);
  --form-field-large-max-width: var(--input-large-max-width);
  --form-field-addon-text-color: var(--text-hint-color);
  --form-field-addon-disabled-text-color: var(--text-hint-color);
  --form-field-addon-small-height: 2rem;
  --form-field-addon-small-width: 2.5rem;
  --form-field-addon-small-padding: 2.25rem;
  --form-field-addon-small-icon-size: var(--button-small-icon-size);
  --form-field-addon-small-font-size: var(--text-button-font-size);
  --form-field-addon-small-line-height: var(--text-button-line-height);
  --form-field-addon-medium-height: 2.5rem;
  --form-field-addon-medium-width: 2.75rem;
  --form-field-addon-medium-padding: 2.5rem;
  --form-field-addon-medium-icon-size: var(--button-medium-icon-size);
  --form-field-addon-medium-font-size: var(--text-button-font-size);
  --form-field-addon-medium-line-height: var(--text-button-line-height);
  --form-field-addon-large-height: 3.5rem;
  --form-field-addon-large-width: 3.75rem;
  --form-field-addon-large-padding: 3rem;
  --form-field-addon-large-icon-size: var(--button-large-icon-size);
  --form-field-addon-large-font-size: var(--text-button-font-size);
  --form-field-addon-large-line-height: var(--text-button-line-height);
  --checkbox-small-width: 1rem;
  --checkbox-small-text-font-size: 0.875rem;
  --checkbox-small-text-line-height: 1.125rem;
  --checkbox-small-text-space: 0.5rem;
  --checkbox-medium-width: 1.5rem;
  --checkbox-medium-text-font-size: 1rem;
  --checkbox-medium-text-line-height: 1.5rem;
  --checkbox-medium-text-space: 0.75rem;
  --checkbox-large-width: 2rem;
  --checkbox-large-text-font-size: 1.125rem;
  --checkbox-large-text-line-height: 1.875rem;
  --checkbox-large-text-space: 1rem;
  --checkbox-border-style: solid;
  --checkbox-border-width: 1px;
  --checkbox-border-radius: 0.25rem;
  --checkbox-text-font-weight: var(--text-paragraph-font-weight);
  --checkbox-text-color: var(--text-basic-color);
  --checkbox-background-color: var(--input-background-color);
  --checkbox-checked-checkmark-color: var(--input-background-color);
  --checkbox-padding: 0;
  --checkbox-disabled-background-color: var(--color-cool-gray-20);
  --checkbox-disabled-border-color: var(--color-gray-40);
  --checkbox-disabled-text-color: var(--text-disabled-color);
  --checkbox-disabled-checked-background-color: var(--color-disabled);
  --checkbox-disabled-checked-border-color: var(--checkbox-disabled-border-color);
  --checkbox-primary-border-color: var(--color-cyan-30);
  --checkbox-primary-checked-background-color: var(--color-primary-default);
  --checkbox-primary-checked-border-color: var(--color-cyan-30);
  --checkbox-primary-indeterminate-background-color: var(--color-primary-default);
  --checkbox-primary-indeterminate-border-color: var(--color-cyan-30);
  --checkbox-primary-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-primary-focus-border-color: var(--color-primary-default);
  --checkbox-primary-focus-checked-background-color: var(--color-primary-default);
  --checkbox-primary-focus-checked-border-color: var(--color-primary-default);
  --checkbox-primary-focus-outline-color: var(--color-primary-outline);
  --checkbox-primary-hover-border-color: var(--color-cyan-50);
  --checkbox-primary-hover-checked-background-color: var(--color-primary-default);
  --checkbox-primary-hover-checked-border-color: var(--color-cyan-50);
  --checkbox-primary-active-border-color: var(--color-primary-default);
  --checkbox-primary-active-checked-background-color: var(--color-primary-active);
  --checkbox-primary-active-checked-border-color: var(--color-primary-default);
  --checkbox-success-border-color: var(--color-green-40);
  --checkbox-success-checked-background-color: var(--color-green-40);
  --checkbox-success-checked-border-color: var(--color-green-40);
  --checkbox-success-indeterminate-background-color: var(--color-green-40);
  --checkbox-success-indeterminate-border-color: var(--color-green-40);
  --checkbox-success-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-success-focus-border-color: var(--color-green-40);
  --checkbox-success-focus-checked-background-color: var(--color-green-40);
  --checkbox-success-focus-checked-border-color: var(--color-green-40);
  --checkbox-success-focus-outline-color: var(--color-green-transparent-40);
  --checkbox-success-hover-border-color: var(--color-green-50);
  --checkbox-success-hover-checked-background-color: var(--color-green-40);
  --checkbox-success-hover-checked-border-color: var(--color-green-50);
  --checkbox-success-active-border-color: var(--color-green-40);
  --checkbox-success-active-checked-background-color: var(--color-green-70);
  --checkbox-success-active-checked-border-color: var(--color-green-40);
  --checkbox-info-border-color: var(--color-gray-50);
  --checkbox-info-checked-background-color: var(--color-gray-50);
  --checkbox-info-checked-border-color: var(--color-gray-50);
  --checkbox-info-indeterminate-background-color: var(--color-gray-50);
  --checkbox-info-indeterminate-border-color: var(--color-gray-50);
  --checkbox-info-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-info-focus-border-color: var(--color-gray-50);
  --checkbox-info-focus-checked-background-color: var(--color-gray-50);
  --checkbox-info-focus-checked-border-color: var(--color-gray-50);
  --checkbox-info-focus-outline-color: var(--color-gray-transparent-50);
  --checkbox-info-hover-border-color: var(--color-cool-gray-50);
  --checkbox-info-hover-checked-background-color: var(--color-gray-50);
  --checkbox-info-hover-checked-border-color: var(--color-cool-gray-50);
  --checkbox-info-active-border-color: var(--color-gray-50);
  --checkbox-info-active-checked-background-color: var(--color-gray-60);
  --checkbox-info-active-checked-border-color: var(--color-gray-50);
  --checkbox-warning-border-color: var(--color-gold-50);
  --checkbox-warning-checked-background-color: var(--color-gold-50);
  --checkbox-warning-checked-border-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-background-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-border-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-warning-focus-border-color: var(--color-gold-50);
  --checkbox-warning-focus-checked-background-color: var(--color-gold-50);
  --checkbox-warning-focus-checked-border-color: var(--color-gold-50);
  --checkbox-warning-focus-outline-color: var(--color-gold-transparent-50);
  --checkbox-warning-hover-border-color: var(--color-gold-40);
  --checkbox-warning-hover-checked-background-color: var(--color-gold-50);
  --checkbox-warning-hover-checked-border-color: var(--color-gold-40);
  --checkbox-warning-active-border-color: var(--color-gold-50);
  --checkbox-warning-active-checked-background-color: var(--color-gold-100);
  --checkbox-warning-active-checked-border-color: var(--color-gold-50);
  --checkbox-danger-border-color: var(--color-danger-default);
  --checkbox-danger-checked-background-color: var(--color-danger-default);
  --checkbox-danger-checked-border-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-background-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-border-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-danger-focus-border-color: var(--color-red-80);
  --checkbox-danger-focus-checked-background-color: var(--color-danger-default);
  --checkbox-danger-focus-checked-border-color: var(--color-red-80);
  --checkbox-danger-focus-outline-color: var(--color-danger-outline);
  --checkbox-danger-hover-border-color: var(--color-danger-hover);
  --checkbox-danger-hover-checked-background-color: var(--color-danger-default);
  --checkbox-danger-hover-checked-border-color: var(--color-danger-hover);
  --checkbox-danger-active-border-color: var(--color-red-80);
  --checkbox-danger-active-checked-background-color: var(--color-danger-active);
  --checkbox-danger-active-checked-border-color: var(--color-red-80);
  --radio-border-style: solid;
  --radio-border-width: 1px;
  --radio-small-width: 1rem;
  --radio-small-text-font-size: 0.875rem;
  --radio-small-text-line-height: 1.125rem;
  --radio-small-text-space: 0.5rem;
  --radio-medium-width: 1.5rem;
  --radio-medium-text-font-size: 1rem;
  --radio-medium-text-line-height: 1.5rem;
  --radio-medium-text-space: 0.75rem;
  --radio-large-width: 2rem;
  --radio-large-text-font-size: 1.125rem;
  --radio-large-text-line-height: 1.875rem;
  --radio-large-text-space: 1rem;
  --radio-text-font-weight: var(--text-paragraph-font-weight);
  --radio-text-color: var(--text-basic-color);
  --radio-background-color: var(--input-background-color);
  --radio-disabled-background-color: var(--color-cool-gray-20);
  --radio-disabled-border-color: var(--color-gray-40);
  --radio-disabled-text-color: var(--text-disabled-color);
  --radio-disabled-checked-background-color: var(--radio-disabled-background-color);
  --radio-disabled-checked-border-color: var(--radio-disabled-border-color);
  --radio-disabled-checked-inner-circle-color: var(--color-disabled);
  --radio-primary-border-color: var(--color-cyan-30);
  --radio-primary-checked-border-color: var(--color-cyan-30);
  --radio-primary-inner-circle-color: var(--color-primary-default);
  --radio-primary-focus-border-color: var(--color-primary-default);
  --radio-primary-focus-checked-border-color: var(--color-primary-default);
  --radio-primary-focus-inner-circle-color: var(--color-primary-default);
  --radio-primary-focus-outline-color: var(--color-primary-outline);
  --radio-primary-hover-border-color: var(--color-cyan-50);
  --radio-primary-hover-inner-circle-color: var(--color-primary-hover);
  --radio-primary-hover-checked-border-color: var(--color-cyan-50);
  --radio-primary-active-border-color: var(--color-primary-active);
  --radio-primary-active-checked-border-color: var(--color-primary-default);
  --radio-primary-active-inner-circle-color: var(--color-primary-active);
  --radio-success-border-color: var(--color-green-40);
  --radio-success-checked-border-color: var(--color-green-40);
  --radio-success-inner-circle-color: var(--color-green-40);
  --radio-success-focus-border-color: var(--color-green-40);
  --radio-success-focus-checked-border-color: var(--color-green-40);
  --radio-success-focus-inner-circle-color: var(--color-green-40);
  --radio-success-focus-outline-color: var(--color-green-transparent-40);
  --radio-success-hover-border-color: var(--color-green-50);
  --radio-success-hover-inner-circle-color: var(--color-green-40);
  --radio-success-hover-checked-border-color: var(--color-green-50);
  --radio-success-active-border-color: var(--color-green-40);
  --radio-success-active-checked-border-color: var(--color-green-40);
  --radio-success-active-inner-circle-color: var(--color-green-70);
  --radio-info-border-color: var(--color-gray-50);
  --radio-info-checked-border-color: var(--color-gray-50);
  --radio-info-inner-circle-color: var(--color-gray-50);
  --radio-info-focus-border-color: var(--color-gray-50);
  --radio-info-focus-checked-border-color: var(--color-gray-50);
  --radio-info-focus-inner-circle-color: var(--color-gray-50);
  --radio-info-focus-outline-color: var(--color-gray-transparent-50);
  --radio-info-hover-border-color: var(--color-cool-gray-50);
  --radio-info-hover-inner-circle-color: var(--color-gray-50);
  --radio-info-hover-checked-border-color: var(--color-cool-gray-50);
  --radio-info-active-border-color: var(--color-gray-50);
  --radio-info-active-checked-border-color: var(--color-gray-50);
  --radio-info-active-inner-circle-color: var(--color-gray-50);
  --radio-warning-border-color: var(--color-gold-50);
  --radio-warning-checked-border-color: var(--color-gold-50);
  --radio-warning-inner-circle-color: var(--color-gold-50);
  --radio-warning-focus-border-color: var(--color-gold-50);
  --radio-warning-focus-checked-border-color: var(--color-gold-50);
  --radio-warning-focus-inner-circle-color: var(--color-gold-50);
  --radio-warning-focus-outline-color: var(--color-gold-transparent-50);
  --radio-warning-hover-border-color: var(--color-gold-40);
  --radio-warning-hover-inner-circle-color: var(--color-gold-50);
  --radio-warning-hover-checked-border-color: var(--color-gold-40);
  --radio-warning-active-border-color: var(--color-gold-50);
  --radio-warning-active-checked-border-color: var(--color-gold-50);
  --radio-warning-active-inner-circle-color: var(--color-gold-100);
  --radio-danger-border-color: var(--color-danger-default);
  --radio-danger-checked-border-color: var(--color-danger-default);
  --radio-danger-inner-circle-color: var(--color-danger-default);
  --radio-danger-focus-border-color: var(--color-red-80);
  --radio-danger-focus-checked-border-color: var(--color-red-80);
  --radio-danger-focus-inner-circle-color: var(--color-danger-focus);
  --radio-danger-focus-outline-color: var(--color-danger-outline);
  --radio-danger-hover-border-color: var(--color-danger-hover);
  --radio-danger-hover-inner-circle-color: var(--color-danger-hover);
  --radio-danger-hover-checked-border-color: var(--color-danger-hover);
  --radio-danger-active-border-color: var(--color-red-80);
  --radio-danger-active-checked-border-color: var(--color-red-80);
  --radio-danger-active-inner-circle-color: var(--color-danger-active);
  --tabset-background-color: transparent;
  --tabset-tab-gap: 0.5rem;
  --tabset-tab-max-width: 20rem;
  --tabset-tab-background-color: var(--color-cool-gray-30);
  --tabset-tab-padding: 0.5rem 1rem;
  --tabset-tab-text-color: var(--text-basic-color);
  --tabset-tab-text-font-weight: 500;
  --tabset-tab-text-line-height: 1.375rem;
  --tabset-tab-border-radius: 0.5rem 0.5rem 0 0;
  --tabset-tab-active-background-color: var(--text-basic-color);
  --tabset-tab-active-text-color: var(--text-button-filled-color);
  --tabset-tab-focus-background-color: var(--tabset-tab-active-background-color);
  --tabset-tab-focus-text-color: var(--tabset-tab-active-text-color);
  --tabset-tab-hover-background-color: var(--color-cool-gray-20);
  --tabset-tab-hover-text-color: var(--text-basic-color);
  --tabset-tab-disabled-background-color: var(--tabset-tab-background-color);
  --tabset-tab-disabled-text-color: var(--text-disabled-color);
  --tabset-divider-color: var(--color-cyan-30);
  --tabset-divider-style: var(--divider-style);
  --tabset-divider-width: var(--divider-width);
  --tabset-content-background-color: transparent;
  --tabset-content-padding: 0;
  --tabset-scrollbar-color: var(--scrollbar-color);
  --tabset-scrollbar-background-color: var(--scrollbar-background-color);
  --tabset-scrollbar-width: var(--scrollbar-width);
  --route-tabset-background-color: transparent;
  --route-tabset-tab-gap: 0.5rem;
  --route-tabset-tab-max-width: 20rem;
  --route-tabset-tab-background-color: var(--color-cool-gray-30);
  --route-tabset-tab-padding: 0.5rem 1rem;
  --route-tabset-tab-text-color: var(--text-basic-color);
  --route-tabset-tab-text-font-weight: 500;
  --route-tabset-tab-text-line-height: 1.375rem;
  --route-tabset-tab-border-radius: 0.5rem 0.5rem 0 0;
  --route-tabset-tab-active-background-color: var(--text-basic-color);
  --route-tabset-tab-active-text-color: var(--text-button-filled-color);
  --route-tabset-tab-focus-background-color: var(--route-tabset-tab-active-background-color);
  --route-tabset-tab-focus-text-color: var(--route-tabset-tab-active-text-color);
  --route-tabset-tab-hover-background-color: var(--color-cool-gray-20);
  --route-tabset-tab-hover-text-color: var(--text-basic-color);
  --route-tabset-tab-disabled-background-color: var(--route-tabset-tab-background-color);
  --route-tabset-tab-disabled-text-color: var(--text-disabled-color);
  --route-tabset-divider-color: var(--color-cyan-30);
  --route-tabset-divider-style: var(--divider-style);
  --route-tabset-divider-width: var(--divider-width);
  --route-tabset-scrollbar-color: var(--scrollbar-color);
  --route-tabset-scrollbar-background-color: var(--scrollbar-background-color);
  --route-tabset-scrollbar-width: var(--scrollbar-width);
  --tooltip-background-color: var(--color-gray-100);
  --tooltip-border-radius: 0.25rem;
  --tooltip-padding: 0.5rem 0.75rem;
  --tooltip-text-color: var(--text-button-filled-color);
  --tooltip-text-font-size: 0.875rem;
  --tooltip-text-line-height: var(--text-paragraph-2-line-height);
  --tooltip-icon-height: 1rem;
  --tooltip-icon-width: 1rem;
  --tooltip-max-width: 18.75rem;
  --popover-text-color: var(--text-basic-color);
  --popover-background-color: var(--card-background-color);
  --popover-border-radius: var(--tooltip-border-radius);
  --popover-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  --popover-padding: var(--tooltip-padding);
  --color-plum-40: #e6d6ea;
  --color-plum-60: #c7a2cc;
  --color-plum-100: #563d82;
  --color-plum-tranparent-40: rgba(230, 214, 234, 0.25);
  --color-plum-tranparent-60: rgba(199, 162, 204, 0.25);
  --color-plum-tranparent-100: rgba(86, 61, 130, 0.25);
  --color-purple-20: #e3deff;
  --color-purple-30: #dbd3f4;
  --color-purple-40: #c7bdff;
  --color-purple-50: #b8a7e9;
  --color-purple-80: #806bff;
  --color-purple-100: #5f38ce;
  --color-purple-transparent-20: rgba(227, 222, 255, 0.25);
  --color-purple-transparent-30: rgba(219, 211, 244, 0.25);
  --color-purple-transparent-40: rgba(199, 189, 255, 0.25);
  --color-purple-transparent-50: rgba(184, 167, 233, 0.25);
  --color-purple-transparent-80: rgba(128, 107, 255, 0.25);
  --color-purple-transparent-100: rgba(95, 56, 206, 0.25);
  --color-cyan-10: #f1f8fb;
  --color-cyan-20: #c7d9e5;
  --color-cyan-30: #abc6d8;
  --color-cyan-40: #8fb3cb;
  --color-cyan-50: #73a0be;
  --color-cyan-60: #578db1;
  --color-cyan-70: #477799;
  --color-cyan-80: #3a627d;
  --color-cyan-90: #2d4c61;
  --color-cyan-100: #203645;
  --color-cyan-transparent-10: rgba(241, 248, 251, 0.25);
  --color-cyan-transparent-20: rgba(199, 217, 229, 0.25);
  --color-cyan-transparent-30: rgba(171, 198, 216, 0.25);
  --color-cyan-transparent-40: rgba(143, 179, 203, 0.25);
  --color-cyan-transparent-50: rgba(115, 160, 190, 0.25);
  --color-cyan-transparent-60: rgba(87, 141, 177, 0.25);
  --color-cyan-transparent-70: rgba(71, 119, 153, 0.25);
  --color-cyan-transparent-80: rgba(58, 98, 125, 0.25);
  --color-cyan-transparent-90: rgba(45, 76, 97, 0.25);
  --color-cyan-transparent-100: rgba(33, 55, 69, 0.25);
  --color-blue-10: #e3f7fd;
  --color-blue-20: #8edff9;
  --color-blue-30: #55daff;
  --color-blue-40: #00ccff;
  --color-blue-50: #00bbff;
  --color-blue-60: #04a5f5;
  --color-blue-70: #0097ff;
  --color-blue-80: #0470ec;
  --color-blue-81: #0475f5;
  --color-blue-90: #1362e2;
  --color-blue-100: #1d41c3;
  --color-blue-transparent-10: rgba(227, 247, 253, 0.25);
  --color-blue-transparent-20: rgba(142, 223, 249, 0.25);
  --color-blue-transparent-30: rgba(85, 218, 255, 0.25);
  --color-blue-transparent-40: rgba(0, 204, 255, 0.25);
  --color-blue-transparent-50: rgba(0, 187, 255, 0.25);
  --color-blue-transparent-60: rgba(4, 165, 245, 0.25);
  --color-blue-transparent-70: rgba(0, 151, 255, 0.25);
  --color-blue-transparent-80: rgba(4, 112, 236, 0.25);
  --color-blue-transparent-81: rgba(4, 117, 245, 0.25);
  --color-blue-transparent-90: rgba(19, 98, 226, 0.25);
  --color-blue-transparent-100: rgba(29, 65, 195, 0.25);
  --color-teal-10: #e0f9f7;
  --color-teal-20: #dff7f3;
  --color-teal-30: #d0efef;
  --color-teal-40: #c1f4ef;
  --color-teal-50: #aeeae2;
  --color-teal-60: #73e6dc;
  --color-teal-70: #6dd9ca;
  --color-teal-80: #9bd4d6;
  --color-teal-90: #59d8d8;
  --color-teal-100: #259f9f;
  --color-teal-transparent-10: rgba(224, 249, 247, 0.25);
  --color-teal-transparent-20: rgba(223, 247, 243, 0.25);
  --color-teal-transparent-30: rgba(208, 239, 239, 0.25);
  --color-teal-transparent-40: rgba(193, 244, 239, 0.25);
  --color-teal-transparent-50: rgba(174, 234, 226, 0.25);
  --color-teal-transparent-60: rgba(115, 230, 220, 0.25);
  --color-teal-transparent-70: rgba(109, 217, 202, 0.25);
  --color-teal-transparent-80: rgba(155, 212, 214, 0.25);
  --color-teal-transparent-90: rgba(89, 216, 216, 0.25);
  --color-teal-transparent-100: rgba(37, 159, 159, 0.25);
  --color-green-10: #f0fff1;
  --color-green-20: #d6ffda;
  --color-green-30: #bff2c4;
  --color-green-40: #a0e9a7;
  --color-green-50: #8de39b;
  --color-green-60: #7bf297;
  --color-green-70: #59d977;
  --color-green-80: #28bf4b;
  --color-green-90: #12a635;
  --color-green-100: #01801f;
  --color-green-transparent-10: rgba(240, 255, 241, 0.25);
  --color-green-transparent-20: rgba(214, 255, 218, 0.25);
  --color-green-transparent-30: rgba(191, 242, 196, 0.25);
  --color-green-transparent-40: rgba(160, 233, 167, 0.25);
  --color-green-transparent-50: rgba(141, 227, 155, 0.25);
  --color-green-transparent-60: rgba(123, 242, 151, 0.25);
  --color-green-transparent-70: rgba(89, 217, 119, 0.25);
  --color-green-transparent-80: rgba(40, 191, 75, 0.25);
  --color-green-transparent-90: rgba(18, 166, 53, 0.25);
  --color-green-transparent-100: rgba(1, 128, 31, 0.25);
  --color-gold-10: #fff7e3;
  --color-gold-20: #fff3de;
  --color-gold-30: #ffefc8;
  --color-gold-40: #fbe2b9;
  --color-gold-50: #ffdc83;
  --color-gold-60: #fed78e;
  --color-gold-70: #ffd469;
  --color-gold-80: #ffcb69;
  --color-gold-90: #fdc34e;
  --color-gold-100: #ffbf3f;
  --color-gold-transparent-10: rgba(255, 248, 229, 0.25);
  --color-gold-transparent-20: rgba(255, 243, 222, 0.25);
  --color-gold-transparent-30: rgba(255, 239, 200, 0.25);
  --color-gold-transparent-40: rgba(251, 226, 185, 0.25);
  --color-gold-transparent-50: rgba(255, 220, 131, 0.25);
  --color-gold-transparent-60: rgba(254, 215, 142, 0.25);
  --color-gold-transparent-70: rgba(255, 212, 105, 0.25);
  --color-gold-transparent-80: rgba(255, 203, 105, 0.25);
  --color-gold-transparent-90: rgba(253, 195, 78, 0.25);
  --color-gold-transparent-100: rgba(255, 191, 63, 0.25);
  --color-orange-10: #fff0e5;
  --color-orange-20: #ffe0cc;
  --color-orange-30: #ffd1b2;
  --color-orange-40: #f5bb94;
  --color-orange-50: #f0a878;
  --color-orange-60: #f19255;
  --color-orange-70: #ed7a23;
  --color-orange-80: #d46d20;
  --color-orange-90: #a14a08;
  --color-orange-100: #873a00;
  --color-orange-transparent-10: rgba(255, 240, 229, 0.25);
  --color-orange-transparent-20: rgba(255, 224, 204, 0.25);
  --color-orange-transparent-30: rgba(255, 209, 178, 0.25);
  --color-orange-transparent-40: rgba(245, 187, 148, 0.25);
  --color-orange-transparent-50: rgba(240, 168, 120, 0.25);
  --color-orange-transparent-60: rgba(241, 146, 85, 0.25);
  --color-orange-transparent-70: rgba(237, 122, 35, 0.25);
  --color-orange-transparent-80: rgba(212, 109, 32, 0.25);
  --color-orange-transparent-90: rgba(161, 74, 8, 0.25);
  --color-orange-transparent-100: rgba(135, 58, 0, 0.25);
  --color-salmon-10: #ffe7df;
  --color-salmon-20: #ffe3da;
  --color-salmon-30: #ffd0c0;
  --color-salmon-40: #ffb9a2;
  --color-salmon-50: #ff9571;
  --color-salmon-60: #ff8157;
  --color-salmon-transparent-10: rgba(255, 231, 223, 0.25);
  --color-salmon-transparent-20: rgba(255, 227, 218, 0.25);
  --color-salmon-transparent-30: rgba(255, 208, 192, 0.25);
  --color-salmon-transparent-40: rgba(255, 185, 162, 0.25);
  --color-salmon-transparent-50: rgba(255, 149, 113, 0.25);
  --color-salmon-transparent-60: rgba(255, 129, 87, 0.25);
  --color-pink-10: #ffe3f3;
  --color-pink-20: #ffc7e7;
  --color-pink-30: #ffb3d7;
  --color-pink-40: #ff82c9;
  --color-pink-50: #ff61a1;
  --color-pink-60: #ff54a5;
  --color-pink-70: #ff5288;
  --color-pink-80: #ff477b;
  --color-pink-90: #e94768;
  --color-pink-100: #e63257;
  --color-pink-transparent-10: rgba(255, 227, 243, 0.25);
  --color-pink-transparent-20: rgba(255, 199, 231, 0.25);
  --color-pink-transparent-30: rgba(255, 179, 215, 0.25);
  --color-pink-transparent-40: rgba(255, 130, 201, 0.25);
  --color-pink-transparent-50: rgba(255, 97, 161, 0.25);
  --color-pink-transparent-60: rgba(255, 84, 165, 0.25);
  --color-pink-transparent-70: rgba(255, 82, 136, 0.25);
  --color-pink-transparent-80: rgba(255, 71, 123, 0.25);
  --color-pink-transparent-90: rgba(255, 56, 97, 0.25);
  --color-pink-transparent-100: rgba(240, 52, 91, 0.25);
  --color-red-10: #ffe3e6;
  --color-red-20: #ffd9eb;
  --color-red-30: #ffc7ce;
  --color-red-40: #ffabb5;
  --color-red-50: #ff82a0;
  --color-red-60: #ff6e8b;
  --color-red-70: #ff5772;
  --color-red-80: #e94768;
  --color-red-90: #e63257;
  --color-red-100: #bd2947;
  --color-red-transparent-10: rgba(255, 227, 230, 0.25);
  --color-red-transparent-20: rgba(255, 217, 235, 0.25);
  --color-red-transparent-30: rgba(255, 199, 206, 0.25);
  --color-red-transparent-40: rgba(255, 171, 181, 0.25);
  --color-red-transparent-50: rgba(255, 130, 160, 0.25);
  --color-red-transparent-60: rgba(255, 110, 139, 0.25);
  --color-red-transparent-70: rgba(255, 87, 114, 0.25);
  --color-red-transparent-80: rgba(233, 71, 104, 0.25);
  --color-red-transparent-90: rgba(230, 50, 87, 0.25);
  --color-red-transparent-100: rgba(189, 41, 7, 0.25);
  --color-gray-10: #fcfcfc;
  --color-gray-20: #f7f7f7;
  --color-gray-30: #efefef;
  --color-gray-40: #dee1e3;
  --color-gray-50: #cdd1d3;
  --color-gray-60: #b0b4b5;
  --color-gray-70: #596063;
  --color-gray-80: #33393d;
  --color-gray-90: #181830;
  --color-gray-100: #04041c;
  --color-gray-transparent-10: rgba(252, 252, 252, 0.25);
  --color-gray-transparent-20: rgba(247, 247, 247, 0.25);
  --color-gray-transparent-30: rgba(239, 239, 239, 0.25);
  --color-gray-transparent-40: rgba(222, 225, 227, 0.25);
  --color-gray-transparent-50: rgba(205, 209, 211, 0.25);
  --color-gray-transparent-60: rgba(176, 180, 181, 0.25);
  --color-gray-transparent-70: rgba(89, 96, 99, 0.25);
  --color-gray-transparent-80: rgba(51, 57, 61, 0.25);
  --color-gray-transparent-90: rgba(24, 24, 48, 0.25);
  --color-gray-transparent-100: rgba(4, 4, 28, 0.25);
  --color-cool-gray-10: #fcfcfe;
  --color-cool-gray-20: #f5f7f7;
  --color-cool-gray-30: #f0f3f7;
  --color-cool-gray-40: #d2d0df;
  --color-cool-gray-50: #bcbad5;
  --color-cool-gray-60: #6b747d;
  --color-cool-gray-70: #464765;
  --color-cool-gray-80: #203645;
  --color-cool-gray-90: #121227;
  --color-cool-gray-100: #04041c;
  --color-cool-gray-transparent-10: rgba(252, 252, 254, 0.25);
  --color-cool-gray-transparent-20: rgba(245, 247, 247, 0.25);
  --color-cool-gray-transparent-30: rgba(240, 243, 247, 0.25);
  --color-cool-gray-transparent-40: rgba(210, 208, 223, 0.25);
  --color-cool-gray-transparent-50: rgba(188, 186, 213, 0.25);
  --color-cool-gray-transparent-60: rgba(107, 116, 125, 0.25);
  --color-cool-gray-transparent-70: rgba(70, 71, 101, 0.25);
  --color-cool-gray-transparent-80: rgba(32, 54, 69, 0.25);
  --color-cool-gray-transparent-90: rgba(18, 18, 39, 0.25);
  --color-cool-gray-transparent-100: rgba(4, 4, 28, 0.25);
  --color-transparent: transparent;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-disabled: var(--color-gray-60);
  --color-border-disabled: var(--color-gray-60);
  --color-primary-default: var(--color-blue-90);
  --color-primary-focus: var(--color-blue-70);
  --color-primary-hover: var(--color-blue-80);
  --color-primary-active: var(--color-blue-100);
  --color-primary-outline: var(--color-blue-transparent-90);
  --color-danger-default: var(--color-red-90);
  --color-danger-focus: var(--color-pink-100);
  --color-danger-hover: var(--color-red-70);
  --color-danger-active: var(--color-red-100);
  --color-danger-outline: var(--color-red-transparent-90);
  --font-family-primary: Inter, sans-serif;
  --font-family-secondary: var(--font-family-primary);
  --text-heading-1-font-family: var(--font-family-primary);
  --text-heading-1-font-size: 3.75rem;
  --text-heading-1-font-weight: 700;
  --text-heading-1-line-height: 4.375rem;
  --text-heading-2-font-family: var(--font-family-primary);
  --text-heading-2-font-size: 2.625rem;
  --text-heading-2-font-weight: 700;
  --text-heading-2-line-height: 3.25rem;
  --text-heading-3-font-family: var(--font-family-primary);
  --text-heading-3-font-size: 2rem;
  --text-heading-3-font-weight: 500;
  --text-heading-3-line-height: 2.75rem;
  --text-heading-4-font-family: var(--font-family-primary);
  --text-heading-4-font-size: 1.75rem;
  --text-heading-4-font-weight: 700;
  --text-heading-4-line-height: 2.25rem;
  --text-heading-5-font-family: var(--font-family-primary);
  --text-heading-5-font-size: 1.25rem;
  --text-heading-5-font-weight: 700;
  --text-heading-5-line-height: 1.75rem;
  --text-heading-6-font-family: var(--font-family-primary);
  --text-heading-6-font-size: 1.125rem;
  --text-heading-6-font-weight: 700;
  --text-heading-6-line-height: 1.5625rem;
  --text-subtitle-color: var(--text-basic-color);
  --text-subtitle-font-family: var(--font-family-primary);
  --text-subtitle-font-size: 0.875rem;
  --text-subtitle-font-weight: 400;
  --text-subtitle-line-height: 1.5rem;
  --text-subtitle-2-color: var(--text-hint-color);
  --text-subtitle-2-font-family: var(--font-family-primary);
  --text-subtitle-2-font-size: 0.875rem;
  --text-subtitle-2-font-weight: 400;
  --text-subtitle-2-line-height: 1.0625rem;
  --text-paragraph-font-family: var(--font-family-primary);
  --text-paragraph-font-size: 1rem;
  --text-paragraph-font-weight: 400;
  --text-paragraph-line-height: 1.25rem;
  --text-paragraph-2-font-family: var(--font-family-primary);
  --text-paragraph-2-font-size: 0.8125rem;
  --text-paragraph-2-font-weight: 400;
  --text-paragraph-2-line-height: 1.125rem;
  --text-label-font-family: var(--font-family-primary);
  --text-label-font-size: 0.75rem;
  --text-label-font-weight: 700;
  --text-label-line-height: 1rem;
  --text-caption-font-family: var(--font-family-primary);
  --text-caption-font-size: 0.75rem;
  --text-caption-font-weight: 400;
  --text-caption-line-height: 1rem;
  --text-caption-2-font-family: var(--font-family-primary);
  --text-caption-2-font-size: 0.75rem;
  --text-caption-2-font-weight: 600;
  --text-caption-2-line-height: 1rem;
  --text-button-font-family: var(--font-family-primary);
  --text-button-font-weight: 700;
  --text-button-font-size: 1rem;
  --text-button-line-height: 1.5rem;
  --text-button-filled-color: var(--color-gray-10);
  --text-basic-color: var(--color-cool-gray-80);
  --text-disabled-color: var(--color-gray-60);
  --text-hint-color: var(--color-cool-gray-60);
  --outline-width: 0.375rem;
  --outline-color: var(--color-transparent);
  --scrollbar-color: var(--color-gray-50);
  --scrollbar-background-color: var(--color-gray-30);
  --scrollbar-width: 0.5rem;
  --shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  --divider-color: var(--color-gray-40);
  --divider-style: solid;
  --divider-width: 1px;
  --transition-duration-easing: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.cj-theme-vega-light {
  --link-text-color: var(--color-primary-default);
  --link-text-decoration: underline;
  --link-text-focus-color: text-primary-focus-color;
  --link-text-hover-color: text-primary-hover-color;
  --overlay-backdrop-background-color: rgba(0, 0, 0, 0.35);
  --layout-background-color: var(--color-transparent);
  --layout-text-color: var(--text-basic-color);
  --layout-text-font-family: var(--text-paragraph-font-family);
  --layout-text-font-size: var(--text-paragraph-font-size);
  --layout-text-font-weight: var(--text-paragraph-font-weight);
  --layout-text-line-height: var(--text-paragraph-line-height);
  --layout-min-height: 100vh;
  --layout-content-width: 900px;
  --layout-window-mode-min-width: 300px;
  --layout-window-mode-background-color: background-basic-color-3;
  --layout-window-mode-padding-top: 4.75rem;
  --layout-window-shadow: var(--shadow);
  --layout-padding: 2rem;
  --layout-medium-padding: 1.5rem 1.5rem 0.5rem;
  --layout-small-padding: 1rem 1rem 0;
  --layout-scrollbar-background-color: var(--scrollbar-background-color);
  --layout-scrollbar-color: var(--scrollbar-color);
  --layout-scrollbar-width: var(--scrollbar-width);
  --header-background-color: rgba(240, 243, 247, 0.88);
  --header-text-color: var(--color-gray-90);
  --header-text-font-family: var(--text-paragraph-font-family);
  --header-text-font-size: var(--text-paragraph-font-size);
  --header-text-font-weight: var(--text-paragraph-font-weight);
  --header-text-line-height: var(--text-paragraph-line-height);
  --header-height: 7.375rem;
  --header-padding: 1.75rem 2rem;
  --header-fixed-height: 5rem;
  --header-fixed-padding: 1.25rem 1.875rem;
  --header-fixed-background-color: var(--color-white);
  --header-border-bottom-width: 0.0625rem;
  --header-border-bottom-style: solid;
  --header-border-bottom-color: var(--color-gray-40);
  --footer-background-color: var(--color-white);
  --footer-text-color: var(--color-gray-90);
  --footer-text-font-family: var(--text-paragraph-font-family);
  --footer-text-font-size: var(--text-paragraph-font-size);
  --footer-text-font-weight: var(--text-paragraph-font-weight);
  --footer-text-line-height: var(--text-paragraph-line-height);
  --footer-text-highlight-color: var(--color-primary-hover);
  --footer-height: 4.725rem;
  --footer-padding: 1.25rem;
  --footer-divider-color: var(--divider-color);
  --footer-divider-style: var(--divider-style);
  --footer-divider-width: var(--divider-width);
  --footer-shadow: var(--shadow);
  --sidebar-scrollbar-background-color: var(--scrollbar-background-color);
  --sidebar-scrollbar-color: var(--scrollbar-color);
  --sidebar-scrollbar-width: 0.25rem;
  --sidebar-background-color: var(--color-white);
  --sidebar-border-right-width: 0.0625rem;
  --sidebar-border-right-style: solid;
  --sidebar-border-right-color: var(--color-gray-40);
  --sidebar-height: 100vh;
  --sidebar-width: 15.625rem;
  --sidebar-width-compact: 4.25rem;
  --sidebar-nav-padding: 0 0 1.5rem;
  --sidebar-nav-compact-padding: 1rem 0.5rem 1.5rem;
  --sidebar-header-height: 6.25rem;
  --sidebar-header-padding: 1.5rem 0.75rem 1rem;
  --sidebar-header-margin: 0 0.75rem;
  --sidebar-header-border-color: var(--color-cyan-30);
  --sidebar-header-compact-border-color: transparent;
  --sidebar-header-compact-padding: 1.5rem 0.75rem 1rem;
  --sidebar-header-compact-margin: 0;
  --sidebar-footer-height: 3rem;
  --sidebar-footer-font-size: 0.75rem;
  --sidebar-footer-padding: 0.75rem;
  --sidebar-footer-margin: 0 0.75rem;
  --sidebar-footer-border-color: var(--color-cyan-30);
  --sidebar-footer-compact-border-color: transparent;
  --sidebar-footer-compact-padding: 0.75rem;
  --sidebar-footer-compact-margin: 0;
  --sidebar-shadow: 0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.08), 0px 6px 40px rgba(2, 2, 15, 0.04);
  --menu-background-color: transparent;
  --menu-text-color: var(--text-basic-color);
  --menu-text-font-weight: 700;
  --menu-group-text-font-weight: 400;
  --menu-group-text-font-size: var(--text-subtitle-font-size);
  --menu-group-text-color: var(--text-hint-color);
  --menu-group-padding: 0.5rem 0.625rem;
  --menu-group-text-transform: uppercase;
  --menu-item-expanded-border-radius: 0.5rem 0.5rem 0 0;
  --menu-item-border-radius: 0.5rem;
  --menu-item-border-width: 0.125rem;
  --menu-item-border-style: solid;
  --menu-item-border-color: transparent;
  --menu-item-padding: 0.75rem;
  --menu-item-compact-padding: 0.75rem;
  --menu-item-with-icon-padding: 0.75rem;
  --menu-item-hover-cursor: pointer;
  --menu-item-hover-background-color: var(--color-cool-gray-20);
  --menu-item-hover-text-color: var(--color-primary-default);
  --menu-item-icon-hover-color: var(--color-primary-default);
  --menu-item-icon-hover-background-color: transparent;
  --menu-item-active-background-color: var(--color-blue-10);
  --menu-item-active-text-color: var(--color-primary-default);
  --menu-item-icon-active-background-color: transparent;
  --menu-item-icon-active-color: var(--color-primary-default);
  --menu-item-active-border-color: var(--color-blue-10);
  --menu-item-focus-border-color: transparent;
  --menu-item-icon-color: var(--text-hint-color);
  --menu-item-icon-margin: 0 0.5rem 0 0;
  --menu-item-icon-width: 1.5rem;
  --menu-submenu-background-color: var(--color-cool-gray-30);
  --menu-submenu-border-radius: 0 0 0.5rem 0.5rem;
  --menu-submenu-padding: 1.5rem;
  --menu-submenu-margin: 0;
  --menu-submenu-item-padding: 0.5rem 0.75rem 0.5rem 1.5rem;
  --menu-submenu-text-font-weight: 700;
  --list-item-divider-color: var(--divider-color);
  --list-item-divider-style: var(--divider-style);
  --list-item-divider-width: var(--divider-width);
  --list-item-padding: 1rem;
  --list-item-text-color: var(--text-basic-color);
  --list-item-font-family: var(--text-paragraph-font-family);
  --list-item-font-size: var(--text-paragraph-font-size);
  --list-item-font-weight: var(--text-paragraph-font-weight);
  --list-item-line-height: var(--text-paragraph-line-height);
  --button-cursor: pointer;
  --button-border-radius: 9999px;
  --button-small-padding: 0.125rem 1rem;
  --button-medium-padding: 0.375rem 1.5rem;
  --button-large-padding: 0.875rem 2em;
  --button-text-font-family: var(--text-button-font-family);
  --button-text-font-weight: var(--text-button-font-weight);
  --button-text-font-size: var(--text-button-font-size);
  --button-text-line-height: var(--text-button-line-height);
  --button-disabled-cursor: default;
  --button-small-icon-size: 1rem;
  --button-small-icon-vertical-margin: -0.125rem;
  --button-small-icon-offset: 0.375rem;
  --button-medium-icon-size: 1.25rem;
  --button-medium-icon-vertical-margin: -0.125rem;
  --button-medium-icon-offset: 0.5rem;
  --button-large-icon-size: 1.5rem;
  --button-large-icon-vertical-margin: -0.125rem;
  --button-large-icon-offset: 0.75rem;
  --button-filled-border-style: solid;
  --button-filled-border-width: 0.125rem;
  --button-filled-text-transform: none;
  --button-filled-primary-background-color: var(--color-primary-default);
  --button-filled-primary-border-color: var(--color-primary-default);
  --button-filled-primary-text-color: var(--text-button-filled-color);
  --button-filled-primary-focus-background-color: var(--color-primary-focus);
  --button-filled-primary-focus-border-color: var(--color-primary-focus);
  --button-filled-primary-hover-background-color: var(--color-primary-hover);
  --button-filled-primary-hover-border-color: var(--color-primary-hover);
  --button-filled-primary-active-background-color: var(--color-primary-active);
  --button-filled-primary-active-border-color: var(--color-primary-active);
  --button-filled-primary-disabled-background-color: var(--color-disabled);
  --button-filled-primary-disabled-border-color: var(--color-disabled);
  --button-filled-primary-disabled-text-color: var(--text-button-filled-color);
  --button-filled-primary-outline-color: var(--color-primary-outline);
  --button-filled-success-background-color: var(--color-green-40);
  --button-filled-success-border-color: var(--color-green-40);
  --button-filled-success-text-color: var(--text-button-filled-color);
  --button-filled-success-focus-background-color: var(--color-green-50);
  --button-filled-success-focus-border-color: var(--color-green-50);
  --button-filled-success-hover-background-color: var(--color-green-30);
  --button-filled-success-hover-border-color: var(--color-green-30);
  --button-filled-success-active-background-color: var(--color-green-70);
  --button-filled-success-active-border-color: var(--color-green-70);
  --button-filled-success-disabled-background-color: var(--color-disabled);
  --button-filled-success-disabled-border-color: var(--color-disabled);
  --button-filled-success-disabled-text-color: var(--text-button-filled-color);
  --button-filled-success-outline-color: var(--color-green-transparent-40);
  --button-filled-info-background-color: var(--color-gray-50);
  --button-filled-info-border-color: var(--color-gray-50);
  --button-filled-info-text-color: var(--text-button-filled-color);
  --button-filled-info-focus-background-color: var(--color-cool-gray-50);
  --button-filled-info-focus-border-color: var(--color-cool-gray-50);
  --button-filled-info-hover-background-color: var(--color-gray-40);
  --button-filled-info-hover-border-color: var(--color-gray-40);
  --button-filled-info-active-background-color: var(--color-gray-60);
  --button-filled-info-active-border-color: var(--color-gray-60);
  --button-filled-info-disabled-background-color: var(--color-disabled);
  --button-filled-info-disabled-border-color: var(--color-disabled);
  --button-filled-info-disabled-text-color: var(--text-button-filled-color);
  --button-filled-info-outline-color: var(--color-gray-transparent-50);
  --button-filled-warning-background-color: var(--color-gold-50);
  --button-filled-warning-border-color: var(--color-gold-50);
  --button-filled-warning-text-color: var(--text-button-filled-color);
  --button-filled-warning-focus-background-color: var(--color-gold-60);
  --button-filled-warning-focus-border-color: var(--color-gold-60);
  --button-filled-warning-hover-background-color: var(--color-gold-40);
  --button-filled-warning-hover-border-color: var(--color-gold-40);
  --button-filled-warning-active-background-color: var(--color-gold-100);
  --button-filled-warning-active-border-color: var(--color-gold-100);
  --button-filled-warning-disabled-background-color: var(--color-disabled);
  --button-filled-warning-disabled-border-color: var(--color-disabled);
  --button-filled-warning-disabled-text-color: var(--text-button-filled-color);
  --button-filled-warning-outline-color: var(--color-gold-transparent-50);
  --button-filled-danger-background-color: var(--color-danger-default);
  --button-filled-danger-border-color: var(--color-danger-default);
  --button-filled-danger-text-color: var(--text-button-filled-color);
  --button-filled-danger-focus-background-color: var(--color-danger-focus);
  --button-filled-danger-focus-border-color: var(--color-danger-focus);
  --button-filled-danger-hover-background-color: var(--color-danger-hover);
  --button-filled-danger-hover-border-color: var(--color-danger-hover);
  --button-filled-danger-active-background-color: var(--color-danger-active);
  --button-filled-danger-active-border-color: var(--color-danger-active);
  --button-filled-danger-disabled-background-color: var(--color-disabled);
  --button-filled-danger-disabled-border-color: var(--color-disabled);
  --button-filled-danger-disabled-text-color: var(--text-button-filled-color);
  --button-filled-danger-outline-color: var(--color-danger-outline);
  --button-outline-border-style: solid;
  --button-outline-border-width: 0.125rem;
  --button-outline-text-transform: none;
  --button-outline-primary-background-color: var(--color-transparent);
  --button-outline-primary-border-color: var(--color-primary-default);
  --button-outline-primary-text-color: var(--color-primary-default);
  --button-outline-primary-focus-background-color: var(--color-transparent);
  --button-outline-primary-focus-border-color: var(--color-primary-default);
  --button-outline-primary-focus-text-color: var(--color-primary-default);
  --button-outline-primary-hover-background-color: var(--color-transparent);
  --button-outline-primary-hover-border-color: var(--color-primary-hover);
  --button-outline-primary-hover-text-color: var(--color-primary-default);
  --button-outline-primary-active-background-color: var(--color-transparent);
  --button-outline-primary-active-border-color: var(--color-primary-active);
  --button-outline-primary-active-text-color: var(--color-primary-active);
  --button-outline-primary-disabled-background-color: var(--color-transparent);
  --button-outline-primary-disabled-border-color: var(--color-border-disabled);
  --button-outline-primary-disabled-text-color: var(--color-disabled);
  --button-outline-primary-outline-color: var(--color-primary-outline);
  --button-outline-success-background-color: var(--color-transparent);
  --button-outline-success-border-color: var(--color-green-40);
  --button-outline-success-text-color: var(--color-green-40);
  --button-outline-success-focus-background-color: var(--color-transparent);
  --button-outline-success-focus-border-color: var(--color-green-50);
  --button-outline-success-focus-text-color: var(--color-green-50);
  --button-outline-success-hover-background-color: var(--color-transparent);
  --button-outline-success-hover-border-color: var(--color-green-30);
  --button-outline-success-hover-text-color: var(--color-green-40);
  --button-outline-success-active-background-color: var(--color-transparent);
  --button-outline-success-active-border-color: var(--color-green-70);
  --button-outline-success-active-text-color: var(--color-green-70);
  --button-outline-success-disabled-background-color: var(--color-transparent);
  --button-outline-success-disabled-border-color: var(--color-border-disabled);
  --button-outline-success-disabled-text-color: var(--color-disabled);
  --button-outline-success-outline-color: var(--color-green-transparent-40);
  --button-outline-info-background-color: var(--color-transparent);
  --button-outline-info-border-color: var(--color-gray-50);
  --button-outline-info-text-color: var(--color-gray-50);
  --button-outline-info-focus-background-color: var(--color-transparent);
  --button-outline-info-focus-border-color: var(--color-cool-gray-50);
  --button-outline-info-focus-text-color: var(--color-cool-gray-50);
  --button-outline-info-hover-background-color: var(--color-transparent);
  --button-outline-info-hover-border-color: var(--color-gray-40);
  --button-outline-info-hover-text-color: var(--color-gray-50);
  --button-outline-info-active-background-color: var(--color-transparent);
  --button-outline-info-active-border-color: var(--color-gray-60);
  --button-outline-info-active-text-color: var(--color-gray-60);
  --button-outline-info-disabled-background-color: var(--color-transparent);
  --button-outline-info-disabled-border-color: var(--color-border-disabled);
  --button-outline-info-disabled-text-color: var(--color-disabled);
  --button-outline-info-outline-color: var(--color-gray-transparent-50);
  --button-outline-warning-background-color: var(--color-transparent);
  --button-outline-warning-border-color: var(--color-gold-50);
  --button-outline-warning-text-color: var(--color-gold-50);
  --button-outline-warning-focus-background-color: var(--color-transparent);
  --button-outline-warning-focus-border-color: var(--color-gold-60);
  --button-outline-warning-focus-text-color: var(--color-gold-60);
  --button-outline-warning-hover-background-color: var(--color-transparent);
  --button-outline-warning-hover-border-color: var(--color-gold-40);
  --button-outline-warning-hover-text-color: var(--color-gold-50);
  --button-outline-warning-active-background-color: var(--color-transparent);
  --button-outline-warning-active-border-color: var(--color-gold-100);
  --button-outline-warning-active-text-color: var(--color-gold-100);
  --button-outline-warning-disabled-background-color: var(--color-transparent);
  --button-outline-warning-disabled-border-color: var(--color-border-disabled);
  --button-outline-warning-disabled-text-color: var(--color-disabled);
  --button-outline-warning-outline-color: var(--color-gold-transparent-50);
  --button-outline-danger-background-color: var(--color-transparent);
  --button-outline-danger-border-color: var(--color-danger-default);
  --button-outline-danger-text-color: var(--color-danger-default);
  --button-outline-danger-focus-background-color: var(--color-transparent);
  --button-outline-danger-focus-border-color: var(--color-danger-default);
  --button-outline-danger-focus-text-color: var(--color-danger-default);
  --button-outline-danger-hover-background-color: var(--color-transparent);
  --button-outline-danger-hover-border-color: var(--color-danger-hover);
  --button-outline-danger-hover-text-color: var(--color-red-80);
  --button-outline-danger-active-background-color: var(--color-transparent);
  --button-outline-danger-active-border-color: var(--color-danger-active);
  --button-outline-danger-active-text-color: var(--color-danger-active);
  --button-outline-danger-disabled-background-color: var(--color-transparent);
  --button-outline-danger-disabled-border-color: var(--color-border-disabled);
  --button-outline-danger-disabled-text-color: var(--color-disabled);
  --button-outline-danger-outline-color: var(--color-danger-outline);
  --icon-font-size: 1.75rem;
  --icon-line-height: 1;
  --icon-width: 1em;
  --icon-height: 1em;
  --icon-svg-vertical-align: middle;
  --icon-primary-color: var(--color-primary-default);
  --icon-info-color: var(--color-gray-50);
  --icon-success-color: var(--color-green-40);
  --icon-warning-color: var(--color-gold-50);
  --icon-danger-color: var(--color-danger-default);
  --icon-button-small-width: 2rem;
  --icon-button-small-padding: 0.5rem;
  --icon-button-medium-width: 2.5rem;
  --icon-button-medium-padding: 0.75rem;
  --icon-button-large-width: 3.5rem;
  --icon-button-large-padding: 1rem;
  --card-background-color: var(--color-white);
  --card-border-radius: 0.75rem;
  --card-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 2px 8px rgba(0, 0, 0, 0.04);
  --card-margin-bottom: 1.875rem;
  --card-padding: 1rem 1.5rem;
  --input-border-style: solid;
  --input-border-width: 0.0625rem;
  --input-border-color: var(--color-cyan-30);
  --input-hover-border-color: var(--color-cyan-50);
  --input-border-radius: 0.5rem;
  --input-placeholder-text-font-family: var(--text-paragraph-font-family);
  --input-placeholder-text-color: var(--text-disabled-color);
  --input-text-font-family: var(--text-paragraph-font-family);
  --input-text-color: var(--text-basic-color);
  --input-background-color: var(--color-gray-10);
  --input-disabled-background-color: var(--color-cool-gray-20);
  --input-disabled-border-color: var(--color-gray-40);
  --input-disabled-text-color: var(--text-hint-color);
  --input-primary-border-color: var(--color-primary-default);
  --input-primary-focus-border-color: var(--color-primary-focus);
  --input-primary-hover-border-color: var(--color-primary-hover);
  --input-primary-outline-color: var(--color-primary-outline);
  --input-success-border-color: var(--color-green-40);
  --input-success-focus-border-color: var(--color-green-50);
  --input-success-hover-border-color: var(--color-green-30);
  --input-success-outline-color: var(--color-green-transparent-40);
  --input-info-border-color: var(--color-gray-50);
  --input-info-focus-border-color: var(--color-cool-gray-50);
  --input-info-hover-border-color: var(--color-gray-40);
  --input-info-outline-color: var(--color-gray-transparent-50);
  --input-warning-border-color: var(--color-gold-50);
  --input-warning-focus-border-color: var(--color-gold-60);
  --input-warning-hover-border-color: var(--color-gold-40);
  --input-warning-outline-color: var(--color-gold-transparent-50);
  --input-danger-border-color: var(--color-danger-default);
  --input-danger-focus-border-color: var(--color-red-80);
  --input-danger-hover-border-color: var(--color-danger-hover);
  --input-danger-outline-color: var(--color-danger-outline);
  --input-small-text-font-size: var(--text-paragraph-font-size);
  --input-small-text-font-weight: var(--text-paragraph-font-weight);
  --input-small-text-line-height: var(--text-subtitle-line-height);
  --input-small-padding: 0.1875rem 0.75rem;
  --input-small-max-width: 18.75rem;
  --input-medium-text-font-size: var(--text-paragraph-font-size);
  --input-medium-text-font-weight: var(--text-paragraph-font-weight);
  --input-medium-text-line-height: var(--text-subtitle-line-height);
  --input-medium-padding: 0.4375rem 1rem;
  --input-medium-max-width: 18.75rem;
  --input-large-text-font-size: var(--text-paragraph-font-size);
  --input-large-text-font-weight: var(--text-paragraph-font-weight);
  --input-large-text-line-height: var(--text-subtitle-line-height);
  --input-large-padding: 0.9375rem 1.25rem;
  --input-large-max-width: 18.75rem;
  --form-field-small-max-width: var(--input-small-max-width);
  --form-field-medium-max-width: var(--input-medium-max-width);
  --form-field-large-max-width: var(--input-large-max-width);
  --form-field-addon-text-color: var(--text-hint-color);
  --form-field-addon-disabled-text-color: var(--text-hint-color);
  --form-field-addon-small-height: 2rem;
  --form-field-addon-small-width: 2.5rem;
  --form-field-addon-small-padding: 2.25rem;
  --form-field-addon-small-icon-size: var(--button-small-icon-size);
  --form-field-addon-small-font-size: var(--text-button-font-size);
  --form-field-addon-small-line-height: var(--text-button-line-height);
  --form-field-addon-medium-height: 2.5rem;
  --form-field-addon-medium-width: 2.75rem;
  --form-field-addon-medium-padding: 2.5rem;
  --form-field-addon-medium-icon-size: var(--button-medium-icon-size);
  --form-field-addon-medium-font-size: var(--text-button-font-size);
  --form-field-addon-medium-line-height: var(--text-button-line-height);
  --form-field-addon-large-height: 3.5rem;
  --form-field-addon-large-width: 3.75rem;
  --form-field-addon-large-padding: 3rem;
  --form-field-addon-large-icon-size: var(--button-large-icon-size);
  --form-field-addon-large-font-size: var(--text-button-font-size);
  --form-field-addon-large-line-height: var(--text-button-line-height);
  --checkbox-small-width: 1rem;
  --checkbox-small-text-font-size: 0.875rem;
  --checkbox-small-text-line-height: 1.125rem;
  --checkbox-small-text-space: 0.5rem;
  --checkbox-medium-width: 1.5rem;
  --checkbox-medium-text-font-size: 1rem;
  --checkbox-medium-text-line-height: 1.5rem;
  --checkbox-medium-text-space: 0.75rem;
  --checkbox-large-width: 2rem;
  --checkbox-large-text-font-size: 1.125rem;
  --checkbox-large-text-line-height: 1.875rem;
  --checkbox-large-text-space: 1rem;
  --checkbox-border-style: solid;
  --checkbox-border-width: 1px;
  --checkbox-border-radius: 0.25rem;
  --checkbox-text-font-weight: var(--text-paragraph-font-weight);
  --checkbox-text-color: var(--text-basic-color);
  --checkbox-background-color: var(--input-background-color);
  --checkbox-checked-checkmark-color: var(--input-background-color);
  --checkbox-padding: 0;
  --checkbox-disabled-background-color: var(--color-cool-gray-20);
  --checkbox-disabled-border-color: var(--color-gray-40);
  --checkbox-disabled-text-color: var(--text-disabled-color);
  --checkbox-disabled-checked-background-color: var(--color-disabled);
  --checkbox-disabled-checked-border-color: var(--checkbox-disabled-border-color);
  --checkbox-primary-border-color: var(--color-cyan-30);
  --checkbox-primary-checked-background-color: var(--color-primary-default);
  --checkbox-primary-checked-border-color: var(--color-cyan-30);
  --checkbox-primary-indeterminate-background-color: var(--color-primary-default);
  --checkbox-primary-indeterminate-border-color: var(--color-cyan-30);
  --checkbox-primary-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-primary-focus-border-color: var(--color-primary-default);
  --checkbox-primary-focus-checked-background-color: var(--color-primary-default);
  --checkbox-primary-focus-checked-border-color: var(--color-primary-default);
  --checkbox-primary-focus-outline-color: var(--color-primary-outline);
  --checkbox-primary-hover-border-color: var(--color-cyan-50);
  --checkbox-primary-hover-checked-background-color: var(--color-primary-default);
  --checkbox-primary-hover-checked-border-color: var(--color-cyan-50);
  --checkbox-primary-active-border-color: var(--color-primary-default);
  --checkbox-primary-active-checked-background-color: var(--color-primary-active);
  --checkbox-primary-active-checked-border-color: var(--color-primary-default);
  --checkbox-success-border-color: var(--color-green-40);
  --checkbox-success-checked-background-color: var(--color-green-40);
  --checkbox-success-checked-border-color: var(--color-green-40);
  --checkbox-success-indeterminate-background-color: var(--color-green-40);
  --checkbox-success-indeterminate-border-color: var(--color-green-40);
  --checkbox-success-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-success-focus-border-color: var(--color-green-40);
  --checkbox-success-focus-checked-background-color: var(--color-green-40);
  --checkbox-success-focus-checked-border-color: var(--color-green-40);
  --checkbox-success-focus-outline-color: var(--color-green-transparent-40);
  --checkbox-success-hover-border-color: var(--color-green-50);
  --checkbox-success-hover-checked-background-color: var(--color-green-40);
  --checkbox-success-hover-checked-border-color: var(--color-green-50);
  --checkbox-success-active-border-color: var(--color-green-40);
  --checkbox-success-active-checked-background-color: var(--color-green-70);
  --checkbox-success-active-checked-border-color: var(--color-green-40);
  --checkbox-info-border-color: var(--color-gray-50);
  --checkbox-info-checked-background-color: var(--color-gray-50);
  --checkbox-info-checked-border-color: var(--color-gray-50);
  --checkbox-info-indeterminate-background-color: var(--color-gray-50);
  --checkbox-info-indeterminate-border-color: var(--color-gray-50);
  --checkbox-info-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-info-focus-border-color: var(--color-gray-50);
  --checkbox-info-focus-checked-background-color: var(--color-gray-50);
  --checkbox-info-focus-checked-border-color: var(--color-gray-50);
  --checkbox-info-focus-outline-color: var(--color-gray-transparent-50);
  --checkbox-info-hover-border-color: var(--color-cool-gray-50);
  --checkbox-info-hover-checked-background-color: var(--color-gray-50);
  --checkbox-info-hover-checked-border-color: var(--color-cool-gray-50);
  --checkbox-info-active-border-color: var(--color-gray-50);
  --checkbox-info-active-checked-background-color: var(--color-gray-60);
  --checkbox-info-active-checked-border-color: var(--color-gray-50);
  --checkbox-warning-border-color: var(--color-gold-50);
  --checkbox-warning-checked-background-color: var(--color-gold-50);
  --checkbox-warning-checked-border-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-background-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-border-color: var(--color-gold-50);
  --checkbox-warning-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-warning-focus-border-color: var(--color-gold-50);
  --checkbox-warning-focus-checked-background-color: var(--color-gold-50);
  --checkbox-warning-focus-checked-border-color: var(--color-gold-50);
  --checkbox-warning-focus-outline-color: var(--color-gold-transparent-50);
  --checkbox-warning-hover-border-color: var(--color-gold-40);
  --checkbox-warning-hover-checked-background-color: var(--color-gold-50);
  --checkbox-warning-hover-checked-border-color: var(--color-gold-40);
  --checkbox-warning-active-border-color: var(--color-gold-50);
  --checkbox-warning-active-checked-background-color: var(--color-gold-100);
  --checkbox-warning-active-checked-border-color: var(--color-gold-50);
  --checkbox-danger-border-color: var(--color-danger-default);
  --checkbox-danger-checked-background-color: var(--color-danger-default);
  --checkbox-danger-checked-border-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-background-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-border-color: var(--color-danger-default);
  --checkbox-danger-indeterminate-checkmark-color: var(--input-background-color);
  --checkbox-danger-focus-border-color: var(--color-red-80);
  --checkbox-danger-focus-checked-background-color: var(--color-danger-default);
  --checkbox-danger-focus-checked-border-color: var(--color-red-80);
  --checkbox-danger-focus-outline-color: var(--color-danger-outline);
  --checkbox-danger-hover-border-color: var(--color-danger-hover);
  --checkbox-danger-hover-checked-background-color: var(--color-danger-default);
  --checkbox-danger-hover-checked-border-color: var(--color-danger-hover);
  --checkbox-danger-active-border-color: var(--color-red-80);
  --checkbox-danger-active-checked-background-color: var(--color-danger-active);
  --checkbox-danger-active-checked-border-color: var(--color-red-80);
  --radio-border-style: solid;
  --radio-border-width: 1px;
  --radio-small-width: 1rem;
  --radio-small-text-font-size: 0.875rem;
  --radio-small-text-line-height: 1.125rem;
  --radio-small-text-space: 0.5rem;
  --radio-medium-width: 1.5rem;
  --radio-medium-text-font-size: 1rem;
  --radio-medium-text-line-height: 1.5rem;
  --radio-medium-text-space: 0.75rem;
  --radio-large-width: 2rem;
  --radio-large-text-font-size: 1.125rem;
  --radio-large-text-line-height: 1.875rem;
  --radio-large-text-space: 1rem;
  --radio-text-font-weight: var(--text-paragraph-font-weight);
  --radio-text-color: var(--text-basic-color);
  --radio-background-color: var(--input-background-color);
  --radio-disabled-background-color: var(--color-cool-gray-20);
  --radio-disabled-border-color: var(--color-gray-40);
  --radio-disabled-text-color: var(--text-disabled-color);
  --radio-disabled-checked-background-color: var(--radio-disabled-background-color);
  --radio-disabled-checked-border-color: var(--radio-disabled-border-color);
  --radio-disabled-checked-inner-circle-color: var(--color-disabled);
  --radio-primary-border-color: var(--color-cyan-30);
  --radio-primary-checked-border-color: var(--color-cyan-30);
  --radio-primary-inner-circle-color: var(--color-primary-default);
  --radio-primary-focus-border-color: var(--color-primary-default);
  --radio-primary-focus-checked-border-color: var(--color-primary-default);
  --radio-primary-focus-inner-circle-color: var(--color-primary-default);
  --radio-primary-focus-outline-color: var(--color-primary-outline);
  --radio-primary-hover-border-color: var(--color-cyan-50);
  --radio-primary-hover-inner-circle-color: var(--color-primary-hover);
  --radio-primary-hover-checked-border-color: var(--color-cyan-50);
  --radio-primary-active-border-color: var(--color-primary-active);
  --radio-primary-active-checked-border-color: var(--color-primary-default);
  --radio-primary-active-inner-circle-color: var(--color-primary-active);
  --radio-success-border-color: var(--color-green-40);
  --radio-success-checked-border-color: var(--color-green-40);
  --radio-success-inner-circle-color: var(--color-green-40);
  --radio-success-focus-border-color: var(--color-green-40);
  --radio-success-focus-checked-border-color: var(--color-green-40);
  --radio-success-focus-inner-circle-color: var(--color-green-40);
  --radio-success-focus-outline-color: var(--color-green-transparent-40);
  --radio-success-hover-border-color: var(--color-green-50);
  --radio-success-hover-inner-circle-color: var(--color-green-40);
  --radio-success-hover-checked-border-color: var(--color-green-50);
  --radio-success-active-border-color: var(--color-green-40);
  --radio-success-active-checked-border-color: var(--color-green-40);
  --radio-success-active-inner-circle-color: var(--color-green-70);
  --radio-info-border-color: var(--color-gray-50);
  --radio-info-checked-border-color: var(--color-gray-50);
  --radio-info-inner-circle-color: var(--color-gray-50);
  --radio-info-focus-border-color: var(--color-gray-50);
  --radio-info-focus-checked-border-color: var(--color-gray-50);
  --radio-info-focus-inner-circle-color: var(--color-gray-50);
  --radio-info-focus-outline-color: var(--color-gray-transparent-50);
  --radio-info-hover-border-color: var(--color-cool-gray-50);
  --radio-info-hover-inner-circle-color: var(--color-gray-50);
  --radio-info-hover-checked-border-color: var(--color-cool-gray-50);
  --radio-info-active-border-color: var(--color-gray-50);
  --radio-info-active-checked-border-color: var(--color-gray-50);
  --radio-info-active-inner-circle-color: var(--color-gray-50);
  --radio-warning-border-color: var(--color-gold-50);
  --radio-warning-checked-border-color: var(--color-gold-50);
  --radio-warning-inner-circle-color: var(--color-gold-50);
  --radio-warning-focus-border-color: var(--color-gold-50);
  --radio-warning-focus-checked-border-color: var(--color-gold-50);
  --radio-warning-focus-inner-circle-color: var(--color-gold-50);
  --radio-warning-focus-outline-color: var(--color-gold-transparent-50);
  --radio-warning-hover-border-color: var(--color-gold-40);
  --radio-warning-hover-inner-circle-color: var(--color-gold-50);
  --radio-warning-hover-checked-border-color: var(--color-gold-40);
  --radio-warning-active-border-color: var(--color-gold-50);
  --radio-warning-active-checked-border-color: var(--color-gold-50);
  --radio-warning-active-inner-circle-color: var(--color-gold-100);
  --radio-danger-border-color: var(--color-danger-default);
  --radio-danger-checked-border-color: var(--color-danger-default);
  --radio-danger-inner-circle-color: var(--color-danger-default);
  --radio-danger-focus-border-color: var(--color-red-80);
  --radio-danger-focus-checked-border-color: var(--color-red-80);
  --radio-danger-focus-inner-circle-color: var(--color-danger-focus);
  --radio-danger-focus-outline-color: var(--color-danger-outline);
  --radio-danger-hover-border-color: var(--color-danger-hover);
  --radio-danger-hover-inner-circle-color: var(--color-danger-hover);
  --radio-danger-hover-checked-border-color: var(--color-danger-hover);
  --radio-danger-active-border-color: var(--color-red-80);
  --radio-danger-active-checked-border-color: var(--color-red-80);
  --radio-danger-active-inner-circle-color: var(--color-danger-active);
  --tabset-background-color: transparent;
  --tabset-tab-gap: 0.5rem;
  --tabset-tab-max-width: 20rem;
  --tabset-tab-background-color: var(--color-cool-gray-30);
  --tabset-tab-padding: 0.5rem 1rem;
  --tabset-tab-text-color: var(--text-basic-color);
  --tabset-tab-text-font-weight: 500;
  --tabset-tab-text-line-height: 1.375rem;
  --tabset-tab-border-radius: 0.5rem 0.5rem 0 0;
  --tabset-tab-active-background-color: var(--text-basic-color);
  --tabset-tab-active-text-color: var(--text-button-filled-color);
  --tabset-tab-focus-background-color: var(--tabset-tab-active-background-color);
  --tabset-tab-focus-text-color: var(--tabset-tab-active-text-color);
  --tabset-tab-hover-background-color: var(--color-cool-gray-20);
  --tabset-tab-hover-text-color: var(--text-basic-color);
  --tabset-tab-disabled-background-color: var(--tabset-tab-background-color);
  --tabset-tab-disabled-text-color: var(--text-disabled-color);
  --tabset-divider-color: var(--color-cyan-30);
  --tabset-divider-style: var(--divider-style);
  --tabset-divider-width: var(--divider-width);
  --tabset-content-background-color: transparent;
  --tabset-content-padding: 0;
  --tabset-scrollbar-color: var(--scrollbar-color);
  --tabset-scrollbar-background-color: var(--scrollbar-background-color);
  --tabset-scrollbar-width: var(--scrollbar-width);
  --route-tabset-background-color: transparent;
  --route-tabset-tab-gap: 0.5rem;
  --route-tabset-tab-max-width: 20rem;
  --route-tabset-tab-background-color: var(--color-cool-gray-30);
  --route-tabset-tab-padding: 0.5rem 1rem;
  --route-tabset-tab-text-color: var(--text-basic-color);
  --route-tabset-tab-text-font-weight: 500;
  --route-tabset-tab-text-line-height: 1.375rem;
  --route-tabset-tab-border-radius: 0.5rem 0.5rem 0 0;
  --route-tabset-tab-active-background-color: var(--text-basic-color);
  --route-tabset-tab-active-text-color: var(--text-button-filled-color);
  --route-tabset-tab-focus-background-color: var(--route-tabset-tab-active-background-color);
  --route-tabset-tab-focus-text-color: var(--route-tabset-tab-active-text-color);
  --route-tabset-tab-hover-background-color: var(--color-cool-gray-20);
  --route-tabset-tab-hover-text-color: var(--text-basic-color);
  --route-tabset-tab-disabled-background-color: var(--route-tabset-tab-background-color);
  --route-tabset-tab-disabled-text-color: var(--text-disabled-color);
  --route-tabset-divider-color: var(--color-cyan-30);
  --route-tabset-divider-style: var(--divider-style);
  --route-tabset-divider-width: var(--divider-width);
  --route-tabset-scrollbar-color: var(--scrollbar-color);
  --route-tabset-scrollbar-background-color: var(--scrollbar-background-color);
  --route-tabset-scrollbar-width: var(--scrollbar-width);
  --tooltip-background-color: var(--color-gray-100);
  --tooltip-border-radius: 0.25rem;
  --tooltip-padding: 0.5rem 0.75rem;
  --tooltip-text-color: var(--text-button-filled-color);
  --tooltip-text-font-size: 0.875rem;
  --tooltip-text-line-height: var(--text-paragraph-2-line-height);
  --tooltip-icon-height: 1rem;
  --tooltip-icon-width: 1rem;
  --tooltip-max-width: 18.75rem;
  --popover-text-color: var(--text-basic-color);
  --popover-background-color: var(--card-background-color);
  --popover-border-radius: var(--tooltip-border-radius);
  --popover-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  --popover-padding: var(--tooltip-padding);
  --color-plum-40: #e6d6ea;
  --color-plum-60: #c7a2cc;
  --color-plum-100: #563d82;
  --color-plum-tranparent-40: rgba(230, 214, 234, 0.25);
  --color-plum-tranparent-60: rgba(199, 162, 204, 0.25);
  --color-plum-tranparent-100: rgba(86, 61, 130, 0.25);
  --color-purple-20: #e3deff;
  --color-purple-30: #dbd3f4;
  --color-purple-40: #c7bdff;
  --color-purple-50: #b8a7e9;
  --color-purple-80: #806bff;
  --color-purple-100: #5f38ce;
  --color-purple-transparent-20: rgba(227, 222, 255, 0.25);
  --color-purple-transparent-30: rgba(219, 211, 244, 0.25);
  --color-purple-transparent-40: rgba(199, 189, 255, 0.25);
  --color-purple-transparent-50: rgba(184, 167, 233, 0.25);
  --color-purple-transparent-80: rgba(128, 107, 255, 0.25);
  --color-purple-transparent-100: rgba(95, 56, 206, 0.25);
  --color-cyan-10: #f1f8fb;
  --color-cyan-20: #c7d9e5;
  --color-cyan-30: #abc6d8;
  --color-cyan-40: #8fb3cb;
  --color-cyan-50: #73a0be;
  --color-cyan-60: #578db1;
  --color-cyan-70: #477799;
  --color-cyan-80: #3a627d;
  --color-cyan-90: #2d4c61;
  --color-cyan-100: #203645;
  --color-cyan-transparent-10: rgba(241, 248, 251, 0.25);
  --color-cyan-transparent-20: rgba(199, 217, 229, 0.25);
  --color-cyan-transparent-30: rgba(171, 198, 216, 0.25);
  --color-cyan-transparent-40: rgba(143, 179, 203, 0.25);
  --color-cyan-transparent-50: rgba(115, 160, 190, 0.25);
  --color-cyan-transparent-60: rgba(87, 141, 177, 0.25);
  --color-cyan-transparent-70: rgba(71, 119, 153, 0.25);
  --color-cyan-transparent-80: rgba(58, 98, 125, 0.25);
  --color-cyan-transparent-90: rgba(45, 76, 97, 0.25);
  --color-cyan-transparent-100: rgba(33, 55, 69, 0.25);
  --color-blue-10: #e3f7fd;
  --color-blue-20: #8edff9;
  --color-blue-30: #55daff;
  --color-blue-40: #00ccff;
  --color-blue-50: #00bbff;
  --color-blue-60: #04a5f5;
  --color-blue-70: #0097ff;
  --color-blue-80: #0470ec;
  --color-blue-81: #0475f5;
  --color-blue-90: #1362e2;
  --color-blue-100: #1d41c3;
  --color-blue-transparent-10: rgba(227, 247, 253, 0.25);
  --color-blue-transparent-20: rgba(142, 223, 249, 0.25);
  --color-blue-transparent-30: rgba(85, 218, 255, 0.25);
  --color-blue-transparent-40: rgba(0, 204, 255, 0.25);
  --color-blue-transparent-50: rgba(0, 187, 255, 0.25);
  --color-blue-transparent-60: rgba(4, 165, 245, 0.25);
  --color-blue-transparent-70: rgba(0, 151, 255, 0.25);
  --color-blue-transparent-80: rgba(4, 112, 236, 0.25);
  --color-blue-transparent-81: rgba(4, 117, 245, 0.25);
  --color-blue-transparent-90: rgba(19, 98, 226, 0.25);
  --color-blue-transparent-100: rgba(29, 65, 195, 0.25);
  --color-teal-10: #e0f9f7;
  --color-teal-20: #dff7f3;
  --color-teal-30: #d0efef;
  --color-teal-40: #c1f4ef;
  --color-teal-50: #aeeae2;
  --color-teal-60: #73e6dc;
  --color-teal-70: #6dd9ca;
  --color-teal-80: #9bd4d6;
  --color-teal-90: #59d8d8;
  --color-teal-100: #259f9f;
  --color-teal-transparent-10: rgba(224, 249, 247, 0.25);
  --color-teal-transparent-20: rgba(223, 247, 243, 0.25);
  --color-teal-transparent-30: rgba(208, 239, 239, 0.25);
  --color-teal-transparent-40: rgba(193, 244, 239, 0.25);
  --color-teal-transparent-50: rgba(174, 234, 226, 0.25);
  --color-teal-transparent-60: rgba(115, 230, 220, 0.25);
  --color-teal-transparent-70: rgba(109, 217, 202, 0.25);
  --color-teal-transparent-80: rgba(155, 212, 214, 0.25);
  --color-teal-transparent-90: rgba(89, 216, 216, 0.25);
  --color-teal-transparent-100: rgba(37, 159, 159, 0.25);
  --color-green-10: #f0fff1;
  --color-green-20: #d6ffda;
  --color-green-30: #bff2c4;
  --color-green-40: #a0e9a7;
  --color-green-50: #8de39b;
  --color-green-60: #7bf297;
  --color-green-70: #59d977;
  --color-green-80: #28bf4b;
  --color-green-90: #12a635;
  --color-green-100: #01801f;
  --color-green-transparent-10: rgba(240, 255, 241, 0.25);
  --color-green-transparent-20: rgba(214, 255, 218, 0.25);
  --color-green-transparent-30: rgba(191, 242, 196, 0.25);
  --color-green-transparent-40: rgba(160, 233, 167, 0.25);
  --color-green-transparent-50: rgba(141, 227, 155, 0.25);
  --color-green-transparent-60: rgba(123, 242, 151, 0.25);
  --color-green-transparent-70: rgba(89, 217, 119, 0.25);
  --color-green-transparent-80: rgba(40, 191, 75, 0.25);
  --color-green-transparent-90: rgba(18, 166, 53, 0.25);
  --color-green-transparent-100: rgba(1, 128, 31, 0.25);
  --color-gold-10: #fff7e3;
  --color-gold-20: #fff3de;
  --color-gold-30: #ffefc8;
  --color-gold-40: #fbe2b9;
  --color-gold-50: #ffdc83;
  --color-gold-60: #fed78e;
  --color-gold-70: #ffd469;
  --color-gold-80: #ffcb69;
  --color-gold-90: #fdc34e;
  --color-gold-100: #ffbf3f;
  --color-gold-transparent-10: rgba(255, 248, 229, 0.25);
  --color-gold-transparent-20: rgba(255, 243, 222, 0.25);
  --color-gold-transparent-30: rgba(255, 239, 200, 0.25);
  --color-gold-transparent-40: rgba(251, 226, 185, 0.25);
  --color-gold-transparent-50: rgba(255, 220, 131, 0.25);
  --color-gold-transparent-60: rgba(254, 215, 142, 0.25);
  --color-gold-transparent-70: rgba(255, 212, 105, 0.25);
  --color-gold-transparent-80: rgba(255, 203, 105, 0.25);
  --color-gold-transparent-90: rgba(253, 195, 78, 0.25);
  --color-gold-transparent-100: rgba(255, 191, 63, 0.25);
  --color-orange-10: #fff0e5;
  --color-orange-20: #ffe0cc;
  --color-orange-30: #ffd1b2;
  --color-orange-40: #f5bb94;
  --color-orange-50: #f0a878;
  --color-orange-60: #f19255;
  --color-orange-70: #ed7a23;
  --color-orange-80: #d46d20;
  --color-orange-90: #a14a08;
  --color-orange-100: #873a00;
  --color-orange-transparent-10: rgba(255, 240, 229, 0.25);
  --color-orange-transparent-20: rgba(255, 224, 204, 0.25);
  --color-orange-transparent-30: rgba(255, 209, 178, 0.25);
  --color-orange-transparent-40: rgba(245, 187, 148, 0.25);
  --color-orange-transparent-50: rgba(240, 168, 120, 0.25);
  --color-orange-transparent-60: rgba(241, 146, 85, 0.25);
  --color-orange-transparent-70: rgba(237, 122, 35, 0.25);
  --color-orange-transparent-80: rgba(212, 109, 32, 0.25);
  --color-orange-transparent-90: rgba(161, 74, 8, 0.25);
  --color-orange-transparent-100: rgba(135, 58, 0, 0.25);
  --color-salmon-10: #ffe7df;
  --color-salmon-20: #ffe3da;
  --color-salmon-30: #ffd0c0;
  --color-salmon-40: #ffb9a2;
  --color-salmon-50: #ff9571;
  --color-salmon-60: #ff8157;
  --color-salmon-transparent-10: rgba(255, 231, 223, 0.25);
  --color-salmon-transparent-20: rgba(255, 227, 218, 0.25);
  --color-salmon-transparent-30: rgba(255, 208, 192, 0.25);
  --color-salmon-transparent-40: rgba(255, 185, 162, 0.25);
  --color-salmon-transparent-50: rgba(255, 149, 113, 0.25);
  --color-salmon-transparent-60: rgba(255, 129, 87, 0.25);
  --color-pink-10: #ffe3f3;
  --color-pink-20: #ffc7e7;
  --color-pink-30: #ffb3d7;
  --color-pink-40: #ff82c9;
  --color-pink-50: #ff61a1;
  --color-pink-60: #ff54a5;
  --color-pink-70: #ff5288;
  --color-pink-80: #ff477b;
  --color-pink-90: #e94768;
  --color-pink-100: #e63257;
  --color-pink-transparent-10: rgba(255, 227, 243, 0.25);
  --color-pink-transparent-20: rgba(255, 199, 231, 0.25);
  --color-pink-transparent-30: rgba(255, 179, 215, 0.25);
  --color-pink-transparent-40: rgba(255, 130, 201, 0.25);
  --color-pink-transparent-50: rgba(255, 97, 161, 0.25);
  --color-pink-transparent-60: rgba(255, 84, 165, 0.25);
  --color-pink-transparent-70: rgba(255, 82, 136, 0.25);
  --color-pink-transparent-80: rgba(255, 71, 123, 0.25);
  --color-pink-transparent-90: rgba(255, 56, 97, 0.25);
  --color-pink-transparent-100: rgba(240, 52, 91, 0.25);
  --color-red-10: #ffe3e6;
  --color-red-20: #ffd9eb;
  --color-red-30: #ffc7ce;
  --color-red-40: #ffabb5;
  --color-red-50: #ff82a0;
  --color-red-60: #ff6e8b;
  --color-red-70: #ff5772;
  --color-red-80: #e94768;
  --color-red-90: #e63257;
  --color-red-100: #bd2947;
  --color-red-transparent-10: rgba(255, 227, 230, 0.25);
  --color-red-transparent-20: rgba(255, 217, 235, 0.25);
  --color-red-transparent-30: rgba(255, 199, 206, 0.25);
  --color-red-transparent-40: rgba(255, 171, 181, 0.25);
  --color-red-transparent-50: rgba(255, 130, 160, 0.25);
  --color-red-transparent-60: rgba(255, 110, 139, 0.25);
  --color-red-transparent-70: rgba(255, 87, 114, 0.25);
  --color-red-transparent-80: rgba(233, 71, 104, 0.25);
  --color-red-transparent-90: rgba(230, 50, 87, 0.25);
  --color-red-transparent-100: rgba(189, 41, 7, 0.25);
  --color-gray-10: #fcfcfc;
  --color-gray-20: #f7f7f7;
  --color-gray-30: #efefef;
  --color-gray-40: #dee1e3;
  --color-gray-50: #cdd1d3;
  --color-gray-60: #b0b4b5;
  --color-gray-70: #596063;
  --color-gray-80: #33393d;
  --color-gray-90: #181830;
  --color-gray-100: #04041c;
  --color-gray-transparent-10: rgba(252, 252, 252, 0.25);
  --color-gray-transparent-20: rgba(247, 247, 247, 0.25);
  --color-gray-transparent-30: rgba(239, 239, 239, 0.25);
  --color-gray-transparent-40: rgba(222, 225, 227, 0.25);
  --color-gray-transparent-50: rgba(205, 209, 211, 0.25);
  --color-gray-transparent-60: rgba(176, 180, 181, 0.25);
  --color-gray-transparent-70: rgba(89, 96, 99, 0.25);
  --color-gray-transparent-80: rgba(51, 57, 61, 0.25);
  --color-gray-transparent-90: rgba(24, 24, 48, 0.25);
  --color-gray-transparent-100: rgba(4, 4, 28, 0.25);
  --color-cool-gray-10: #fcfcfe;
  --color-cool-gray-20: #f5f7f7;
  --color-cool-gray-30: #f0f3f7;
  --color-cool-gray-40: #d2d0df;
  --color-cool-gray-50: #bcbad5;
  --color-cool-gray-60: #6b747d;
  --color-cool-gray-70: #464765;
  --color-cool-gray-80: #203645;
  --color-cool-gray-90: #121227;
  --color-cool-gray-100: #04041c;
  --color-cool-gray-transparent-10: rgba(252, 252, 254, 0.25);
  --color-cool-gray-transparent-20: rgba(245, 247, 247, 0.25);
  --color-cool-gray-transparent-30: rgba(240, 243, 247, 0.25);
  --color-cool-gray-transparent-40: rgba(210, 208, 223, 0.25);
  --color-cool-gray-transparent-50: rgba(188, 186, 213, 0.25);
  --color-cool-gray-transparent-60: rgba(107, 116, 125, 0.25);
  --color-cool-gray-transparent-70: rgba(70, 71, 101, 0.25);
  --color-cool-gray-transparent-80: rgba(32, 54, 69, 0.25);
  --color-cool-gray-transparent-90: rgba(18, 18, 39, 0.25);
  --color-cool-gray-transparent-100: rgba(4, 4, 28, 0.25);
  --color-transparent: transparent;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-disabled: var(--color-gray-60);
  --color-border-disabled: var(--color-gray-60);
  --color-primary-default: var(--color-blue-90);
  --color-primary-focus: var(--color-blue-70);
  --color-primary-hover: var(--color-blue-80);
  --color-primary-active: var(--color-blue-100);
  --color-primary-outline: var(--color-blue-transparent-90);
  --color-danger-default: var(--color-red-90);
  --color-danger-focus: var(--color-pink-100);
  --color-danger-hover: var(--color-red-70);
  --color-danger-active: var(--color-red-100);
  --color-danger-outline: var(--color-red-transparent-90);
  --font-family-primary: Inter, sans-serif;
  --font-family-secondary: var(--font-family-primary);
  --text-heading-1-font-family: var(--font-family-primary);
  --text-heading-1-font-size: 3.75rem;
  --text-heading-1-font-weight: 700;
  --text-heading-1-line-height: 4.375rem;
  --text-heading-2-font-family: var(--font-family-primary);
  --text-heading-2-font-size: 2.625rem;
  --text-heading-2-font-weight: 700;
  --text-heading-2-line-height: 3.25rem;
  --text-heading-3-font-family: var(--font-family-primary);
  --text-heading-3-font-size: 2rem;
  --text-heading-3-font-weight: 500;
  --text-heading-3-line-height: 2.75rem;
  --text-heading-4-font-family: var(--font-family-primary);
  --text-heading-4-font-size: 1.75rem;
  --text-heading-4-font-weight: 700;
  --text-heading-4-line-height: 2.25rem;
  --text-heading-5-font-family: var(--font-family-primary);
  --text-heading-5-font-size: 1.25rem;
  --text-heading-5-font-weight: 700;
  --text-heading-5-line-height: 1.75rem;
  --text-heading-6-font-family: var(--font-family-primary);
  --text-heading-6-font-size: 1.125rem;
  --text-heading-6-font-weight: 700;
  --text-heading-6-line-height: 1.5625rem;
  --text-subtitle-color: var(--text-basic-color);
  --text-subtitle-font-family: var(--font-family-primary);
  --text-subtitle-font-size: 0.875rem;
  --text-subtitle-font-weight: 400;
  --text-subtitle-line-height: 1.5rem;
  --text-subtitle-2-color: var(--text-hint-color);
  --text-subtitle-2-font-family: var(--font-family-primary);
  --text-subtitle-2-font-size: 0.875rem;
  --text-subtitle-2-font-weight: 400;
  --text-subtitle-2-line-height: 1.0625rem;
  --text-paragraph-font-family: var(--font-family-primary);
  --text-paragraph-font-size: 1rem;
  --text-paragraph-font-weight: 400;
  --text-paragraph-line-height: 1.25rem;
  --text-paragraph-2-font-family: var(--font-family-primary);
  --text-paragraph-2-font-size: 0.8125rem;
  --text-paragraph-2-font-weight: 400;
  --text-paragraph-2-line-height: 1.125rem;
  --text-label-font-family: var(--font-family-primary);
  --text-label-font-size: 0.75rem;
  --text-label-font-weight: 700;
  --text-label-line-height: 1rem;
  --text-caption-font-family: var(--font-family-primary);
  --text-caption-font-size: 0.75rem;
  --text-caption-font-weight: 400;
  --text-caption-line-height: 1rem;
  --text-caption-2-font-family: var(--font-family-primary);
  --text-caption-2-font-size: 0.75rem;
  --text-caption-2-font-weight: 600;
  --text-caption-2-line-height: 1rem;
  --text-button-font-family: var(--font-family-primary);
  --text-button-font-weight: 700;
  --text-button-font-size: 1rem;
  --text-button-line-height: 1.5rem;
  --text-button-filled-color: var(--color-gray-10);
  --text-basic-color: var(--color-cool-gray-80);
  --text-disabled-color: var(--color-gray-60);
  --text-hint-color: var(--color-cool-gray-60);
  --outline-width: 0.375rem;
  --outline-color: var(--color-transparent);
  --scrollbar-color: var(--color-gray-50);
  --scrollbar-background-color: var(--color-gray-30);
  --scrollbar-width: 0.5rem;
  --shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  --divider-color: var(--color-gray-40);
  --divider-style: solid;
  --divider-width: 1px;
  --transition-duration-easing: 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

/*
 * Grid Defaults
 */
/*
 * Typography Settings
 */
/*
 * Form and UI Settings
 */
/* prefixed */
/* Base Colors */
/* Alias Colors */
/* Base Typography Tokens */
/* Alias Typography Tokens */
.s-font-h1 {
  letter-spacing: -1%;
  line-height: 50px;
  font: 700 42px "Inter", sans-serif;
}

.m-font-h1 {
  letter-spacing: -1.6%;
  line-height: 50px;
  font: 700 42px "Inter", sans-serif;
}

.l-font-h1 {
  letter-spacing: -2.2%;
  line-height: 56px;
  font: 700 48px "Inter", sans-serif;
}

.xl-font-h1 {
  letter-spacing: -3%;
  line-height: 70px;
  font: 700 60px "Inter", sans-serif;
}

.s-font-h2 {
  letter-spacing: 0%;
  line-height: 40px;
  font: 700 32px "Inter", sans-serif;
}

.l-font-h2 {
  letter-spacing: -0.5%;
  line-height: 44px;
  font: 700 36px "Inter", sans-serif;
}

.xl-font-h2 {
  letter-spacing: -1%;
  line-height: 52px;
  font: 700 42px "Inter", sans-serif;
}

.s-font-h3 {
  letter-spacing: -1%;
  line-height: 36px;
  font: 500 28px "Inter", sans-serif;
}

.xl-font-h3 {
  letter-spacing: -1%;
  line-height: 44px;
  font: 500 32px "Inter", sans-serif;
}

.s-font-h4 {
  letter-spacing: 0%;
  line-height: 32px;
  font: 700 24px "Inter", sans-serif;
}

.xl-font-h4 {
  letter-spacing: 0%;
  line-height: 36px;
  font: 700 28px "Inter", sans-serif;
}

.s-font-h5 {
  letter-spacing: 0%;
  line-height: 28px;
  font: 700 20px "Inter", sans-serif;
}

.s-font-h6 {
  letter-spacing: 0%;
  line-height: 25px;
  font: 700 18px "Inter", sans-serif;
}

.s-font-p1-short {
  letter-spacing: 0%;
  line-height: 28px;
  font: 500 20px "Inter", sans-serif;
}

.m-font-p1-short {
  letter-spacing: 0%;
  line-height: 27px;
  font: 500 20px "Inter", sans-serif;
}

.s-font-p1-long {
  letter-spacing: 0%;
  line-height: 32px;
  font: 500 20px "Inter", sans-serif;
}

.m-font-p1-long {
  letter-spacing: 0%;
  line-height: 30px;
  font: 500 20px "Inter", sans-serif;
}

.s-font-p2-short {
  letter-spacing: 0%;
  line-height: 28px;
  font: 500 18px "Inter", sans-serif;
}

.m-font-p2-short {
  letter-spacing: 0%;
  line-height: 22px;
  font: 500 16px "Inter", sans-serif;
}

.s-font-p2-long {
  letter-spacing: 0%;
  line-height: 30px;
  font: 500 18px "Inter", sans-serif;
}

.m-font-p2-long {
  letter-spacing: 0%;
  line-height: 28px;
  font: 500 16px "Inter", sans-serif;
}

.s-font-btn-label {
  letter-spacing: 0%;
  line-height: 24px;
  font: 700 18px "Inter", sans-serif;
}

.m-font-btn-label {
  letter-spacing: 0%;
  line-height: 22px;
  font: 700 16px "Inter", sans-serif;
}

.s-font-btn-link-label {
  letter-spacing: 0%;
  line-height: 24px;
  text-decoration: underline;
  font: 700 18px "Inter", sans-serif;
}

.m-font-btn-link-label {
  letter-spacing: 0%;
  line-height: 22px;
  text-decoration: underline;
  font: 700 16px "Inter", sans-serif;
}

.s-font-field-label {
  letter-spacing: 0%;
  line-height: 22px;
  font: 500 16px "Inter", sans-serif;
}

.s-font-field-value-em {
  letter-spacing: 0%;
  line-height: 22px;
  font: 700 16px "Inter", sans-serif;
}

.s-font-field-value {
  letter-spacing: 0%;
  line-height: 22px;
  font: 400 16px "Inter", sans-serif;
}

.s-font-sidebar-link-label {
  letter-spacing: 0%;
  line-height: 24px;
  font: 500 16px "Inter", sans-serif;
}

.s-font-footnote1-short {
  letter-spacing: 0%;
  line-height: 18px;
  font: 400 14px "Inter", sans-serif;
}

.s-font-footnote1-long {
  letter-spacing: 0%;
  line-height: 20px;
  font: 400 14px "Inter", sans-serif;
}

.m-font-footnote1-long {
  letter-spacing: 0%;
  line-height: 18px;
  font: 400 12px "Inter", sans-serif;
}

.s-font-footnote2-short {
  letter-spacing: 0%;
  line-height: 16px;
  font: 400 12px "Inter", sans-serif;
}

.s-font-footnote2-long {
  letter-spacing: 0%;
  line-height: 20px;
  font: 400 12px "Inter", sans-serif;
}

.m-font-footnote2-long {
  letter-spacing: 0%;
  line-height: 18px;
  font: 400 12px "Inter", sans-serif;
}

/* Base Borders */
/* Alias Borders */
/* Spacing (or Size) Tokens */
/* Rounded Corner Tokens */
/* Breakpoints Tokens */
@media (max-width: 1200px) {
  html {
    overflow-x: hidden;
  }
}

body {
  background-color: #F2F5F8;
  font: 400 14px "Inter", sans-serif;
  overflow-x: hidden;
  min-height: 100vh;
}

a,
button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
a:hover, a:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: none !important;
}

ul {
  list-style: none;
  padding: 0;
}
a {
  font: 700 14px "Inter", sans-serif;
  color: #1362E2;
}
a:hover {
  color: #0475F5;
}

svg {
  margin-right: 6px;
  vertical-align: middle;
}

.clearfix {
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden;
}

.form-control-round {
  border-radius: 26px !important;
  -moz-border-radius: 26px !important;
  -webkit-border-radius: 26px !important;
}

.btn {
  cursor: pointer;
  min-width: 38px;
  height: 38px;
  text-align: center;
  border: 2px solid;
  font: 700 16px "Inter", sans-serif;
  padding: 0 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -webkit-border-radius: 9999px !important;
}
.btn svg {
  margin-right: 4px;
}
.btn.btn-sml {
  height: 33px;
  min-width: 30px;
  padding: 0 16px;
}
.btn.btn-lg {
  height: 54px;
  min-width: 54px;
  padding: 0 32px;
}
.btn.btn-ico {
  padding: 0;
}
.btn.btn-ico svg {
  margin: 0;
  padding: 0;
}
.btn.btn-prim {
  color: #FFFFFF;
  background-color: #1362E2;
  border-color: #1362E2;
}
.btn.btn-prim:hover, .btn.btn-prim:active, .btn.btn-prim:focus {
  color: #FFFFFF;
  background-color: #0475F5;
  border-color: #0475F5;
}
.btn.btn-prim.btn.disabled, .btn.btn-prim.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn.btn-prim.btn.disabled:hover, .btn.btn-prim.btn:disabled:hover {
  color: #FFFFFF;
  background-color: #1362E2;
  border-color: #1362E2;
}
.btn.btn-lite {
  color: #596063;
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #B0B4B5;
}
.btn.btn-lite:hover, .btn.btn-lite:active, .btn.btn-lite:focus {
  color: #181830;
  background-color: #FFFFFF;
  border-color: #181830;
}
.btn.btn-lite.btn.disabled, .btn.btn-lite.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn.btn-lite.btn.disabled:hover, .btn.btn-lite.btn:disabled:hover {
  color: #FFFFFF;
  background-color: #1362E2;
  border-color: #1362E2;
}
.btn.btn-outline {
  color: #1362E2;
  background-color: #FFFFFF;
  border-color: #1362E2;
  font-size: 1rem;
}
.btn.btn-outline:hover, .btn.btn-outline:active, .btn.btn-outline:focus {
  color: #FFFFFF;
  background-color: #0475F5;
  border-color: #0475F5;
}
.btn.btn-outline.btn.disabled, .btn.btn-outline.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn.btn-outline.btn.disabled:hover, .btn.btn-outline.btn:disabled:hover {
  color: #1362E2;
  background-color: #FFFFFF;
  border-color: #1362E2;
}
.btn.btn-secondary {
  color: #FFFFFF;
  background-color: #6B747D;
  border-color: #6B747D;
}
.btn.btn-secondary:hover {
  color: #FFFFFF;
  background-color: #BCBAD5;
  border-color: #BCBAD5;
}
.btn svg {
  margin-right: 4px;
}

.custom-switch {
  margin-bottom: 6px;
}

.custom-checkbox input {
  display: none;
}
.custom-checkbox input:checked ~ label:after {
  content: "";
  background-color: #1362E2;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 6px;
}
.custom-checkbox label {
  font-size: 1rem;
  color: #203645;
  padding-left: 30px;
  padding-top: 4px;
  position: relative;
}
.custom-checkbox label:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 24px;
  height: 24px;
  border: 2px solid #1362E2;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}

.img-placeholder {
  width: 100%;
  height: 200px;
  background: rgba(22, 41, 58, 0.05);
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 0.75rem;
}
.form-group label {
  color: #203645;
  font: 400 14px "Inter", sans-serif;
  font-weight: 300;
}
.form-group.has-icon {
  background: transparent;
  border: 0;
  position: relative;
}
.form-group.has-icon svg {
  font-size: 20px;
  position: absolute;
  left: 10px;
  bottom: 18px;
  color: #BCBAD5;
}
.form-group.has-icon .form-control {
  padding-left: 36px;
  border: 1px solid #ABC6D8;
}

.form-control {
  color: #181830;
  font: 400 14px "Inter", sans-serif;
  background: #FFFFFF;
  border: 1px solid #ABC6D8;
  outline: none;
  height: 38px;
  border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
}
.form-control.form-lg {
  height: 54px;
}
.form-control:hover, .form-control:focus {
  outline: none;
  box-shadow: none;
}
.form-control:hover {
  border-color: #6B747D !important;
}
.form-control:focus {
  border-color: #0097FF !important;
}
.form-control::-webkit-input-placeholder {
  color: #B0B4B5;
  font-weight: 300;
}
.form-control:-ms-input-placeholder {
  color: #B0B4B5;
  font-weight: 300;
}
.form-control::placeholder {
  color: #B0B4B5;
  font-weight: 300;
}
.form-control.ng-invalid {
  border-color: #dc3545;
}

.content-card {
  background: #FFFFFF;
  border: 1px solid #F0F3F7;
  border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -webkit-border-radius: 24px !important;
  -webkit-box-shadow: rgba(24, 24, 48, 0.1) 0px 1px 1px;
  -moz-box-shadow: rgba(24, 24, 48, 0.1) 0px 1px 1px;
  box-shadow: rgba(24, 24, 48, 0.1) 0px 1px 1px;
}

.content-wrapper .content-title h1,
.content-wrapper .content-title h2 {
  color: #181830;
  font-size: 1.25rem;
  font-weight: 800;
}
.content-wrapper .content-title h1 .capitalize,
.content-wrapper .content-title h2 .capitalize {
  text-transform: capitalize;
}
.content-wrapper .content-title .d-flex {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  align-items: center !important;
  row-gap: 10px;
}
@media only screen and (min-width: 1024px) {
  .content-wrapper .content-title .d-flex {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    align-items: center !important;
    row-gap: 0;
  }
}
.content-wrapper .empty-403 {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.content-wrapper .empty-403 h2 {
  color: #181830;
  font-size: 3rem;
  font-weight: 800;
}
.content-wrapper.interior-wrapper .content-title {
  padding: 20px 20px 10px;
}
.content-wrapper.interior-wrapper .content-title .btn-group {
  gap: 15px;
  flex-direction: column;
}
@media only screen and (min-width: 667px) {
  .content-wrapper.interior-wrapper .content-title .btn-group {
    flex-direction: row;
  }
}
.content-wrapper.interior-wrapper .content-title .btn-group .btn.btn-outline.btn-outline-success {
  border: 1px solid #28a745;
  color: #28a745;
}
.content-wrapper.interior-wrapper .content-nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0;
  align-items: flex-start;
}
.content-wrapper.interior-wrapper .content-nav-wrapper.content-nav-wrapper-button {
  justify-content: flex-end;
  padding-bottom: 20px;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li {
  padding: 0 2px;
  display: flex;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a {
  background: #F0F3F7;
  color: #2D4C61;
  font: 400 14px "Inter", sans-serif;
  padding: 10px 16px;
  background: #F7F7F7;
  border: none;
  border-radius: 20px 20px 0px 0px !important;
  -moz-border-radius: 20px 20px 0px 0px !important;
  -webkit-border-radius: 20px 20px 0px 0px !important;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a.bg-danger {
  background-color: #FCFCFC !important;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a.bg-danger.active, .content-wrapper.interior-wrapper .content-nav-wrapper .nav li a.bg-danger:hover {
  background: #FFFFFF;
  color: #1362E2;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a.active {
  background: #2D4C61;
  color: #FFFFFF;
  font: 700 14px "Inter", sans-serif;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a.active:hover {
  background: #2D4C61;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .nav li a:hover {
  background: #C7D9E5;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .content-btn {
  display: inline-flex;
  gap: 10px;
}
.content-wrapper.interior-wrapper .content-nav-wrapper .content-btn .btn-outline {
  border: 1px solid #334e56;
  color: #324e57;
  font-size: 0.938rem;
}
.content-wrapper.interior-wrapper .content-grid-wrapper {
  border-top: 2px solid #ededed;
  margin-top: -2.5px;
}
.content-wrapper.interior-wrapper .content-grid-wrapper .content-tab {
  border-top: 1px solid #EFEFEF;
}
.content-wrapper.interior-wrapper .content-grid-wrapper .content-tab .filter {
  padding: 20px 20px;
  display: flex;
  border-bottom: 2px solid #d0d3d6;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}
.content-wrapper.interior-wrapper .content-grid-wrapper .content-tab .filter.filter.filter-title {
  justify-content: space-between;
}

img[src*="preloader.gif"] {
  max-height: 120px;
  margin: 50px;
}

select:required:invalid,
select:required.ng-invalid {
  color: #bfc3c3;
}

select.form-control {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  -webkit-appearance: none;
  appearance: none;
}
select.form-control:disabled {
  background-color: #f8f8f8;
  color: #B0B4B5;
}
select:required:invalid {
  color: #bfc3c3;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: initial;
}

option[value=""][disabled] {
  display: none;
}

option {
  color: black;
}

label {
  color: #203645;
  font: 400 14px "Inter", sans-serif;
  font-weight: 300;
}

.nav-pillmain .nav-item .nav-link {
  margin: 0 4px 4px 0;
  background: #F7F7F7;
  font: 400 14px "Inter", sans-serif;
  color: #2D4C61;
  border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -webkit-border-radius: 9999px !important;
}
.nav-pillmain .nav-item .nav-link.active {
  font: 700 14px "Inter", sans-serif;
  background: #2D4C61;
  color: #FFFFFF;
}

.form-wrap {
  margin-bottom: 20px;
}
.form-wrap .form-horizontal {
  margin-bottom: 10px;
}
.form-wrap .form-horizontal .action-delete {
  padding: 32px 0 0;
}
.form-wrap .form-horizontal .custom-checkbox {
  margin-top: 10px;
}
.form-wrap > .btn {
  margin: 10px 0 0;
}

.input-group-text {
  border-radius: 8px !important;
  margin-right: 4px;
}

.cj-tooltip {
  position: relative;
}
.cj-tooltip:hover .span-cjtooltip {
  display: block;
}
.cj-tooltip .span-cjtooltip {
  display: none;
  background: rgba(24, 24, 48, 0.9);
  position: absolute;
  bottom: -26px;
  font: 400 12px "Inter", sans-serif;
  color: #FFFFFF;
  padding: 2px 6px;
  border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -webkit-border-radius: 9999px !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.cj-tooltip .span-cjtooltip:before {
  top: -5px;
  left: calc(50% - 5px);
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(24, 24, 48, 0.9);
}

.tooltip-enabled > span {
  position: relative;
}
.tooltip-enabled > span:hover .tooltips {
  display: block;
}
.tooltip-enabled > span .tooltips {
  display: none;
  color: #fff;
  background: #000;
  text-align: center;
  border-radius: 6px;
  position: fixed;
  z-index: 99999;
  bottom: 9px;
  margin-left: -11px;
  padding: 5px 10px;
  line-height: 1.2em;
}

.main-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}
.main-wrap .main-sidebar {
  background: #FCFCFC;
  overflow: hidden;
  position: fixed;
  z-index: 99;
  padding: 0;
  left: 0;
  width: 280px;
  height: 100vh;
  margin-top: -80px;
}
.main-wrap .main-sidebar .sidebar {
  height: 100vh;
}
.main-wrap .main-sidebar .sidebar.dropdown {
  background: #FCFCFC;
}
.main-wrap .main-sidebar .sidebar.dropdown .sidebar-ticker {
  display: none;
  position: absolute;
  width: 38px;
  height: 38px;
  background: url("../../images/icon/ico-mobile-sidebar-open.svg") no-repeat;
  background-position: center;
  background-size: contain;
  border: 0;
  text-indent: -999px;
  overflow: hidden;
  position: absolute;
  right: -58px;
  top: 5px;
  z-index: 100;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.main-wrap .main-sidebar .sidebar.dropdown.show .sidebar-ticker {
  background: url("../../images/icon/ico-mobile-sidebar-close.svg") no-repeat;
  background-size: contain;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  right: -44px;
}
.main-wrap .main-sidebar .sidebar .logo {
  padding: 14px 0;
  text-align: left;
  border-bottom: 1px solid #ABC6D8;
  width: 90%;
  margin: 0 auto;
}
.main-wrap .main-sidebar .sidebar .logo .app-logo {
  padding: 0 10px;
}
.main-wrap .main-sidebar .sidebar .logo .tagline {
  padding: 10px 10px 0;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount {
  width: 100%;
  padding: 0 0 10px 0;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: #FCFCFC;
  border-width: 0px;
  box-shadow: none;
  display: block;
  top: 90px;
  z-index: 0;
  box-sizing: content-box;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .dropdown-header {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 10px 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .dropdown-header::first-child {
  padding-top: 0px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount > *:last-child {
  padding-bottom: 50px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li {
  position: relative;
  z-index: 1;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li .ico-toggle {
  position: absolute;
  right: 20px;
  top: 16px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li .divider {
  display: block;
  color: #6b747d;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 15px 25px 15px 25px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a {
  position: relative;
  display: block;
  color: #203645;
  font-size: 1rem;
  font-weight: 600;
  min-height: 50px;
  padding: 15px 25px 15px 50px;
  width: 95%;
  margin: 0 auto;
  border-radius: 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a svg.side-ico {
  font-size: 20px;
  padding: 8px 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a.link-inactive {
  pointer-events: none;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  position: absolute;
  left: 5px;
  top: 6px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-dashboard {
  background-image: url("../../images/icon/ico-dashboard.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-customer {
  background-image: url("../../images/icon/ico-customers.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-inventory {
  background-image: url("../../images/icon/ico-products.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-workorders {
  background-image: url("../../images/icon/ico-workorder.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-invoice {
  background-image: url("../../images/icon/icon-invoice.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-pl {
  background-image: url("../../images/icon/icon-payment-link.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-pf {
  background-image: url("../../images/icon/icon-payment-forms.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-subscription {
  background-image: url("../../images/icon/ico-subscription.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-tips {
  background-image: url("../../images/icon/ico-tips.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-reports {
  background-image: url("../../images/icon/ico-reports.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-settings {
  background-image: url("../../images/icon/ico-settings.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-virtual-sell {
  background-image: url("../../images/icon/ico-virtualsell.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-pos {
  background-image: url("../../images/icon/ico-pos.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-payroll {
  background-image: url("../../images/icon/ico-payroll.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-capital {
  background-image: url("../../images/icon/ico-capital.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-metric {
  background-image: url("../../images/icon/ico-metric.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li a .side-ico-pmplus {
  background-image: url("../../images/icon/ico-pmplus.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a {
  background-color: #E3F7FD;
  color: #1362E2;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-dashboard {
  background-image: url("../../images/icon/ico-dashboard-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-customer {
  background-image: url("../../images/icon/ico-customers-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-inventory {
  background-image: url("../../images/icon/ico-products-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-workorders {
  background-image: url("../../images/icon/ico-workorder.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-invoice {
  background-image: url("../../images/icon/icon-invoice-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-pl {
  background-image: url("../../images/icon/icon-payment-link-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-pf {
  background-image: url("../../images/icon/icon-payment-forms-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-subscription {
  background-image: url("../../images/icon/ico-subscription.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-tips {
  background-image: url("../../images/icon/ico-tips.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-reports {
  background-image: url("../../images/icon/ico-reports-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-settings {
  background-image: url("../../images/icon/ico-settings-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-virtual-sell {
  background-image: url("../../images/icon/ico-virtualsell-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-pos, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-pos, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-pos, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-pos {
  background-image: url("../../images/icon/ico-pos-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-payroll, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-payroll, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-payroll, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-payroll {
  background-image: url("../../images/icon/ico-payroll-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-capital, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-capital, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-capital, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-capital {
  background-image: url("../../images/icon/ico-capital-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-metric, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-metric, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-metric, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-metric {
  background-image: url("../../images/icon/ico-metric-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active a .side-ico-pmplus, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:focus a .side-ico-pmplus, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:active a .side-ico-pmplus, .main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li:hover a .side-ico-pmplus {
  background-image: url("../../images/icon/ico-pmplus-active.png");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount li.active > .sublinks {
  display: block;
  background: #f0f3f7;
  width: 95%;
  margin: -10px auto 0;
  padding-top: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .sublinks {
  padding-top: 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .sublinks ul li {
  background-color: transparent;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .sublinks ul li.active > a {
  color: #1362E2;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .sublinks ul li a {
  color: #203645;
  min-height: 40px;
  padding: 8px 20px 8px 45px;
  background: transparent;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .invoice-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  background: #fff;
  z-index: 999999;
  height: 100vh;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .invoice-menu ul li svg {
  width: 10px !important;
  height: 10px !important;
  vertical-align: middle !important;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .invoice-menu ul li a {
  padding: 8px 20px 8px 20px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-myaccount .invoice-menu ul li .divider {
  border-bottom: 1px solid #dae6ee;
  margin-bottom: 15px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus {
  width: 100%;
  padding: 0 0 10px 0;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: #FCFCFC;
  border-width: 0px;
  box-shadow: none;
  display: block;
  top: 90px;
  z-index: 0;
  box-sizing: content-box;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus .dropdown-header {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 10px 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus .dropdown-header::first-child {
  padding-top: 0px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus > *:last-child {
  padding-bottom: 50px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li .ico-toggle {
  position: absolute;
  right: 20px;
  top: 16px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a {
  position: relative;
  display: block;
  color: #203645;
  font-size: 1rem;
  font-weight: 600;
  min-height: 50px;
  padding: 15px 25px 15px 60px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a svg.side-ico {
  font-size: 20px;
  padding: 8px 10px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  position: absolute;
  left: 15px;
  top: 6px;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-dashboard {
  background-image: url("../../images/icon/icon-dashboard.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-customer {
  background-image: url("../../images/icon/icon-customers.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-inventory {
  background-image: url("../../images/icon/icon-product-services.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-workorders {
  background-image: url("../../images/icon/ico-workorder.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-invoice {
  background-image: url("../../images/icon/icon-invoice.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-pl {
  background-image: url("../../images/icon/icon-payment-link.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-pf {
  background-image: url("../../images/icon/icon-payment-forms.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-subscription {
  background-image: url("../../images/icon/ico-subscription.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-tips {
  background-image: url("../../images/icon/ico-tips.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-reports {
  background-image: url("../../images/icon/icon-reports.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-settings {
  background-image: url("../../images/icon/icon-settings.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li a .side-ico-virtual-sell {
  background-image: url("../../images/icon/icon-virtualsell.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a {
  background-color: #E3F7FD;
  color: #1362E2;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-dashboard, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-dashboard {
  background-image: url("../../images/icon/icon-dashboard-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-customer, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-customer {
  background-image: url("../../images/icon/icon-customers-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-inventory, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-inventory {
  background-image: url("../../images/icon/icon-product-services-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-workorders, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-workorders {
  background-image: url("../../images/icon/ico-workorder.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-invoice, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-invoice {
  background-image: url("../../images/icon/icon-invoice-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-pl, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-pl {
  background-image: url("../../images/icon/icon-payment-link-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-pf, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-pf {
  background-image: url("../../images/icon/icon-payment-forms-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-subscription, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-subscription {
  background-image: url("../../images/icon/ico-subscription.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-tips, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-tips {
  background-image: url("../../images/icon/ico-tips.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-reports, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-reports {
  background-image: url("../../images/icon/icon-reports-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-settings, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-settings {
  background-image: url("../../images/icon/icon-settings-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:focus a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:active a .side-ico-virtual-sell, .main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li:hover a .side-ico-virtual-sell {
  background-image: url("../../images/icon/icon-virtualsell-hover.svg");
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus li.active .sublinks {
  display: block;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus .sublinks li {
  background-color: transparent;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus .sublinks li.active a {
  color: #1362E2;
}
.main-wrap .main-sidebar .sidebar .dropdown-menu-pmplus .sublinks li a {
  color: #203645;
  min-height: 40px;
  padding: 8px 20px;
  margin-left: 30px;
  background: transparent;
}

.version {
  background-color: #FCFCFC;
  border-top: 1px solid #ABC6D8;
  width: 90%;
  text-align: left;
  color: #162132;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 17px 15px;
  font: 400 12px "Inter", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .main-wrap .main-sidebar {
    width: 20%;
  }
}
@media only screen and (min-width: 1280px) {
  .main-wrap .main-sidebar {
    width: 18%;
  }
}
@media only screen and (min-width: 1440px) {
  .main-wrap .main-sidebar {
    width: 16%;
  }
}
@media only screen and (min-width: 1550px) {
  .main-wrap .main-sidebar {
    width: 14.5%;
  }
}
@media (min-width: 320px) and (max-width: 1199px) {
  .main-wrap {
    padding-top: 40px;
    position: static;
  }
  .main-wrap .main-sidebar {
    background: transparent;
    overflow: visible;
    margin-top: 0;
    bottom: 0;
    top: 0;
    left: -280px;
  }
  .main-wrap .main-sidebar .sidebar {
    left: 0;
    position: relative;
  }
  .main-wrap .main-sidebar .sidebar.dropdown {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .main-wrap .main-sidebar .sidebar.dropdown .sidebar-ticker {
    display: block;
  }
  .main-wrap .main-sidebar .sidebar.dropdown.dropdown.show {
    left: 280px;
    height: 100%;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .main-wrap .main-sidebar .sidebar.dropdown.dropdown.show .dropdown-menu {
    transform: translate(0, 80px) !important;
    padding: 0 0 10px 0;
  }

  .version {
    padding: 10px 10px 20px;
    bottom: 0;
  }
}
.main-container {
  width: 100%;
  padding: 30px;
  position: relative;
  min-height: calc(100vh - 200px);
}
@media only screen and (min-width: 1200px) {
  .main-container {
    width: 80%;
  }
}
@media only screen and (min-width: 1280px) {
  .main-container {
    width: 82%;
  }
}
@media only screen and (min-width: 1440px) {
  .main-container {
    width: 84%;
  }
}
@media only screen and (min-width: 1550px) {
  .main-container {
    width: 85.5%;
  }
}

.cjgrid-table table tbody tr td svg {
  color: #151515;
}

.agrid-header .filter .agrid-title h4 {
  color: #181830;
  font-size: 1rem;
  font-weight: 800;
  margin: 0;
}
.agrid-header .filter .action {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 10px 20px;
  border-top: 1px solid #F0F3F7;
}
@media only screen and (min-width: 1024px) {
  .agrid-header .filter .action {
    flex-direction: row;
  }
}
.agrid-header .filter .action .btn-wrap {
  justify-content: right;
}
.agrid-header .filter .action .btn-wrap .btn.btn-outline {
  padding-left: 0;
  padding-right: 0;
}
.agrid-header .filter .action .action-wrap {
  display: inline-flex;
  flex: 0 0 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (min-width: 667px) {
  .agrid-header .filter .action .action-wrap {
    flex-direction: row;
    gap: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .agrid-header .filter .action .action-wrap {
    flex: 0 0 87%;
  }
}
.agrid-header .filter .action .action-wrap > select {
  background: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  height: 38px;
  margin-right: 0;
  width: 100% !important;
}
@media only screen and (min-width: 667px) {
  .agrid-header .filter .action .action-wrap > select {
    margin-right: 4px;
    width: auto !important;
  }
}
.agrid-header .filter .action .action-wrap > select:active, .agrid-header .filter .action .action-wrap > select:hover, .agrid-header .filter .action .action-wrap > select:focus {
  border-color: #828d98;
  box-shadow: initial;
}
.agrid-header .filter .action .action-wrap > form .form-group {
  height: 38px;
}
.agrid-header .filter .action .action-wrap > form .form-group .form-control {
  height: 38px;
}
.agrid-header .filter .action .action-wrap > form .form-group.has-icon {
  border-left: 0;
  border-radius: 0 26px 26px 0;
  -moz-border-radius: 0 26px 26px 0;
  -webkit-border-radius: 0 26px 26px 0;
}
.agrid-header .filter .action .action-wrap > form .form-group.has-icon svg {
  bottom: 10px;
}
.agrid-header .filter .action .action-wrap > form .form-group.has-icon .form-control {
  border-radius: 0;
  height: 38px;
}
.agrid-header .filter .action .action-wrap > form.no-source .form-group.has-icon {
  border-left: 1px solid #ced4da;
  border-radius: 4px;
}
.agrid-header .filter .action .action-wrap .cta-wrap {
  display: inline-flex;
  gap: 10px;
  margin-left: 0;
  flex-direction: column;
}
@media only screen and (min-width: 667px) {
  .agrid-header .filter .action .action-wrap .cta-wrap {
    margin-left: 10px;
    flex-direction: row;
  }
}

.ag-paging-panel {
  justify-content: center;
  border: none !important;
}

.ag-theme-material .ag-header {
  background-color: #F5F7F7;
  border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  text-transform: uppercase;
  border: none;
}
.ag-theme-material .ag-header .ag-header-row {
  margin: 0 20px;
}
.ag-theme-material .ag-header .ag-header-cell {
  padding-left: 4px;
}
.ag-theme-material .ag-header .ag-header-cell .ag-header-cell-text {
  color: #181830;
  font: 700 12px "Inter", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.ag-theme-material .ag-body-viewport .ag-row .ag-cell .badge {
  border-radius: 9999px;
  border: 0;
  padding: 7px 10px 4px;
}
.ag-theme-material .ag-body-viewport .ag-row .ag-cell .actions {
  cursor: pointer;
}
.ag-theme-material .ag-body-viewport .ag-row .ag-cell .actions svg {
  color: #1362E2;
}
.ag-theme-material .ag-body-viewport .ag-row .ag-cell .actions svg path {
  fill: #1362E2;
}

.ag-root {
  padding: 0 20px;
}

.ag-root-wrapper {
  border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
}

.ag-center-cols-viewport .ag-row {
  border: 0;
  border: 1px solid #FFFFFF;
  border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ag-center-cols-viewport .ag-row:nth-child(even) {
  background-color: #F5F7F7 !important;
}

.ag-row-focus, .ag-row-hover {
  border: 1px solid #C7D9E5 !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ag-center-cols-container {
  min-width: 100%;
}

footer {
  padding: 3rem 0;
  color: #6B747D;
  font: 400 12px "Inter", sans-serif;
}

.footnote {
  border-left: 5px solid #1362E2;
  padding: 10px 0 10px 25px;
}
.footnote ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footnote ul li p {
  font-size: 0.75rem;
  font-weight: 800;
  display: inline-block;
  line-height: 1;
  margin-right: 30px;
  margin-bottom: 5px;
  color: #324e57cc;
}
.footnote ul li p span {
  font-weight: normal;
  margin-right: 6px;
  text-transform: uppercase;
}

.footnote-dialog {
  background: #F0F3F7;
  border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
}
.footnote-dialog ul {
  margin: 0;
  padding: 15px;
  list-style: none;
}
.footnote-dialog ul li p {
  font: 400 12px "Inter", sans-serif;
  font-weight: 800;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 4px;
  color: #324e57cc;
}
.footnote-dialog ul li p span {
  font-weight: normal;
  margin-right: 6px;
  text-transform: uppercase;
}

.cta-btngroup {
  width: 100%;
  text-align: right;
  padding: 20px 0;
  display: block;
}
.cta-btngroup .btn {
  display: inline-block;
  flex: none;
  margin-left: 10px !important;
}

.empty-403 {
  height: 75vh;
}
.empty-403 h1 {
  color: #324E57;
  font-size: 2rem;
  font-weight: 600;
}
.modal.fade {
  transition: none;
}
.modal.fade .modal-dialog {
  transition: none;
  height: auto;
  width: auto;
}
.modal .modal-title {
  font: 700 16px "Inter", sans-serif;
}
.modal > .modal-dialog > .modal-content {
  border: 0;
}

.modal-backdrop {
  background-color: #121227;
}
.modal-backdrop.show {
  opacity: 0.85;
}
.modal-backdrop.fade {
  transition: none;
}
.modal-backdrop.semi {
  background-color: #203645;
}
.modal-backdrop.semi.show {
  opacity: 0.2;
}
.modal-backdrop.semi.fade {
  transition: opacity 0.25s ease-in-out;
}

.modal-content-right {
  width: 100%;
  min-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.15) -10px 0 20px;
}
@media only screen and (min-width: 1024px) {
  .modal-content-right {
    width: 50%;
    min-width: 600px;
  }
}
.modal-content-right .modal-header {
  display: block;
  position: relative;
}
.modal-content-right .modal-footer {
  padding: 15px;
}
.modal-content-right .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-content-right button.close {
  background: url(../../images/icon/ico-mobile-sidebar-close.svg) no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  outline: 0;
  position: absolute;
  left: 0;
  top: 17px;
  right: 20px;
  opacity: 1;
  text-indent: -9999px;
  overflow: hidden;
}
.modal-content-right button.close:hover, .modal-content-right button.close:focus {
  opacity: 1;
}
.modal-content-right button.close:not(:disabled):not(.disabled) {
  opacity: 1;
}
.modal-content-right button.close span {
  display: inherit;
  margin-top: -8px;
  margin-left: -1px;
}
@media only screen and (min-width: 1024px) {
  .modal-content-right button.close {
    position: absolute;
    left: -44px;
    top: 26px;
    right: initial;
  }
}

.modal-full-size {
  background: #FFFFFF;
  width: 100%;
  max-width: 100%;
  margin: 0;
  max-height: initial;
  min-height: 100%;
}
.modal-full-size .modal-content {
  width: 75%;
  margin: 0 auto;
  border: 0;
  border-radius: 0;
  padding: 75px 0;
}
.modal-full-size .modal-content .modal-header {
  padding: 1rem 0;
}
.modal-full-size .modal-content .modal-header .feature-logo {
  width: 12% !important;
}
@media (max-width: 1024px) {
  .modal-full-size .modal-content .modal-header .feature-logo {
    width: 20% !important;
  }
}
@media (max-width: 768px) {
  .modal-full-size .modal-content .modal-header .feature-logo {
    width: 25% !important;
  }
}
@media (max-width: 667px) {
  .modal-full-size .modal-content .modal-header .feature-logo {
    width: 30% !important;
  }
}
@media (max-width: 480px) {
  .modal-full-size .modal-content .modal-header .feature-logo {
    width: 50% !important;
  }
}
.modal-full-size .modal-content .modal-body {
  padding: 1rem 0;
}
@media (max-width: 1470px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 31.4% !important;
  }
}
@media (max-width: 1399px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 31.3% !important;
  }
}
@media (max-width: 1365px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 31.2% !important;
  }
}
@media (max-width: 1279px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 31% !important;
  }
}
@media (max-width: 1190px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 48% !important;
  }
}
@media (max-width: 981px) {
  .modal-full-size .modal-content .modal-body .feature-wrap .feature-list .icon {
    flex: 0 0 100% !important;
  }
}
.modal-full-size .modal-content .modal-footer {
  padding: 15px 0 0;
}

.workorder h3 {
  font: 700 20px "Inter", sans-serif;
  padding-left: 4px;
}
.workorder h3 .badge {
  position: relative;
  top: -3px;
}
.workorder section {
  padding: 10px 0;
}
.workorder section label {
  color: #259F9F;
  font: 700 14px "Inter", sans-serif;
  margin-bottom: 10px;
}

.form-control-static {
  display: flex;
}
.form-control-static span {
  flex: 0 0 35%;
}
.form-control-static p {
  font: 700 14px "Inter", sans-serif;
}

.form-col-left {
  padding: 0 0 0 20px;
}

.badge {
  border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -webkit-border-radius: 9999px !important;
  border: 0;
  padding: 6px;
  vertical-align: middle;
}
.badge.badge-pill {
  line-height: 1.3;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  font-size: 11px;
  padding: 8px 16px;
  /*box-shadow: rgb(0 0 0 / 10%) 0 2px 1px;*/
  text-transform: uppercase;
  color: #fff;
}
.badge.badge-pill.badge-sent {
  color: #203645;
  background-color: #C7BDFF;
}
.badge.badge-pill.badge-sent:focus, .badge.badge-pill.badge-sent:hover {
  color: #203645;
  background-color: #C7BDFF;
}
.badge.badge-pill.badge-viewed {
  color: #203645;
  background-color: #FFB9A2;
}
.badge.badge-pill.badge-viewed:focus, .badge.badge-pill.badge-viewed:hover {
  color: #203645;
  background-color: #FFB9A2;
}
.badge.badge-pill.badge-primary {
  color: #FCFCFC;
  background-color: #FF9571;
}
.badge.badge-pill.badge-primary:focus, .badge.badge-pill.badge-primary:hover {
  color: #FCFCFC;
  background-color: #FF9571;
}
.badge.badge-pill.badge-secondary {
  color: #203645;
  background-color: #C7A2CC;
}
.badge.badge-pill.badge-secondary:focus, .badge.badge-pill.badge-secondary:hover {
  color: #203645;
  background-color: #C7A2CC;
}
.badge.badge-pill.badge-success {
  color: #FCFCFC;
  background-color: #806BFF;
}
.badge.badge-pill.badge-success:focus, .badge.badge-pill.badge-success:hover {
  color: #FCFCFC;
  background-color: #806BFF;
}
.badge.badge-pill.badge-info {
  color: #203645;
  background-color: #00BBFF;
}
.badge.badge-pill.badge-info:focus, .badge.badge-pill.badge-info:hover {
  color: #203645;
  background-color: #00BBFF;
}
.badge.badge-pill.badge-warning {
  color: #203645;
  background-color: #FFBF3F;
}
.badge.badge-pill.badge-warning:focus, .badge.badge-pill.badge-warning:hover {
  color: #203645;
  background-color: #FFBF3F;
}
.badge.badge-pill.badge-danger {
  color: #FCFCFC;
  background-color: #BD2947;
}
.badge.badge-pill.badge-danger:focus, .badge.badge-pill.badge-danger:hover {
  color: #FCFCFC;
  background-color: #BD2947;
}
.badge.badge-pill.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge.badge-pill.badge-light:focus, .badge.badge-pill.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
.badge.badge-pill.badge-dark {
  color: #203645;
  background-color: #ED7A23;
}
.badge.badge-pill.badge-dark:focus, .badge.badge-pill.badge-dark:hover {
  color: #203645;
  background-color: #ED7A23;
}
.badge.badge-pill.badge-accepted {
  color: #203645;
  background-color: #73E6DC;
}
.badge.badge-pill.badge-accepted:focus, .badge.badge-pill.badge-accepted:hover {
  color: #203645;
  background-color: #73E6DC;
}
.badge.badge-pill.badge-archive {
  color: #203645;
  background-color: #C7A2CC;
}
.badge.badge-pill.badge-archive:focus, .badge.badge-pill.badge-archive:hover {
  color: #203645;
  background-color: #C7A2CC;
}

.tags {
  border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -webkit-border-radius: 9999px !important;
  border: 0;
  vertical-align: middle;
  line-height: 1.3;
  border-radius: 9999px;
  -moz-border-radius: 9999px;
  -webkit-border-radius: 9999px;
  font-size: 11px;
  padding: 8px 16px;
  /*box-shadow: rgb(0 0 0 / 10%) 0 2px 1px;*/
  text-transform: uppercase;
  color: #fff;
  padding: 7px 10px 4px;
  font-weight: 700;
}
.tags.active {
  color: #203645;
  background-color: #7BF297;
}
.tags.active:focus, .tags.active:hover {
  color: #203645;
  background-color: #7BF297;
}
.tags.inactive {
  color: #203645;
  background-color: #ED7A23;
}
.tags.inactive:focus, .tags.inactive:hover {
  color: #203645;
  background-color: #ED7A23;
}

/* Invoice List Badge */
.invoice-source {
  border-radius: 50%;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px;
  padding: 9px 0;
  font-weight: 700;
  position: relative;
  text-align: center;
  line-height: 15px;
  width: 32px;
  height: 32px;
  margin: 5px;
  text-transform: uppercase;
}

.invoice-source-ri {
  color: #fff;
  background-color: #23612f;
}

.invoice-source-mi {
  color: #fff;
  background-color: #3a718e;
}

.invoice-source-es {
  color: #fff;
  background-color: #e49c23;
}

.invoice-source-wo {
  color: #fff;
  background-color: #379230;
}

.invoice-source-ri {
  color: #fff;
  background-color: #23612f;
}

.invoice-source-rp {
  color: #fff;
  background-color: #319e99;
}

.invoice-source-rect {
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px;
  padding: 9px;
  font-weight: 700;
  position: relative;
  text-align: center;
  line-height: 15px;
  margin: 5px;
}

.heartland-payment-type #credit-card-card-number iframe {
  width: 50%;
  padding-right: 15px;
}
@media (max-width: 981px) {
  .heartland-payment-type #credit-card-card-number iframe {
    width: 100%;
    padding-right: 0;
  }
}
.heartland-payment-type #credit-card-card-expiration iframe,
.heartland-payment-type #credit-card-card-cvv iframe {
  width: 100%;
}

.form-builder-embed .custom-submit-button svg {
  display: none;
}
.form-builder-embed .custom-submit-button.submit-fail svg {
  display: none;
}
.form-builder-embed .formio-component-productfield .dropdown-menu.show {
  height: 200px;
  transform: translate3d(0px, 5px, 0px) !important;
  top: 30px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (max-width: 768px) {
  .form-builder-embed .formio-component-heartlandpayment .btn-primary {
    margin: 0 0 15px;
  }
  .form-builder-embed .formio-component-heartlandpayment .btn-success {
    margin: 0 0 15px;
  }
}
.form-builder-embed .formio-component-subscriptionPlans {
  background: #fff;
  padding: 25px;
  margin: 20px 0;
}
.form-builder-embed .formio-component-subscriptionPlans .subscription-location-filter {
  position: absolute;
  right: 25px;
  width: 250px;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .subscription-location-filter {
    position: relative;
    right: 0;
    width: 250px;
    margin: 0 0 20px auto;
  }
}
@media (max-width: 667px) {
  .form-builder-embed .formio-component-subscriptionPlans .subscription-location-filter {
    width: 350px;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav {
  border-top: 0;
  background: transparent;
  text-align: center;
  margin-bottom: 20px;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item {
  display: block;
  width: 100%;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link {
  display: none;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
  display: block;
  border-bottom: 4px solid #aa1313;
  padding: 6px 10px 6px;
  width: 25%;
  margin: 0 auto;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 40%;
  }
}
@media (max-width: 667px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 60%;
  }
}
@media (max-width: 414px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 100%;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card {
  text-align: center;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card .card-img-top {
  height: 200px;
  margin: 0 auto;
  object-fit: cover;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card .card-header {
  padding: 10px;
  margin-bottom: 0;
  background-color: #e6e6e6;
  border-bottom: 0;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card .card-header h4 {
  font-size: 22px;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card .card-body {
  text-align: left;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #all-details-tab-panel .card .card-body .btn {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body {
  padding: 0;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-4 {
  text-align: center;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-4 img {
  padding: 10px;
  margin-top: 20px;
  border: 0;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 {
    padding-left: 40px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 h4 {
  font-size: 30px;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 h3 {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-description {
  font-size: 16px;
  margin: 20px 0 0;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-description {
    display: block !important;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-description span {
  padding: 0;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-description .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-inclusion {
  font-size: 16px;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-inclusion span {
  padding: 0;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-inclusion .col {
  padding: 0;
}
@media (max-width: 981px) {
  .form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-susbscription-inclusion .d-flex {
    display: block !important;
  }
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .plan-option-addon input[type=checkbox] {
  vertical-align: middle;
  margin-right: 5px;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-subscription-btn {
  margin: 0 !important;
  padding-top: 20px !important;
}
.form-builder-embed .formio-component-subscriptionPlans .product-tabs .tab-content #product-details-tab-panel .card-body .row .col-sm-8 .product-subscription-btn .btn {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
}
.form-builder-embed .formio-component-form .fileSelector {
  position: relative;
}
.form-builder-embed .formio-component-form .fileSelector svg {
  display: none;
}
.form-builder-embed .formio-component-form .fileSelector:before {
  content: url("/assets/images/icon/ico-file-upload-solid.svg");
  width: 13px;
  height: auto;
  display: inline-block;
  vertical-align: sub;
  margin: 0 5px;
}
.form-builder-embed .formio-component-file .list-group .list-group-item:not(:first-child) .row .col-md-1 {
  position: relative;
}
.form-builder-embed .formio-component-file .list-group .list-group-item:not(:first-child) .row .col-md-1 svg {
  display: none;
}
.form-builder-embed .formio-component-file .list-group .list-group-item:not(:first-child) .row .col-md-1:before {
  content: url("/assets/images/icon/ico-trash-can-solid.svg");
  width: 13px;
  height: auto;
  display: inline-block;
  vertical-align: sub;
  margin: 0 5px;
}

.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

/* NOTIFICATION VARIABLES */
.swal2-container .swal2-popup {
  background: #FCFCFC;
  box-shadow: 0px 2px 2px rgba(2, 2, 15, 0.01), 0px 2px 4px rgba(2, 2, 15, 0.01), 0px 0px 24px rgba(2, 2, 15, 0.01), 0px 2px 72px rgba(2, 2, 15, 0.01);
  border-radius: 12px;
  padding: 30px 20px;
}
.swal2-container .swal2-popup .swal2-close {
  display: block !important;
  color: #203645;
  width: auto;
  height: auto;
  position: relative;
  top: -20px;
}
.swal2-container .swal2-popup .swal2-title {
  color: #203645;
  padding: 5px 1em 0;
}
.swal2-container .swal2-popup .swal2-html-container {
  color: #6B747D;
  font-size: 16px;
}
.swal2-container .swal2-popup .swal2-actions .swal2-confirm {
  background-color: #1362E2;
  display: block;
  width: 85%;
  border-radius: 9999px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.swal2-container .swal2-popup .swal2-actions .swal2-deny {
  background-color: #FF5772;
  display: block;
  width: 85%;
  border-radius: 9999px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.swal2-container .swal2-popup .swal2-actions .swal2-cancel {
  background-color: #CDD1D3;
  display: block;
  width: 85%;
  border-radius: 9999px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.swal2-container .swal2-popup .swal2-icon {
  color: #203645;
}
.swal2-container .swal2-popup .swal2-icon.swal2-success .swal2-success-line-tip {
  background-color: #203645;
  z-index: 9999;
}
.swal2-container .swal2-popup .swal2-icon.swal2-success .swal2-success-line-long {
  background-color: #203645;
  z-index: 9999;
}
.swal2-container .swal2-popup .swal2-icon.swal2-success .swal2-success-ring {
  background: #A0E9A7;
}
.swal2-container .swal2-popup .swal2-icon.swal2-error {
  border-color: #FF5772;
  color: #203645;
  background: #FF5772;
}
.swal2-container .swal2-popup .swal2-icon.swal2-error .swal2-x-mark-line-left,
.swal2-container .swal2-popup .swal2-icon.swal2-error .swal2-x-mark-line-right {
  background-color: #203645;
}
.swal2-container .swal2-popup .swal2-icon.swal2-info {
  border-color: #CDD1D3;
  color: #203645;
  background: #CDD1D3;
}
.swal2-container .swal2-popup .swal2-icon.swal2-info .swal2-icon-content {
  color: #203645;
}
.swal2-container .swal2-popup .swal2-icon.swal2-warning {
  border-color: #FFDC83;
  color: #203645;
  background: #FFDC83;
}
.swal2-container .swal2-popup .swal2-icon.swal2-warning .swal2-icon-content {
  color: #203645;
}
.swal2-container .swal2-popup .swal2-icon.swal2-question {
  border-color: #CDD1D3;
  color: #203645;
  background: #CDD1D3;
}
.swal2-container .swal2-popup .swal2-icon.swal2-question .swal2-icon-content {
  color: #203645;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.text-xs {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1rem;
  /* 16px */
}

.text-sm {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
}

.text-base {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
}

.text-lg {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}

.text-xl {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
}

.text-2xl {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}

.\!text-xs {
  font-size: 0.75rem !important;
  /* 12px */
  line-height: 1rem !important;
  /* 16px */
}

.\!text-sm {
  font-size: 0.875rem !important;
  /* 14px */
  line-height: 1.25rem !important;
  /* 20px */
}

.\!text-base {
  font-size: 1rem !important;
  /* 16px */
  line-height: 1.5rem !important;
  /* 24px */
}

.\!text-lg {
  font-size: 1.125rem !important;
  /* 18px */
  line-height: 1.75rem !important;
  /* 28px */
}

.\!text-xl {
  font-size: 1.25rem !important;
  /* 20px */
  line-height: 1.75rem !important;
  /* 28px */
}

.\!text-2xl {
  font-size: 1.5rem !important;
  /* 24px */
  line-height: 2rem !important;
  /* 32px */
}

.font-poppins {
  font-family: Poppins, Arial, sans-serif;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.gu-unselectable {
  scroll-behavior: auto;
}
.gu-unselectable .gu-mirror {
  scroll-behavior: auto;
}
.gu-unselectable .gu-mirror * {
  display: none;
}

.recurring-configuration .e-spin-up::before {
  display: none;
}
.recurring-configuration .e-spin-up::after {
  display: inline-block;
  content: "";
  border-bottom: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-top: 0;
  border-left: 0.5em solid transparent;
}
.recurring-configuration #RecurrenceEditor .e-end-on-date {
  display: none;
}

.formio-component-key .col-form-label {
  color: #fff;
  position: relative;
  font-size: 0;
}
.formio-component-key .col-form-label::before {
  content: "Field Name";
  color: #5c6874;
  font-size: 12px;
}
.formio-component-key .col-form-label svg {
  font-size: 12px;
  margin-left: 10px;
}

.invoice-recurring-payment .e-spin-up::before {
  display: none;
}
.invoice-recurring-payment .e-spin-up::after {
  display: inline-block;
  content: "";
  border-bottom: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-top: 0;
  border-left: 0.5em solid transparent;
}

.formio .builder-sidebar .card {
  margin-bottom: 15px;
  border: 0;
}
.formio .builder-sidebar .card .card-header {
  border-bottom: 0;
}
.formio .builder-sidebar .card .card-body .btn-block {
  justify-content: flex-start;
  border-radius: 6px !important;
  border: 0;
  margin-bottom: 5px;
  padding: 0 25px;
}

.formio-dialog .formio-component .ck-editor .ck-sticky-panel .ck-toolbar__items .ck-file-dialog-button,
.formio-dialog .formio-component .ck-editor .ck-sticky-panel .ck-toolbar__items .ck-dropdown {
  display: none !important;
}
.formio-dialog .formio-component .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-heading-dropdown,
.formio-dialog .formio-component .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-font-family-dropdown,
.formio-dialog .formio-component .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-font-size-dropdown {
  display: inline-block !important;
}
.formio-dialog .formio-component .ck-editor .ck-content ul {
  padding: 0 0 0 30px;
}
.formio-dialog .formio-component .ck-editor .ck-content ul li {
  list-style: initial;
}
.formio-dialog .formio-component .ck-editor .ck-content ol {
  padding: 0 0 0 30px;
}
.formio-dialog .formio-component .ck-editor .ck-content dl {
  padding: 0 0 0 20px;
}
.formio-dialog .formio-component.formio-component-datagrid .table tbody tr td:first-child {
  position: relative;
  width: 65px;
}
.formio-dialog .formio-component.formio-component-datagrid .table tbody tr td:first-child::before {
  content: "=";
  font-size: 25px;
  padding: 0px 13px 0;
  line-height: 1.6;
  color: #fff;
  position: absolute;
  background: #6c757d;
  border-radius: 50px;
}
.formio-dialog .formio-component.formio-component-datagrid .table tbody tr td:first-child svg {
  padding: 19px 20px;
  border: 0;
  position: absolute;
}
.formio-dialog .formio-component.formio-component-customConditionalPanel .card-title {
  position: relative;
  vertical-align: middle;
}
.formio-dialog .formio-component.formio-component-customConditionalPanel .card-title::before {
  content: "⊞";
  color: #fff;
  font-size: 26px;
  background: #6c757d;
  border-radius: 3px;
  padding: 1px 11px 5px;
  top: 0;
  left: 26px;
}
.formio-dialog .formio-component.formio-component-customConditionalPanel .card-title svg {
  display: none;
}
.formio-dialog .formio-component.formio-component-overlay .card-title {
  position: relative;
}
.formio-dialog .formio-component.formio-component-overlay .card-title::before {
  content: "⊞";
  color: #666;
  font-size: 20px;
  line-height: 1em;
  text-align: center;
  vertical-align: bottom;
}
.formio-dialog .formio-component.formio-component-overlay .card-title svg {
  display: none;
}
.formio-dialog .formio-component.formio-component-custom-validation-js .card-title {
  position: relative;
}
.formio-dialog .formio-component.formio-component-custom-validation-js .card-title::before {
  content: "⊞";
  color: #666;
  font-size: 20px;
  line-height: 1em;
  text-align: center;
  vertical-align: bottom;
}
.formio-dialog .formio-component.formio-component-custom-validation-js .card-title svg {
  display: none;
}
.formio-dialog .formio-component.formio-component-json-validation-json .card-title {
  position: relative;
}
.formio-dialog .formio-component.formio-component-json-validation-json .card-title::before {
  content: "⊞";
  color: #666;
  font-size: 20px;
  line-height: 1em;
  text-align: center;
  vertical-align: bottom;
}
.formio-dialog .formio-component.formio-component-json-validation-json .card-title svg {
  display: none;
}
.formio-dialog .formio-component.formio-component-customDefaultValuePanel .card-title {
  position: relative;
}
.formio-dialog .formio-component.formio-component-customDefaultValuePanel .card-title::before {
  content: "⊞";
  color: #666;
  font-size: 20px;
  line-height: 1em;
  text-align: center;
  vertical-align: bottom;
}
.formio-dialog .formio-component.formio-component-customDefaultValuePanel .card-title svg {
  display: none;
}
.formio-dialog .formio-component.formio-component-calculateValuePanel .card-title {
  position: relative;
}
.formio-dialog .formio-component.formio-component-calculateValuePanel .card-title::before {
  content: "⊞";
  color: #666;
  font-size: 20px;
  line-height: 1em;
  text-align: center;
  vertical-align: bottom;
}
.formio-dialog .formio-component.formio-component-calculateValuePanel .card-title svg {
  display: none;
}
.formio-dialog .formio-component .formio-button-remove-row {
  position: relative;
  padding: 5px 11px;
}
.formio-dialog .formio-component .formio-button-remove-row::before {
  content: "✖";
  color: #fff;
  font-size: 20px;
  line-height: 1.2em;
  text-align: center;
  vertical-align: text-top;
}
.formio-dialog .formio-component .formio-button-remove-row svg {
  display: none;
}
.formio-dialog .formio-component table tbody td svg path {
  display: none;
}
.formio-dialog .component-edit-container .form-check-input {
  margin-top: 0.1rem;
}
.formio-dialog .component-edit-container .formio-component .choices__list--dropdown .choices__list {
  position: relative;
}
.formio-dialog .component-edit-container .choices__list--dropdown {
  width: 250px;
}
.formio-dialog .component-edit-container .choices__list--dropdown .choices__list {
  background: #fff;
  position: fixed;
  width: 250px;
  margin-top: -10px;
}
.formio-dialog .component-edit-container .row:first-child .col-sm-6:nth-child(2) > div {
  margin-right: 40px !important;
  margin-top: 5px !important;
}
.formio-dialog .component-edit-container .row:first-child .col-sm-6:last-child {
  display: none;
}
.formio-dialog .component-edit-container .row:last-child .col-sm-6:last-child .preview-panel {
  display: block;
}
.formio-dialog .component-edit-container .row:last-child .col-sm-6:last-child > div {
  display: inline-flex;
}
.formio-dialog .component-edit-container .row:last-child .col-sm-12 > div:last-child {
  display: flex;
  justify-content: flex-start;
}
.formio-dialog .component-edit-container .formio-component-subscriptionPlans .subscription-location-filter {
  width: 215px;
  margin: 0 0 30px auto;
}
@media (max-width: 981px) {
  .formio-dialog .component-edit-container .formio-component-subscriptionPlans .subscription-location-filter {
    width: 270px;
  }
}
@media (max-width: 667px) {
  .formio-dialog .component-edit-container .formio-component-subscriptionPlans .subscription-location-filter {
    width: auto;
  }
}
.formio-dialog .component-edit-container .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
  width: 60%;
}
@media (max-width: 981px) {
  .formio-dialog .component-edit-container .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 60%;
  }
}
@media (max-width: 667px) {
  .formio-dialog .component-edit-container .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 80%;
  }
}
@media (max-width: 414px) {
  .formio-dialog .component-edit-container .formio-component-subscriptionPlans .product-tabs > .nav .nav-item .nav-link.active {
    width: 100%;
  }
}
.formio-dialog .component-edit-container .formio-component-subscriptionPlans .tab-pane .owl-nav .owl-prev svg {
  margin: 5px 0 0;
}
.formio-dialog .component-edit-container .formio-component-subscriptionPlans .tab-pane .owl-nav .owl-next svg {
  margin: 5px 0 0;
}
.formio-dialog .component-edit-container .formio-component-subscriptionPlans .tab-pane .card .card-img-top {
  height: 150px;
  vertical-align: middle;
}
.formio-dialog .component-edit-container .formio-component-textarea.required {
  color: #212529;
}

.formio-component-termscondition .custom-modal-terms {
  overflow: auto !important;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body ul {
  padding: 0 0 0 30px;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body ul li {
  list-style: initial;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body ol {
  padding: 0 0 0 30px;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body dl {
  padding: 0 0 0 20px;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body .text-huge {
  font-size: 1.8em;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body .text-big {
  font-size: 1.4em;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body .text-small {
  font-size: 0.85em;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body .text-tiny {
  font-size: 0.7em;
}
.formio-component-termscondition .custom-modal-terms .modal-content .modal-body blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}

.payment-confirm-action {
  position: fixed;
  left: 0;
  right: 0;
  width: 40%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 800px) {
  .payment-confirm-action {
    width: 75%;
  }
}
@media (max-width: 485px) {
  .payment-confirm-action {
    width: 90%;
  }
}

.payment-form-builder .form-check-input {
  margin-top: 0.1rem;
}
.payment-form-builder .btn-primary[data-type=radio] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=radio]::before {
  content: "⊙";
  font-size: 17px;
  line-height: 0.85em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=radio] svg {
  display: none;
}
.payment-form-builder .builder-component .component-settings-button-move {
  position: relative;
}
.payment-form-builder .builder-component .component-settings-button-move::before {
  content: "⇄";
  color: #212529;
  font-size: 25px;
  line-height: 1.2em;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.payment-form-builder .builder-component .component-settings-button-move svg {
  display: none;
}
.payment-form-builder .builder-component .component-settings-button-remove {
  position: relative;
}
.payment-form-builder .builder-component .component-settings-button-remove::before {
  content: "✖";
  color: #fff;
  font-size: 28px;
  line-height: 1.2em;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.payment-form-builder .builder-component .component-settings-button-remove svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=time] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=time]::before {
  content: "◴";
  font-size: 17px;
  line-height: 0.6em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=time] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=currency] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=currency]::before {
  content: "$";
  font-size: 14px;
  line-height: 1em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=currency] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=signature] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=signature]::before {
  content: "✎";
  font-size: 17px;
  line-height: 0.9em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=signature] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=recaptcha-v3] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=recaptcha-v3]::before {
  content: "♲";
  font-size: 14px;
  line-height: 0.9em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=recaptcha-v3] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=content] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=content]::before {
  content: "☱";
  font-size: 14px;
  line-height: 0.9em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=content] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=tabs] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=tabs]::before {
  content: "❏";
  font-size: 14px;
  line-height: 0.9em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=tabs] svg {
  display: none;
}
.payment-form-builder .btn-primary[data-type=well] {
  position: relative;
  padding-left: 40px !important;
}
.payment-form-builder .btn-primary[data-type=well]::before {
  content: "⊡";
  font-size: 16px;
  line-height: 0.8em;
  position: absolute;
  left: 25px;
  margin-top: -4px;
}
.payment-form-builder .btn-primary[data-type=well] svg {
  display: none;
}

.embedded body {
  background: #fff;
}
@media (max-width: 736px) {
  .embedded body > div {
    top: 63vh !important;
    position: fixed !important;
  }
}
@media (max-width: 736px) {
  .embedded body > div.swal2-container {
    top: 0 !important;
  }
}

.subscription-form .builder-component .component-btn-group {
  z-index: 8;
}

.formio-component-subscriptionPlans .col-form-label {
  display: none;
}
.formio-component-subscriptionPlans .subscription-location-filter {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
  justify-content: flex-end;
}
.formio-component-subscriptionPlans .subscription-location-filter label {
  margin: 0;
}
.formio-component-subscriptionPlans .subscription-location-filter .form-control {
  width: 160px;
}
.formio-component-subscriptionPlans .tab-content .tab-pane h1 {
  color: #181830;
  font-size: 1.25rem;
  font-weight: 800;
  padding: 30px 0 25px;
}
.formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  grid-gap: 0;
  row-gap: 30px;
}
.formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container .subscription-list {
  flex: 100%;
  background-color: #FFFFFF;
  border: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
@media only screen and (min-width: 768px) {
  .formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container .subscription-list {
    flex: 0 0 49%;
  }
}
@media only screen and (min-width: 1200px) {
  .formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container .subscription-list {
    flex: 0 0 32.33%;
  }
}
.formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container .subscription-list .btn {
  height: 43px;
}
.formio-component-subscriptionPlans .tab-content .tab-pane .subscription-container .subscription-list .card {
  background-color: transparent;
  border: 0;
}

.virtual-terminal #credit-card-submit {
  height: 50px !important;
}
.virtual-terminal #credit-card-card-holder iframe,
.virtual-terminal #credit-card-card-number iframe,
.virtual-terminal #credit-card-card-expiration iframe,
.virtual-terminal #credit-card-card-cvv iframe {
  width: 100%;
}
.virtual-terminal .form-group .dv-paymentlink-btns #credit-card-submit iframe {
  width: 100%;
}

.customer-detail-container .tab-pane .ag-body-viewport .badge.badge-pill {
  font-size: 9px;
}

.ck-body-wrapper {
  position: relative;
  z-index: 9999;
}

.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck-file-dialog-button,
.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck-dropdown,
.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck-disabled {
  display: none !important;
}
.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-heading-dropdown,
.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-font-family-dropdown,
.tcp-editor .ck-editor .ck-sticky-panel .ck-toolbar__items .ck.ck-dropdown.ck-font-size-dropdown {
  display: inline-block !important;
}
.tcp-editor .ck-editor .ck-content {
  height: 500px !important;
  min-height: 500px !important;
}
.tcp-editor .ck-editor .ck-content ul {
  padding: 0 0 0 30px;
}
.tcp-editor .ck-editor .ck-content ul li {
  list-style: initial;
}
.tcp-editor .ck-editor .ck-content ol {
  padding: 0 0 0 30px;
}
.tcp-editor .ck-editor .ck-content dl {
  padding: 0 0 0 20px;
}

.tcp-container ul {
  padding: 0 0 0 30px;
}
.tcp-container ul li {
  list-style: initial;
}
.tcp-container ol {
  padding: 0 0 0 30px;
}
.tcp-container dl {
  padding: 0 0 0 20px;
}
.tcp-container .text-huge, .tcp-container h1 {
  font-size: 1.8em;
}
.tcp-container .text-big, .tcp-container h2 {
  font-size: 1.4em;
}
.tcp-container .text-small, .tcp-container h3 {
  font-size: 0.85em;
}
.tcp-container .text-tiny, .tcp-container h4 {
  font-size: 0.7em;
}
.tcp-container blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}

/*
  Declare variables before making them global.
  dart-sass doesn't allow to declare variable with !global.
 */
/**
 * This mixin generates keyfames.
 * Because of all keyframes can't be scoped,
 * we need to puts unique name in each btn-pulse call.
 */
/*

According to the specification (https://www.w3.org/TR/css-scoping-1/#host-selector)
:host and :host-context are pseudo-classes. So we assume they could be combined,
like other pseudo-classes, even same ones.
For example: ':nth-of-type(2n):nth-of-type(even)'.

Ideal solution would be to prepend any selector with :host-context([dir=rtl]).
Then nebular components will behave as an html element and respond to [dir] attribute on any level,
so direction could be overridden on any component level.

Implementation code:

@mixin cj-rtl() {
  // add # to scss interpolation statement.
  // it works in comments and we can't use it here
  @at-root {selector-append(':host-context([dir=rtl])', &)} {
    @content;
  }
}

And when we call it somewhere:

:host {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}
:host-context(...) {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}

Result will look like:

:host-context([dir=rtl]):host .some-class {
  ...
}
:host-context([dir=rtl]):host-context(...) .some-class {
  ...
}

*
  Side note:
  :host-context():host selector are valid. https://lists.w3.org/Archives/Public/www-style/2015Feb/0305.html

  :host-context([dir=rtl]):host-context(...) should match any permutation,
  so order is not important.
*


Currently, there're two problems with this approach:

First, is that we can't combine :host, :host-context. Angular bugs #14349, #19199.
For the moment of writing, the only possible way is:
:host {
  :host-context(...) {
    ...
  }
}
It doesn't work for us because mixin could be called somewhere deeper, like:
:host {
  p {
    @include cj-rtl() { ... }
  }
}
We are not able to go up to :host level to place content passed to mixin.

The second problem is that we only can be sure that we appending :host-context([dir=rtl]) to another
:host/:host-context pseudo-class when called in theme files (*.theme.scss).
  *
    Side note:
    Currently, cj-install-component uses another approach where :host prepended with the theme name
    (https://github.com/angular/angular/blob/5b96078624b0a4760f2dbcf6fdf0bd62791be5bb/packages/compiler/src/shadow_css.ts#L441),
    but it was made to be able to use current realization of rtl and it can be rewritten back to
    :host-context($theme) once we will be able to use multiple shadow selectors.
  *
But when it's called in *.component.scss we can't be sure, that selector starts with :host/:host-context,
because angular allows omitting pseudo-classes if we don't need to style :host component itself.
We can break such selectors, by just appending :host-context([dir=rtl]) to them.
  ***
    Possible solution
    check if we in theme by some theme variables and if so append, otherwise nest like
    @at-root :host-context([dir=rtl]) {
      // add # to scss interpolation statement.
      // it works in comments and we can't use it here
      {&} {
        @content;
      }
    }
    What if :host specified? Can we add space in :host-context(...) :host?
    Or maybe add :host selector anyway? If multiple :host selectors are allowed
  ***


Problems with the current approach.

1. Direction can be applied only on document level, because mixin prepends theme class,
which placed on the body.
2. *.component.scss styles should be in :host selector. Otherwise angular will add host
attribute to [dir=rtl] attribute as well.


General problems.

Ltr is default document direction, but for proper work of cj-ltr (means ltr only),
[dir=ltr] should be specified at least somewhere. ':not([dir=rtl]' not applicable here,
because it's satisfy any parent, that don't have [dir=rtl] attribute.
Previous approach was to use single rtl mixin and reset ltr properties to initial value.
But sometimes it's hard to find, what the previous value should be. And such mixin call looks too verbose.
*/
/**
 * Sass map contains a list of all Theme variables and also their mappings into Component variables
 * A theme consists of a list of colors, backgrounds, borders, text styles and supporting variables.
 */
*, *::before, *::after {
  box-sizing: border-box;
}

body, .layout,
p, .paragraph {
  line-height: normal !important;
}

a {
  text-decoration: none !important;
}
a.btn {
  font-weight: 700;
}

.tag-pill {
  padding: 4px 12px;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.tag-pill.tag-new {
  color: var(--color-cyan-70);
  background-color: var(--color-blue-20);
}
.tag-pill.tag-overdue {
  color: var(--color-red-80);
  background-color: var(--color-red-30);
}
.tag-pill.tag-suspended {
  color: var(--color-red-60);
  background-color: var(--color-red-20);
}
.tag-pill.tag-archive, .tag-pill.tag-archived {
  color: var(--color-cyan-60);
  background-color: var(--color-cyan-20);
}
.tag-pill.tag-accepted {
  color: var(--color-teal-100);
  background-color: var(--color-teal-40);
}
.tag-pill.tag-active, .tag-pill.tag-recurring-active {
  color: var(--color-teal-100);
  background-color: var(--color-teal-60);
}
.tag-pill.tag-live {
  color: var(--color-pink-10);
  background-color: var(--color-pink-60);
}
.tag-pill.tag-completed {
  color: var(--color-green-80);
  background-color: var(--color-green-40);
}
.tag-pill.tag-in-progress {
  color: var(--color-orange-60);
  background-color: var(--color-salmon-20);
}
.tag-pill.tag-partially-paid, .tag-pill.tag-partial {
  color: var(--color-gold-100);
  background-color: var(--color-gold-20);
}
.tag-pill.tag-cancelled, .tag-pill.tag-paused {
  color: var(--color-red-10);
  background-color: var(--color-red-50);
}
.tag-pill.tag-viewed {
  color: var(--color-cyan-70);
  background-color: var(--color-cyan-30);
}
.tag-pill.tag-paid {
  color: var(--color-green-90);
  background-color: var(--color-green-40);
}
.tag-pill.tag-processed, .tag-pill.tag-refunded {
  color: var(--color-cyan-100);
  background-color: var(--color-purple-40);
}
.tag-pill.tag-for-payment {
  color: var(--color-orange-100);
  background-color: var(--color-orange-40);
}
.tag-pill.tag-pending, .tag-pill.tag-cancel-pending {
  color: var(--color-salmon-40);
  background-color: var(--color-gold-20);
}
.tag-pill.tag-sent {
  color: var(--color-purple-80);
  background-color: var(--color-plum-40);
}

.sk-loader {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 4px;
  background-color: var(--color-gray-30);
  width: 100%;
  height: 20px;
  will-change: transform;
}
.sk-loader::after, .sk-loader::before {
  box-sizing: border-box;
}
.sk-loader.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.sk-loader.progress-wave {
  transform: translate3d(0, 0, 0);
}
.sk-loader.progress-wave::after,
.sk-loader.progress-wave .progess-highlight {
  box-sizing: border-box;
}
.sk-loader.progress-wave .progess-highlight {
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  animation: progress 4s ease-in-out infinite;
  background-size: 200px 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  content: "";
}

@keyframes progress {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  100% {
    transform: translate3d(calc(200px + 100vw), 0, 0);
  }
}
hr,
.header-content,
.footer-content,
.summary-container {
  border-style: solid;
  border-color: var(--color-gray-50);
  border-width: 0;
}

.header-content {
  padding-bottom: 20px;
  border-bottom-width: 1px;
}

.footer-content {
  padding-top: 20px;
  border-top-width: 1px;
}

.modal-content-right {
  border-radius: 12px 0 0 12px;
}
.modal-content-right .modal-close {
  font-size: 26px;
  position: absolute;
  left: -50px;
  top: 10px;
}

.icon-small {
  font-size: 14px;
}

.icon-giant {
  font-size: 24px;
}

.currency-item::first-letter {
  padding-right: 4px;
}

.summary-container {
  border-width: 1px;
  border-radius: 12px;
  overflow-y: auto;
  scrollbar-face-color: var(--layout-scrollbar-color);
  scrollbar-track-color: transparent;
}
.summary-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.summary-container::-webkit-scrollbar-thumb {
  background: var(--layout-scrollbar-color);
  cursor: pointer;
  border-radius: calc(8px * 0.5);
}
.summary-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: calc(8px * 0.5);
}
.summary-container::-webkit-scrollbar-corner {
  background: transparent;
}
.summary-container hr {
  margin: 0;
  border-top-width: 1px;
}
.summary-container label {
  display: block;
  color: var(--color-gray-100);
  margin-bottom: 10px;
  font-weight: 700;
  font-size: initial;
  line-height: 19px;
}
.summary-container cj-form-field label {
  margin-bottom: 0;
}
.summary-container .details-container {
  border: 1px solid var(--color-cyan-20);
  border-radius: 10px;
  padding: 1.25rem;
}

.select-field {
  position: relative;
}
.select-field:after {
  content: "";
  display: inline-block;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  bottom: 10px;
  right: 16px;
  color: var(--color-blue-90);
}
.select-field label {
  display: block;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
}
.select-field label.required::after {
  content: " *";
  color: var(--color-danger-active);
}
.select-field select {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.4375rem 2.5rem 0.4375rem 1rem;
  height: 40px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: none;
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
}
.select-field select:focus {
  outline: none;
  border-color: var(--input-primary-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-primary-outline-color);
}
.select-field select:hover {
  border-color: var(--input-hover-border-color);
}
.select-field select:disabled {
  background-color: var(--input-disabled-background-color);
  border-color: var(--input-disabled-border-color);
  color: var(--input-disabled-text-color);
}
.select-field select.invalid {
  border-color: var(--input-danger-border-color);
}
.select-field select.invalid:focus {
  border-color: var(--input-danger-focus-border-color);
  box-shadow: 0 0 0 4px var(--input-danger-outline-color);
}
.select-field select.invalid:hover {
  border-color: var(--input-danger-hover-border-color);
}

.modal-content {
  border-radius: 12px;
}

.summary-content {
  height: 100%;
  overflow: auto;
}
@media (max-width: 1500px) {
  .summary-content {
    overflow: auto;
  }
}
@media (max-width: 1200px) {
  .summary-content .summary-history {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1366px) {
  .summary-content .summary-container {
    display: flex;
    max-height: 100% !important;
  }
}
@media (max-width: 1200px) {
  .summary-content .summary-container {
    flex-direction: column;
    gap: 20px;
  }
  .summary-content .summary-container .summary-details {
    padding: 0 25px 30px;
  }
}

.invoice-summary-number {
  color: var(--color-teal-100);
  font-size: 24px;
  line-height: 29px;
}

.summary-history {
  color: var(--color-cyan-60);
  border-left: 4px solid var(--color-cyan-80);
}
.summary-history strong {
  color: var(--color-cyan-70);
}

/*
  Declare variables before making them global.
  dart-sass doesn't allow to declare variable with !global.
 */
/**
 * This mixin generates keyfames.
 * Because of all keyframes can't be scoped,
 * we need to puts unique name in each btn-pulse call.
 */
/*

According to the specification (https://www.w3.org/TR/css-scoping-1/#host-selector)
:host and :host-context are pseudo-classes. So we assume they could be combined,
like other pseudo-classes, even same ones.
For example: ':nth-of-type(2n):nth-of-type(even)'.

Ideal solution would be to prepend any selector with :host-context([dir=rtl]).
Then nebular components will behave as an html element and respond to [dir] attribute on any level,
so direction could be overridden on any component level.

Implementation code:

@mixin cj-rtl() {
  // add # to scss interpolation statement.
  // it works in comments and we can't use it here
  @at-root {selector-append(':host-context([dir=rtl])', &)} {
    @content;
  }
}

And when we call it somewhere:

:host {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}
:host-context(...) {
  .some-class {
    @include cj-rtl() {
      ...
    }
  }
}

Result will look like:

:host-context([dir=rtl]):host .some-class {
  ...
}
:host-context([dir=rtl]):host-context(...) .some-class {
  ...
}

*
  Side note:
  :host-context():host selector are valid. https://lists.w3.org/Archives/Public/www-style/2015Feb/0305.html

  :host-context([dir=rtl]):host-context(...) should match any permutation,
  so order is not important.
*


Currently, there're two problems with this approach:

First, is that we can't combine :host, :host-context. Angular bugs #14349, #19199.
For the moment of writing, the only possible way is:
:host {
  :host-context(...) {
    ...
  }
}
It doesn't work for us because mixin could be called somewhere deeper, like:
:host {
  p {
    @include cj-rtl() { ... }
  }
}
We are not able to go up to :host level to place content passed to mixin.

The second problem is that we only can be sure that we appending :host-context([dir=rtl]) to another
:host/:host-context pseudo-class when called in theme files (*.theme.scss).
  *
    Side note:
    Currently, cj-install-component uses another approach where :host prepended with the theme name
    (https://github.com/angular/angular/blob/5b96078624b0a4760f2dbcf6fdf0bd62791be5bb/packages/compiler/src/shadow_css.ts#L441),
    but it was made to be able to use current realization of rtl and it can be rewritten back to
    :host-context($theme) once we will be able to use multiple shadow selectors.
  *
But when it's called in *.component.scss we can't be sure, that selector starts with :host/:host-context,
because angular allows omitting pseudo-classes if we don't need to style :host component itself.
We can break such selectors, by just appending :host-context([dir=rtl]) to them.
  ***
    Possible solution
    check if we in theme by some theme variables and if so append, otherwise nest like
    @at-root :host-context([dir=rtl]) {
      // add # to scss interpolation statement.
      // it works in comments and we can't use it here
      {&} {
        @content;
      }
    }
    What if :host specified? Can we add space in :host-context(...) :host?
    Or maybe add :host selector anyway? If multiple :host selectors are allowed
  ***


Problems with the current approach.

1. Direction can be applied only on document level, because mixin prepends theme class,
which placed on the body.
2. *.component.scss styles should be in :host selector. Otherwise angular will add host
attribute to [dir=rtl] attribute as well.


General problems.

Ltr is default document direction, but for proper work of cj-ltr (means ltr only),
[dir=ltr] should be specified at least somewhere. ':not([dir=rtl]' not applicable here,
because it's satisfy any parent, that don't have [dir=rtl] attribute.
Previous approach was to use single rtl mixin and reset ltr properties to initial value.
But sometimes it's hard to find, what the previous value should be. And such mixin call looks too verbose.
*/
/**
 * Sass map contains a list of all Theme variables and also their mappings into Component variables
 * A theme consists of a list of colors, backgrounds, borders, text styles and supporting variables.
 */
vega-table td {
  border-color: #F2F5F8 !important;
  border-bottom-width: 2px !important;
}
vega-table label {
  margin: 0;
}
vega-table vega-pagination {
  padding: 0 30px;
}

vega-pagination ul.vega-pagination li {
  color: var(--color-cool-gray-70) !important;
  border: 1px solid #F0F3F7;
  border-radius: 4px;
}
vega-pagination ul.vega-pagination li:not(.page-ellipsis) {
  margin: 0 4px;
}
vega-pagination ul.vega-pagination li:first-child {
  margin-left: 0;
}
vega-pagination ul.vega-pagination li:first-child {
  margin-right: 0;
}
vega-pagination ul.vega-pagination li:hover {
  text-decoration: none !important;
}
vega-pagination ul.vega-pagination li:hover:not(.active) {
  background-color: rgba(4, 4, 28, 0.05) !important;
}
vega-pagination ul.vega-pagination li.active {
  background-color: var(--color-blue-90);
}
vega-pagination ul.vega-pagination li.active button {
  color: #FFFFFF !important;
}
vega-pagination ul.vega-pagination li.pager-ellipsis, vega-pagination ul.vega-pagination li.disabled {
  pointer-events: none;
}
vega-pagination ul.vega-pagination li.disabled {
  opacity: 0.3;
}
vega-pagination ul.vega-pagination li button {
  color: var(--color-cool-gray-70) !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
}

.vega-input svg {
  margin: unset;
}