.main-wrap {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	position: absolute;
	top: 80px;
	bottom: 0;
	left: 0;
	right: 0;
	.main-sidebar {
		background: $sidebg;
		overflow: hidden;
		position: fixed;
		z-index: 99;
		padding: 0;
		left: 0;
		width: 280px;
		height: 100vh;
		margin-top: -80px;
		.sidebar {
			height:100vh;
			&.dropdown {
				background: $sidebg;
				.sidebar-ticker {
					display: none;
					position: absolute;
					width: 38px;
					height: 38px;
					background: url("../../images/icon/ico-mobile-sidebar-open.svg") no-repeat;
					background-position: center;
					background-size: contain;
					border: 0;
					text-indent: -999px;
					overflow: hidden;
					position: absolute;
					right: -58px;
					top: 5px;
					z-index: 100;
					@include transition-effect;
				}
				&.show {
					.sidebar-ticker {
						background: url("../../images/icon/ico-mobile-sidebar-close.svg") no-repeat;
						background-size: contain;
						@include transition-effect;
						right: -44px;
					}
				}
			}
			.logo {
				padding: 14px 0;
			    text-align: left;
			    border-bottom: 1px solid #ABC6D8;
			    width: 90%;
			    margin: 0 auto;
			    .app-logo {
				    padding: 0 10px;
				}
				.tagline {
				    padding: 10px 10px 0;
				}
			}
			.dropdown-menu-myaccount {
				width: 100%;
			    padding: 0 0 10px 0;
			    height: calc(100vh - 140px);
			    overflow-y: auto;
			    overflow-x: hidden;
			    margin: 0;
			    border: 0;
			    border-radius: 0;
			    background-color: $sidebg;
			    border-width: 0px;
			    box-shadow: none;
			    display: block;
			    top: 90px;
			    z-index: 0;
			    box-sizing: content-box;
				.dropdown-header {
					font-size: 16px;
					font-weight: 600;
					padding: 15px 10px 10px;
					&::first-child {
						padding-top: 0px;
					}
				}
				&::-webkit-scrollbar {
				    width: 0;  
				    background: transparent;
				}
				> * {
					&:last-child {
						padding-bottom: 50px;
					}
				}
				li {
					position: relative;
					z-index: 1;
					.ico-toggle {
						position: absolute;
						right: 20px;
						top: 16px;
					}
					.divider {
					    display: block;
					    color: #6b747d;
					    font-size: 0.85rem;
					    font-weight: 400;
					    padding: 15px 25px 15px 25px;
					}
					a {
    					position: relative;
					    display: block;
					    color: #203645;
					    font-size: $font_normal;
					    font-weight: 600;
					    min-height: 50px;
					    padding: 15px 25px 15px 50px;
					    width: 95%;
					    margin: 0 auto;
					    border-radius: 10px;
						svg {
							&.side-ico {
								font-size: $iconsize;
								padding: 8px 10px;
							}
						}
						&.link-inactive {
						    pointer-events: none;
						}
						.side-ico {
							width: 40px;
							height: 40px;
							display: inline-block;
							vertical-align: middle;
							background-repeat: no-repeat;
							background-size:50%;
							background-position: center;
							position: absolute;
							left: 5px;
							top: 6px;
							&-dashboard {
								background-image: url('../../images/icon/ico-dashboard.png');
							}
							&-customer {
								background-image: url('../../images/icon/ico-customers.png');
							}
							&-inventory {
								background-image: url('../../images/icon/ico-products.png');
							}
							&-workorders {
								background-image: url('../../images/icon/ico-workorder.svg');
							}
							&-invoice {
								background-image: url('../../images/icon/icon-invoice.svg');
							}
							&-pl {
								background-image: url('../../images/icon/icon-payment-link.svg');
							}
							&-pf {
								background-image: url('../../images/icon/icon-payment-forms.svg');
							}
							&-subscription {
								background-image: url('../../images/icon/ico-subscription.svg');
							}
							&-tips {
								background-image: url('../../images/icon/ico-tips.svg');
							}
							&-reports {
								background-image: url('../../images/icon/ico-reports.png');
							}
							&-settings {
								background-image: url('../../images/icon/ico-settings.png');
							}
							&-virtual-sell {
								background-image: url('../../images/icon/ico-virtualsell.png');
							}
							&-pos {
								background-image: url('../../images/icon/ico-pos.png');
							}
							&-payroll {
								background-image: url('../../images/icon/ico-payroll.png');
							}
							&-capital {
								background-image: url('../../images/icon/ico-capital.png');
							}
							&-metric {
								background-image: url('../../images/icon/ico-metric.png');
							}
							&-pmplus {
								background-image: url('../../images/icon/ico-pmplus.png');
							}
						}
					}
					&.active {
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
					}
					&.active,
					&:focus,
					&:active,
					&:hover {
						a {
							background-color: #E3F7FD;
							color: #1362E2;
							.side-ico {
								&-dashboard {
									background-image: url('../../images/icon/ico-dashboard-active.png');
								}
								&-customer {
									background-image: url('../../images/icon/ico-customers-active.png');
								}
								&-inventory {
									background-image: url('../../images/icon/ico-products-active.png');
								}
								&-workorders {
									background-image: url('../../images/icon/ico-workorder.svg');
								}
								&-invoice {
									background-image: url('../../images/icon/icon-invoice-hover.svg');
								}
								&-pl {
									background-image: url('../../images/icon/icon-payment-link-hover.svg');
								}
								&-pf {
									background-image: url('../../images/icon/icon-payment-forms-hover.svg');
								}
								&-subscription {
									background-image: url('../../images/icon/ico-subscription.svg');
								}
								&-tips {
									background-image: url('../../images/icon/ico-tips.svg');
								}
								&-reports {
									background-image: url('../../images/icon/ico-reports-active.png');
								}
								&-settings {
									background-image: url('../../images/icon/ico-settings-active.png');
								}
								&-virtual-sell {
								background-image: url('../../images/icon/ico-virtualsell-active.png');
								}
								&-pos {
									background-image: url('../../images/icon/ico-pos-active.png');
								}
								&-payroll {
									background-image: url('../../images/icon/ico-payroll-active.png');
								}
								&-capital {
									background-image: url('../../images/icon/ico-capital-active.png');
								}
								&-metric {
									background-image: url('../../images/icon/ico-metric-active.png');
								}
								&-pmplus {
									background-image: url('../../images/icon/ico-pmplus-active.png');
								}
							}
						}
					}
					&.active {
						> .sublinks {
							display: block;
						    background: #f0f3f7;
						    width: 95%;
						    margin: -10px auto 0;
    						padding-top: 20px;
						    border-bottom-right-radius: 10px;
						    border-bottom-left-radius: 10px;
						}
					}
				}
				.sublinks {
					padding-top: 10px;
					ul {
						li {
							background-color: transparent;
							&.active {
								> a {
									color: #1362E2;
								}
							}
							a {
								color: #203645;
								min-height: 40px;
								padding: 8px 20px 8px 45px;
								background: transparent;
							}
						}
					}
				}
				.invoice-menu {
					position: absolute;
				    top: 0px;
				    right: 0px;
				    left: 0px;
				    background: #fff;
				    z-index: 999999;
				    height: 100vh;
				    ul {
				    	li {
				    		svg {
							    width: 10px !important;
							    height: 10px !important;
							    vertical-align: middle !important;
							}
				    		a {
				    			padding: 8px 20px 8px 20px;
				    		}
				    		.divider {
							    border-bottom: 1px solid #dae6ee;
							    margin-bottom: 15px;
							}
				    	}
				    }
				}
			}

			.dropdown-menu-pmplus {
				width: 100%;
			    padding: 0 0 10px 0;
			    height: calc(100vh - 140px);
			    overflow-y: auto;
			    overflow-x: hidden;
			    margin: 0;
			    border: 0;
			    border-radius: 0;
			    background-color: $sidebg;
			    border-width: 0px;
			    box-shadow: none;
			    display: block;
			    top: 90px;
			    z-index: 0;
			    box-sizing: content-box;
				.dropdown-header {
					font-size: 16px;
					font-weight: 600;
					padding: 15px 10px 10px;
					&::first-child {
						padding-top: 0px;
					}
				}
				&::-webkit-scrollbar {
				    width: 0;  
				    background: transparent;
				}
				> * {
					&:last-child {
						padding-bottom: 50px;
					}
				}
				li {
					&
					.ico-toggle {
						position: absolute;
						right: 20px;
						top: 16px;
					}
					a {
						position: relative;
						display: block;
						color: #203645;;
						font-size: $font_normal;
						font-weight: 600;
						min-height: 50px;
    					padding: 15px 25px 15px 60px;
						svg {
							&.side-ico {
								font-size: $iconsize;
								padding: 8px 10px;
							}
						}
						.side-ico {
							width: 40px;
							height: 40px;
							display: inline-block;
							vertical-align: middle;
							background-repeat: no-repeat;
							background-size:50%;
							background-position: center;
							position: absolute;
							left: 15px;
							top: 6px;
							&-dashboard {
								background-image: url('../../images/icon/icon-dashboard.svg');
							}
							&-customer {
								background-image: url('../../images/icon/icon-customers.svg');
							}
							&-inventory {
								background-image: url('../../images/icon/icon-product-services.svg');
							}
							&-workorders {
								background-image: url('../../images/icon/ico-workorder.svg');
							}
							&-invoice {
								background-image: url('../../images/icon/icon-invoice.svg');
							}
							&-pl {
								background-image: url('../../images/icon/icon-payment-link.svg');
							}
							&-pf {
								background-image: url('../../images/icon/icon-payment-forms.svg');
							}
							&-subscription {
								background-image: url('../../images/icon/ico-subscription.svg');
							}
							&-tips {
								background-image: url('../../images/icon/ico-tips.svg');
							}
							&-reports {
								background-image: url('../../images/icon/icon-reports.svg');
							}
							&-settings {
								background-image: url('../../images/icon/icon-settings.svg');
							}
							&-virtual-sell {
								background-image: url('../../images/icon/icon-virtualsell.svg');
							}
						}
					}
					&.active,
					&:focus,
					&:active,
					&:hover {
						a {
							background-color: #E3F7FD;
							color: #1362E2;
							.side-ico {
								&-dashboard {
									background-image: url('../../images/icon/icon-dashboard-hover.svg');
								}
								&-customer {
									background-image: url('../../images/icon/icon-customers-hover.svg');
								}
								&-inventory {
									background-image: url('../../images/icon/icon-product-services-hover.svg');
								}
								&-workorders {
									background-image: url('../../images/icon/ico-workorder.svg');
								}
								&-invoice {
									background-image: url('../../images/icon/icon-invoice-hover.svg');
								}
								&-pl {
									background-image: url('../../images/icon/icon-payment-link-hover.svg');
								}
								&-pf {
									background-image: url('../../images/icon/icon-payment-forms-hover.svg');
								}
								&-subscription {
									background-image: url('../../images/icon/ico-subscription.svg');
								}
								&-tips {
									background-image: url('../../images/icon/ico-tips.svg');
								}
								&-reports {
									background-image: url('../../images/icon/icon-reports-hover.svg');
								}
								&-settings {
									background-image: url('../../images/icon/icon-settings-hover.svg');
								}
								&-virtual-sell {
								background-image: url('../../images/icon/icon-virtualsell-hover.svg');
							}
							}
						}
					}
					&.active {
						.sublinks {
							display: block;
						}
					}
				}
				.sublinks {
					li {
						background-color: transparent;
						&.active {
							a {
								color: #1362E2;
							}
						}
						a {
							color: #203645;
							min-height: 40px;
							padding: 8px 20px;
							margin-left: 30px;
							background: transparent;
						}
					}
				}
			}
		}
	}
}

.version {
	background-color: #FCFCFC;
    border-top: 1px solid #ABC6D8;
    width: 90%;
    text-align: left;
    color: #162132;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 17px 15px;
	font: $small;
}

@include breakpoint(desktop){
	.main-wrap {
		.main-sidebar {
			width: 20%;
		}
	}
}
@include breakpoint(desktop-normal){
	.main-wrap {
		.main-sidebar {
			width: 18%;
		}
	}
}
@include breakpoint(desktop-wide){
	.main-wrap {
		.main-sidebar {
			width: 16%;
		}
	}
}
@include breakpoint(desktop-extra-wide){
	.main-wrap {
		.main-sidebar {
			width: 14.5%;
		}
	}
}
//side
@media (min-width:$screen-S) and (max-width:1199px) {
	.main-wrap {
		padding-top: 40px;
		position: static;
		.main-sidebar {
			background: transparent;
			overflow: visible;
			margin-top: 0;
			bottom: 0;
			top: 0;
			left: -280px;
			.sidebar {
				left: 0;
				position: relative;
				&.dropdown {
					@include transition-effect;
					.sidebar-ticker {
						display: block;
					}
					&.dropdown.show {
						left:280px;
						height:100%;
						@include transition-effect;
						.dropdown-menu {
							transform: translate(0, 80px) !important;
							padding: 0 0 10px 0;
						}
					}
				}
			}
		}
	}
	.version {
		padding: 10px 10px 20px;
		bottom: 0;
	}
}