/* NOTIFICATION VARIABLES */
$alert_success: #A0E9A7;
$alert_error: #FF5772;
$alert_info: #CDD1D3;
$alert_warning: #FFDC83;
$alert_question: #CDD1D3;
$alert_font_color: #203645;

.swal2-container {
	.swal2-popup {
		background: #FCFCFC;
	    box-shadow: 0px 2px 2px rgb(2 2 15 / 1%), 0px 2px 4px rgb(2 2 15 / 1%), 0px 0px 24px rgb(2 2 15 / 1%), 0px 2px 72px rgb(2 2 15 / 1%);
	    border-radius: 12px;
	    padding: 30px 20px;
		.swal2-close {
		    display: block !important;
		    color: $alert_font_color;
		    width: auto;
		    height: auto;
		    position: relative;
		    top: -20px;
		}
		.swal2-title {
		    color: $alert_font_color;
		    padding: 5px 1em 0;
		}
		.swal2-html-container {
		    color: #6B747D;
		    font-size: 16px;
		}
		.swal2-actions {
			.swal2-confirm {
			    background-color: #1362E2;
			    display: block;
			    width: 85%;
			    border-radius: 9999px;
			    padding: 12px 0;
			    font-size: 16px;
          font-weight: 700;
          line-height: 24px;
			}
			.swal2-deny {
			    background-color: #FF5772;
			    display: block;
			    width: 85%;
			    border-radius: 9999px;
			    padding: 12px 0;
			    font-size: 16px;
          font-weight: 700;
          line-height: 24px;
			}
			.swal2-cancel {
			    background-color: #CDD1D3;
			    display: block;
			    width: 85%;
			    border-radius: 9999px;
			    padding: 12px 0;
			    font-size: 16px;
          font-weight: 700;
          line-height: 24px;
			}
		}
		.swal2-icon {
			color: $alert_font_color;
			&.swal2-success {
				.swal2-success-line-tip {
				    background-color: $alert_font_color;
				    z-index: 9999;
				}
				.swal2-success-line-long {
				    background-color: $alert_font_color;
				    z-index: 9999;
				}
				.swal2-success-ring {
					background: $alert_success;
				}
			}
			&.swal2-error {
				border-color: $alert_error;
			    color: $alert_font_color;
			    background: $alert_error;
				.swal2-x-mark-line-left,
				.swal2-x-mark-line-right {
				    background-color: $alert_font_color;
				}
			}
			&.swal2-info {
				border-color: $alert_info;
			    color: $alert_font_color;
			    background: $alert_info;
			    .swal2-icon-content {
			    	color: $alert_font_color;
			    }
			}
			&.swal2-warning {
				border-color: $alert_warning;
			    color: $alert_font_color;
			    background: $alert_warning;
			    .swal2-icon-content {
			    	color: $alert_font_color;
			    }
			}
			&.swal2-question {
				border-color: $alert_question;
			    color: $alert_font_color;
			    background: $alert_question;
			    .swal2-icon-content {
			    	color: $alert_font_color;
			    }
			}
		}
	}
}