.heartland-payment-type {
  #credit-card-card-number {
    iframe {
      width: 50%;
      padding-right: 15px;
      @media (max-width: 981px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
  #credit-card-card-expiration,
  #credit-card-card-cvv {
    iframe {
      width: 100%;
    }
  }
}

.form-builder-embed {
  .custom-submit-button {
    svg {
      display: none;
    }
    &.submit-fail {
      svg {
        display: none;
      }
    }
  }
  .formio-component-productfield {
    .dropdown-menu {
      &.show {
        height: 200px;
        transform: translate3d(0px, 5px, 0px) !important;
        top: 30px !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .formio-component-heartlandpayment {
    @media (max-width: 768px) {
      .btn-primary {
        margin: 0 0 15px;
      }
      .btn-success {
        margin: 0 0 15px;
      }
    }
  }
  .formio-component-subscriptionPlans {
    background: #fff;
    padding: 25px;
    margin: 20px 0;

    .subscription-location-filter {
      position: absolute;
      right: 25px;
      width: 250px;

      @media (max-width: 981px) {
        position: relative;
        right: 0;
        width: 250px;
        margin: 0 0 20px auto;
      }

      @media (max-width: 667px) {
        width: 350px;
      }
    }

    .product-tabs {
      &> .nav {
        border-top: 0;
        background: transparent;
        text-align: center;
        margin-bottom: 20px;

        .nav-item {
          display: block;
          width: 100%;

          .nav-link  {
            display: none;

            &.active {
              display: block;
              border-bottom: 4px solid #aa1313;
              padding: 6px 10px 6px;
              width: 25%;
              margin: 0 auto;

              @media (max-width: 981px) {
                width: 40%;
              }

              @media (max-width: 667px) {
                width: 60%;
              }

              @media (max-width: 414px) {
                width: 100%;
              }
            }
          }
        }
      }

      .tab-content {

        #all-details-tab-panel {
          .card {
            text-align: center;

            .card-img-top {
              height: 200px;
              margin: 0 auto;
              object-fit: cover;
            }

            .card-header {
              padding: 10px;
              margin-bottom: 0;
              background-color: #e6e6e6;
              border-bottom: 0;

              h4 {
                font-size: 22px;
              }
            }

            .card-body {
              text-align: left;

              .btn {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
              }
            }
          }
        }

        #product-details-tab-panel {
          .card-body {
            padding: 0;

            .row {
              .col-sm-4 {
                text-align: center;

                @media (max-width: 981px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                }

                img {
                  padding: 10px;
                  margin-top: 20px;
                  border: 0;
                }
              }

              .col-sm-8 {
                padding-top: 25px;
                padding-bottom: 25px;
                padding-right: 40px;

                @media (max-width: 981px) {
                  padding-left: 40px;
                  flex: 0 0 100%;
                  max-width: 100%;
                }

                h4 {
                  font-size: 30px;
                }

                h3 {
                  background: transparent !important;
                  padding: 0 !important;
                  margin: 0 !important;
                }

                .product-susbscription-description {
                  font-size: 16px;
                  margin: 20px 0 0;

                  @media (max-width: 981px) {
                    display: block !important;
                  }

                  span {
                    padding: 0;
                  }

                  .col-sm-3 {
                    @media (max-width: 981px) {
                      flex: 0 0 100%;
                      max-width: 100%;
                      display: block;
                    }
                  }
                }

                .product-susbscription-inclusion {
                  font-size: 16px;

                  span {
                    padding: 0;
                  }

                  .col {
                    padding: 0;
                  }

                  .d-flex {
                    @media (max-width: 981px) {
                      display: block !important;
                    }
                  }


                }

                .plan-option-addon {
                  input[type="checkbox"] {
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                }

                .product-subscription-btn {
                  margin: 0 !important;
                  padding-top: 20px !important;

                  .btn {
                    font-size: 17px;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .formio-component-form {
    .fileSelector {
      position: relative;
      svg {
        display: none;
      }
      &:before {
        content: url('/assets/images/icon/ico-file-upload-solid.svg');
        width: 13px;
        height: auto;
        display: inline-block;
        vertical-align: sub;
        margin: 0 5px;
      }
    }
  }
  .formio-component-file {
    .list-group  {
      .list-group-item:not(:first-child) {
        .row {
          .col-md-1 {
            position: relative;
            svg {
              display: none;
            }
            &:before {
              content: url('/assets/images/icon/ico-trash-can-solid.svg');
              width: 13px;
              height: auto;
              display: inline-block;
              vertical-align: sub;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
