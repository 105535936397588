.ck-body-wrapper {
    position: relative;
    z-index: 9999;
}
.tcp-editor {
	.ck-editor {
		.ck-sticky-panel {
			.ck-toolbar__items {
				.ck-file-dialog-button,
				.ck-dropdown,
				.ck-disabled {
				    display: none !important;
				}
				.ck.ck-dropdown.ck-heading-dropdown,
				.ck.ck-dropdown.ck-font-family-dropdown,
				.ck.ck-dropdown.ck-font-size-dropdown {
				    display: inline-block !important;
				}
			}
		}
		.ck-content {
			height: 500px !important;
    		min-height: 500px !important;
			ul {
		      	padding: 0 0 0 30px;
		      	li {
		      		list-style: initial;
		      	}
		    }
	      	ol {
			    padding: 0 0 0 30px;
			}
			dl {
			    padding: 0 0 0 20px;
			}
		}
	}
}
.tcp-container {
  ul {
    padding: 0 0 0 30px;
    li {
      list-style: initial;
    }
  }
  ol {
    padding: 0 0 0 30px;
  }
  dl {
    padding: 0 0 0 20px;
  }
  .text-huge, h1 {
    font-size: 1.8em;
  }
  .text-big, h2 {
    font-size: 1.4em;
  }
  .text-small, h3 {
    font-size: .85em;
  }
  .text-tiny, h4 {
    font-size: .7em;
  }
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
}
