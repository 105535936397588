$total-cols: nth(nth($grid-breakpoints, length($grid-breakpoints)), 2);

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin breakpoint($n) {
  @media only screen and (min-width: map_get($grid-breakpoints, $n)) {
    @content;
  }
}

@mixin container($justify : space-between) {
  $break: $site-container-width;

  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content($justify);

  margin: auto;
  gap: $grid-gutter;
  @if ($grid-type == magic) {
    max-width: #{$break};
    width: 100%;
  } @else if ($grid-type == fluid) {
    max-width: 100%;
    width: 100%;
  }
}

@mixin cols($cols: false, $reference: $total-cols, $grid-gutter:$grid-gutter) {
  @if ($grid-padding != false) {
    padding-left: $grid-padding;
    padding-right: $grid-padding;
  }

  @if ($cols == false) {
    // each element will try to take up as much space as possible on the same line
    // no gutter will be applied.
    @include flex(1);
  } @else {
    @include flex(0, 0, auto);

    // assign widths
    @if ($grid-gutter != false) {
      @include flex-basis(calc(
        (#{$cols} / #{$reference} * 100%) - #{$grid-gutter} + (#{$cols} / #{$reference} * #{$grid-gutter})
      ));

      //max-width: calc(
      //  (#{$cols} / #{$reference} * 100%) - #{$grid-gutter} + (#{$cols} / #{$reference} * #{$grid-gutter})
      //);
    } @else {
      @include flex-basis(calc(#{$cols} / #{$reference} * 100%));
      //max-width: calc(#{$cols} / #{$reference} * 100%);
    }
    flex-grow: 1;
  }
}

@mixin pre($cols, $reference: $total-cols) {
  @if ($grid-gutter != false) {
    margin-left: calc((#{$cols} / #{$reference} * 100%) + (#{$cols} / #{$reference} * #{$grid-gutter}));
  } @else {
    margin-left: calc(#{$cols} / #{$reference} * 100%);
  }
}

@mixin post($cols, $reference: $total-cols) {
  @if ($grid-gutter != false) {
    margin-right: calc((#{$cols} / #{$reference} * 100%) + (#{$cols} / #{$reference} * #{$grid-gutter}));
  } @else {
    margin-right: calc(#{$cols} / #{$reference} * 100%);
  }
}

