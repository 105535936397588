.badge {
	@include border-radius($rounded-full);
  border: 0;
  padding: 6px;
  vertical-align: middle;
  &.badge-pill {
    line-height: 1.3;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    font-size: 11px;
    padding: 8px 16px;
    /*box-shadow: rgb(0 0 0 / 10%) 0 2px 1px;*/
    text-transform: uppercase;
    color: #fff;
    &.badge-sent {
        color: #203645;
        background-color: #C7BDFF;
        &:focus,
        &:hover {
          color: #203645;
          background-color: #C7BDFF;
        }
    }
    &.badge-viewed {
        color: #203645;
        background-color: #FFB9A2;
        &:focus,
        &:hover {
          color: #203645;
          background-color: #FFB9A2;
        }
    }
    &.badge-primary {
      color: #FCFCFC;
      background-color: #FF9571; 
      &:focus,
      &:hover {
        color: #FCFCFC;
        background-color: #FF9571;
      }
    }
    &.badge-secondary {
      color: #203645;
      background-color: #C7A2CC; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #C7A2CC;
      }
    }
    &.badge-success {
      color: #FCFCFC;
      background-color: #806BFF; 
      &:focus,
      &:hover {
        color: #FCFCFC;
        background-color: #806BFF;
      }
    }
    &.badge-info {
      color: #203645;
      background-color: #00BBFF; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #00BBFF;
      }
    }
    &.badge-warning {
      color: #203645;
      background-color: #FFBF3F; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #FFBF3F;
      }
    }
    &.badge-danger {
      color: #FCFCFC;
      background-color: #BD2947;
      &:focus,
      &:hover {
        color: #FCFCFC;
        background-color: #BD2947;
      } 
    }
    &.badge-light {
      color: #212529;
      background-color: #f8f9fa; 
      &:focus,
      &:hover {
        color: #212529;
        background-color: #dae0e5;
      } 
    }
    &.badge-dark {
      color: #203645;
      background-color: #ED7A23; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #ED7A23;
      } 
    }
    &.badge-accepted {
      color: #203645;
      background-color: #73E6DC; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #73E6DC;
      } 
    }
    &.badge-archive {
      color: #203645;
      background-color: #C7A2CC; 
      &:focus,
      &:hover {
        color: #203645;
        background-color: #C7A2CC;
      } 
    }
	}
}

.tags {
  @include border-radius($rounded-full);
  border: 0;
  vertical-align: middle;
  line-height: 1.3;
  border-radius: $rounded-full;
  -moz-border-radius: $rounded-full;
  -webkit-border-radius: $rounded-full;
  font-size: 11px;
  padding: 8px 16px;
  /*box-shadow: rgb(0 0 0 / 10%) 0 2px 1px;*/
  text-transform: uppercase;
  color: #fff;
  padding: 7px 10px 4px;
  font-weight: 700;
  &.active {
    color: #203645;
    background-color: #7BF297;
    &:focus,
    &:hover {
      color: #203645;
      background-color: #7BF297;
    }
  }
  &.inactive {
    color: #203645;
    background-color: #ED7A23;
    &:focus,
    &:hover {
      color: #203645;
      background-color: #ED7A23;
    }
  }
}


/* Invoice List Badge */
.invoice-source {
    border-radius: 50%;
    font-size: 12px;
    box-shadow: rgb(0 0 0 / 10%) 0 2px 1px;
    padding: 9px 0;
    font-weight: 700;
    position: relative;
    text-align: center;
    line-height: 15px;
    width: 32px;
    height: 32px;
    margin: 5px;
    text-transform: uppercase;
}
.invoice-source-ri {
    color: #fff;
    background-color: #23612f;
}
.invoice-source-mi {
  color: #fff;
  background-color: #3a718e;
} 

.invoice-source-es {
  color: #fff;
  background-color: #e49c23;
}

.invoice-source-wo {
  color: #fff;
  background-color: #379230;
}

.invoice-source-ri {
  color: #fff;
  background-color: #23612f;
}

.invoice-source-rp {
  color: #fff;
  background-color: #319e99;
}
.invoice-source-rect{
	font-size: 12px;
	box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px;
	padding: 9px;
	font-weight: 700;
	position: relative;
	text-align: center;
	line-height: 15px;
	margin: 5px;
}