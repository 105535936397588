@use 'src/themes' as *;

vega-table {
  td {
    border-color: #F2F5F8 !important;
    border-bottom-width: 2px !important;
  }

  label {
    margin: 0;
  }

  vega-pagination {
    padding: 0 30px;
  }
}

vega-pagination {
  ul.vega-pagination li {
    color: cj-theme(color-cool-gray-70) !important;
    border: 1px solid #F0F3F7;
    border-radius: 4px;

    &:not(.page-ellipsis) {
      margin: 0 4px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:first-child {
      margin-right: 0;
    }

    &:hover {
      text-decoration: none !important;

      &:not(.active) {
        background-color: rgba(4, 4, 28, 0.05) !important
      };
    }

    &.active {
      background-color: cj-theme(color-blue-90);

      button {
        color: #FFFFFF !important;
      }
    }

    &.pager-ellipsis,
    &.disabled {
      pointer-events: none;
    }

    &.disabled {
      opacity: 0.3;
    }

    button {
      color: cj-theme(color-cool-gray-70) !important;
      font-weight: 500 !important;
      border-radius: 4px !important;
    }
  }
}

.vega-input svg {
  margin: unset;
}