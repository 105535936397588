.empty-403 {
	height: 75vh;
	h1 {
		color: #324E57;
	    font-size: 2rem;
	    font-weight: 600;
	}
	p {

	}
	a {

	}
}