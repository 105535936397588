.cjgrid-table {
	table {
		tbody {
		    tr {
		    	td {
				    svg {
				    	color: #151515;
				    }
				}
		    }
		}
	}
}