.main-container {
    width: 100%;
    padding: 30px;
		position: relative;
		min-height: calc(100vh - 200px);
    @include breakpoint(desktop){
	   width: 80%;
	}
    @include breakpoint(desktop-normal){
	   width: 82%;
	}
    @include breakpoint(desktop-wide){
	   width: 84%;
	}
    @include breakpoint(desktop-extra-wide){
	   width: 85.5%;
	}
}
