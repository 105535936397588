.grid {
  display: grid;
}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.\!text-xs {
  font-size: 0.75rem !important; /* 12px */
  line-height: 1rem !important; /* 16px */
}

.\!text-sm {
  font-size: 0.875rem !important; /* 14px */
  line-height: 1.25rem !important; /* 20px */
}

.\!text-base {
  font-size: 1rem !important; /* 16px */
  line-height: 1.5rem !important; /* 24px */
}

.\!text-lg {
  font-size: 1.125rem !important; /* 18px */
  line-height: 1.75rem !important; /* 28px */
}

.\!text-xl {
  font-size: 1.25rem !important; /* 20px */
  line-height: 1.75rem !important; /* 28px */
}

.\!text-2xl {
  font-size: 1.5rem !important; /* 24px */
  line-height: 2rem !important; /* 32px */
}

.font-poppins {
  font-family: Poppins, Arial, sans-serif;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}
.break-words {
  overflow-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
