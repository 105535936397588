.subscription-form {
	.builder-component {
		.component-btn-group {
			z-index: 8;
		}
	}
}

.formio-component-subscriptionPlans {
	.col-form-label {
	    display: none;
	}
	.subscription-location-filter {
      text-align: right;
	  display: flex;
	  align-items: center;
	  gap: 10px;
	  margin: 30px 0;
	  justify-content: flex-end;
      label {
      	margin: 0;
      }
      .form-control {
      	width: 160px;
      }
    }
	.tab-content {
		.tab-pane {
			h1 {
				color: #181830;
			    font-size: 1.25rem;
			    font-weight: 800;
			    padding: 30px 0 25px;
			}
			.subscription-container {
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    align-items: stretch;
			    grid-gap: 0;
			    row-gap: 30px;
			    .subscription-list {
				    flex: 100%;
				    background-color: $white;
				   	border: 0;
				   	border: 1px solid rgba(0,0,0,.125);
				   	@include breakpoint(tablet){
					   flex: 0 0 49%;
					}
				   	@include breakpoint(desktop){
					   flex: 0 0 32.33%;
					}
				    .btn {
				    	height: 43px;
				    }
				    .card {
				    	background-color: transparent;
				    	border: 0;
				    }
				}
			}
		}
	}
}