/* prefixed */
@mixin border-radius($radius: 3px) {
  border-radius: $radius !important;
  -moz-border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
}
@mixin border-radius2($radius1,$radius2,$radius3,$radius4) {
  border-radius: $radius1 $radius2 $radius3 $radius4;
  -moz-border-radius: $radius1 $radius2 $radius3 $radius4;
  -webkit-border-radius: $radius1 $radius2 $radius3 $radius4;
}
@mixin transition-effect ($trans: all 0.2s ease) {
  -webkit-transition: $trans;
  -moz-transition: $trans;
  -ms-transition: $trans;
  -o-transition: $trans;
  transition: $trans;
}
@mixin boxshadow ($color: $black, $alph: 0.10, $x: 0px, $y: 1px, $area: 1px) {
  -webkit-box-shadow: rgba($color,$alph) $x $y $area;
  -moz-box-shadow: rgba($color,$alph) $x $y $area;
  box-shadow: rgba($color,$alph) $x $y $area;
}

/* Base Colors */
$red-10: #FFE3E6;
$red-20: #FFD9EB;
$red-30: #FFC7CE;
$red-40: #FFABB5;
$red-50: #FF82A0;
$red-60: #FF6E8B;
$red-70: #FF5772;
$red-80: #E94768;
$red-90: #E63257;
$red-100: #BD2947;
$pink-10: #FFE3F3;
$pink-20: #FFC7E7;
$pink-30: #FFB3D7;
$pink-40: #FF82C9;
$pink-50: #FF61A1;
$pink-60: #FF54A5;
$pink-70: #FF5288;
$pink-80: #FF477B;
$pink-90: #FF3861;
$pink-100: #F0345B;
$orange-40: #F5BB94;
$orange-60: #F19255;
$orange-100: #ED7A23;
$salmon-10: #FFE7DF;
$salmon-20: #FFE3DA;
$salmon-30: #FFD0C0;
$salmon-40: #FFB9A2;
$salmon-50: #FF9571;
$salmon-60: #FF8157;
$gold-10: #FFF7E3;
$gold-20: #FFF3DE;
$gold-30: #FFEFC8;
$gold-40: #FBE2B9;
$gold-50: #FFDC83;
$gold-60: #FED78E;
$gold-70: #FFD469;
$gold-80: #FFCB69;
$gold-100: #FFBF3F;
$green-40: #A0E9A7;
$green-60: #7BF297;
$teal-10: #E0F9F7;
$teal-20: #DFF7F3;
$teal-30: #D0EFEF;
$teal-40: #C1F4EF;
$teal-50: #AEEAE2;
$teal-60: #73E6DC;
$teal-70: #6DD9CA;
$teal-80: #9BD4D6;
$teal-100: #259F9F;
$cyan-10: #F1F8FB;
$cyan-20: #C7D9E5;
$cyan-30: #ABC6D8;
$cyan-40: #8FB3CB;
$cyan-50: #73A0BE;
$cyan-60: #578DB1;
$cyan-70: #477799;
$cyan-80: #3A627D;
$cyan-90: #2D4C61;
$cyan-100: #203645;
$blue-10: #E3F7FD;
$blue-20: #8EDFF9;
$blue-30: #55DAFF;
$blue-40: #00CCFF;
$blue-50: #00BBFF;
$blue-60: #04A5F5;
$blue-70: #0097FF;
$blue-80: #0475F5;
$blue-90: #1362E2;
$blue-100: #1D41C3;
$plum-40: #E6D6EA;
$plum-60: #C7A2CC;
$plum-100: #563D82;
$purple-20: #E3DEFF;
$purple-30: #DBD3F4;
$purple-40: #C7BDFF;
$purple-50: #B8A7E9;
$purple-80: #806BFF;
$purple-100: #5F38CE;
$gray-10: #FCFCFC;
$gray-20: #F7F7F7;
$gray-30: #EFEFEF;
$gray-40: #DEE1E3;
$gray-50: #CDD1D3;
$gray-60: #B0B4B5;
$gray-70: #596063;
$gray-80: #33393D;
$gray-90: #181830;
$gray-100: #04041C;
$coolGray-10: #FCFCFE;
$coolGray-20: #F5F7F7;
$coolGray-30: #F0F3F7;
$coolGray-40: #D2D0DF;
$coolGray-50: #BCBAD5;
$coolGray-60: #6B747D;
$coolGray-70: #464765;
$coolGray-80: #203645;
$coolGray-90: #121227;
$coolGray-100: #04041C;
$transparent-10: rgba(0, 0, 0, 0);

/* Alias Colors */
$light-bg-brand: $gray-70;
$dark-bg-brand: $coolGray-40;
$light-bg-page: $coolGray-30;
$dark-bg-page: $gray-90;
$light-bg-primary: $gray-10;
$dark-bg-primary: $gray-100;
$light-bg-secondary: $coolGray-20;
$dark-bg-secondary: $coolGray-90;
$light-bg-tertiary: $coolGray-30;
$dark-bg-tertiary: $gray-90;
$light-bg-quaternary: $cyan-10;
$dark-bg-quaternary: $coolGray-70;
$light-bg-inverted-primary: $gray-100;
$dark-bg-inverted-primary: $gray-10;
$light-bg-inverted-secondary: $coolGray-90;
$dark-bg-inverted-secondary: $gray-20;
$light-bg-inverted-tertiary: $gray-90;
$dark-bg-inverted-tertiary: $coolGray-30;
$light-bg-inverted-quaternary: $coolGray-70;
$dark-bg-inverted-quaternary: $cyan-10;
$light-bg-action: $blue-90;
$dark-bg-action: $blue-50;
$light-bg-action-hover: $blue-80;
$dark-bg-action-hover: $blue-40;
$light-bg-action-focus: $blue-70;
$dark-bg-action-focus: $blue-30;
$light-bg-action-active: $blue-100;
$dark-bg-action-active: $blue-60;
$light-bg-action-secondary: $gray-10;
$dark-bg-action-secondary: $transparent-10;
$light-bg-danger: $red-90;
$dark-bg-danger: $pink-90;
$light-bg-danger-hover: $red-70;
$dark-bg-danger-hover: $pink-80;
$light-bg-danger-focus: $pink-100;
$dark-bg-danger-focus: $pink-70;
$light-bg-danger-active: $red-100;
$dark-bg-danger-active: $red-90;
$light-bg-status-success: $green-40;
$dark-bg-status-success: $green-60;
$light-bg-status-warning: $gold-50;
$dark-bg-status-warning: $gold-70;
$light-bg-status-info: $gray-50;
$dark-bg-status-info: $gray-10;
$light-bg-status-error: $red-70;
$dark-bg-status-error: $pink-90;
$light-bg-backdrop-sidebar: rgba($coolGray-90, 0.85);
$dark-bg-backdrop-sidebar: rgba($coolGray-70, 0.8);
$light-bg-transparent: $transparent-10;
$dark-bg-transparent: $transparent-10;
$light-bg-accent1-primary: $teal-60;
$dark-bg-accent1-primary: $teal-70;
$light-bg-accent1-secondary: $teal-40;
$dark-bg-accent1-secondary: $teal-50;
$light-bg-accent1-tertiary: $teal-10;
$dark-bg-accent1-tertiary: $teal-20;
$light-bg-accent2-primary: $purple-80;
$dark-bg-accent2-primary: $purple-100;
$light-bg-accent2-secondary: $purple-40;
$dark-bg-accent2-secondary: $purple-50;
$light-bg-accent2-tertiary: $purple-20;
$dark-bg-accent2-tertiary: $purple-30;
$light-bg-accent3-primary: $gold-50;
$dark-bg-accent3-primary: $gold-80;
$light-bg-accent3-secondary: $gold-30;
$dark-bg-accent3-secondary: $gold-40;
$light-bg-accent3-tertiary: $gold-10;
$dark-bg-accent3-tertiary: $gold-20;
$light-bg-accent4-primary: $salmon-50;
$dark-bg-accent4-primary: $salmon-60;
$light-bg-accent4-secondary: $salmon-30;
$dark-bg-accent4-secondary: $salmon-40;
$light-bg-accent4-tertiary: $salmon-10;
$dark-bg-accent4-tertiary: $salmon-20;
$light-bg-accent5-primary: $blue-50;
$dark-bg-accent5-primary: $blue-60;
$light-bg-accent5-secondary: $blue-20;
$dark-bg-accent5-secondary: $blue-30;
$light-bg-accent5-tertiary: $blue-10;
$dark-bg-accent5-tertiary: $blue-10;
$light-bg-accent6-primary: $pink-40;
$dark-bg-accent6-primary: $pink-60;
$light-bg-accent6-secondary: $pink-20;
$dark-bg-accent6-secondary: $pink-30;
$light-bg-accent6-tertiary: $pink-10;
$dark-bg-accent6-tertiary: $red-20;
$light-bg-accent7-primary: $teal-100;
$dark-bg-accent7-primary: $teal-100;
$light-bg-accent7-secondary: $teal-80;
$dark-bg-accent7-secondary: $teal-80;
$light-bg-accent7-tertiary: $teal-30;
$dark-bg-accent7-tertiary: $teal-30;
$light-bg-accent8-primary: $plum-100;
$dark-bg-accent8-primary: $plum-100;
$light-bg-accent8-secondary: $plum-60;
$dark-bg-accent8-secondary: $plum-60;
$light-bg-accent8-tertiary: $plum-40;
$dark-bg-accent8-tertiary: $plum-40;
$light-bg-accent9-primary: $gold-100;
$dark-bg-accent9-primary: $gold-100;
$light-bg-accent9-secondary: $gold-60;
$dark-bg-accent9-secondary: $gold-60;
$light-bg-accent9-tertiary: $gold-40;
$dark-bg-accent9-tertiary: $gold-40;
$light-bg-accent10-primary: $orange-100;
$dark-bg-accent10-primary: $orange-100;
$light-bg-accent10-secondary: $orange-60;
$dark-bg-accent10-secondary: $orange-60;
$light-bg-accent10-tertiary: $orange-40;
$dark-bg-accent10-tertiary: $orange-40;
$light-text-primary: $coolGray-80;
$dark-text-primary: $gray-10;
$light-text-secondary: $coolGray-60;
$dark-text-secondary: $coolGray-50;
$light-text-inverted-primary: $gray-10;
$dark-text-inverted-primary: $gray-100;
$light-text-inverted-secondary: $coolGray-50;
$dark-text-inverted-secondary: $coolGray-60;
$light-text-link: $blue-90;
$dark-text-link: $blue-50;
$light-text-link-hover: $blue-80;
$dark-text-link-hover: $blue-40;
$light-text-link-active: $blue-100;
$dark-text-link-active: $blue-60;
$light-text-link-focus: $blue-80;
$dark-text-link-focus: $blue-40;
$light-text-link-visited: $purple-100;
$dark-text-link-visited: $purple-80;
$light-text-danger-link: $red-90;
$dark-text-danger-link: $pink-90;
$light-text-danger-link-hover: $red-80;
$dark-text-danger-link-hover: $pink-80;
$light-text-danger-link-active: $red-100;
$dark-text-danger-link-active: $red-90;
$light-text-danger-link-focus: $pink-100;
$dark-text-danger-link-focus: $pink-70;
$light-text-danger-link-visited: $red-90;
$dark-text-danger-link-visited: $pink-90;
$light-text-accent1-primary: $teal-60;
$dark-text-accent1-primary: $teal-70;
$light-text-accent2-primary: $purple-80;
$dark-text-accent2-primary: $purple-100;
$light-text-accent3-primary: $gold-50;
$dark-text-accent3-primary: $gold-80;
$light-text-accent4-primary: $salmon-50;
$dark-text-accent4-primary: $salmon-60;
$light-text-accent5-primary: $blue-50;
$dark-text-accent5-primary: $blue-60;
$light-text-accent6-primary: $pink-100;
$dark-text-accent6-primary: $pink-80;
$light-text-accent7-primary: $teal-100;
$dark-text-accent7-primary: $teal-100;
$light-text-accent8-primary: $plum-100;
$dark-text-accent8-primary: $plum-100;
$light-text-accent9-primary: $gold-100;
$dark-text-accent9-primary: $gold-100;
$light-text-accent10-primary: $orange-100;
$dark-text-accent10-primary: $orange-100;
$light-borderColor-action: $blue-90;
$dark-borderColor-action: $blue-50;
$light-borderColor-action-hover: $blue-80;
$dark-borderColor-action-hover: $blue-40;
$light-borderColor-action-active: $blue-100;
$dark-borderColor-action-active: $blue-60;
$light-borderColor-danger: $red-90;
$dark-borderColor-danger: $pink-90;
$light-borderColor-danger-hover: $red-70;
$dark-borderColor-danger-hover: $pink-80;
$light-borderColor-danger-active: $red-100;
$dark-borderColor-danger-active: $red-90;
$light-borderColor-input-field: $cyan-30;
$dark-borderColor-input-field: $coolGray-80;
$light-borderColor-input-field-hover: $cyan-50;
$dark-borderColor-input-field-hover: $cyan-80;
$light-borderColor-input-field-focus: $blue-90;
$dark-borderColor-input-field-focus: $blue-50;
$light-borderColor-input-field-danger: $red-90;
$dark-borderColor-input-field-danger: $pink-90;
$light-borderColor-input-field-danger-hover: $red-70;
$dark-borderColor-input-field-danger-hover: $pink-80;
$light-borderColor-input-field-danger-focus: $red-80;
$dark-borderColor-input-field-danger-focus: $pink-90;


/* Base Typography Tokens */
$fontName-inter: "Inter", sans-serif;
$fontName-roboto-mono: "Roboto Mono", monospace;

$font-regular-12: 400 12px $fontName-inter;
$font-regular-14: 400 14px $fontName-inter;
$font-regular-16: 400 16px $fontName-inter;
$font-regular-18: 400 18px $fontName-inter;
$font-regular-20: 400 20px $fontName-inter;
$font-regular-24: 400 24px $fontName-inter;
$font-regular-28: 400 28px $fontName-inter;
$font-regular-32: 400 32px $fontName-inter;
$font-regular-34: 400 34px $fontName-inter;
$font-regular-36: 400 36px $fontName-inter;
$font-regular-42: 400 42px $fontName-inter;
$font-regular-48: 400 48px $fontName-inter;
$font-regular-54: 400 54px $fontName-inter;
$font-regular-60: 400 60px $fontName-inter;
$font-med-12: 500 12px $fontName-inter;
$font-med-14: 500 14px $fontName-inter;
$font-med-16: 500 16px $fontName-inter;
$font-med-18: 500 18px $fontName-inter;
$font-med-20: 500 20px $fontName-inter;
$font-med-24: 500 24px $fontName-inter;
$font-med-28: 500 28px $fontName-inter;
$font-med-32: 500 32px $fontName-inter;
$font-med-34: 500 34px $fontName-inter;
$font-med-36: 500 36px $fontName-inter;
$font-med-42: 500 42px $fontName-inter;
$font-med-48: 500 48px $fontName-inter;
$font-med-54: 500 54px $fontName-inter;
$font-med-60: 500 60px $fontName-inter;
$font-bold-12: 700 12px $fontName-inter;
$font-bold-14: 700 14px $fontName-inter;
$font-bold-16: 700 16px $fontName-inter;
$font-bold-18: 700 18px $fontName-inter;
$font-bold-20: 700 20px $fontName-inter;
$font-bold-24: 700 24px $fontName-inter;
$font-bold-28: 700 28px $fontName-inter;
$font-bold-32: 700 32px $fontName-inter;
$font-bold-34: 700 34px $fontName-inter;
$font-bold-36: 700 36px $fontName-inter;
$font-bold-42: 700 42px $fontName-inter;
$font-bold-48: 700 48px $fontName-inter;
$font-bold-54: 700 54px $fontName-inter;
$font-bold-60: 700 60px $fontName-inter;

/* Alias Typography Tokens */
.s-font-h1 {
	letter-spacing: -1%;
	line-height: 50px;
	font: $font-bold-42;
}
.m-font-h1 {
	letter-spacing: -1.6%;
	line-height: 50px;
	font: $font-bold-42;
}
.l-font-h1 {
	letter-spacing: -2.2%;
	line-height: 56px;
	font: $font-bold-48;
}
.xl-font-h1 {
	letter-spacing: -3%;
	line-height: 70px;
	font: $font-bold-60;
}
.s-font-h2 {
	letter-spacing: 0%;
	line-height: 40px;
	font: $font-bold-32;
}
.l-font-h2 {
	letter-spacing: -0.5%;
	line-height: 44px;
	font: $font-bold-36;
}
.xl-font-h2 {
	letter-spacing: -1%;
	line-height: 52px;
	font: $font-bold-42;
}
.s-font-h3 {
	letter-spacing: -1%;
	line-height: 36px;
	font: $font-med-28;
}
.xl-font-h3 {
	letter-spacing: -1%;
	line-height: 44px;
	font: $font-med-32;
}
.s-font-h4 {
	letter-spacing: 0%;
	line-height: 32px;
	font: $font-bold-24;
}
.xl-font-h4 {
	letter-spacing: 0%;
	line-height: 36px;
	font: $font-bold-28;
}
.s-font-h5 {
	letter-spacing: 0%;
	line-height: 28px;
	font: $font-bold-20;
}
.s-font-h6 {
	letter-spacing: 0%;
	line-height: 25px;
	font: $font-bold-18;
}
.s-font-p1-short {
	letter-spacing: 0%;
	line-height: 28px;
	font: $font-med-20;
}
.m-font-p1-short {
	letter-spacing: 0%;
	line-height: 27px;
	font: $font-med-20;
}
.s-font-p1-long {
	letter-spacing: 0%;
	line-height: 32px;
	font: $font-med-20;
}
.m-font-p1-long {
	letter-spacing: 0%;
	line-height: 30px;
	font: $font-med-20;
}
.s-font-p2-short {
	letter-spacing: 0%;
	line-height: 28px;
	font: $font-med-18;
}
.m-font-p2-short {
	letter-spacing: 0%;
	line-height: 22px;
	font: $font-med-16;
}
.s-font-p2-long {
	letter-spacing: 0%;
	line-height: 30px;
	font: $font-med-18;
}
.m-font-p2-long {
	letter-spacing: 0%;
	line-height: 28px;
	font: $font-med-16;
}
.s-font-btn-label {
	letter-spacing: 0%;
	line-height: 24px;
	font: $font-bold-18;
}
.m-font-btn-label {
	letter-spacing: 0%;
	line-height: 22px;
	font: $font-bold-16;
}
.s-font-btn-link-label {
	letter-spacing: 0%;
	line-height: 24px;
	text-decoration: underline;
	font: $font-bold-18;
}
.m-font-btn-link-label {
	letter-spacing: 0%;
	line-height: 22px;
	text-decoration: underline;
	font: $font-bold-16;
}
.s-font-field-label {
	letter-spacing: 0%;
	line-height: 22px;
	font: $font-med-16;
}
.s-font-field-value-em {
	letter-spacing: 0%;
	line-height: 22px;
	font: $font-bold-16;
}
.s-font-field-value {
	letter-spacing: 0%;
	line-height: 22px;
	font: $font-regular-16;
}
.s-font-sidebar-link-label {
	letter-spacing: 0%;
	line-height: 24px;
	font: $font-med-16;
}
.s-font-footnote1-short {
	letter-spacing: 0%;
	line-height: 18px;
	font: $font-regular-14;
}
.s-font-footnote1-long {
	letter-spacing: 0%;
	line-height: 20px;
	font: $font-regular-14;
}
.m-font-footnote1-long {
	letter-spacing: 0%;
	line-height: 18px;
	font: $font-regular-12;
}
.s-font-footnote2-short {
	letter-spacing: 0%;
	line-height: 16px;
	font: $font-regular-12;
}
.s-font-footnote2-long {
	letter-spacing: 0%;
	line-height: 20px;
	font: $font-regular-12;
}
.m-font-footnote2-long {
	letter-spacing: 0%;
	line-height: 18px;
	font: $font-regular-12;
}


/* Base Borders */
$border-0: 0px solid;
$border-1: 1px solid;
$border-2: 2px solid;
$border-4: 4px solid;
$border-8: 8px solid;

/* Alias Borders */
$light-border-secondary-btn: $border-2 $light-borderColor-action;
$dark-border-secondary-btn: $border-2 $dark-borderColor-action;
$light-border-secondary-btn-hover: $border-4 $light-borderColor-action-hover;
$dark-border-secondary-btn-hover: $border-4 $dark-borderColor-action-hover;
$light-border-secondary-danger-btn: $border-2 $light-borderColor-danger;
$dark-border-secondary-danger-btn: $border-2 $dark-borderColor-danger;
$light-border-secondary-btn-active: $border-4 $light-borderColor-action-active;
$dark-border-secondary-btn-active: $border-4 $dark-borderColor-action-active;
$light-border-secondary-danger-btn-hover: $border-4 $light-borderColor-danger-hover;
$dark-border-secondary-danger-btn-hover: $border-4 $dark-borderColor-danger-hover;
$light-border-secondary-danger-btn-active: $border-4 $light-borderColor-danger-active;
$dark-border-secondary-danger-btn-active: $border-4 $dark-borderColor-danger-active;
$light-border-input-field: $border-1 $light-borderColor-input-field;
$dark-border-input-field: $border-1 $dark-borderColor-input-field;
$light-border-input-field-hover: $border-1 $light-borderColor-input-field-hover;
$dark-border-input-field-hover: $border-1 $dark-borderColor-input-field-hover;
$light-border-input-field-focus: $border-1 $light-borderColor-input-field-focus;
$dark-border-input-field-focus: $border-1 $dark-borderColor-input-field-focus;
$light-border-input-field-danger: $border-1 $light-borderColor-input-field-danger;
$dark-border-input-field-danger: $border-1 $dark-borderColor-input-field-danger;
$light-border-input-field-danger-hover: $border-1 $light-borderColor-input-field-danger-hover;
$dark-border-input-field-danger-hover: $border-1 $dark-borderColor-input-field-danger-hover;
$light-border-input-field-danger-focus: $border-1 $light-borderColor-input-field-danger-focus;
$dark-border-input-field-danger-focus: $border-1 $dark-borderColor-input-field-danger-focus;


/* Spacing (or Size) Tokens */
$size-4: 4px;
$size-8: 8px;
$size-12: 12px;
$size-16: 16px;
$size-20: 20px;
$size-24: 24px;
$size-32: 32px;
$size-40: 40px;
$size-48: 48px;
$size-64: 64px;
$size-72: 72px;
$size-80: 80px;
$size-112: 112px;

/* Rounded Corner Tokens */
$rounded-2: 2px;
$rounded-4: 4px;
$rounded-8: 8px;
$rounded-12: 12px;
$rounded-16: 16px;
$rounded-24: 24px;
$rounded-32: 32px;
$rounded-48: 48px;
$rounded-full: 9999px;
$rounded-t-full: 9999px 9999px 0px 0px;
$rounded-b-full: 0px 0px 9999px 9999px;
$rounded-l-full: 9999px 0px 0px 9999px;
$rounded-r-full: 0px 9999px 9999px 0px;

/* Breakpoints Tokens */
$screen-S: 320px;//mobile - 375
$screen-M: 768px;//tablet
$screen-L: 1024px; //tablet-wide
$screen-XL: 1440px;//desktop-wide


// CURRENT SETUP
// Forms/Button Colors
$btnprimary:$blue-90;
$btnhover:$blue-80;
$btnsec:$coolGray-60;
$btnsechover:$coolGray-50;
$label: #596063;
$link_color:$blue-90;
$link_hover:$blue-80;
$link_font:$font-bold-14;

$sidebg: #FCFCFC;
$side-linkactive:rgba($blue-90, 0.4);
$side-iconactive:$blue-90;
$graydefault:#D2D0DF;

// USAGE
$formborder: $cyan-30;
$formplaceholder: $gray-60;
$formlabel: $cyan-100;
$aSmall:400 10px $fontName-inter;
$account:$font-regular-14;
$bgmain: #F2F5F8;

$color-payment:#04A5F5;
$color-invoice: #FF9571;
$color-sales:#259F9F;
$color-revenue:$plum-60;
$color-credit:$red-70;
$color-cash:$green-60;


$colorStatus-success:$green-60;
$colorStatus-failed:$red-90;
$colorStatus-warning:$orange-100;
$colorStatus-info:$cyan-90;



$contentBox-border: $coolGray-30;

// TABS
$tabfont-active:$font-bold-14;
$tabfont-default:$font-regular-14;
$tabBG-active:$cyan-90;
$tabBG-hover:$cyan-20;
$tabBG-default:$coolGray-30;
$rounded-tab: 20px 20px 0px 0px;

// Primary Colors
$primary:$blue-90;
$white:#FFFFFF;
$black:$gray-90;
$light:#FCFCFC; //gray10
$brand:#596063;
$grayvega:#EFEFEF;

// Secondary Colors
$gray50:#cdd1d3;
$pink100:#F0345B;
$red70:$red-70;
$blue50:#00BBFF;
$purple100:#5F38CE;
$teal100:#259f9f;
$coolgray60:#828d98;
$coolgray50:#BCBAD5;
$light_default:#D2D0DF;
$gray_tertiary:#F0F3F7;


// Font
$h1:$font-med-36;
$h3:$font-bold-20;
$h4:$font-bold-16;
$h5:$font-bold-16;
$subhead:$font-regular-16;
$p:$font-regular-14;
$iconsize:$size-20;
$small:$font-regular-12;

$dashboardIcon:44px;


// Button
$btnfont:$font-bold-16;
$btnradius:$rounded-full;

$font_normal: 1rem;
$font_sml: .75rem;
$font_h5: 1.25rem;
$font_h3: 1.5rem;

$font_md: 14px;
$font_vsml: 10px;
$font_h1: 48px;
$font_h2: 32px;
$font_h4: 20px;
