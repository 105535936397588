.customer-detail-container {
	.tab-pane {
		.ag-body-viewport {
			.badge {
				&.badge-pill {
					font-size: 9px;
				}
			}
		}
	}
}