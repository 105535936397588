.agrid-header {
	.filter {
		.agrid-title {
			h4 {
				color: $black;
				font-size: 1rem;
				font-weight: 800;
				margin: 0;
			}
		}
		.action {
			background: $white;
		    display: flex;
		    flex-direction: column;
		    justify-content: space-between;
		    align-items: center;
		    gap: 6px;
		    padding: 10px 20px;
			border-top:1px solid $coolGray-30;
			@include breakpoint(tablet-wide){
				flex-direction: row;
			}
		    .btn-wrap {
					justify-content: right;
		    	.btn {
		    		&.btn-outline {
		    			padding-left: 0;
    					padding-right: 0;
		    		}
		    	}
		    }
		    .action-wrap {
			    display: inline-flex;
			    flex: 0 0 100%;
			    justify-content: flex-start;
			    align-items: center;
			    flex-direction: column;
			    gap: 10px;
			    @include breakpoint(mobile-landscape){
					flex-direction: row;
					gap: 0;
				}
			    @include breakpoint(tablet-wide){
					flex: 0 0 87%;
				}
			    > select {
			    	background: $white;
				    padding-left: 15px;
				    padding-right: 15px;
				    height: 38px;
					margin-right: 0;
					width: 100% !important;
					@include breakpoint(mobile-landscape){
						margin-right: 4px;
						width: auto !important;
					}
				    &:active,
				    &:hover,
				    &:focus {
					    border-color: $coolgray60;
					    box-shadow: initial;
				    }
			    }
			    > form {
			    	.form-group {
							height: 38px;
							.form-control {
								height: 38px;
							}
			    		&.has-icon {
			    			border-left: 0;
								@include border-radius2 (0, 26px, 26px, 0);
								svg {
									bottom:10px;
								}
			    			.form-control {
			    				border-radius: 0;
									height: 38px;
			    			}
			    		}
			    	}
			    	&.no-source {
			    		.form-group {
				    		&.has-icon {
				    			border-left: 1px solid #ced4da;
    							border-radius: 4px;
				    		}
				    	}
			    	}
			    }
			    .cta-wrap {
			    	display: inline-flex;
				    gap: 10px;
				    margin-left: 0;
				    flex-direction: column;
				    @include breakpoint(mobile-landscape){
						margin-left: 10px;
						flex-direction: row;
					}
			    }
			}
		}
	}
}
.ag-paging-panel {
	justify-content: center;
	border: none !important;
}
.ag-theme-material {
	.ag-header {
		background-color:$coolGray-20;
		@include border-radius($rounded-12);
		text-transform: uppercase;
		border: none;
		.ag-header-row {
			margin: 0 20px;
		}
		.ag-header-cell {
			padding-left: 4px;
			.ag-header-cell-text {
			    color: $black;
					font: $font-bold-12;
			    text-transform: uppercase;
			    text-decoration: none;
			    cursor: pointer;
			}
		}
	}
	.ag-body-viewport {
		.ag-row {
			.ag-cell {
				.badge {
					border-radius: $rounded-full;
					border: 0;
					padding: 7px 10px 4px;
				}
				.actions {
					cursor: pointer;
					svg {
						color: $primary;
						path {
							fill: $primary;
						}
					}
				}
			}
		}
	}
}
.ag-root {
	padding: 0 20px;
}
.ag-root-wrapper {
	@include border-radius ($rounded-12);
}
.ag-center-cols-viewport {
	.ag-row {
		border: 0;
		border:$border-1 $white;
		@include border-radius ($rounded-12);
		@include transition-effect;
		&:nth-child(even) {
			background-color: $coolGray-20 !important;
		}
	}
}
.ag-row-focus, .ag-row-hover {
	border:$border-1 $cyan-20 !important;
	@include transition-effect;
}
.ag-center-cols-container {
	min-width: 100%;
}