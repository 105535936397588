@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

html {
  @media (max-width: 1200px) {
    overflow-x: hidden;
  }
}

body {
  background-color: $bgmain;
  font: $p;
  overflow-x: hidden;
  min-height: 100vh;
}
a,
button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none !important;
    box-shadow: none !important;
  }
}
ul {
  list-style: none;
  padding: 0;
  li {
  }
}
a {
  font: $link_font;
  color: $link_color;
  &:hover {
    color: $link_hover;
  }
}
svg {
  margin-right: 6px;
  vertical-align: middle;
}
.clearfix {
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden;
}
.form-control-round {
  @include border-radius(26px);
}
.btn {
  cursor: pointer;
  min-width: 38px;
  height: 38px;
  text-align: center;
  border: $border-2;
  font: $btnfont;
  padding: 0 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include border-radius($btnradius);
  svg {
    margin-right: 4px;
  }
  &.btn-sml {
    height: 33px;
    min-width: 30px;
    padding: 0 16px;
  }
  &.btn-lg {
    height: 54px;
    min-width: 54px;
    padding: 0 32px;
  }
  &.btn-ico {
    padding: 0;
    svg {
      margin: 0;
      padding: 0;
    }
  }
  &.btn-prim {
    color: $white;
    background-color: $btnprimary;
    border-color: $btnprimary;
    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: $btnhover;
      border-color: $btnhover;
    }
    &.btn.disabled,
    &.btn:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        color: $white;
        background-color: $btnprimary;
        border-color: $btnprimary;
      }
    }
  }
  &.btn-lite {
    color: $gray-70;
    background-color: $white;
    border: $border-1;
    border-color: $gray-60;
    &:hover,
    &:active,
    &:focus {
      color: $black;
      background-color: $white;
      border-color: $gray-90;
    }
    &.btn.disabled,
    &.btn:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        color: $white;
        background-color: $btnprimary;
        border-color: $btnprimary;
      }
    }
  }
  &.btn-outline {
    color: $btnprimary;
    background-color: $white;
    border-color: $btnprimary;
    font-size: $font_normal;
    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: $btnhover;
      border-color: $btnhover;
    }
    &.btn.disabled,
    &.btn:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        color: $btnprimary;
        background-color: $white;
        border-color: $btnprimary;
      }
    }
  }
  &.btn-secondary {
    color: $white;
    background-color: $btnsec;
    border-color: $btnsec;
    &:hover {
      color: $white;
      background-color: $btnsechover;
      border-color: $btnsechover;
    }
  }
  svg {
    margin-right: 4px;
  }
}

.custom-switch {
  margin-bottom: 6px;
}

.custom-checkbox {
  input {
    display: none;
    &:checked ~ label:after {
      content: "";
      background-color: $primary;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }
  label {
    font-size: $font_normal;
    color: $formlabel;
    padding-left: 30px;
    padding-top: 4px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 24px;
      height: 24px;
      border: 2px solid $primary;
      @include border-radius(0);
    }
  }
}

.img-placeholder {
  width: 100%;
  height: 200px;
  background: rgba(22, 41, 58, 0.05);
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 0.75rem;
  label {
    color: $formlabel;
    font: $p;
    font-weight: 300;
  }
  &.has-icon {
    background: transparent;
    border: 0;
    position: relative;
    svg {
      font-size: $iconsize;
      position: absolute;
      left: 10px;
      bottom: 18px;
      color: $coolGray-50;
    }
    .form-control {
      padding-left: 36px;
      border: 1px solid $formborder;
    }
  }
}

.form-control {
  color: $black;
  font: $p;
  background: $white;
  border: 1px solid $formborder;
  outline: none;
  height: 38px;
  @include border-radius($rounded-8);
  &.form-lg {
    height: 54px;
  }
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    border-color: $coolGray-60 !important;
  }
  &:focus {
    border-color: $blue-70 !important;
  }
  &::-webkit-input-placeholder {
    color: $formplaceholder;
    font-weight: 300;
  }
  &:-ms-input-placeholder {
    color: $formplaceholder;
    font-weight: 300;
  }
  &::placeholder {
    color: $formplaceholder;
    font-weight: 300;
  }
  &.ng-invalid {
    border-color: #dc3545;
  }
}

.content-card {
  background: $white;
  border: $border-1 $contentBox-border;
  @include border-radius($rounded-24);
  @include boxshadow;
}
.content-wrapper {
  .content-title {
    h1,
    h2 {
      color: $black;
      font-size: 1.25rem;
      font-weight: 800;
      .capitalize {
        text-transform: capitalize;
      }
    }
    .d-flex {
      display: flex !important;
      justify-content: space-between !important;
      flex-direction: column !important;
      align-items: center !important;
      row-gap: 10px;
      @include breakpoint(tablet-wide) {
        display: flex !important;
        justify-content: space-between !important;
        flex-direction: row !important;
        align-items: center !important;
        row-gap: 0;
      }
    }
  }
  .empty-403 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    h2 {
      color: $black;
      font-size: 3rem;
      font-weight: 800;
    }
  }
  &.interior-wrapper {
    .content-title {
      padding: 20px 20px 10px;
      .btn-group {
        gap: 15px;
        flex-direction: column;
        @include breakpoint(mobile-landscape) {
          flex-direction: row;
        }
        .btn {
          &.btn-outline {
            &.btn-outline-success {
              border: 1px solid #28a745;
              color: #28a745;
            }
          }
        }
      }
    }
    .content-nav-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 15px 10px 0;
      align-items: flex-start;
      &.content-nav-wrapper-button {
        justify-content: flex-end;
        padding-bottom: 20px;
      }
      .nav {
        li {
          padding: 0 2px;
          display: flex;

          a {
            background: $tabBG-default;
            color: $tabBG-active;
            font: $tabfont-default;
            padding: 10px 16px;
            background: $gray-20;
            border: none;
            @include border-radius($rounded-tab);
            &.bg-danger {
              background-color: $light !important;
              &.active,
              &:hover {
                background: $white;
                color: $primary;
              }
            }
            &.active {
              background: $tabBG-active;
              color: $white;
              font: $tabfont-active;
              &:hover {
                background: $tabBG-active;
              }
            }
            &:hover {
              background: $tabBG-hover;
            }
          }
        }
      }
      .content-btn {
        display: inline-flex;
        gap: 10px;
        .btn-outline {
          border: 1px solid #334e56;
          color: #324e57;
          font-size: 0.938rem;
        }
      }
    }
    .content-grid-wrapper {
      border-top: 2px solid #ededed;
      margin-top: -2.5px;
      .content-tab {
        border-top: 1px solid $grayvega;
        .filter {
          padding: 20px 20px;
          display: flex;
          border-bottom: 2px solid #d0d3d6;
          justify-content: flex-end;
          align-items: center;
          gap: 30px;
          &.filter.filter-title {
            justify-content: space-between;
          }
        }
      }
    }
  }
}

img[src*="preloader.gif"] {
  max-height: 120px;
  margin: 50px;
}

select:required:invalid,
select:required.ng-invalid {
  color: #bfc3c3;
}

select {
  &.form-control {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: 98% 50%;
    -webkit-appearance: none;
    appearance: none;
    &:disabled {
      background-color: #f8f8f8;
      color: $formplaceholder;
    }
  }
  &:required {
    &:invalid {
      color: #bfc3c3;
    }
  }
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: initial;
}

option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

label {
  color: $formlabel;
  font: $p;
  font-weight: 300;
}

.nav-pillmain {
  .nav-item {
    .nav-link {
      margin: 0 4px 4px 0;
      background: $gray-20;
      font: $tabfont-default;
      color: $tabBG-active;
      @include border-radius($rounded-full);
      &.active {
        font: $tabfont-active;
        background: $tabBG-active;
        color: $white;
      }
    }
  }
}

.form-wrap {
  margin-bottom: 20px;
  .form-horizontal {
    margin-bottom: 10px;
    .action-delete {
      padding: 32px 0 0;
    }
    .custom-checkbox {
      margin-top: 10px;
    }
  }
  > .btn {
    margin: 10px 0 0;
  }
}
.input-group-text {
  border-radius: $rounded-8 !important;
  margin-right: 4px;
}

.cj-tooltip {
  position: relative;
  &:hover {
    .span-cjtooltip {
      display: block;
    }
  }
  .span-cjtooltip {
    display: none;
    background: rgba($black, 0.9);
    position: absolute;
    bottom: -26px;
    font: $small;
    color: $white;
    padding: 2px 6px;
    @include border-radius($rounded-full);
    @include transition-effect;
    &:before {
      top: -5px;
      left: calc(50% - 5px);
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid rgba($black, 0.9);
    }
  }
}
.tooltip-enabled  {
  > span {
    position: relative;
    &:hover {
      .tooltips {
        display: block;
      }
    }
    .tooltips {
      display: none;
      color: #fff;
      background: #000;
      text-align: center;
      border-radius: 6px;
      position: fixed;
      z-index: 99999;
      bottom: 9px;
      margin-left: -11px;
      padding: 5px 10px;
      line-height: 1.2em;
    }
  }
}